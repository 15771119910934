import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerIcon } from "../../../../../components/icons";
import type { DataSource, DataSourceCodeConfig } from "../../../../../models/dataSource";

type ICheckCodeSnippetConnectionProps = {
  dataSource?: Partial<DataSource>;
};

const CheckCodeSnippetConnection: FunctionComponent<ICheckCodeSnippetConnectionProps> = ({ dataSource }) => {
  const { t } = useTranslation();

  const configuration = dataSource?.configuration as DataSourceCodeConfig | undefined;

  return (
    <div className="m-auto align-middle">
      <SpinnerIcon loading className="inline h-4" />
      <p className="ml-2 mr-1 inline text-sm font-normal leading-5 text-gray-600">
        {t("data_source_edit:code.check_installation.checking")}
      </p>
      <p className="inline text-sm font-semibold leading-5 text-gray-600">
        <a href={configuration?.url} target="_blank" rel="noreferrer" className="text-sm font-semibold text-gray-600">
          {configuration?.url}
        </a>
        ...
      </p>
    </div>
  );
};

export default CheckCodeSnippetConnection;
