import { SpinnerIcon } from "../icons/";

const Loading = () => (
  <div className="fixed left-0 top-0 z-50 block h-full w-full bg-white-100 opacity-75">
    <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
      <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
    </span>
  </div>
);

export default Loading;
