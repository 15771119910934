import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { Button } from "../../buttons";

type IErrorStatus = {
  error?: FetchBaseQueryError | SerializedError | string;
  handleTryAgain?: () => void;
};

function isString(error: FetchBaseQueryError | SerializedError | string): error is string {
  return typeof error === "string" || error instanceof String;
}

function isFetchError(error: FetchBaseQueryError | SerializedError): error is FetchBaseQueryError {
  return (error as FetchBaseQueryError).data !== undefined;
}

const ErrorStatus: FunctionComponent<IErrorStatus> = ({ error, handleTryAgain }) => {
  const { t } = useTranslation("data_source_edit");

  const retrieveDisplayMessage = (error: FetchBaseQueryError | SerializedError | string): string => {
    if (isString(error)) {
      return String(error);
    }

    if (isFetchError(error)) {
      return error.data as string;
    }

    return String(error.message);
  };

  return (
    <div className="mt-1 w-full sm:col-span-2 sm:mt-0">
      <div className="flex h-52 justify-center rounded-md border-2 border-dashed border-red-100 bg-red-50 px-6 pb-6 pt-5">
        <div className="m-auto space-y-1 text-center transition duration-500">
          <div className="mb-2 flex h-7 justify-center text-center">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask
                id="mask0"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="32"
              >
                <path
                  d="M19.4752 16.0002L24.6871 10.7882C25.3267 10.1486 25.3267 9.11162 24.6871 8.47152L23.5288 7.31318C22.8892 6.6736 21.8522 6.6736 21.2121 7.31318L16.0002 12.5252L10.7882 7.31318C10.1486 6.6736 9.11162 6.6736 8.47152 7.31318L7.31318 8.47152C6.6736 9.1111 6.6736 10.1481 7.31318 10.7882L12.5252 16.0002L7.31318 21.2121C6.6736 21.8517 6.6736 22.8887 7.31318 23.5288L8.47152 24.6871C9.1111 25.3267 10.1486 25.3267 10.7882 24.6871L16.0002 19.4752L21.2121 24.6871C21.8517 25.3267 22.8892 25.3267 23.5288 24.6871L24.6871 23.5288C25.3267 22.8892 25.3267 21.8522 24.6871 21.2121L19.4752 16.0002Z"
                  fill="black"
                />
              </mask>
              <g mask="url(#mask0)">
                <rect width="38.4" height="38.4" transform="translate(-3.2002 -3.2002)" fill="#DC2626" />
              </g>
            </svg>
          </div>
          <div className="flex flex-col justify-center">
            <p className="mb-2 rounded-md text-xl font-medium  text-red-900">
              {t("file.name_and_file.file_upload_input_component.something_went_wrong")}
            </p>
            {error ? (
              <p className="mb-2 rounded-md text-sm font-medium text-red-900">{retrieveDisplayMessage(error)}</p>
            ) : null}
            <br />
            {handleTryAgain ? (
              <div className="flex justify-center">
                <Button variant="delete" className="w-24" onClick={handleTryAgain}>
                  {t("file.name_and_file.file_upload_input_component.try_again")}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorStatus;
