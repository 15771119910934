import { Fragment, type FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { toggleSidebar } from "../../../features/sidebar/sidebarSlice";
import type { NavigationItem } from "../../../models/navigation";
import { type RootState, useAppDispatch } from "../../../reducers";
import SidebarContent from "./SidebarContent";

type ISidebarProps = {
  items: NavigationItem[];
  onItemClick?: (item: NavigationItem) => void;
};

const Sidebar: FunctionComponent<ISidebarProps> = ({ items, onItemClick }) => {
  const dispatch = useAppDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);

  const onCloseSidebarClicked = () => {
    dispatch(toggleSidebar(false));
  };

  const onSidebarContentClick = (item: NavigationItem) => {
    onCloseSidebarClicked();

    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      {/* Check if need a toggle function instead of close */}
      <Dialog static as="div" className="fixed inset-0 z-40 flex md:hidden" open={show} onClose={onCloseSidebarClicked}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700 pb-4 pt-5">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="focus:ring-white-100 ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset"
                  onClick={onCloseSidebarClicked}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="text-white-100 h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <SidebarContent items={items} onItemClick={onSidebarContentClick} />
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
