import type { GetTokenSilentlyOptions } from "@auth0/auth0-react";

type Generator = (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

class PrivateAuth0Service {
  private static _instance: PrivateAuth0Service;
  private _tokenGenerator: Generator | undefined;

  private constructor() {
    // ...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  public setTokenGenerator(tokenGenerator: Generator): void {
    this._tokenGenerator = tokenGenerator;
  }

  public async getAccessTokenSilently(options?: GetTokenSilentlyOptions | undefined): Promise<string> {
    if (!this._tokenGenerator) {
      // Check if it's better to raise an exception here
      return "";
    }

    return this._tokenGenerator(options);
  }
}

export const Auth0Service = PrivateAuth0Service.Instance;
