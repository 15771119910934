import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { orderBy } from "lodash";
import { twMerge } from "tailwind-merge";

import { type DataSourceEvent, isTrackEvent } from "../../../../models/events";

export type IDataSourceEventListProps = {
  events?: DataSourceEvent[];
  onClick?: (item: DataSourceEvent) => void;
};

const DataSourceEventList: FunctionComponent<IDataSourceEventListProps> = ({ events, onClick }) => {
  const { t } = useTranslation("data_source_details");

  const getEventName = (ev: DataSourceEvent) => ev.event?.type?.toUpperCase() || "UNKNOWN";

  const getBadgeColor = (ev: DataSourceEvent) => {
    switch (ev.channel?.toLowerCase() || "") {
      case "browser": {
        return "bg-green-100 text-green-800";
      }

      case "api": {
        return "bg-purple-100 text-purple-800";
      }

      case "android":
      case "ios": {
        return "bg-blue-100 text-blue-800";
      }

      default: {
        return "bg-pink-100 text-pink-800";
      }
    }
  };

  const displayUserData = (ev: DataSourceEvent) => {
    const { event } = ev;
    if (
      !event.userId &&
      !event.anonymousId &&
      !event.traits?.email &&
      !event.traits?.first_name &&
      !event.traits?.last_name
    ) {
      return null;
    }

    const elements: string[] = [];
    event.anonymousId && elements.push(event.anonymousId + " (anonId)");
    event.userId && elements.push(event.userId + " (userId)");
    event.traits?.email && elements.push(event.traits.email);
    (event.traits?.first_name || event.traits?.last_name) &&
      elements.push(`${event.traits.first_name || ""} ${event.traits.last_name || ""}`);

    return <div className="font-base truncate pl-12 text-sm text-gray-600">{elements.join(" - ")}</div>;
  };

  return (
    <div className="bg-white-100 overflow-hidden shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {orderBy(events, (e) => parseISO(e.timestamp), "desc").map((ev) => (
          <li
            key={ev.event._id}
            className={onClick ? "cursor-pointer hover:bg-gray-50" : ""}
            onClick={() => onClick?.(ev)}
          >
            <div className="flex items-center px-4 pt-4 sm:px-6">
              <div className="flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex-1 truncate">
                  <div className="flex items-center">
                    <div className="mr-4 flex h-6 w-6 flex-wrap content-center justify-center rounded-lg">
                      <CheckCircleIcon className="h-6 w-6 text-blue-600" />
                    </div>
                    <p className="font-base w-20 truncate text-sm text-gray-600">{getEventName(ev)}</p>
                    <div className="flex flex-grow justify-between">
                      <span className="font-base text-sm text-gray-900">{isTrackEvent(ev) ? ev.name : ev.path}</span>
                      <div className="">
                        <span className="font-base text-xs text-gray-700">{t("channel")}</span>
                        <span
                          className={twMerge(
                            "mx-2 items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
                            getBadgeColor(ev)
                          )}
                        >
                          {ev.channel || t("unknown_channel")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-auto ml-4 w-40">
                <span className="text-sm text-gray-900">{format(parseISO(ev.timestamp), "Ppp")}</span>
              </div>
            </div>
            <div className="px-4 pb-4">{displayUserData(ev)}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DataSourceEventList;
