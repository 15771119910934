import { Fragment, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Popover, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";

import type { ISelectItem } from "../../../../models/select";

export enum EAudienceTypes {
  AUDIENCE = 0,
  EXTENDED_AUDIENCE = 1,
}

type IAudienceTypeSelectorProps = {
  onTypeSelected: (type: ISelectItem) => void;
};

const AudienceTypeSelector: FunctionComponent<IAudienceTypeSelectorProps> = ({ onTypeSelected }) => {
  const { t } = useTranslation("audiences");

  const types: ISelectItem[] = [
    {
      id: EAudienceTypes.AUDIENCE,
      label: t("selector.audience"),
    },
    {
      id: EAudienceTypes.EXTENDED_AUDIENCE,
      label: t("selector.extended_audience"),
    },
  ];

  return (
    <Popover className="relative max-w-max">
      {() => (
        <>
          <Popover.Button className="h-45 text-white-100 mr-6 inline-flex items-center justify-center rounded bg-green-600 px-3 py-2 text-base shadow-sm outline-none hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 disabled:opacity-40">
            <PlusIcon className="text-white-100 mr-2 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" />
            <span>{t("buttons.add_audience")}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel static className="absolute z-10 mt-1 w-auto">
              {({ close }) => (
                <div className="w-full overflow-hidden rounded-lg border shadow-lg">
                  <div className="bg-white-100 relative gap-8 py-2">
                    {types.map((t) => (
                      <button
                        key={t.id}
                        className="bg-white-100 group flex w-full px-6 py-3 hover:bg-blue-600"
                        onClick={() => {
                          onTypeSelected(t);

                          close();
                        }}
                      >
                        <span className="group-hover:text-white-100 truncate font-medium text-gray-700">{t.label}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default AudienceTypeSelector;
