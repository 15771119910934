import type React from "react";
import type { FunctionComponent } from "react";

import { concatClassNames } from "../../utils";

type ICircleButtonProps = {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

type TButtonType = ICircleButtonProps & React.ComponentProps<"button">;

const CircleButton: FunctionComponent<TButtonType> = (props) => {
  const { disabled, onClick, className, ...rest } = props;

  let style =
    "p-2 h-10 w-10 bg-white-64 hover:bg-white-80 rounded-full text-base justify-center outline-none inline-flex items-center border-none disabled:opacity-40";

  if (disabled) {
    style = concatClassNames(style, "cursor-not-allowed");
  }

  return (
    <button
      {...rest}
      className={className ? concatClassNames(style, className) : style}
      disabled={disabled}
      onClick={onClick}
    >
      <props.icon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default CircleButton;
