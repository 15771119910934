import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { WorkspaceSelector } from "../../../features/workspaces/components/";
import type { NavigationItem } from "../../../models/navigation";
import type { RootState } from "../../../reducers";
import { useGetWorkspacesQuery } from "../../../services/endpoints/workspaces";
import RouteConstants from "../../router/RouteConstants";
import { SidebarLink } from "./";

type ISidebarContentProps = {
  items: NavigationItem[];
  onItemClick?: (item: NavigationItem) => void;
};

const SidebarContent: FunctionComponent<ISidebarContentProps> = ({ items, onItemClick }) => {
  const workspace = useSelector((state: RootState) => state.workspaces?.currentWorkspace);

  const { data } = useGetWorkspacesQuery({
    limit: 50,
    offset: 0,
  });

  const onLinkClicked = (item: NavigationItem) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  const workspaces = data?.items || (workspace ? [workspace] : []);

  return (
    <>
      <div className="flex flex-shrink-0 items-center justify-between px-4">
        <img className="h-10 w-auto" src="/assets/sidebar/logo.svg" alt="logo" />
        {/* <span className="text-base font-medium text-white-100">BETA</span> */}
      </div>
      <div className="mt-5 flex h-0 flex-1 flex-col">
        <nav className="space-y-1 px-2">
          {items.map(
            (item) =>
              (workspace || item.to === RouteConstants.home) && (
                <SidebarLink key={item.name} item={item} onClick={onLinkClicked} />
              )
          )}
        </nav>
        {workspaces.length > 0 ? (
          <div className="m-2 mt-auto">
            <WorkspaceSelector workspaces={workspaces} />
          </div>
        ) : (
          <div className="m-2 mt-auto" />
        )}

        <div className="-mb-2 flex justify-end space-x-6 px-3 text-sm text-gray-50 md:order-2">
          <span className="px-1 text-gray-400">{import.meta.env.AUR_VERSION_STRING}</span>
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
