import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form, Formik, type FormikProps } from "formik";

import { Select } from "../../../../../components/selects";
import type { TFileData } from "../../../../../models/fileUpload";
import type { GroupedTraits } from "../../../../../models/traits";
import type { RootState } from "../../../../../reducers";
import { generateSlug } from "../../../../../utils";
import { useColumnMapInitialValues } from "../../../hooks/useColumnMapInitialValues";
import { type IColumnMapSelect, type TColumnItem, TRAITS } from "../../../types";
import { TraitInput } from "./input";
import DataTypeInput from "./input/DataTypeInput";

type IFileDataSourceColumnMap = {
  columnMapFormRef: React.RefObject<FormikProps<TColumnItem[]>>;
  groupedTraits?: GroupedTraits;
};

// 3 rows + header
const MAX_ROWS = 4;

const FileDataSourceColumnMap: FunctionComponent<IFileDataSourceColumnMap> = ({ columnMapFormRef, groupedTraits }) => {
  const { t } = useTranslation("data_source_edit");

  const fileData: TFileData | undefined = useSelector((state: RootState) => state.fileUploading.fileData);

  const columnMapSelect: IColumnMapSelect[] = [
    {
      id: TRAITS.MAP_TO_TRAIT,
      label: t("file.map_column.map_to_trait"),
    },
    {
      id: TRAITS.CUSTOM_TRAIT,
      label: t("file.map_column.custom_trait"),
    },
    {
      id: TRAITS.CONSENT_TRAIT,
      label: t("file.map_column.consent_trait"),
    },
    {
      id: TRAITS.IGNORE_COLUMN,
      label: t("file.map_column.ignore_column"),
    },
  ];

  const fileHeader: string[] = fileData ? [...fileData.header] : [];
  const fileContent: string[][] = fileData
    ? fileData.isDefaultHeader
      ? [...fileData.preview.slice(0, MAX_ROWS).slice(1)]
      : [...fileData.preview.slice(0, MAX_ROWS)]
    : [];

  const validateForm = (values: TColumnItem[]) => {
    const errors: Array<Record<number, string>> = [];
    values.filter((column, index) => {
      switch (column.trait.id) {
        case TRAITS.MAP_TO_TRAIT: {
          if (!column.traitValue) {
            errors[index] = t("file.map_column.validation.select_trait");
          }

          break;
        }

        case TRAITS.CUSTOM_TRAIT: {
          if (
            !column.traitValue ||
            !column.traitValue.id ||
            !column.traitValue.displayValue ||
            !column.customDataType
          ) {
            errors[index] = t("file.map_column.validation.value_cannot_be_blank");
          }

          break;
        }

        case TRAITS.CONSENT_TRAIT: {
          // In the future we need more validations
          if (!column.traitValue) {
            errors[index] = t("file.map_column.validation.value_cannot_be_blank");
          }

          break;
        }

        default: {
          break;
        }
      }
    });
    return errors;
  };

  const initialValues = useColumnMapInitialValues(fileHeader, columnMapSelect, groupedTraits);

  return (
    <div className="max-h-50vh">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validate={validateForm}
        innerRef={columnMapFormRef}
        onSubmit={async (values, { validateForm }) => validateForm(values)}
      >
        {({ values, setFieldValue, setFieldError, errors }) => (
          <Form>
            {values && fileContent
              ? values.map((columnItem, index) => (
                  <div key={columnItem.id} className="mt-3 flex rounded-lg border border-solid border-gray-200 p-5">
                    <div className="mx-2 flex flex-1 flex-col">
                      <div className="border border-solid border-blue-300 bg-blue-50 px-2 py-1 text-left text-blue-700">
                        {columnItem.header}
                      </div>
                      {fileContent.map((fileRows, i) => (
                        <div
                          key={`${fileRows[index]}_${i}`}
                          className="border border-solid border-gray-300 px-2 py-1 text-left text-gray-700"
                        >
                          {fileRows[index]}
                        </div>
                      ))}
                    </div>
                    <div className="mx-2 flex-1">
                      <Select
                        items={columnMapSelect}
                        className="-mt-1 w-full text-left"
                        value={columnItem.trait}
                        onChange={(selectItemValue) => {
                          setFieldError(String(index));
                          console.log("columnItem", columnItem, "selectItemValue", selectItemValue);
                          if (selectItemValue.id == TRAITS.CUSTOM_TRAIT) {
                            setFieldValue(String(index), {
                              ...columnItem,
                              trait: selectItemValue,
                              traitValue: {
                                id: generateSlug(columnItem.header),
                                displayValue: columnItem.header,
                              },
                            });
                          } else {
                            setFieldValue(String(index), {
                              ...columnItem,
                              trait: selectItemValue,
                              traitValue: undefined,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="mx-2 flex-1">
                      <TraitInput
                        option={columnItem.trait}
                        error={errors[index] as string}
                        value={columnItem.traitValue}
                        onChange={(value) => {
                          setFieldError(String(index));
                          setFieldValue(String(index), { ...columnItem, traitValue: value });
                        }}
                      />
                    </div>
                    <div className="mx-2 flex-1">
                      <DataTypeInput
                        option={columnItem.trait}
                        error={errors[index] as string}
                        value={columnItem.customDataType}
                        onChange={(value) => {
                          setFieldError(String(index));
                          setFieldValue(String(index), { ...columnItem, customDataType: value });
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FileDataSourceColumnMap;
