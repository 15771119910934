import { type FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { Input } from "../../../../components/inputs";
import { SimplePagination } from "../../../../components/tables/pagination";
import { useDebounce } from "../../../../hooks/useDebounce";
import type { DistributionChannel } from "../../../../models/distributionChannels";
import { DestinationTable } from "../../../destinations/components";
import { useWorkspaceAdmin } from "../../../workspaces/hooks/useWorkspace";

type IAudienceDestinationsProps = {
  isLoading: boolean;
  destinations: DistributionChannel[];
  onConnectClick: () => void;
  onDelete: (destination: DistributionChannel) => void;
};

const AudienceDestinations: FunctionComponent<IAudienceDestinationsProps> = ({
  isLoading,
  destinations,
  onConnectClick,
  onDelete,
}) => {
  const { t } = useTranslation("audience_details");

  const [pageNo, setPageNo] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [itemCount, setItemCount] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(-1);
  const pageSizeRef = useRef<number>(pageSize);

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);

  const [pagedDestinations, setPagedDestinations] = useState<DistributionChannel[]>([]);

  useEffect(() => {
    if (!destinations || !destinations.length) {
      setPageNo(0);
      setLastPage(0);
      setPagedDestinations([]);
    } else {
      const lcSearchText = debouncedSearchText.toLowerCase();
      const filteredDestinations = lcSearchText
        ? destinations.filter((item) => item.name.toLowerCase().includes(lcSearchText))
        : destinations;

      const totalItemCount = filteredDestinations.length;
      const lastPage = Math.ceil(totalItemCount / pageSize) - 1;
      setLastPage(lastPage);

      const realPageNo = Math.min(pageNo, lastPage);
      setPageNo(realPageNo);

      const start = realPageNo * pageSize;
      const end = start + pageSize;
      setPagedDestinations(filteredDestinations.slice(start, end));
      setItemCount(end <= totalItemCount ? pageSize : pageSize + totalItemCount - end);
    }

    pageSizeRef.current = pageSize;
  }, [destinations, pageNo, pageSize, debouncedSearchText]);

  const updatePageSize = (pageSize: number) => {
    if (pageSize !== pageSizeRef.current) {
      setPageNo(0);
      setPageSize(pageSize);
    }
  };

  return (
    <div className="flex-1">
      <div className="py-8">
        <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("destinations.title")}</h1>
        {isLoading ? (
          <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
            <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
          </span>
        ) : destinations.length ? (
          <>
            {destinations.length > 10 && (
              <div className="mb-2 flex justify-end">
                <div className="w-72">
                  <Input
                    icon={MagnifyingGlassIcon}
                    placeholder={t("destinations.search_placeholder")}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            <DestinationTable destinations={pagedDestinations} onDelete={onDelete} />

            {destinations.length > 10 && (
              <div>
                <SimplePagination
                  extended
                  firstPage={pageNo === 0}
                  lastPage={pageNo === lastPage}
                  nextPage={() => {
                    setPageNo(pageNo + 1);
                  }}
                  prevPage={() => {
                    setPageNo(pageNo - 1);
                  }}
                  pageNo={pageNo}
                  itemCount={itemCount}
                  pageSize={pageSize}
                  pageSizes={[10, 15]}
                  toFirstPage={() => {
                    setPageNo(0);
                  }}
                  loading={isLoading}
                  onChangePageSize={updatePageSize}
                />
              </div>
            )}
          </>
        ) : (
          <div className="bg-white-100 overflow-hidden shadow sm:rounded-md">
            <div className="flex items-center p-4 sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex items-center text-sm">
                    <p className="truncate font-medium text-blue-600">{t("destinations.empty")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Button variant="primary" disabled={!useWorkspaceAdmin()} className="mt-2" onClick={onConnectClick}>
          {t("destinations.connect")}
        </Button>
      </div>
    </div>
  );
};

export default AudienceDestinations;
