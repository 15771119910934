import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { HeartIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../components/buttons";
import type { SegmentOption } from "../../../models/extendedAudiences";
import { useGetSearchInterestsSegmentsQuery } from "../../../services/endpoints/extendedAudiences";
import { useWorkspace } from "../../workspaces/hooks";
import { InterestsDropdown } from "./dropdowns";

type IInterestsProps = {
  selectedInterests?: string[];
  onInterestsChanged?: (interests: SegmentOption[]) => void;
  readonly?: boolean;
};

type FakeItem = {
  id: string;
};

type NotDefinedSegmentOption = SegmentOption | FakeItem;

function isSegmentOption(obj: any): obj is SegmentOption {
  return obj.name !== undefined;
}

const Interests: FunctionComponent<IInterestsProps> = ({ readonly, selectedInterests, onInterestsChanged }) => {
  const { id: workspaceId } = useWorkspace();
  const { t } = useTranslation("extended_audience_edit");
  const [interests, setInterests] = useState<NotDefinedSegmentOption[]>([]);
  const { data, isLoading } = useGetSearchInterestsSegmentsQuery({ workspaceId, q: "" });

  const handleAddInterestClicked = () => {
    setInterests([...interests, { id: crypto.randomUUID() }]);
  };

  const handleRemoveInterestClicked = (index: string) => {
    setInterests(interests.filter((int) => int.id !== index));
  };

  useEffect(() => {
    if (interests.length > 0) {
      const r = interests.filter((int) => isSegmentOption(int)) as SegmentOption[];
      onInterestsChanged?.(r);
    }
  }, [interests]);

  useEffect(() => {
    if (data && selectedInterests && interests.length == 0) {
      setInterests(data?.filter((i) => selectedInterests.includes(i.id)) || []);
    }
  }, [data]);

  return (
    <div className="flex flex-col">
      {interests.map((int, index) => (
        <div key={int.id} className="mb-2 inline-flex flex-grow flex-row items-center">
          <span className="mr-3 w-28 py-2 text-right text-sm font-medium text-gray-700">
            {t(
              index > 0
                ? "extended_audience_details.add_interests_other"
                : "extended_audience_details.add_interests_one"
            )}
          </span>
          <div className="flex flex-grow items-center bg-white px-3 py-2 shadow" style={{ backgroundColor: "#fff" }}>
            <HeartIcon className="mr-5 w-5" />
            <InterestsDropdown
              readOnly={readonly}
              isLoading={isLoading}
              interests={data || []}
              pickedResult={isSegmentOption(int) ? int : undefined}
              setPickedResult={(interest) => {
                setInterests(interests.map((int, i) => (i === index ? interest : int)));
              }}
            />
            {readonly || (
              <Button
                variant="transparent"
                className="ml-1 h-8"
                onClick={() => {
                  handleRemoveInterestClicked(int.id);
                }}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              </Button>
            )}
          </div>
        </div>
      ))}
      {readonly || (
        <div className="inline-flex flex-grow flex-row items-center">
          <span className="mr-3 w-28 py-2 text-right text-sm font-medium text-gray-700">
            {t(
              interests.length > 0
                ? "extended_audience_details.add_interests_other"
                : "extended_audience_details.add_interests_one"
            )}
          </span>

          <Button
            variant="transparent"
            icon={() => <PlusIcon className="mr-3 w-5" />}
            className="mr-6 h-9 text-left text-sm"
            onClick={handleAddInterestClicked}
          >
            {t("buttons.add_interest")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Interests;
