import type React from "react";
import type { FunctionComponent } from "react";

import { SpinnerIcon } from "../../../../components/icons";
import type { AudienceSyncScheduleType } from "../../../../models/audiences";

type IAudienceSyncTypeConfigProps = {
  isFetchingSummary: boolean;
  realTimeAudienceLeft: number;
  scheduleType: AudienceSyncScheduleType;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const AudienceSyncTypeConfig: FunctionComponent<IAudienceSyncTypeConfigProps> = ({
  isFetchingSummary,
  realTimeAudienceLeft,
  scheduleType,
  handleChange,
}) => {
  const items = [
    {
      id: "realtime",
      value: "REALTIME",
      disabled: () => realTimeAudienceLeft < 1,
      checked: () => scheduleType === "REALTIME",
      className: () => `${realTimeAudienceLeft < 1 ? "opacity-50" : ""}`,
      title: (
        <>
          Realtime
          {isFetchingSummary ? (
            <SpinnerIcon loading className="ml-2 h-3 w-3" fill="rgba(21,68,155,1)" />
          ) : (
            ` (${realTimeAudienceLeft} left on your plan)`
          )}
        </>
      ),
      description: "Every update in your datasources are applied in realtime on this audience.",
    },
    {
      id: "scheduled",
      value: "SCHEDULED",
      disabled: () => realTimeAudienceLeft < 1,
      checked: () => scheduleType === "SCHEDULED",
      className: () => ``,
      title: "Scheduled",
      description: "The audience is updated periodically, based on your specifications.",
    },
    {
      id: "manual",
      value: "MANUAL",
      disabled: () => realTimeAudienceLeft < 1,
      checked: () => scheduleType === "MANUAL",
      className: () => ``,
      title: "Manual",
      description: "The audience will not make any changes until you explicit command.",
    },
  ];
  return (
    <div className="flex flex-row space-x-6 p-6">
      {items.map((item) => (
        <div key={item.id} className="w-1/3">
          <label className="flex cursor-pointer flex-row items-center">
            <input
              type="radio"
              className="mr-3 h-5 w-5 cursor-pointer"
              name="scheduleType"
              id={item.id}
              value={item.value}
              disabled={item.disabled()}
              checked={item.checked()}
              onChange={handleChange}
            />
            <span className={`flex flex-row items-center font-semibold text-black ${item.className()}`}>
              {item.title}
            </span>
          </label>
          <p className="ml-7 mt-1 text-base text-gray-500">{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default AudienceSyncTypeConfig;
