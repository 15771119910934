import { type FunctionComponent, useEffect } from "react";

import { Form, Formik, type FormikValues } from "formik";
import * as Yup from "yup";

import { Button } from "../../../components/buttons";
import { SpinnerIcon } from "../../../components/icons";
import { Input } from "../../../components/inputs";
import type { Workspace } from "../../../models/workspace";
import { useInviteWorkspaceMutation } from "../../../services/endpoints/workspaces";

type Role = "ADMIN" | "ACCOUNTING" | "READONLY";

type RoleItem = {
  id: Role;
  label: string;
};

type INewCompanyInviteRowProps = {
  onComplete: (saved: boolean) => void;
  workspace: Workspace;
};

const NewWorkspaceInviteRow: FunctionComponent<INewCompanyInviteRowProps> = ({ onComplete, workspace }) => {
  const [inviteCompanyUser, { data, isLoading, isError }] = useInviteWorkspaceMutation();

  useEffect(() => {
    if (data && !isLoading && !isError) {
      onComplete(false);
    }
  }, [data, isLoading, isError]);

  const roleItems: RoleItem[] = [
    {
      id: "ADMIN",
      label: "Admin",
    },
    {
      id: "READONLY",
      label: "Readonly",
    },
    {
      id: "ACCOUNTING",
      label: "Accounting",
    },
  ];

  const validationSchema = Yup.object({
    email: Yup.string().email("Not a valid email").required("Email is required"),
    role: Yup.string().oneOf(["ADMIN", "READONLY", "ACCOUNTING"], "Invalid role"),
  });

  const handleSaveButtonClicked = async ({ email, role }: FormikValues) => {
    inviteCompanyUser({
      workspaceId: workspace.id,
      collaborator: {
        email,
        role,
      },
    });
  };

  const handleCancelButtonClicked = () => {
    onComplete(false);
  };

  return (
    <Formik
      initialValues={{ email: "", role: roleItems[0].id }}
      validationSchema={validationSchema}
      onSubmit={handleSaveButtonClicked}
    >
      {({ values, handleChange, errors }) => (
        <tr className="bg-white-100 border-b border-gray-200">
          <td colSpan={3} className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
            <Form>
              {console.log(errors)}
              <div className="flex flex-row">
                <div className="flex w-1/3 flex-row space-x-2">
                  <label htmlFor="email" className="block py-2 text-sm font-medium text-gray-700">
                    Email:
                  </label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="john.doe@company.com"
                    value={values.email}
                    error={errors.email}
                    disabled={isLoading}
                    onChange={(e) => {
                      handleChange("email")(e.target.value);
                    }}
                  />
                </div>
                <div className="ml-3 flex w-1/3 flex-row items-start space-x-2">
                  <label htmlFor="role" className="block py-2 text-sm font-medium text-gray-700">
                    Role:
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="block rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                    disabled={isLoading}
                    onChange={(e) => {
                      handleChange("role")(e.target.value);
                    }}
                  >
                    {roleItems.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ml-auto flex flex-row justify-end">
                  {isLoading ? (
                    <span className="relative my-2 text-blue-500 opacity-75">
                      <SpinnerIcon loading className="mr-1 h-5 w-5" />
                    </span>
                  ) : null}

                  <Button
                    variant="primary"
                    type="submit"
                    className="inline-flex justify-center px-4 py-2"
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                  <Button
                    variant="light"
                    type="button"
                    className="ml-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    disabled={isLoading}
                    onClick={handleCancelButtonClicked}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </td>
        </tr>
      )}
    </Formik>
  );
};

export default NewWorkspaceInviteRow;
