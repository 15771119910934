import { forwardRef, type Ref } from "react";

import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

import { Button } from "../buttons";

type IDefaultModalProps = {
  closeModal?: () => void;
};

function DefaultModal({ closeModal }: IDefaultModalProps, ref: Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      className="bg-white-100 inline-block transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
    >
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
          Payment successful
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
          </p>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <Button className="inline-flex w-full justify-center" onClick={closeModal}>
          Close modal
        </Button>
      </div>
    </div>
  );
}

export default forwardRef(DefaultModal);
