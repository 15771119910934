import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";

import type { Step } from "../../components/steppers";
import type { ExtendedAudienceEdit, ExtendedAudienceLicenseEdit } from "../../models/extendedAudiences";
import { type RootState, useAppDispatch } from "../../reducers";
import { ExtendedAudienceForm } from "./components";
import ExtendedAudienceLicenseForm from "./components/ExtendedAudienceLicenseForm";
import ExtendedAudienceSummary from "./components/ExtendedAudienceSummary";
import { EExtendedAudienceSteps } from "./ExtendedAudienceEdit";
import { stashExtendedAudience, stashExtendedAudienceLicense } from "./extendedAudienceEditSlice";

export type ExtendedAudienceFormModel = Partial<ExtendedAudienceEdit>;
export type ExtendedAudienceLicenseFormModel = Partial<ExtendedAudienceLicenseEdit>;

type IAudienceContentProps = {
  createLicenseFormRef: React.RefObject<FormikProps<ExtendedAudienceLicenseFormModel>>;
  createFormRef: React.RefObject<FormikProps<ExtendedAudienceFormModel>>;
  step?: Step;
};

const ExtendedAudienceStepContainer: FunctionComponent<IAudienceContentProps> = ({
  step,
  createFormRef,
  createLicenseFormRef,
}) => {
  const { t } = useTranslation("extended_audience_edit");
  const dispatch = useAppDispatch();

  const audience = useSelector((state: RootState) => state.extendedAudienceEdit.extendedAudience);
  const selectedOptions = useSelector((state: RootState) => state.extendedAudienceEdit.selectedOptions);
  const license = useSelector((state: RootState) => state.extendedAudienceEdit.license);

  const handleSubmit = async (form: ExtendedAudienceFormModel) => {
    if (form.name) {
      dispatch(stashExtendedAudience({ ...form }));
    }
  };

  const handleLicenseSubmit = async (form: ExtendedAudienceLicenseFormModel) => {
    if (form.product) {
      console.log("stash license");
      dispatch(stashExtendedAudienceLicense({ ...form }));
    }
  };

  console.log(selectedOptions);
  const stepTemplate = () => {
    switch (step?.id) {
      case EExtendedAudienceSteps.NAME_AND_EXPRESSION: {
        return <ExtendedAudienceForm formRef={createFormRef} extendedAudience={audience} onSubmit={handleSubmit} />;
      }

      case EExtendedAudienceSteps.LICENSE: {
        return (
          <ExtendedAudienceLicenseForm
            formRef={createLicenseFormRef}
            license={license}
            onSubmit={handleLicenseSubmit}
          />
        );
      }

      case EExtendedAudienceSteps.CONFIRMATION: {
        return <ExtendedAudienceSummary />;
      }

      default: {
        return null;
      }
    }
  };

  return <div className="mt-6 h-auto rounded-lg">{stepTemplate()}</div>;
};

export default ExtendedAudienceStepContainer;
