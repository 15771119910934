import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { DataSourceCard, DataSourceTypePanel } from "..";
import { SpinnerIcon } from "../../../../components/icons";
import { Input } from "../../../../components/inputs";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useDelayedBoolean } from "../../../../hooks/useDelayedBoolean";
import { useSlideOverContext } from "../../../../hooks/useSlideOverContext";
import { type DataSourceType, EDataSourceCategory } from "../../../../models/dataSource";
import { useGetDataSourceTypesQuery } from "../../../../services/dataSources";

type IDataSourceCatalogProps = {
  onConfirmClicked: (dataSourceType: DataSourceType) => void;
};

const DataSourceCatalog: FunctionComponent<IDataSourceCatalogProps> = ({ onConfirmClicked }) => {
  const { t } = useTranslation();
  const { openSlideOver } = useSlideOverContext();

  const { isFetching, data: sources } = useGetDataSourceTypesQuery();
  // Get requested data sources
  // const { data: requestedDataSources } = useGetRequestedDataSourceTypesQuery();

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);

  const availableSources = sources?.filter(
    (source) =>
      source.category === EDataSourceCategory.CODE ||
      source.category === EDataSourceCategory.FILE ||
      source.category === EDataSourceCategory.CRM
  );

  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);

  const handleClick = (dataSourceType: DataSourceType) => {
    openSlideOver({
      renderContent: DataSourceTypePanel,
      renderContentProps: { dataSourceType, onAddDataSourceClicked: onConfirmClicked },
    });
  };

  if (isFetchingDelayed) {
    return (
      <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
        <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
      </span>
    );
  }

  return (
    <div>
      <div className="mb-8 flex w-full">
        <div className="m-auto flex-1">
          <h1 className="text-2xl font-semibold leading-7 text-gray-900">
            {t("data_sources:tabs.catalog.source_catalog")}
          </h1>
        </div>
        <div className="flex flex-1 justify-end">
          <div className="w-72">
            <Input
              icon={MagnifyingGlassIcon}
              placeholder={t("data_sources:tabs.catalog.search_sources")}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <h1 className="text-lg font-semibold leading-7 text-gray-700">
          {t("data_sources:tabs.catalog.available_sources")}
        </h1>
        <div className="mb-12 grid grid-cols-1 gap-8 pt-4 md:grid-cols-3 md:gap-8">
          {availableSources
            ?.filter((source) =>
              debouncedSearchText ? source.name.toLowerCase().includes(debouncedSearchText.toLowerCase()) : true
            )
            .map((source) => (
              <DataSourceCard
                key={source.name}
                dataSourceType={source}
                onClick={() => {
                  handleClick(source);
                }}
              />
            ))}
        </div>
      </div>
      {/* REQUESTED DATA SOURCES */}
      {/* <div className='mt-16'>
        <h1 className='text-lg font-semibold text-gray-700 leading-7'>{t('data_sources:tabs.catalog.requested_sources')}</h1>
        <h1 className='text-sm font-normal text-gray-600 whitespace-pre-line mt-2'>{t('data_sources:tabs.catalog.requested_sources_description')}</h1>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pt-4 mb-12 mt-4'>
          {requestedDataSources?.map((source) => (
            <DataSourceCard key={source.name} dataSourceType={source} />
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default DataSourceCatalog;
