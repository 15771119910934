import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";

import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as HandPointerIcon } from "../../../../assets/icons/hand-pointer.svg";
import { SearchDropDown } from "../../../../components/dropdowns";
import { Input } from "../../../../components/inputs";
import DatePickerInput from "../../../../components/inputs/DatePickerInput";
import type { ExpressionCondition } from "../../../../models/audiences";
import { EventSelect } from "../selects";

type IActionConditionProps = {
  expression: ExpressionCondition;
  onChange?: (condition: ExpressionCondition) => void;
  isViewMode?: boolean;
};

const ActionCondition: FunctionComponent<IActionConditionProps> = ({ expression, isViewMode, onChange }) => {
  const { t } = useTranslation("audience_edit");

  const comparisonOp: Array<{ id: string; displayName: string }> = [
    {
      id: "AT_LEAST",
      displayName: t("rules.at_least"),
    },
    {
      id: "EXACTLY",
      displayName: t("rules.exactly"),
    },
    {
      id: "AT_MOST",
      displayName: t("rules.at_most"),
    },
    {
      id: "NEVER",
      displayName: t("rules.no_times"),
    },
  ];

  const temporalHorizonOp: Array<{ id: string; displayName: string }> = [
    {
      id: "WITHIN",
      displayName: t("rules.within"),
    },
    {
      id: "IN_BETWEEN",
      displayName: t("rules.in_between"),
    },
  ];

  const temporalHorizonUnits: Array<{ id: string; displayName: string }> = [
    {
      id: "DAY",
      displayName: t("rules.days"),
    },
    {
      id: "MONTH",
      displayName: t("rules.months"),
    },
    {
      id: "YEAR",
      displayName: t("rules.years"),
    },
  ];

  const [comparisonSearchQuery, setComparisonSearchQuery] = useState("");
  const [temporalHorizonSearchQuery, setTemporalHorizonSearchQuery] = useState("");
  const [temporalHorizonUnitsSearchQuery, setTemporalHorizonUnitsSearchQuery] = useState("");

  return (
    <div className="flex w-full items-center">
      <div className="mx-2 inline-flex items-center">
        <span className="mr-2 whitespace-nowrap text-sm">
          {expression.kind === "EVENT" ? (
            <span className="font-bold">
              <HandPointerIcon className="mr-2 inline-block h-6 w-6" /> {t("rules.have_performed")}
            </span>
          ) : (
            <span className="font-bold">
              <EyeIcon className="mr-2 inline-block h-6 w-6" /> {t("rules.have_visited")}
            </span>
          )}
        </span>
        <div className="">
          <EventSelect
            ruleId={expression.dataSourceRuleId}
            type={expression.kind === "EVENT" ? "TRACK" : "VIEW"}
            isViewMode={isViewMode}
            onChange={(ruleItemValue) => {
              onChange?.({
                ...expression,
                dataSourceRuleId: String(ruleItemValue.id),
              });
            }}
          />
        </div>
      </div>
      <div className="mx-2 inline-flex items-center">
        <div className="">
          <SearchDropDown
            openWithFocus
            items={
              comparisonSearchQuery?.trim()
                ? comparisonOp.filter((item) =>
                    item.displayName.toLowerCase().includes(comparisonSearchQuery?.trim().toLowerCase())
                  )
                : comparisonOp
            }
            item={comparisonOp.find((op) => op.id === expression.occurrences?.comparisonOp)}
            setItem={(opItemValue) => {
              onChange?.({
                ...expression,
                occurrences: {
                  ...expression.occurrences,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  comparisonOp: String(opItemValue.id),
                },
              });
            }}
            setQuery={setComparisonSearchQuery}
            loading={false}
            readOnly={isViewMode}
            className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
          >
            {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
          </SearchDropDown>
        </div>
      </div>
      {expression.occurrences?.comparisonOp !== "NEVER" && (
        <div className="mx-2 inline-flex items-center">
          <Input
            name="times"
            id="times"
            type="number"
            className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
            value={expression.occurrences?.times}
            isViewMode={isViewMode}
            onKeyPress={(event) => {
              if (!/\d/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(ev) => {
              onChange?.({
                ...expression,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                occurrences: {
                  ...expression.occurrences,
                  times: Number.parseInt(ev.target.value.replace(/\D/, "")),
                },
              });
            }}
          />
          <span className="mx-2 text-left text-sm">{t("rules.times")}</span>
          <SearchDropDown
            openWithFocus
            items={
              temporalHorizonSearchQuery?.trim()
                ? temporalHorizonOp.filter((item) =>
                    item.displayName.toLowerCase().includes(temporalHorizonSearchQuery?.trim().toLowerCase())
                  )
                : temporalHorizonOp
            }
            item={temporalHorizonOp.find((op) => op.id === expression.temporalHorizon?.type)}
            setItem={(opItemValue) => {
              onChange?.({
                ...expression,
                temporalHorizon: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  type: String(opItemValue.id),
                },
              });
            }}
            setQuery={setTemporalHorizonSearchQuery}
            loading={false}
            readOnly={isViewMode}
            className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
          >
            {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
          </SearchDropDown>
        </div>
      )}

      {expression.occurrences?.comparisonOp !== "NEVER" && expression.temporalHorizon?.type == "WITHIN" && (
        <div className="mx-2 inline-flex items-center">
          <div className="mr-2 w-20">
            <Input
              name="value"
              id="value"
              type="number"
              className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
              value={expression.temporalHorizon?.value}
              isViewMode={isViewMode}
              onKeyPress={(event) => {
                if (!/\d/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(ev) => {
                onChange?.({
                  ...expression,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  temporalHorizon: {
                    ...expression.temporalHorizon,
                    value: Number.parseInt(ev.target.value.replace(/\D/, "")),
                  },
                });
              }}
            />
          </div>
          <div className="w-full">
            <SearchDropDown
              openWithFocus
              items={
                temporalHorizonUnitsSearchQuery?.trim()
                  ? temporalHorizonUnits.filter((item) =>
                      item.displayName.toLowerCase().includes(temporalHorizonUnitsSearchQuery?.trim().toLowerCase())
                    )
                  : temporalHorizonUnits
              }
              item={temporalHorizonUnits.find((op) => op.id === expression.temporalHorizon?.unit)}
              setItem={(opItemValue) => {
                onChange?.({
                  ...expression,
                  temporalHorizon: {
                    ...expression.temporalHorizon,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    unit: String(opItemValue.id),
                  },
                });
              }}
              setQuery={setTemporalHorizonUnitsSearchQuery}
              loading={false}
              readOnly={isViewMode}
              className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
            >
              {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
            </SearchDropDown>
          </div>
        </div>
      )}
      {expression.occurrences?.comparisonOp !== "NEVER" && expression.temporalHorizon?.type == "IN_BETWEEN" && (
        <div className="mx-2 inline-flex items-center">
          <div className="mr-2 w-full">
            <DatePickerInput
              selected={expression.temporalHorizon?.from ? new Date(expression.temporalHorizon?.from) : undefined}
              dateFormat="P"
              isViewMode={isViewMode}
              onChange={(date) => {
                if (date instanceof Date) {
                  onChange?.({
                    ...expression,
                    temporalHorizon: {
                      ...expression.temporalHorizon,
                      from: format(date, "yyyy-MM-dd"),
                    },
                  });
                }
              }}
            />
          </div>
          <div className="w-full">
            <DatePickerInput
              selected={expression.temporalHorizon?.to ? new Date(expression.temporalHorizon?.to) : undefined}
              dateFormat="P"
              isViewMode={isViewMode}
              onChange={(date) => {
                if (date instanceof Date) {
                  onChange?.({
                    ...expression,
                    temporalHorizon: {
                      ...expression.temporalHorizon,
                      to: format(date, "yyyy-MM-dd"),
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionCondition;
