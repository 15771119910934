import type { FunctionComponent } from "react";

import type { DataSource } from "../../../../../../models/dataSource";
import { CommonActiveDataSource } from "../common";

type IActiveCrmDataSourceProps = {
  dataSource?: DataSource;
};

const ActiveCrmDataSource: FunctionComponent<IActiveCrmDataSourceProps> = ({ dataSource }) =>
  dataSource ? <CommonActiveDataSource dataSource={dataSource} polling={false} /> : <div />;

export default ActiveCrmDataSource;
