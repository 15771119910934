import type { FunctionComponent } from "react";

import { Button } from "../buttons";

type ISlideOverFooter = {
  confirmLabel?: string;
  cancelLabel?: string;
  closeSlideOver: () => void;
  onConfirmClicked: () => void;
};

const SlideOverFooter: FunctionComponent<ISlideOverFooter> = ({
  cancelLabel,
  confirmLabel,
  closeSlideOver,
  onConfirmClicked,
}) => (
  <div className="flex flex-shrink-0 justify-end p-4">
    <Button
      type="button"
      variant="secondary"
      className="bg-white-100 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={closeSlideOver}
    >
      {cancelLabel || "Cancel"}
    </Button>
    <Button
      type="submit"
      className="text-white-100 ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={onConfirmClicked}
    >
      {confirmLabel || "Save"}
    </Button>
  </div>
);

export default SlideOverFooter;
