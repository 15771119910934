import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { concatClassNames } from "../../../../../../utils";

type ITrackUserClickPanelProps = {
  className?: string;
};

const TrackUserClickPanel: FunctionComponent<ITrackUserClickPanelProps> = ({ className, children }) => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className={concatClassNames(className || "", "w-full rounded-lg border border-gray-200 shadow")}>
      <div className="flex rounded-lg bg-gray-50 px-6 py-4">
        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.543 14.8314H13.4071L15.5838 20.1333C15.7354 20.5009 15.5621 20.913 15.2156 21.0689L13.2988 21.9043C12.9415 22.0602 12.5408 21.882 12.3892 21.5256L10.3209 16.491L6.94215 19.9662C6.49189 20.4292 5.76172 20.0723 5.76172 19.465V2.71341C5.76172 2.07404 6.53837 1.76234 6.94211 2.2122L18.0303 13.6173C18.4775 14.0532 18.1475 14.8314 17.543 14.8314Z"
            fill="#54647A"
          />
        </svg>
        <h1 className="text-sm font-semibold text-gray-900">{t("code.track_events.track_event_on_click")}</h1>
      </div>
      {children}
    </div>
  );
};

export default TrackUserClickPanel;
