import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { DataSourceCard, DataSourceTypePanel } from ".";
import { useSlideOverContext } from "../../../hooks/useSlideOverContext";
import { type DataSourceType, EDataSourceCategory } from "../../../models/dataSource";
import { useGetDataSourceTypesQuery } from "../../../services/dataSources";
import { MainContentWrapper } from "../../layout/main";
import { Topbar } from "../../layout/topbar";

type IAddFirstDataSourceProps = {
  onConfirmClicked: (dataSourceType: DataSourceType) => void;
};

const AddFirstDataSource: FunctionComponent<IAddFirstDataSourceProps> = ({ onConfirmClicked }) => {
  const { t } = useTranslation();
  const { openSlideOver } = useSlideOverContext();

  const { isLoading, data } = useGetDataSourceTypesQuery();

  const availableSources = data?.filter(
    (data) => data.category === EDataSourceCategory.CODE || data.category === EDataSourceCategory.FILE
  );

  const mostUsedResources = availableSources?.filter((data) => data.category === EDataSourceCategory.CODE);
  const otherResources = availableSources?.filter((data) => data.category !== EDataSourceCategory.CODE);

  const handleClick = (dataSourceType: DataSourceType) => {
    openSlideOver({
      renderContent: DataSourceTypePanel,
      renderContentProps: { dataSourceType, onAddDataSourceClicked: onConfirmClicked },
    });
  };

  return (
    <>
      <Topbar title={t("data_sources:add_first_data_source.title")} />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="mb-10 mt-8 w-1/2">
            <p className="text-sm leading-5 text-gray-600">{t("data_sources:add_first_data_source.description")}</p>
          </div>
          <h1 className="text-lg font-semibold leading-7 text-gray-900">
            {t("data_sources:add_first_data_source.most_used_sources")}
          </h1>
          <div className="mb-12 grid grid-cols-1 gap-8 pt-4 md:grid-cols-3 md:gap-8">
            {mostUsedResources?.map((source) => (
              <DataSourceCard
                key={source.name}
                dataSourceType={source}
                onClick={() => {
                  handleClick(source);
                }}
              />
            ))}
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <h1 className="text-lg font-semibold leading-7 text-gray-900">
            {t("data_sources:add_first_data_source.other_resources")}
          </h1>
          <div className="mb-12 grid grid-cols-1 gap-8 pt-4 md:grid-cols-3 md:gap-8">
            {otherResources?.map((source) => (
              <DataSourceCard
                key={source.name}
                dataSourceType={source}
                onClick={() => {
                  handleClick(source);
                }}
              />
            ))}
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default AddFirstDataSource;
