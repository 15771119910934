import type React from "react";
import { type FunctionComponent, useState } from "react";

import { omit } from "lodash";
import { twMerge } from "tailwind-merge";

type IPrefixInputProps = {
  id?: string;
  type?: "text" | "number" | "email" | "password" | "file" | "submit";
  label?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  prefix?: string;
  disabled?: boolean;
  extraInfo?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  isViewMode?: boolean;
};

type TInputType = IPrefixInputProps & React.ComponentProps<"input">;

const PrefixInput: FunctionComponent<TInputType> = ({
  id,
  type = "text",
  name,
  label,
  prefix,
  className,
  onChange,
  error,
  placeholder,
  extraInfo,
  isViewMode,
  ...rest
}) => {
  const [inputFocus, setInputFocus] = useState(false);

  let inputStyle =
    "inline-flex w-full py-2 px-4 sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 bg-white-100";

  if (rest.icon) {
    inputStyle = twMerge(inputStyle, "pl-10");
  }

  if (error) {
    inputStyle = twMerge(inputStyle, "border-red-500 hover:border-red-500 focus:border-red-500 outline-none");
  }

  if (rest.disabled) {
    inputStyle = twMerge(inputStyle, "bg-gray-200 text-gray-500");
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const focusStyle = "border border-blue-600 outline-none ring-1 ring-blue-600";
  const inputClassname = className ? twMerge(inputStyle, className) : inputStyle;

  return (
    <div className="inline-flex w-full flex-col text-left">
      {label ? (
        <label htmlFor={name} className="block py-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}

      <div className={inputFocus ? twMerge(inputClassname, focusStyle) : inputClassname}>
        {prefix ? <span className="flex-none text-sm text-gray-500">{prefix}</span> : null}
        <input
          {...omit(rest, "icon")}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          className="flex-auto border-none bg-transparent p-0 text-sm leading-none focus:ring-0"
          disabled={rest.disabled || isViewMode}
          onChange={handleChange}
          onFocus={() => {
            setInputFocus(true);
          }}
          onBlur={() => {
            setInputFocus(false);
          }}
        />
      </div>
      {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : null}
      {extraInfo && !error ? <p className="mt-2 text-sm text-gray-500">{extraInfo}</p> : null}
    </div>
  );
};

export default PrefixInput;
