import type React from "react";
import { type FunctionComponent, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { SpinnerIcon } from "../../components/icons";
import Paginated from "../../components/tables/pagination/Paginated";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import type { DistributionChannel } from "../../models/distributionChannels";
import type { RootState } from "../../reducers";
import { useLazyGetDistributionChannelsQuery } from "../../services/endpoints/distributionChannels";
import { MainContentWrapper } from "../layout/main";
import { useWorkspace } from "../workspaces/hooks";
import DestinationList from "./DestinationList";
import { setPagination } from "./destinationsSlice";

type IDestinationsProps = Record<string, unknown>;

const MINIMUM_LOADING_DURATION = 400;

const Destinations: FunctionComponent<IDestinationsProps> = () => {
  const workspace = useWorkspace();

  const searchText = useSelector((state: RootState) => state.destinations.ui.searchText);

  const [getDistributionChannels, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetDistributionChannelsQuery();
  const [destinations, setDestinations] = useState<DistributionChannel[]>([]);

  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  const queryCallback = useCallback(
    async (pageNo: number, pageSize: number) =>
      getDistributionChannels({
        workspaceId: workspace.id,
        offset: pageNo * pageSize,
        limit: pageSize,
        searchText: searchText && searchText.length > 2 ? searchText : undefined,
      }),
    [searchText]
  );

  const renderContent = (pager: React.ReactNode) =>
    shouldShowLoading ? (
      <MainContentWrapper>
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
          <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
        </span>
      </MainContentWrapper>
    ) : (
      <DestinationList destinations={destinations} pager={pager} />
    );

  return (
    <Paginated
      stateSelector={(state) => state.destinations.ui.pagination}
      stateUpdater={setPagination}
      query={queryCallback}
      queryResponse={data ? data : undefined}
      setItems={setDestinations}
      querying={isFetching}
    >
      {renderContent}
    </Paginated>
  );
};

export default Destinations;
