import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";
import { first, last } from "lodash";

import { DataSourceHeader, FileDataSourceContent, FileDataSourceProgressBar } from "../..";
import { Button } from "../../../../../components/buttons";
import { EStepStatus, type Step, Stepper } from "../../../../../components/steppers";
import type { DataSourceType, Header } from "../../../../../models/dataSource";
import { EStatus } from "../../../../../models/fileUpload";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import { useSaveDataSourceMutation } from "../../../../../services/dataSources";
import { useGetTraitsQuery } from "../../../../../services/endpoints/traits";
import { setDataSourceTabs, stashFileDataSource } from "../../../../dataSources/dataSourcesSlice";
import { useWorkspace } from "../../../../workspaces/hooks";
import { EFileSteps } from "../../../constants";
import { stashSteps } from "../../../dataSourceEditSlice";
import { useDataSourceSteps } from "../../../hooks/useDataSourceSteps";
import { type TColumnItem, TRAITS } from "../../../types";
import type { FileDataSourceUploadFormModel } from "./FileDataSourceUploadForm";

type IFileDataSourceContainerProps = {
  dataSourceType: DataSourceType;
  closeModal: () => void;
};

const FileDataSourceContainer: FunctionComponent<IFileDataSourceContainerProps> = ({ dataSourceType, closeModal }) => {
  const { t } = useTranslation("data_source_edit");

  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const formRef = useRef<FormikProps<FileDataSourceUploadFormModel>>(null);
  const columnMapFormRef = useRef<FormikProps<TColumnItem[]>>(null);

  const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs);
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);
  const fileStatus = useSelector((state: RootState) => state.fileUploading.status);
  const fileInfo = useSelector((state: RootState) => state.fileUploading.fileInfo);
  const fileData = useSelector((state: RootState) => state.fileUploading.fileData);

  const [steps, handleNextStep, handlePrevStep] = useDataSourceSteps();

  const [saveDataSource, { isLoading, data }] = useSaveDataSourceMutation();
  const { data: groupedTraits } = useGetTraitsQuery({ workspaceId: workspace.id });

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCompleted = first(steps)?.status === EStepStatus.COMPLETE;

  const fileSteps: Step[] = [
    {
      id: EFileSteps.NAME_AND_FILE,
      label: t("file.steps.name_and_file"),
      status: EStepStatus.CURRENT,
    },
    {
      id: EFileSteps.HEADER,
      label: t("file.steps.header"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.DATA_TYPE,
      label: t("file.steps.data_type"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.MAP_COLUMNS,
      label: t("file.steps.map_columns"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.CONFIRMATION,
      label: t("file.steps.confirmation"),
      status: EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    dispatch(stashSteps(fileSteps));
  }, []);

  const handlePrevButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === EFileSteps.MAP_COLUMNS) {
      await columnMapFormRef.current?.submitForm();

      if (columnMapFormRef.current?.isValid && columnMapFormRef.current?.values) {
        const columnMap: TColumnItem[] = columnMapFormRef.current?.values;
        const headers: Header[] = getHeadersFromColumnItem(columnMap);

        if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
          dispatch(stashFileDataSource({ configuration: { ...fileDataSource.configuration, headers } }));
          handlePrevStep();
        }
      }

      return;
    }

    handlePrevStep();
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;
    if (currentStepId === EFileSteps.NAME_AND_FILE) {
      await formRef.current?.submitForm();

      if (formRef.current?.isValid && formRef.current?.values) {
        dispatch(stashFileDataSource({ ...formRef.current.values }));
        handleNextStep();
      }

      return;
    }

    if (
      currentStepId === EFileSteps.HEADER &&
      fileDataSource &&
      fileDataSource.configuration &&
      fileData?.isDefaultHeader !== undefined
    ) {
      dispatch(
        stashFileDataSource({
          configuration: { ...fileDataSource.configuration, isDefaultHeader: fileData.isDefaultHeader },
        })
      );
      handleNextStep();
    }

    if (currentStepId === EFileSteps.MAP_COLUMNS) {
      await columnMapFormRef.current?.submitForm();

      if (columnMapFormRef.current?.isValid && columnMapFormRef.current?.values) {
        const columnMap: TColumnItem[] = columnMapFormRef.current?.values;

        const headers: Header[] = getHeadersFromColumnItem(columnMap);

        if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
          dispatch(stashFileDataSource({ configuration: { ...fileDataSource.configuration, headers } }));
          handleNextStep();
        }
      }

      return;
    }

    if (currentStepId === EFileSteps.CONFIRMATION) {
      if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
        saveDataSource({
          workspaceId: workspace.id,
          dataSource: {
            type: dataSourceType.id,
            name: fileDataSource.name,
            configuration: {
              isDefaultHeader: fileDataSource.configuration?.isDefaultHeader,
              headers: fileDataSource.configuration?.headers,
              type: fileDataSource.configuration?.type,
              url: fileDataSource.configuration?.url || fileStatus?.data?.path,
            },
          },
        });

        closeModal();
        dispatch(setDataSourceTabs(tabs.map((t) => ({ ...t, current: t.id === "datasource.table" }))));
      }

      return;
    }

    handleNextStep();
  };

  const getHeadersFromColumnItem = (columnMap: TColumnItem[]): Header[] =>
    columnMap.map((column) => {
      if (column.trait.id !== TRAITS.IGNORE_COLUMN) {
        return {
          name: column.header,
          traitId: column.traitValue?.id || "",
          displayName: column.traitValue?.displayValue || "",
          custom: column.trait.id === TRAITS.CUSTOM_TRAIT,
          customDataType: column.customDataType,
          skip: false,
        };
      }

      return {
        name: column.header,
        skip: column.trait.id === TRAITS.IGNORE_COLUMN,
      };
    });

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <div className="flex flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <DataSourceHeader
          closeModal={handleCloseModal}
          title={t("title", { name: dataSourceType.name })}
          loading={isLoading}
        />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <Stepper steps={steps} />
          <FileDataSourceContent
            dataSourceType={dataSourceType}
            groupedTraits={groupedTraits}
            step={currentStep}
            formRef={formRef}
            columnMapFormRef={columnMapFormRef}
            goNext={handleNextButtonClicked}
            loading={isLoading}
            closeModal={closeModal}
          />
        </div>
      </div>
      <div className="flex">
        <div className="m-auto flex h-20 w-96 flex-1 flex-shrink-0 justify-start rounded-bl-lg bg-gray-50 p-4">
          {!isLastStepCurrent && (
            <FileDataSourceProgressBar
              fileUpload={
                fileStatus?.uploadingStatus === undefined
                  ? {
                      status: EStatus.NOT_SELECTED,
                      percentage: { total: 0, loaded: 0, percentageCompleted: 0 },
                    }
                  : {
                      status: fileStatus.uploadingStatus,
                      percentage: fileStatus.progress || { total: 0, loaded: 0, percentageCompleted: 0 },
                    }
              }
              filename={fileInfo?.filename || ""}
            />
          )}
        </div>
        <div className="m-auto flex h-full flex-1 flex-shrink-0 items-center justify-end rounded-br-lg bg-gray-50 p-4">
          {isFirstStepCompleted ? (
            <Button
              variant="light"
              type="button"
              className="h-10 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              disabled={isLoading}
              onClick={handlePrevButtonClicked}
            >
              {t("back")}
            </Button>
          ) : null}
          <div className={!isLastStepCurrent && fileStatus?.uploadingStatus === EStatus.UPLOADING ? "has-tooltip" : ""}>
            <span className="tooltip bg-white-100 right-2 -mt-12 w-auto origin-top-right rounded p-2 text-gray-900 shadow-xl">
              {t("file.proceed_with_background_upload")}
            </span>
            <Button
              variant={isLastStepCurrent ? "confirm" : "primary"}
              type="submit"
              className="ml-4 inline-flex h-10 justify-center px-4 py-2"
              disabled={isLastStepCurrent ? fileStatus?.uploadingStatus === EStatus.UPLOADING : null}
              loading={isLoading}
              onClick={handleNextButtonClicked}
            >
              {t(isLastStepCurrent ? "finish" : "next")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDataSourceContainer;
