import { type FunctionComponent, useState } from "react";

import { CheckIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../../components/buttons";
import { IconContainer } from "../../../../components/icons";
import type { DataSourceRequestedConfig, DataSourceType, RequestedDataSourceType } from "../../../../models/dataSource";
import { concatClassNames, isDataSourceType } from "../../../../utils";

type IDataSourceTypeCardHeaderProps = {
  dataSourceType: DataSourceType | RequestedDataSourceType;
};

const DataSourceTypeCardHeader: FunctionComponent<IDataSourceTypeCardHeaderProps> = ({ dataSourceType }) => {
  const [isUpvoted, setUpvoted] = useState(false);

  // Switch base on data source type category here!
  const configuration = dataSourceType?.configuration as DataSourceRequestedConfig | undefined;

  const handleUpvote = () => {
    // Increment or decrement vote
    // temp useState
    setUpvoted(!isUpvoted);
  };

  return (
    <div className="bg-white-100 flex space-x-3 rounded-lg px-4 py-5 shadow-md sm:px-6">
      <div className="flex flex-1">
        <div className="mr-3 flex-shrink-0">
          <div className="flex h-14 w-14 flex-wrap content-center justify-center rounded-lg">
            <IconContainer path={dataSourceType.logoUrl} className="h-12" />
          </div>
        </div>
        <div className="flex flex-wrap content-center">
          <div className="flex-col">
            <p className="text-base font-medium text-gray-900">{dataSourceType.name}</p>
          </div>
        </div>
      </div>
      {isDataSourceType(dataSourceType) ? null : (
        <div className="m-auto flex flex-1 justify-end">
          <Button
            variant="light"
            icon={isUpvoted ? CheckIcon : ChevronUpIcon}
            className={concatClassNames(
              "h-10 rounded-md border-2 py-4 shadow-sm",
              isUpvoted ? "border-green-100 text-green-700" : "border-gray-300"
            )}
            onClick={handleUpvote}
          >
            {configuration?.upvotes}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DataSourceTypeCardHeader;
