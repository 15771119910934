import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../../../components/buttons";
import type { Company } from "../../../../models/company";
import { type RootState, useAppDispatch } from "../../../../reducers";
import { useCreateCompanyMutation } from "../../../../services/endpoints/companies";
import { setCompany } from "../../../userInfo/userInfoSlice";

type CompanyCreateProps = {
  complete?: () => void;
};

const CompanyCreate: FunctionComponent<CompanyCreateProps> = ({ complete }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("company");
  const { user } = useAuth0();

  const company = useSelector((r: RootState) => r.userInfo.company);
  const [createCompany, { data: createdCompany, isLoading: isCreateCompanyLoading }] = useCreateCompanyMutation();

  const [alreadyCreated, setAlreadyCreated] = useState(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyMainDomain, setCompanyMainDomain] = useState<string>("");
  const [hasOneDomain, setHasOneDomain] = useState<boolean>(true);
  const [otherDomains, setOtherDomains] = useState<string[]>([""]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [domainErrorMap, setDomainErrorMap] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (company) {
      setAlreadyCreated(true);
      setCompanyName(company.name);
      setCompanyMainDomain(company.domains[0]);
      setHasOneDomain(company.domains.length === 1);
      setOtherDomains(company.domains.slice(1));
    }
  }, [company]);

  useEffect(() => {
    console.log("createdCompany", createdCompany);
    if (createdCompany) {
      console.log("dispatch");
      dispatch(setCompany(createdCompany));
    }
  }, [createdCompany]);

  useEffect(() => {
    if (user) {
      const mainDomain = user?.email?.split("@")[1];
      if (mainDomain) {
        setCompanyMainDomain(mainDomain);
      }
    }
  }, [user]);

  const domainValidateRegex = /^[\dA-Za-z][\dA-Za-z-]{1,61}[\dA-Za-z](?:\.[A-Za-z]{2,})+$/;
  const create = () => {
    if (isCreateCompanyLoading) {
      return;
    }

    const domainErrorMap: Record<number, boolean> = {};
    let hasIncorrectDomain = false;
    for (const [i, domain] of otherDomains.entries()) {
      if (domain) {
        const hasError = !domainValidateRegex.test(domain);
        domainErrorMap[i] = hasError;
        hasIncorrectDomain = hasIncorrectDomain || hasError;
      }
    }

    setDomainErrorMap(domainErrorMap);

    if (hasIncorrectDomain) {
      setErrorMessage(t("creation.check_your_domains"));
      return;
    }

    setErrorMessage("");

    const company: Partial<Company> = {
      name: companyName,
      domains: hasOneDomain ? [companyMainDomain] : [companyMainDomain, ...otherDomains.filter(Boolean)],
    };
    // Return console.log(company);
    createCompany({ company });
  };

  return (
    <div className="bg-wave flex h-full w-full items-center justify-center bg-gray-100">
      <div className="bg-white-100 flex flex-col items-center rounded-lg p-8 shadow-md" style={{ width: "400px" }}>
        <h3 className="mb-5 text-xl font-semibold">Create the company</h3>
        <div className="image-area mb-6 flex h-16 w-16 items-center justify-center rounded-full bg-blue-500 text-lg">
          {companyName ? companyName[0].toUpperCase() : "C"}
        </div>
        {errorMessage ? (
          <div className="text-white-100 mb-3 w-full rounded border-red-300 bg-red-600 p-2">{errorMessage}</div>
        ) : null}
        <form className="w-full">
          <div className="mb-4">
            <label className="mb-1 block font-medium">{t("creation.company_name")}</label>
            <input
              className="w-full rounded-md border-gray-300 shadow-sm"
              type="text"
              value={companyName}
              disabled={alreadyCreated}
              readOnly={isCreateCompanyLoading || alreadyCreated}
              onChange={(event) => {
                setCompanyName(event.target.value);
              }}
            />
          </div>
          <div className="mb-4">
            <label className="mb-1 block font-medium">{t("creation.domain")}</label>
            <input
              disabled
              className="w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
              type="text"
              value={companyMainDomain}
            />
          </div>
          <div className="mb-6">
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={hasOneDomain}
                disabled={alreadyCreated}
                readOnly={alreadyCreated}
                onChange={(event) => {
                  !alreadyCreated && !isCreateCompanyLoading && setHasOneDomain(event.target.checked);
                }}
              />
              {t("creation.my_company_has_only_one_domain")}
            </label>
          </div>
          {!hasOneDomain &&
            otherDomains.map((domain, index) => (
              <div key={index} className="mb-3">
                <input
                  className={`w-full rounded-md shadow-sm ${
                    domainErrorMap[index] ? "border-red-300" : "border-gray-300"
                  }`}
                  type="text"
                  placeholder="other-domain.com"
                  readOnly={isCreateCompanyLoading || alreadyCreated}
                  value={domain}
                  onChange={(event) => {
                    otherDomains[index] = event.target.value;
                    if (otherDomains[otherDomains.length - 1]) {
                      // Last element not empty
                      otherDomains.push("");
                    } else {
                      // Last element empty
                      let i = otherDomains.length - 1;
                      while (i > 0 && !otherDomains[i - 1]) {
                        otherDomains.pop();
                        i--;
                      }
                    }

                    setOtherDomains([...otherDomains]);
                  }}
                />
              </div>
            ))}

          {alreadyCreated ? (
            <Button
              className="w-full"
              type="button"
              loading={isCreateCompanyLoading}
              onClick={() => {
                complete && complete();
              }}
            >
              {t("creation.btn_continue")}
            </Button>
          ) : (
            <Button
              className="w-full"
              type="button"
              disabled={!companyName}
              loading={isCreateCompanyLoading}
              onClick={() => {
                create();
              }}
            >
              {t("creation.btn_continue")}
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CompanyCreate;
