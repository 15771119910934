import type React from "react";
import { type FunctionComponent, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { SpinnerIcon } from "../../../../components/icons";
import Paginated from "../../../../components/tables/pagination/Paginated";
import { useDelayedBoolean } from "../../../../hooks/useDelayedBoolean";
import type { Audience } from "../../../../models/audiences";
import type { RootState } from "../../../../reducers";
import { useLazyGetAudiencesQuery } from "../../../../services/endpoints/audiences";
import { MainContentWrapper } from "../../../layout/main";
import { useWorkspace } from "../../../workspaces/hooks";
import { setRegularPagination, setRegularSearchText } from "../../audiencesSlice";
import { AudienceList } from "../index";
import EmptyAudiences from "./EmptyAudiences";

const MINIMUM_LOADING_DURATION = 400;

type IAudienceRegularListTabProps = Record<string, unknown>;

const AudienceRegularListTab: FunctionComponent<IAudienceRegularListTabProps> = () => {
  const workspace = useWorkspace();

  const searchText = useSelector((state: RootState) => state.audiences.ui.regular.searchText);

  const [getAudiences, { data, isFetching, isLoading, isUninitialized }] = useLazyGetAudiencesQuery();
  const [audiences, setAudiences] = useState<Audience[]>([]);

  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  const queryCallback = useCallback(
    async (pageNo: number, pageSize: number) =>
      getAudiences({
        workspaceId: workspace.id,
        offset: pageNo * pageSize,
        limit: pageSize,
        searchText: searchText && searchText.length > 2 ? searchText : undefined,
      }),
    [searchText]
  );

  const renderContent = (pager: React.ReactNode) =>
    shouldShowLoading ? (
      <MainContentWrapper>
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
          <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
        </span>
      </MainContentWrapper>
    ) : isFetching || data?.items?.length || Boolean(searchText) ? (
      <AudienceList
        showSync
        audiences={audiences}
        searchStateSelector={(state: RootState) => state.audiences.ui.regular.searchText}
        searchStateUpdater={setRegularSearchText}
        pager={pager}
      />
    ) : (
      <EmptyAudiences />
    );

  return (
    <Paginated
      stateSelector={(state) => state.audiences.ui.regular.pagination}
      stateUpdater={setRegularPagination}
      query={queryCallback}
      queryResponse={data ? data.items : undefined}
      setItems={setAudiences}
      querying={isFetching}
    >
      {renderContent}
    </Paginated>
  );
};

export default AudienceRegularListTab;
