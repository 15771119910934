import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { ErrorStatus } from "../../../../../components/inputs";
import { EStatus } from "../../../../../models/fileUpload";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import { updateBackgroundStatus } from "../../../../fileUploading/fileUploadingSlice";
import { useWorkspace } from "../../../../workspaces/hooks";
import { ConfigurationCompleted, UploadingOngoing } from "./FileDataSourceConfigurationStatus";

type IFileDataSourceConfigurationCompleted = {
  closeModal: () => void;
};

const FileDataSourceConfigurationCompleted: FunctionComponent<IFileDataSourceConfigurationCompleted> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();

  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);
  const fileUploadStatus = useSelector((state: RootState) => state.fileUploading.status);
  const fileUploadData = useSelector((state: RootState) => state.fileUploading.fileInfo);
  const workspace = useWorkspace();

  const handleContinueInBackground = () => {
    dispatch(updateBackgroundStatus(workspace.id));
    closeModal();
  };

  if (fileDataSource?.configuration) {
    switch (fileUploadStatus?.uploadingStatus) {
      case EStatus.SUCCESS: {
        return <ConfigurationCompleted />;
      }

      case EStatus.UPLOADING: {
        return (
          <UploadingOngoing
            fileUpload={{
              status: fileUploadStatus.uploadingStatus,
              percentage: fileUploadStatus.progress || { total: 0, loaded: 0, percentageCompleted: 0 },
            }}
            filename={fileUploadData?.filename || ""}
            onClick={handleContinueInBackground}
          />
        );
      }

      case EStatus.ERROR: {
        return <ErrorStatus error={fileUploadStatus.error} />;
      }

      default: {
        return null;
      }
    }
  }

  return null;
};

export default FileDataSourceConfigurationCompleted;
