import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { DestinationRow } from "../";
import { type ISimpleTableProps, SimpleTable } from "../../../../components/tables";
import type { DistributionChannel } from "../../../../models/distributionChannels";

type IDestinationTableProps = {
  destinations: DistributionChannel[];
  onDelete?: (destination: DistributionChannel) => void;
} & ISimpleTableProps;

const DestinationTable: FunctionComponent<IDestinationTableProps> = ({ destinations, onDelete, ...rest }) => {
  const { t } = useTranslation("destinations");

  const headings = [
    { label: t("table.audience_name") },
    { label: t("table.destination_name") },
    { label: t("table.resource") },
    { label: "Last Sync" },
    { label: t("table.change") },
    // { label: t('table.total_users') },
  ];

  const renderRow = (destination: DistributionChannel, index: number, list: DistributionChannel[]) => {
    const key = `${destination.audience.id}-${destination.id}-${index}`;
    const prevDestination = index > 0 ? list[index - 1] : undefined;
    return (
      <DestinationRow
        key={key}
        id={key}
        destination={destination}
        prevDestination={prevDestination}
        onDelete={onDelete}
      />
    );
  };

  return (
    <SimpleTable {...rest}>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope="col"
              className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              {heading.label}
            </th>
          ))}
          {onDelete ? (
            <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              &nbsp;
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>{destinations?.map(renderRow)}</tbody>
    </SimpleTable>
  );
};

export default DestinationTable;
