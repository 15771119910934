import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ArrowDownOnSquareIcon } from "@heroicons/react/20/solid";
import { Form, Formik } from "formik";

import { Button } from "../../../../../components/buttons";
import { Input } from "../../../../../components/inputs";
import {
  type DataSource,
  type DataSourceCodeConfig,
  EDataSourceCategory,
  type TDataSource,
} from "../../../../../models/dataSource";

type IGeneralDataSourceSettingsProps = {
  dataSource: DataSource;
  isUpdateLoading?: boolean;
  onSubmit: (form: DataSource) => void;
};

const GeneralDataSourceSettings: FunctionComponent<IGeneralDataSourceSettingsProps> = ({
  dataSource,
  onSubmit,
  isUpdateLoading = false,
}) => {
  const { t } = useTranslation("data_source_details");

  const dataSourceType = dataSource?.type.category;

  return (
    <div>
      <Formik initialValues={{ ...dataSource }} onSubmit={onSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <h1 className="text-black-100 text-lg font-semibold">{t("settings.general.general")}</h1>
            <div className="flex items-end justify-start">
              <div className="mr-4 w-1/3">
                <Input
                  id="name"
                  name="name"
                  label={t("settings.general.name")}
                  value={values.name}
                  onChange={(e) => {
                    handleChange("name")(e.target.value);
                  }}
                />
              </div>
              {dataSourceType === EDataSourceCategory.CODE && (
                <div className="mr-4 w-1/3">
                  <Input
                    disabled
                    label={t("settings.general.url")}
                    value={(values as TDataSource<DataSourceCodeConfig>).configuration.url}
                  />
                </div>
              )}

              <div className="w-1/3">
                <Button type="submit" icon={ArrowDownOnSquareIcon} loading={isUpdateLoading}>
                  {t("settings.general.save")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralDataSourceSettings;
