import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../../../../components/buttons";
import { Input } from "../../../../../../components/inputs";
import type { Audience } from "../../../../../../models/audiences";
import type { DistributionChannel } from "../../../../../../models/distributionChannels";
import type { IModalContentProps } from "../../../../../../models/modals";
import { ToastType } from "../../../../../../models/toast";
import { useAppDispatch } from "../../../../../../reducers";
import { useDisconnectDistributionChannelMutation } from "../../../../../../services/endpoints/distributionChannels";
import { showToast } from "../../../../../toasts/toastsSlice";
import { useWorkspace } from "../../../../../workspaces/hooks";

type IDisconnectDestinationModalProps = {
  audience: Audience;
  destination: DistributionChannel;
};

const DisconnectDestinationModal: FunctionComponent<IDisconnectDestinationModalProps & IModalContentProps> = ({
  audience,
  destination,
  closeModal,
}) => {
  const workspace = useWorkspace();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("audience_details");

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [disconnectDistributionChannel, { isLoading, isSuccess }] = useDisconnectDistributionChannelMutation();

  const handleDeleteClicked = () => {
    disconnectDistributionChannel({
      workspaceId: workspace.id,
      audienceId: audience.id,
      distributionChannelId: destination.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("disconnect.success_title"),
          message: t("disconnect.success_body", { destinationName: destination.name }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className="flex h-96 justify-center ">
      <div className="my-auto max-w-4xl p-5 text-left">
        <h1 className="text-black-100 mb-5 text-lg font-semibold">{t("disconnect.confirm_title")}</h1>
        <p className="text-base">
          {t("disconnect.confirm_body")} &nbsp;(<b>{destination.name}</b>)
        </p>
        <Input
          className="mb-10 mt-5"
          onChange={(e) => {
            setButtonDisabled(e.target.value !== destination.name);
          }}
        />
        <Button
          icon={TrashIcon}
          variant="delete"
          loading={isLoading}
          disabled={isButtonDisabled}
          onClick={handleDeleteClicked}
        >
          {t("disconnect.confirm_button")}
        </Button>
      </div>
    </div>
  );
};

export default DisconnectDestinationModal;
