import type { FunctionComponent } from "react";

import { type DataSource, EDataSourceCategory } from "../../../../models/dataSource";
import { CodeOverview, CrmOverview, FileOverview } from "./overview";

type IDataSourceOverviewProps = {
  dataSource?: DataSource;
};

const DataSourceOverview: FunctionComponent<IDataSourceOverviewProps> = ({ dataSource }) => {
  switch (dataSource?.type.category) {
    case EDataSourceCategory.CODE: {
      return <CodeOverview dataSource={dataSource} />;
    }

    case EDataSourceCategory.FILE: {
      return <FileOverview dataSource={dataSource} />;
    }

    case EDataSourceCategory.CRM: {
      return <CrmOverview dataSource={dataSource} />;
    }

    default: {
      return <div />;
    }
  }
};

export default DataSourceOverview;
