import type { FunctionComponent } from "react";

import { concatClassNames } from "../../../../../../utils";

type IFIleDataSourceRadioButtonProps = {
  title: string;
  description: string;
  currentValue: string;
  value: string;
  onClick: () => void;
};

const FileDataSourceRadioButton: FunctionComponent<IFIleDataSourceRadioButtonProps> = (props) => {
  const { title, description, currentValue, value, onClick } = props;

  return (
    <div
      className={concatClassNames(
        "inline-flex w-3/4 cursor-pointer rounded-sm border p-5",
        currentValue === value ? "border-blue-300 bg-blue-100" : "border-gray-300"
      )}
      onClick={onClick}
    >
      <div className="mr-3 flex-none justify-start">
        <input
          readOnly
          type="radio"
          className="form-radio cursor-pointer"
          name="radio"
          value={value}
          checked={currentValue === value}
        />
      </div>
      <div className="flex-auto">
        <span className="text-sm font-semibold text-gray-700">{title}</span>
        <br />
        <span className="text-sm text-gray-700">{description}</span>
      </div>
    </div>
  );
};

export default FileDataSourceRadioButton;
