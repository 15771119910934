import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";
import { first, isEmpty, last } from "lodash";

import { Button } from "../../components/buttons";
import { EStepStatus, type Step, Stepper } from "../../components/steppers";
import type { ExtendedAudienceId } from "../../models/extendedAudiences";
import type { IModalContentProps } from "../../models/modals";
import { ToastType } from "../../models/toast";
import { type RootState, useAppDispatch } from "../../reducers";
import {
  useCreateExtendedAudienceMutation,
  useLicenseExtendedAudienceMutation,
} from "../../services/endpoints/extendedAudiences";
import { showToast } from "../toasts/toastsSlice";
import { useWorkspace } from "../workspaces/hooks";
import { type ExtendedAudienceFormModel, ExtendedAudienceHeader } from "./components";
import type { ExtendedAudienceLicenseFormModel } from "./components/ExtendedAudienceLicenseForm";
import {
  stashExtendedAudience,
  stashExtendedAudienceLicense,
  stashExtendedAudienceOptions,
  stashSteps,
} from "./extendedAudienceEditSlice";
import ExtendedAudienceStepContainer from "./ExtendedAudienceStepContainer";
import { useExtendedAudienceSteps } from "./hooks/useExtendedAudienceSteps";

type IAudienceEditProps = { audienceId?: ExtendedAudienceId; initialStep?: EExtendedAudienceSteps };

export enum EExtendedAudienceSteps {
  NAME_AND_EXPRESSION = 1,
  LICENSE = 2,
  CONFIRMATION = 3,
}

const AudienceEdit: FunctionComponent<IAudienceEditProps & IModalContentProps> = ({
  closeModal,
  audienceId,
  initialStep,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("extended_audience_edit");

  const formRef = useRef<FormikProps<ExtendedAudienceFormModel>>(null);
  const licenseFormRef = useRef<FormikProps<ExtendedAudienceLicenseFormModel>>(null);

  const workspace = useWorkspace();
  const [steps, handleNextStep, handlePrevStep] = useExtendedAudienceSteps();

  const stashedAudience = useSelector((state: RootState) => state.extendedAudienceEdit.extendedAudience);
  const stashedLicense = useSelector((state: RootState) => state.extendedAudienceEdit.license);

  const [saveExtendedAudience, { isLoading, isSuccess: isSaveSuccess }] = useCreateExtendedAudienceMutation();
  const [licenseExtendedAudience, { isSuccess: isCreateLicenseSuccess }] = useLicenseExtendedAudienceMutation();

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCurrent = first(steps)?.status === EStepStatus.CURRENT;

  const audienceSteps: Step[] = [
    {
      id: EExtendedAudienceSteps.NAME_AND_EXPRESSION,
      label: t("steps.name"),
      status:
        initialStep == EExtendedAudienceSteps.NAME_AND_EXPRESSION || !initialStep
          ? EStepStatus.CURRENT
          : EStepStatus.UPCOMING,
    },
    {
      id: EExtendedAudienceSteps.LICENSE,
      label: t("steps.license.name"),
      status: initialStep == EExtendedAudienceSteps.LICENSE ? EStepStatus.CURRENT : EStepStatus.UPCOMING,
    },
    {
      id: EExtendedAudienceSteps.CONFIRMATION,
      label: t("steps.confirmation.name"),
      status: initialStep == EExtendedAudienceSteps.CONFIRMATION ? EStepStatus.CURRENT : EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    dispatch(stashSteps(audienceSteps));
  }, []);

  useEffect(() => {
    if (isSaveSuccess || isCreateLicenseSuccess) {
      closeModal();
    }
  }, [isSaveSuccess, isCreateLicenseSuccess]);

  // UseEffect(() => {
  //   if (createdExtendedAudience) {
  //   }
  // }, [createdExtendedAudience]);

  const handlePrevButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === EExtendedAudienceSteps.NAME_AND_EXPRESSION) {
      return;
    }

    if (initialStep && currentStepId <= initialStep) {
      return;
    }

    handlePrevStep();
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;
    if (currentStepId === EExtendedAudienceSteps.NAME_AND_EXPRESSION) {
      const audienceForm = formRef.current?.values;
      await formRef.current?.validateForm();
      if (!formRef.current?.isValid || !audienceForm) {
        return;
      }

      dispatch(stashExtendedAudience(audienceForm));
      const { genderSegmentOptions, demographicSegmentOptions, geographicSegmentOptions, interestsSegmentOptions } =
        audienceForm;
      dispatch(
        stashExtendedAudienceOptions({
          genderSegmentOptions,
          demographicSegmentOptions,
          geographicSegmentOptions,
          interestsSegmentOptions,
        })
      );
    }

    if (currentStepId === EExtendedAudienceSteps.LICENSE) {
      await licenseFormRef.current?.validateForm();
      if (!licenseFormRef.current?.isValid || !licenseFormRef.current?.values) {
        return;
      }

      dispatch(stashExtendedAudienceLicense(licenseFormRef.current?.values));

      console.log("form valid, stash license", licenseFormRef.current?.values);
      dispatch(stashExtendedAudienceLicense(licenseFormRef.current?.values));
    }

    if (currentStepId === EExtendedAudienceSteps.CONFIRMATION) {
      handleFinishButtonClicked();
      return;
    }

    handleNextStep();
  };

  const handleFinishButtonClicked = async () => {
    let id = audienceId;
    console.log(id);
    if (stashedAudience && stashedAudience.name) {
      if (
        isEmpty(stashedAudience.genderSegmentIds) &&
        isEmpty(stashedAudience.demographicSegmentIds) &&
        isEmpty(stashedAudience.geographicSegmentIds) &&
        isEmpty(stashedAudience.interestsSegmentIds)
      ) {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            title: t("validations.at_least_one_interest_required_title"),
            message: t("validations.at_least_one_interest_required_message"),
          })
        );
        return;
      }

      const audience = await saveExtendedAudience({
        workspaceId: workspace.id,
        extendedAudience: {
          name: stashedAudience.name,
          genderSegmentIds: stashedAudience.genderSegmentIds || [],
          demographicSegmentIds: stashedAudience.demographicSegmentIds || [],
          geographicSegmentIds: stashedAudience.geographicSegmentIds || [],
          interestsSegmentIds: stashedAudience.interestsSegmentIds || [],
        },
      }).unwrap();
      id = audience.id;
    }

    if (
      id &&
      stashedLicense &&
      stashedLicense.dmp &&
      stashedLicense.product &&
      stashedLicense.currency &&
      stashedLicense.seatId
    ) {
      try {
        await licenseExtendedAudience({
          workspaceId: workspace.id,
          extendedAudienceId: id,
          license: {
            dmp: stashedLicense.dmp,
            product: stashedLicense.product,
            currency: stashedLicense.currency,
            seatId: stashedLicense.seatId,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <div className="flex min-h-0 flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <ExtendedAudienceHeader closeModal={closeModal} title={initialStep ? t("license_title") : t("title")} />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <Stepper steps={steps} />
          <ExtendedAudienceStepContainer
            step={currentStep}
            createFormRef={formRef}
            createLicenseFormRef={licenseFormRef}
          />
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        <Button
          variant="light"
          disabled={isFirstStepCurrent}
          className="ml-4 inline-flex justify-center px-4 py-2"
          loading={isLoading}
          onClick={handlePrevButtonClicked}
        >
          {t("back")}
        </Button>
        <Button
          variant={isLastStepCurrent ? "confirm" : "primary"}
          type="submit"
          className="ml-4 inline-flex justify-center px-4 py-2"
          loading={isLoading}
          onClick={handleNextButtonClicked}
        >
          {t(isLastStepCurrent ? "finish" : "next")}
        </Button>
      </div>
    </div>
  );
};

export default AudienceEdit;
