import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DataSourceRows } from "..";
import { type ISimpleTableProps, SimpleTable } from "../../../../components/tables";
import type { DataSource } from "../../../../models/dataSource";

type IDataSourceTableProps = {
  dataSources?: DataSource[];
} & ISimpleTableProps;

const DataSourceTable: FunctionComponent<IDataSourceTableProps> = ({ dataSources, ...rest }) => {
  const { t } = useTranslation("data_sources");

  const headings = [
    {
      label: "",
      className: "",
    },
    {
      label: t("table.id"),
      className: "pl-2",
    },
    {
      label: t("table.data_source_name"),
      className: "",
    },
    {
      label: t("table.data_source_created_at"),
      className: "",
    },
    {
      label: t("table.data_source_status"),
      className: "",
    },
  ];

  const navigate = useNavigate();

  const handleClick = (data: DataSource) => {
    // Open datasource details
    const { id } = data;

    navigate(`/data-sources/${id}`);
  };

  return (
    <SimpleTable {...rest}>
      <colgroup>
        <col style={{ width: "50px" }} />
        <col style={{ width: "200px" }} />
        <col style={{}} />
        <col style={{ width: "150px" }} />
        <col style={{ width: "100px" }} />
      </colgroup>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope="col"
              className={`sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 ${heading.className}`}
            >
              {heading.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataSources?.map((data) => (
          <DataSourceRows
            key={data.id}
            data={data}
            onClick={(data) => {
              handleClick(data);
            }}
          />
        ))}
      </tbody>
    </SimpleTable>
  );
};

export default DataSourceTable;
