import { Route, Routes } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "../../components/loaders";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

const AppRoutes = () => {
  const { isLoading, isAuthenticated, user, error, loginWithRedirect } = useAuth0();

  if (error) {
    console.error(error);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: location.pathname + location.search + location.hash } });
    return <Loading />;
  }

  if (isAuthenticated && user !== undefined && window.DD_RUM !== undefined && window.DD_LOGS !== undefined) {
    window.DD_RUM.onReady(() => {
      window.DD_RUM.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
      });
      window.DD_RUM.startSessionReplayRecording();
    });

    window.DD_LOGS.onReady(() => {
      window.DD_LOGS.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
      });
    });
  }

  return (
    <Routes>
      <Route path="/*" element={<AuthenticatedRoutes />} />
    </Routes>
  );
};

export default AppRoutes;
