import type { TFunction } from "react-i18next";

import { isEmpty } from "lodash";

import type { Rule } from "../../../../../../../models/rules";
import {
  ESelector,
  type ITrackClickEvents,
  type ITrackWebsiteValue,
  type TTrackWebsiteError,
} from "../../../../../types";

export const mapRulesToEvents = (rules: Array<Partial<Rule>>, baseUrl: string) => {
  const events: ITrackWebsiteValue = { clickEvents: [], pageVisitEvents: [] };

  for (const rule of rules) {
    if (rule.type === "TRACK") {
      events.clickEvents.push({
        id: rule.id,
        eventName: rule.name || "",
        selectorName: rule.configuration?.selector || "",
        selectorType: rule.configuration?.element === "ID" ? ESelector.ID : ESelector.CLASS,
        identifier: crypto.randomUUID(),
      });
    }

    if (rule.type === "VIEW") {
      const path = rule.configuration?.path;
      const pagePath = path ? (path.startsWith("/") ? path.slice(1) : path) : "";

      events.pageVisitEvents.push({
        id: rule.id,
        eventName: rule.name || "",
        pagePath,
        baseUrl,
        identifier: crypto.randomUUID(),
      });
    }
  }

  return events;
};

export const mapEventsToRules = (events: ITrackWebsiteValue): Array<Partial<Rule>> => {
  const rules: Array<Partial<Rule>> = [];

  for (const event of events.clickEvents) {
    const isClassElement = event.selectorType === ESelector.CLASS;
    rules.push({
      name: event.eventName,
      type: "TRACK",
      configuration: {
        type: "CLICK",
        selector: event.selectorName,
        element: isClassElement ? "CLASS" : "ID",
      },
      ...(event.id && { id: event.id }),
    });
  }

  for (const event of events.pageVisitEvents) {
    if (!event.pagePath) {
      event.pagePath = "/";
    }

    const path = `${event.pagePath.startsWith("/") ? "" : "/"}${event.pagePath}`;

    rules.push({
      name: event.eventName,
      type: "VIEW",
      configuration: {
        path,
      },
      ...(event.id && { id: event.id }),
    });
  }

  return rules;
};

// Returns a list of ids
export const isEventNameUnique = (
  eventName: string,
  events: Array<Pick<ITrackClickEvents, "identifier" | "eventName">>
): string[] => {
  const sameEventNameList = events.filter((event) => event.eventName === eventName);

  if (sameEventNameList.length > 0) {
    return sameEventNameList.map((event) => event.identifier);
  }

  return [];
};

// Events validation
export const customValidation = (
  values: ITrackWebsiteValue,
  t: TFunction<"data_source_edit">
): TTrackWebsiteError | [] => {
  const errors: TTrackWebsiteError = { clickEvents: [], pageVisitEvents: [] };

  // CLICK EVENTS VALIDATION
  if (!isEmpty(values.clickEvents)) {
    values.clickEvents.filter((event, index) => {
      if (!event.selectorName) {
        const errorObj = errors.clickEvents[index];
        errors.clickEvents[index] = { ...errorObj, selectorName: t("validations.required") };
      } else if (/\s/.test(event.selectorName)) {
        const errorObj = errors.clickEvents[index];
        errors.clickEvents[index] = { ...errorObj, selectorName: t("validations.invalid_selector") };
      }

      if (event.eventName) {
        // Returns a list of ids
        const sameClickEventNameIdentifiers = isEventNameUnique(event.eventName, values.clickEvents);

        if (sameClickEventNameIdentifiers.length > 1) {
          sameClickEventNameIdentifiers.filter((identifier) => {
            // Get values.events index from id
            const index = values.clickEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.clickEvents[index];
            errors.clickEvents[index] = { ...errorObj, eventName: t("validations.unique") };
          });
        }
      } else {
        const errorObj = errors.clickEvents[index];
        errors.clickEvents[index] = { ...errorObj, eventName: t("validations.required") };
      }
    });
  }

  // PAGE EVENTS VALIDATION
  if (!isEmpty(values.pageVisitEvents)) {
    values.pageVisitEvents.filter((event, index) => {
      if (event.pagePath) {
        const errorObj = errors.pageVisitEvents[index];
        // Check if a valid url

        const urlRegex =
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\da-z]+([.\-][\da-z]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/gm;

        const path = `${event.baseUrl}${event.pagePath}`;

        if (!path.match(urlRegex)) {
          errors.pageVisitEvents[index] = { ...errorObj, pagePath: t("validations.not_valid_url") };
        }
      }

      if (event.eventName) {
        // Returns a list of ids
        const samePageEventNameIdentifiers = isEventNameUnique(event.eventName, values.pageVisitEvents);
        const sameClickEventNameIdentifiers = isEventNameUnique(event.eventName, values.clickEvents);

        const isEventNameDuplicate = samePageEventNameIdentifiers.length + sameClickEventNameIdentifiers.length > 1;

        if (!isEmpty(samePageEventNameIdentifiers) && isEventNameDuplicate) {
          samePageEventNameIdentifiers.filter((identifier) => {
            // Get values.events index from identifier
            const index = values.pageVisitEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.pageVisitEvents[index];
            errors.pageVisitEvents[index] = { ...errorObj, eventName: t("validations.unique") };
          });
        }

        if (!isEmpty(sameClickEventNameIdentifiers) && isEventNameDuplicate) {
          sameClickEventNameIdentifiers.filter((identifier) => {
            // Get values.events index from id
            const index = values.clickEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.clickEvents[index];
            errors.clickEvents[index] = { ...errorObj, eventName: t("validations.unique") };
          });
        }
      } else {
        const errorObj = errors.pageVisitEvents[index];
        errors.pageVisitEvents[index] = { ...errorObj, eventName: t("validations.required") };
      }
    });
  }

  return isEmpty(errors.clickEvents) && isEmpty(errors.pageVisitEvents) ? [] : errors;
};
