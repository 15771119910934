const RouteConstants = {
  defaultRoute: "/data-sources",
  home: "/",
  callbackLanding: "/cb",
  logout: "/logout",
  createWorkspace: "/create-workspace",
  createCompany: "/create-company",
  dataSources: "/data-sources",
  audiences: "/audiences",
  extendedAudiences: "/extended-audiences",
  destinations: "/destinations",
  reports: "/reports",
  componentShowcase: "/components-list",
  settings: "/settings",
  cyclrAuthRedirect: "/cyclr/auth/redirect",
} as const;

export const SidebarRoutes: string[] = [
  RouteConstants.home,
  RouteConstants.dataSources,
  RouteConstants.audiences,
  RouteConstants.extendedAudiences,
  RouteConstants.destinations,
  RouteConstants.reports,
  RouteConstants.componentShowcase,
  RouteConstants.settings,
  RouteConstants.cyclrAuthRedirect,
];

export default RouteConstants;
