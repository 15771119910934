import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon } from "@heroicons/react/20/solid";

const ConfigurationCompleted: FunctionComponent = () => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className="flex flex-col justify-center text-center">
      <span className="m-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
        <CheckIcon className="text-white-100 h-6 w-6" aria-hidden="true" />
      </span>
      <h3 className="my-3 text-lg font-medium text-gray-900">
        {t("file.confirmation.configuration_and_upload_completed")}
      </h3>
      <p className="m-auto w-2/3 text-base font-normal text-gray-900">
        {t("file.confirmation.configuration_and_upload_completed_description")}
      </p>
    </div>
  );
};

export default ConfigurationCompleted;
