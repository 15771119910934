import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { DataSource, DataSourceFileConfig, TDataSource } from "../../models/dataSource";
import type { PaginationState } from "../../models/pagination";
import type { DataSourceTopbarTab } from "../../models/tabs";

type DataSourcesState = {
  items?: DataSource[];
  edit?: {
    dataSource?: Partial<DataSource>;
    fileDataSource?: Partial<TDataSource<DataSourceFileConfig>>;
  };
  ui: {
    tabs: DataSourceTopbarTab[];
    pagination: PaginationState;
    searchText: string;
  };
};

export const initialState: DataSourcesState = {
  items: [],
  ui: {
    tabs: [
      { id: "datasource.table", current: true },
      { id: "datasource.catalog", current: false },
    ],
    pagination: {
      pageNo: 0,
      pageSize: 0,
    },
    searchText: "",
  },
};

const dataSourcesSlice = createSlice({
  name: "dataSources",
  initialState,
  reducers: {
    stashDataSource: (state, action: PayloadAction<Partial<DataSource>>) => ({
      ...state,
      edit: {
        ...state.edit,
        dataSource: { ...state.edit?.dataSource, ...action.payload },
      },
    }),
    clearStashedDataSource: (state) => ({
      ...state,
      edit: {
        ...state.edit,
        dataSource: undefined,
      },
    }),
    stashFileDataSource: (state, action: PayloadAction<Partial<TDataSource<DataSourceFileConfig>>>) => ({
      ...state,
      edit: {
        ...state.edit,
        fileDataSource: {
          ...state.edit?.fileDataSource,
          ...action.payload,
        },
      },
    }),
    clearStashedFileDataSource: (state) => ({
      ...state,
      edit: {
        ...state.edit,
        fileDataSource: undefined,
      },
    }),
    setDataSourceTabs: (state, action: PayloadAction<DataSourceTopbarTab[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        tabs: action.payload,
      },
    }),
    setPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        pagination: action.payload,
      },
    }),
    setSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        searchText: action.payload,
      },
    }),
  },
});

export const {
  stashDataSource,
  clearStashedDataSource,
  stashFileDataSource,
  clearStashedFileDataSource,
  setDataSourceTabs,
  setPagination,
  setSearchText,
} = dataSourcesSlice.actions;

export default dataSourcesSlice.reducer;
