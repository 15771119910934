import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { Rule } from "../../models/rules";

type rulesState = {
  rulesList: Array<Partial<Rule>> | [];
};

export const initialState: rulesState = {
  rulesList: [],
};

const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    stashRules: (state, action: PayloadAction<Array<Partial<Rule>>>) => ({
      ...state,
      rulesList: action.payload,
    }),
    clearStashedRules: (state) => ({
      ...state,
      rulesList: [],
    }),
  },
});

export const { stashRules, clearStashedRules } = rulesSlice.actions;

export default rulesSlice.reducer;
