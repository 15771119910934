import { type FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Loading } from "../../../components/loaders";
import type { RootState } from "../../../reducers";
import { useGetUserInfoQuery } from "../../../services/endpoints/users";
import RouteConstants from "../../router/RouteConstants";
import AdministratorInfo from "./companyCreate/AdministratorInfo";
import CompanyCreate from "./companyCreate/CompanyCreate";
import InviteCollaborators from "./companyCreate/InviteCollaborators";

type CompanyCreationProps = Record<string, unknown>;

const CompanyCreation: FunctionComponent<CompanyCreationProps> = () => {
  const { isLoading, data: userInfo } = useGetUserInfoQuery();

  const company = useSelector((r: RootState) => r.userInfo.company);
  if (userInfo?.company) {
    return <Navigate to={RouteConstants.home} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const [activeTab, setActiveTab] = useState<string>(company ? "administrativeInfo" : "create");

  let tabTemplate;
  switch (activeTab) {
    case "create": {
      tabTemplate = (
        <CompanyCreate
          complete={() => {
            setActiveTab("administrativeInfo");
          }}
        />
      );
      break;
    }

    case "administrativeInfo": {
      tabTemplate = (
        <AdministratorInfo
          back={() => {
            setActiveTab("create");
          }}
          complete={() => {
            setActiveTab("invite");
          }}
        />
      );
      break;
    }

    case "invite": {
      tabTemplate = (
        <InviteCollaborators
          back={() => {
            setActiveTab("administrativeInfo");
          }}
        />
      );
      break;
    }
  }

  return <>{tabTemplate}</>;
};

export default CompanyCreation;
