import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { PersistGate } from "redux-persist/integration/react";

import { initialState as audienceEdit } from "./features/audienceEdit/audienceEditSlice";
import { initialState as auth } from "./features/auth/authSlice";
import { initialState as bootstrap } from "./features/bootstrap/bootstrapSlice";
import { initialState as dataSourceEdit } from "./features/dataSourceEdit/dataSourceEditSlice";
import { initialState as dataSources } from "./features/dataSources/dataSourcesSlice";
import { initialState as destinations } from "./features/destinations/destinationsSlice";
import { initialState as fileUploading } from "./features/fileUploading/fileUploadingSlice";
import { AppRoutes } from "./features/router/";
import { initialState as settings } from "./features/settings/settingsSlice";
import { initialState as sidebar } from "./features/sidebar/sidebarSlice";
import { initialState as slideOver } from "./features/slideOver/slideOverSlice";
import { initialState as theme } from "./features/theme/themeSlice";
import { initialState as toasts } from "./features/toasts/toastsSlice";
import { initialState as userInfo } from "./features/userInfo/userInfoSlice";
import { initialState as userTable } from "./features/userTable/userTableSlice";
import { initialState as workspaces } from "./features/workspaces/workspacesSlice";
import "./localization";
import { FileUploadProvider, ModalProvider, SlideOverProvider, ToastProvider } from "./providers";
import { Auth0ProviderWithNavigate } from "./providers/Auth0ProviderWithNavigate";
import { configureAppStore } from "./reducers/store";

// Redux configuration and setup with initialStates
export const { store, persistor } = configureAppStore({
  auth,
  audienceEdit,
  bootstrap,
  dataSources,
  dataSourceEdit,
  destinations,
  fileUploading,
  settings,
  sidebar,
  slideOver,
  theme,
  toasts,
  userTable,
  workspaces,
  userInfo,
  audiencerateApi: undefined,
});

export type AppDispatch = typeof store.dispatch;

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <FileUploadProvider>
            <SlideOverProvider>
              <ModalProvider>
                <AppRoutes />
                <ToastProvider />
              </ModalProvider>
            </SlideOverProvider>
          </FileUploadProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
