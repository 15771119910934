import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useModalContext } from "../../../../hooks/useModalContext";
import type { ISelectItem } from "../../../../models/select";
import type { TabType } from "../../../../models/tabs";
import { type RootState, useAppDispatch } from "../../../../reducers";
import { useDeleteAudienceMutation } from "../../../../services/endpoints/audiences";
import { AudienceEdit } from "../../../audienceEdit";
import { clearStashedAudience } from "../../../audienceEdit/audienceEditSlice";
import { ExtendedAudienceEdit } from "../../../extendedAudienceEdit";
import { clearStashedExtendedAudience } from "../../../extendedAudienceEdit/extendedAudienceEditSlice";
import { MainContentWrapper } from "../../../layout/main";
import { TopbarTabs } from "../../../layout/topbar";
import { useWorkspace } from "../../../workspaces/hooks";
import { useWorkspaceAdmin } from "../../../workspaces/hooks/useWorkspace";
import { setAudienceTabs } from "../../audiencesSlice";
import AudienceTypeSelector, { EAudienceTypes } from "../dropdowns/AudienceTypeSelector";
import AudienceRegularListTab from "./AudienceRegularListTab";
import ExtendedAudienceListTab from "./extended/ExtendedAudienceListTab";

type IAudienceListTabProps = Record<string, unknown>;

const AudienceListTabs: FunctionComponent<IAudienceListTabProps> = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();
  const isWorkspaceAdmin = useWorkspaceAdmin();

  const { t } = useTranslation("audiences");
  const { openModal } = useModalContext();

  const tabs = useSelector((state: RootState) => state.audiences.ui.tabs).map((tab) => {
    switch (tab.id) {
      case "audience.regular": {
        return { ...tab, label: t("tabs.regular") };
      }

      case "audience.extended": {
        return { ...tab, label: t("tabs.extended") };
      }
    }
  });

  const breadcrumbs = [
    {
      label: t("title"),
    },
  ];

  const [deleteAudience] = useDeleteAudienceMutation();
  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const stashedAudienceRef = useRef(stashedAudience);

  useEffect(() => {
    stashedAudienceRef.current = stashedAudience;
  }, [stashedAudience]);

  const handleCreateAudience = (type: ISelectItem) => {
    if (type.id === EAudienceTypes.AUDIENCE) {
      dispatch(clearStashedAudience());

      openModal({
        renderContent: AudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
        onClose: handleModalClose,
      });
    } else {
      dispatch(clearStashedExtendedAudience());

      openModal({
        renderContent: ExtendedAudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
      });
    }
  };

  const handleTopBarRightElement = () => <AudienceTypeSelector onTypeSelected={handleCreateAudience} />;

  const handleTabChange = (selectedTab: TabType) => {
    dispatch(setAudienceTabs(tabs.map((tab) => ({ id: tab.id, current: tab.id === selectedTab.id }))));
  };

  const handleModalClose = () => {
    const createdAudience = stashedAudienceRef.current;
    stashedAudienceRef.current = undefined;

    if (createdAudience && createdAudience.id && Object.keys(createdAudience.expression || {}).length === 0) {
      deleteAudience({
        workspaceId: workspace.id,
        audienceId: createdAudience.id,
      });
    }
  };

  const renderTab = () => {
    const activeTabId = tabs.find((tab) => tab.current)?.id || "";

    switch (activeTabId) {
      case "audience.regular": {
        return <AudienceRegularListTab />;
      }

      case "audience.extended": {
        return <ExtendedAudienceListTab />;
      }

      default: {
        return <div />;
      }
    }
  };

  return (
    <>
      <TopbarTabs
        tabs={tabs}
        rightElement={isWorkspaceAdmin ? handleTopBarRightElement : undefined}
        breadcrumbs={breadcrumbs}
        onTabChange={handleTabChange}
      />
      <MainContentWrapper childClassName="h-full">
        <div className="mx-auto h-full max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="h-full py-4">
            <div className="h-full">{renderTab()}</div>
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default AudienceListTabs;
