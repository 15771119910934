import { createSlice } from "@reduxjs/toolkit";

type ThemeState = {
  className: string;
};

export const initialState: ThemeState = {
  className: "theme-light",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggle(state) {
      state.className = state.className == "theme-light" ? "theme-dark" : "theme-light";
    },
  },
});

export default themeSlice.reducer;
