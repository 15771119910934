import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TrashIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../../../../components/buttons";
import { Input } from "../../../../../../components/inputs";
import type { DataSource } from "../../../../../../models/dataSource";
import type { IModalContentProps } from "../../../../../../models/modals";
import { ToastType } from "../../../../../../models/toast";
import { useAppDispatch } from "../../../../../../reducers";
import { useDeleteDataSourceMutation } from "../../../../../../services/dataSources";
import { showToast } from "../../../../../toasts/toastsSlice";
import { useWorkspace } from "../../../../../workspaces/hooks";

type IDeleteDataSourceModalProps = {
  dataSource: DataSource | undefined;
};

const DeleteDataSourceModal: FunctionComponent<IDeleteDataSourceModalProps & IModalContentProps> = ({
  dataSource,
  closeModal,
}) => {
  const { t } = useTranslation("data_source_details");

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const workspace = useWorkspace();

  const [deleteDataSource, { isLoading, isSuccess }] = useDeleteDataSourceMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDeleteDataSourceClicked = () => {
    if (workspace.id && dataSource && dataSource.id)
      deleteDataSource({
        workspaceId: workspace.id,
        dataSourceId: dataSource?.id,
      });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      navigate("/data-sources");

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("settings.delete.datasource_eliminated"),
          message: t("settings.delete.removed_successfully", { dataSourceName: dataSource?.name }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className="flex h-96 justify-center ">
      <div className="my-auto max-w-4xl p-5 text-left">
        <h1 className="text-black-100 mb-5 text-lg font-semibold">{t("settings.delete.delete_edit_title")}</h1>
        <p className="text-base">
          {t("settings.delete.confirm_deletion")}&nbsp;(<b>{dataSource?.name}</b>)
        </p>
        <Input
          className="mb-10 mt-5"
          onChange={(e) => {
            setButtonDisabled(e.target.value !== dataSource?.name);
          }}
        />
        <Button
          icon={TrashIcon}
          variant="delete"
          loading={isLoading}
          disabled={isButtonDisabled}
          onClick={handleDeleteDataSourceClicked}
        >
          {t("settings.delete.delete_this_datasource")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteDataSourceModal;
