import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../components/buttons";
import { Input } from "../../../components/inputs";
import { useDebounce } from "../../../hooks/useDebounce";
import { useModalContext } from "../../../hooks/useModalContext";
import type { Company } from "../../../models/company";
import type { Workspace } from "../../../models/workspace";
import { useGetCompanyWorkspacesQuery } from "../../../services/endpoints/companies";
import { WorkspaceEdit } from "../../workspaceEdit";
import { WorkspacesTable } from "./";

type IWorkspacesProps = {
  company: Company;
};

const CompanyWorkspaces: FunctionComponent<IWorkspacesProps> = ({ company }) => {
  const { t } = useTranslation("settings");
  const { openModal } = useModalContext();

  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);

  const {
    data,
    isLoading: isGetLoading,
    isError: isGetError,
    error: getError,
  } = useGetCompanyWorkspacesQuery({ q: debouncedSearchText, companyId: company.id });

  const filterWorkspace = (workspace: Workspace, searchText: string): boolean =>
    workspace.name.toLowerCase().includes(searchText.toLowerCase());

  useEffect(() => {
    if (!isGetLoading && !isGetError && data) {
      setWorkspaces(data);
    }
  }, [data, isGetLoading, isGetError]);

  const handleCreateNewWorkspaceClicked = () => {
    openModal({
      renderContent: WorkspaceEdit,
      renderContentProps: {},
      dismissable: true,
      fullHeight: true,
    });
  };

  return (
    <div className="py-6">
      <div className="mb-2 flex flex-row justify-end">
        <div className="w-1/5">
          <Input
            icon={MagnifyingGlassIcon}
            placeholder={t("workspace.search")}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>

      <WorkspacesTable
        workspaces={
          debouncedSearchText ? workspaces.filter((ws) => filterWorkspace(ws, debouncedSearchText)) : workspaces
        }
        isLoading={isGetLoading}
      />

      <Button
        variant="transparent"
        icon={PlusIcon}
        className="whitespace-nowrap"
        onClick={handleCreateNewWorkspaceClicked}
      >
        {t("workspace.create")}
      </Button>
    </div>
  );
};

export default CompanyWorkspaces;
