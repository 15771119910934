import { type FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import type { PayloadAction } from "@reduxjs/toolkit";

import { useDebounce } from "../../../hooks/useDebounce";
import { type RootState, useAppDispatch } from "../../../reducers";
import { Input } from "../../inputs";

type ISearchInputProps = {
  stateSelector: (state: RootState) => string;
  stateUpdater: (searchText: string) => PayloadAction<string>;
  placeholder: string;
};

const SearchInput: FunctionComponent<ISearchInputProps> = ({ stateSelector, stateUpdater, placeholder }) => {
  const dispatch = useAppDispatch();

  const stateSearchText = useSelector(stateSelector);
  const [searchText, setSearchText] = useState<string>(stateSearchText || "");
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    dispatch(stateUpdater(debouncedSearchText));
  }, [debouncedSearchText]);

  return (
    <div className="flex justify-end">
      <div className="w-72">
        <Input
          icon={MagnifyingGlassIcon}
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
