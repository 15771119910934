import type {
  ExtendedAudience,
  ExtendedAudienceEdit,
  ExtendedAudienceEstimate,
  ExtendedAudienceId,
  ExtendedAudienceLicenseEdit,
  SegmentOption,
} from "../../models/extendedAudiences";
import type { WorkspaceId } from "../../models/workspace";
import { audiencerateApi } from "../audiencerate";

const extendedAudienceApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getExtendedAudiences: build.query<
      { items: ExtendedAudience[]; limit: number; offset: number },
      GetExtendedAudiencesArg
    >({
      query: ({ workspaceId, limit, offset, searchText: q }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences`,
        params: { limit, offset, q },
      }),
      providesTags: ["ExtendedAudiences"],
    }),
    getExtendedAudience: build.query<ExtendedAudience, GetExtendedAudienceArg>({
      query: ({ workspaceId, extendedAudienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/${extendedAudienceId}`,
      }),
    }),
    licenseExtendedAudience: build.mutation<ExtendedAudience, LicenseExtendedAudienceArg>({
      query: ({ workspaceId, extendedAudienceId, license }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/${extendedAudienceId}/license`,
        method: "POST",
        body: license,
      }),
      invalidatesTags: ["ExtendedAudiences"],
    }),
    createExtendedAudience: build.mutation<ExtendedAudienceEdit, CreateExtendedAudienceArg>({
      query: ({ workspaceId, extendedAudience }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences`,
        method: "POST",
        body: extendedAudience,
      }),
      invalidatesTags: ["ExtendedAudiences"],
    }),
    deleteExtendedAudience: build.mutation<void, GetExtendedAudienceArg>({
      query: ({ workspaceId, extendedAudienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/${extendedAudienceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExtendedAudiences"],
    }),
    getSearchGeographicSegments: build.query<SegmentOption[], GetGeographicSegmentsArg>({
      query: ({ workspaceId, q }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/segments/geographic`,
        params: { q },
      }),
    }),
    getSearchDemographicSegments: build.query<
      { demographic: SegmentOption[]; gender: SegmentOption[] },
      GetDemographicSegmentsArg
    >({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/segments/demographic`,
      }),
    }),
    getSearchInterestsSegments: build.query<SegmentOption[], GetGeographicSegmentsArg>({
      query: ({ workspaceId, q }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/segments/interest`,
        params: { q },
      }),
    }),
    getExtendedEstimate: build.query<ExtendedAudienceEstimate, GetExtendedAudienceEstimateArg>({
      query: ({ workspaceId, extendedAudienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/${extendedAudienceId}/estimate`,
      }),
    }),
    postExtendedEstimate: build.mutation<ExtendedAudienceEstimate, PostExtendedAudienceEstimateArg>({
      query: ({ workspaceId, extendedAudience }) => ({
        url: `/v1/workspaces/${workspaceId}/extended-audiences/estimate`,
        method: "POST",
        body: extendedAudience,
      }),
    }),
  }),
  overrideExisting: false,
});

type GetExtendedAudiencesArg = {
  workspaceId: string;
  limit?: number;
  offset?: number;
  searchText?: string;
};

type GetExtendedAudienceArg = {
  workspaceId: string;
  extendedAudienceId: string;
};

type GetGeographicSegmentsArg = {
  workspaceId: string;
  q?: string;
};

type GetDemographicSegmentsArg = {
  workspaceId: string;
};

type PostExtendedAudienceEstimateArg = {
  workspaceId: string;
  extendedAudience: Pick<
    ExtendedAudienceEdit,
    "genderSegmentIds" | "demographicSegmentIds" | "geographicSegmentIds" | "interestsSegmentIds"
  >;
};

type GetExtendedAudienceEstimateArg = {
  workspaceId: string;
  extendedAudienceId: string;
};

type CreateExtendedAudienceArg = {
  workspaceId: string;
  extendedAudience: Pick<
    ExtendedAudienceEdit,
    "name" | "genderSegmentIds" | "demographicSegmentIds" | "geographicSegmentIds" | "interestsSegmentIds"
  >;
};

type LicenseExtendedAudienceArg = {
  workspaceId: WorkspaceId;
  extendedAudienceId: ExtendedAudienceId;
  license: Pick<ExtendedAudienceLicenseEdit, "dmp" | "product" | "currency" | "seatId">;
};

export const {
  useLazyGetExtendedAudiencesQuery,
  useGetExtendedAudienceQuery,
  useLazyGetSearchGeographicSegmentsQuery,
  useGetSearchGeographicSegmentsQuery,
  useLazyGetSearchInterestsSegmentsQuery,
  useGetSearchInterestsSegmentsQuery,
  useGetSearchDemographicSegmentsQuery,
  useLazyGetSearchDemographicSegmentsQuery,
  useCreateExtendedAudienceMutation,
  useLicenseExtendedAudienceMutation,
  useLazyGetExtendedEstimateQuery,
  useGetExtendedEstimateQuery,
  usePostExtendedEstimateMutation,
  useDeleteExtendedAudienceMutation,
} = extendedAudienceApi;
