import { Fragment, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Popover, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as HandPointerIcon } from "../../../../assets/icons/hand-pointer.svg";
import { ReactComponent as IdBadgeIcon } from "../../../../assets/icons/id-badge.svg";
import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import type { Condition } from "../../../../models/rules";
import { useGetAudiencesQuery } from "../../../../services/endpoints/audiences";
import { useGetRulesQuery } from "../../../../services/endpoints/rules";
import { useWorkspace } from "../../../workspaces/hooks";

type IConditionSelectorProps = {
  datasourceIds?: string[];
  onConditionSelected: (condition: Condition) => void;
  isFirstCondition?: boolean;
};

const CONDITIONS: Array<{ condition: Condition; name: string; icon: any }> = [
  {
    name: "Part of an audience",
    icon: UserGroupIcon,
    condition: {
      id: 1,
      kind: "MEMBERSHIP",
    },
  },
  {
    name: "Performed an event",
    icon: HandPointerIcon,
    condition: {
      id: 2,
      kind: "EVENT",
    },
  },
  {
    name: "Viewed a page",
    icon: EyeIcon,
    condition: {
      id: 3,
      kind: "VISIT",
    },
  },
  {
    name: "Have a property",
    icon: IdBadgeIcon,
    condition: {
      id: 4,
      kind: "PROPERTY",
    },
  },
];

const ConditionSelector: FunctionComponent<IConditionSelectorProps> = ({
  onConditionSelected,
  isFirstCondition,
  datasourceIds,
}) => {
  const { t } = useTranslation("audience_edit");

  const workspace = useWorkspace();

  const { isLoading: isAudiencesLoading, data: audiences } = useGetAudiencesQuery({
    workspaceId: workspace.id,
  });

  const { isLoading: isLoadingView, data: viewRules } = useGetRulesQuery({
    workspaceId: workspace.id,
    type: "VIEW",
    datasourceIds: datasourceIds || [],
  });

  const { isLoading: isLoadingTrack, data: trackRules } = useGetRulesQuery({
    workspaceId: workspace.id,
    type: "TRACK",
    datasourceIds: datasourceIds || [],
  });

  return (
    <Popover className="relative max-w-max">
      {() => (
        <>
          <Popover.Button className="h-45 bg-white-100 inline-flex items-center justify-center rounded px-3 py-2 text-base font-medium text-blue-700 outline-none hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 disabled:opacity-40">
            <PlusIcon className="mr-2 h-5 w-5 text-blue-700 transition duration-150 ease-in-out" aria-hidden="true" />
            <span className="">
              {t(isFirstCondition ? "rules.buttons.add_first_condition" : "rules.buttons.add_condition")}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel static className="absolute -left-1 top-full z-10 mt-1 origin-top-right">
              {({ close }) => (
                <div className="w-full overflow-hidden rounded-lg border shadow-lg">
                  <div className="bg-white-100 relative gap-8">
                    {CONDITIONS.map((c) => {
                      let isDisabled = false;
                      let isLoading = false;
                      switch (c.condition.kind) {
                        case "MEMBERSHIP": {
                          isDisabled = audiences?.items.length == 0;
                          isLoading = isAudiencesLoading;
                          break;
                        }

                        case "EVENT": {
                          isDisabled = trackRules?.length == 0;
                          isLoading = isLoadingTrack;
                          break;
                        }

                        case "VISIT": {
                          isDisabled = viewRules?.length == 0;
                          isLoading = isLoadingView;
                        }
                      }

                      return (
                        <button
                          key={c.condition.id}
                          className={classNames(
                            isDisabled ? "bg-gray-100" : "bg-white-100 hover:bg-blue-600",
                            `group flex w-full items-center py-2 pl-3 pr-6 `
                          )}
                          disabled={isLoading || isDisabled}
                          onClick={() => {
                            onConditionSelected(c.condition);
                            close();
                          }}
                        >
                          <c.icon className="mr-3 h-5 w-5" />
                          <span
                            className={classNames(
                              isDisabled ? "" : "group-hover:text-white-100",
                              `truncate text-gray-700 `
                            )}
                          >
                            {c.name}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ConditionSelector;
