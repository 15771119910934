import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { concatClassNames } from "../../utils";
import { SpinnerIcon } from "../icons";

export type ICheckboxProps = {
  id?: string;
  label?: string;
  description?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  loading?: boolean;
  extraInfo?: string;
  additionalRequiredLabel?: boolean;
  forceShowingDescription?: boolean;
};

type TInputType = ICheckboxProps & React.ComponentProps<"input">;

const Checkbox: FunctionComponent<TInputType> = ({
  id,
  name,
  label,
  className,
  error,
  placeholder,
  extraInfo,
  description,
  loading,
  additionalRequiredLabel,
  forceShowingDescription,
  ...rest
}) => {
  const { t } = useTranslation("checkbox");

  let inputStyle = "focus:ring-blue-500 h-5 w-5 border-gray-300 rounded mt-1";

  if (error) {
    inputStyle = concatClassNames(inputStyle, "border-red-500 hover:border-red-500 focus:border-red-500 outline-none");
  }

  inputStyle = rest.disabled
    ? concatClassNames(inputStyle, "mt-2 bg-gray-200 text-blue-400")
    : concatClassNames(inputStyle, "mt-2 text-blue-600");

  id ??= crypto.randomUUID();

  return (
    <div className="my-2 inline-flex w-full flex-col text-left">
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            {...rest}
            id={id}
            name={name}
            type="checkbox"
            placeholder={placeholder}
            className={className ? concatClassNames(inputStyle, className) : inputStyle}
          />
        </div>
        <div className="ml-3 text-base">
          {label ? (
            <label htmlFor={id} className="block cursor-pointer font-medium text-gray-700">
              {label}
              {additionalRequiredLabel && rest.required ? <sub> ({t("required")})</sub> : null}
            </label>
          ) : null}
          <p
            id="checkbox-description"
            className={`font-normal text-gray-500 ${forceShowingDescription && !description ? "h-6" : ""}`}
          >
            {description}
          </p>
        </div>
        {loading ? (
          <div className="ml-4 flex h-6 justify-center text-center">
            <SpinnerIcon loading className="h-4 w-4" fill="rgba(21,68,155,1)" />
          </div>
        ) : null}
      </div>
      {extraInfo ? <p className="mt-2 text-sm text-gray-800">{extraInfo}</p> : null}
      {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : null}
    </div>
  );
};

export default Checkbox;
