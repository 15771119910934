import type { FunctionComponent } from "react";

import { StatusBadge } from "../../../../components/badges";
import { IconContainer } from "../../../../components/icons";
import { TimeAgo } from "../../../../components/time-ago";
import { type DataSource, type DataSourceCodeConfig, EDataSourceCategory } from "../../../../models/dataSource";

type IDataSourceRowProps = {
  data: DataSource;
  onClick: (data: DataSource) => void;
};

const DataSourceRows: FunctionComponent<IDataSourceRowProps> = ({ data, onClick }) => {
  const renderStatus = () => {
    if (data.createdOnDate) {
      const createdAgo = Date.now() - data.createdOnDate.getTime();
      if (createdAgo <= 1000 * 60 * 15) {
        // 15min
        const minuteAgo = Math.round(createdAgo / 1000 / 60);
        return (
          <span className="text-green-500">
            <b>Added</b> {minuteAgo < 2 ? "a few seconds" : minuteAgo + " minutes"} ago
          </span>
        );
      }
    }

    return <StatusBadge status={data.status} />;
  };

  return (
    <tr key={data.name} id={data.id} className="bg-white-100 border-b border-gray-200">
      <td className="whitespace-nowrap px-3 text-sm font-medium text-gray-900">
        <div className="flex h-14 w-14 flex-wrap content-center justify-center rounded-lg">
          <IconContainer path={data.type.logoUrl} className="h-10" />
        </div>
      </td>
      <td className="whitespace-nowrap pl-2 pr-6 text-sm font-medium text-gray-900">
        <p className="mt-2 w-44 text-sm font-medium text-gray-900">{data.id}</p>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-6 text-sm font-medium text-gray-900 hover:underline"
        onClick={() => {
          onClick(data);
        }}
      >
        <p className="text-sm font-medium text-gray-900">{data.name}</p>
        {data.type.category === EDataSourceCategory.CODE ? (
          <p className="text-sm font-normal text-gray-500">
            {`${data.type.name} • ${(data.configuration as DataSourceCodeConfig).url}`}{" "}
          </p>
        ) : (
          <p className="text-sm font-normal text-gray-500">{`${data.type.name}`} </p>
        )}
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">
          <TimeAgo time={data.createdOn} />
        </p>
      </td>
      <td className="whitespace-nowrap px-6 text-sm font-medium text-gray-900">{renderStatus()}</td>
    </tr>
  );
};

export default DataSourceRows;
