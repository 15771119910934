import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { twMerge } from "tailwind-merge";

import { Input } from "../../../components/inputs";
import { Select } from "../../../components/selects";
import type { DataSourceCodeConfig, TDataSource } from "../../../models/dataSource";
import type { ISelectItem } from "../../../models/select";
import { TrackUserClick, TrackUserClickPanel } from "../../dataSourceEdit/components/categories/code/tracking";
import { ESelector, type ITrackClickEvents } from "../../dataSourceEdit/types";
import TrackRuleForm from "./TrackRuleForm";

type ITrackUserClickViewProps = {
  dataSource?: TDataSource<DataSourceCodeConfig>;
  events: ITrackClickEvents[];
  className?: string;
};

const TrackUserClickView: FunctionComponent<ITrackUserClickViewProps> = ({ dataSource, events, className }) => {
  const { t } = useTranslation("data_source_edit");

  const selectItems: ISelectItem[] = [
    { id: ESelector.ID, label: t("code.track_events.clicks_on_id") },
    { id: ESelector.CLASS, label: t("code.track_events.clicks_on_class") },
  ];

  const handleDeleteRule = (ruleId: string) => {
    console.warn("Ignoring delete rule:", ruleId);
  };

  return (
    <TrackUserClickPanel className={className}>
      <div className="mt-4">
        {events?.map((event, index) => (
          <div key={index} className="my-2 flex px-4">
            <div className="flex w-1/2">
              <Select isViewMode items={selectItems} value={selectItems[event.selectorType]} className="ml-1 w-40" />
              <div className="relative flex-auto">
                <span
                  className={twMerge(
                    "absolute z-10 h-11 text-gray-400",
                    event.selectorType === ESelector.ID ? "mt-2 ml-2.5" : "mt-2 ml-4"
                  )}
                >
                  {event.selectorType === ESelector.ID ? "#" : "."}
                </span>
                <Input
                  isViewMode
                  className="mx-1 flex-auto"
                  placeholder={
                    event.selectorType === ESelector.ID
                      ? t("code.track_events.name_of_the_id")
                      : t("code.track_events.name_of_the_class")
                  }
                  value={event.selectorName}
                />
              </div>
            </div>
            <div className="flex w-1/2">
              <span className="ml-2 mr-1 h-11 py-2">{t("code.track_events.creates")}</span>
              <div className="flex-auto">
                <Input isViewMode placeholder={t("code.track_events.name_of_the_event")} value={event.eventName} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TrackRuleForm
        dataSource={dataSource}
        currentEventCount={events?.length || 0}
        newEventCountFn={(values) => values.clickEvents.length}
      >
        {({ values, setFieldValue, setFieldError, errors }) => (
          <TrackUserClick
            values={values}
            handleSetFieldValue={setFieldValue}
            handleSetFieldError={setFieldError}
            handleDeleteRule={handleDeleteRule}
            errors={errors}
          />
        )}
      </TrackRuleForm>
    </TrackUserClickPanel>
  );
};

export default TrackUserClickView;
