import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { ReactComponent as AudiencesIcon } from "../../assets/icons/audiences.svg";
import { ReactComponent as DataSourcesIcon } from "../../assets/icons/data-sources.svg";
import { ReactComponent as DestinationsIcon } from "../../assets/icons/destinations.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { Loading } from "../../components/loaders";
import { Home } from "../../containers/home";
import { DataSourcesRouter } from "../../features/dataSources";
import type { NavigationItem } from "../../models/navigation";
import { useAppSelector } from "../../reducers";
import { AudiencesRouter } from "../audiences";
import CompanyCreation from "../company/components/CompanyCreation";
import CyclrAuthRedirect from "../cyclr/CyclrAuthRedirect";
import { Destinations } from "../destinations";
import { ExtendedAudienceDetails } from "../extendedAudienceDetails";
import { CallbackLanding } from "../landing";
import Logout from "../logout/Logout";
import RouteConstants, { SidebarRoutes } from "../router/RouteConstants";
import { Settings, SettingsRouter } from "../settings";
import { useUserInfo } from "../userInfo/hooks";
import WorkspaceCreation from "../workspaceEdit/WorkspaceCreation";
import { Footer } from "./footer";
import { CollapsibleSidebar, SidebarContent } from "./sidebar";

const getSidebarItems = (ws: string): NavigationItem[] => [
  {
    name: "Home",
    to: RouteConstants.home,
    icon: () => <HomeIcon className="mr-3" />,
    current: location.pathname === "/",
  },
  {
    name: "Data sources",
    to: RouteConstants.dataSources,
    icon: () => <DataSourcesIcon className="mr-3" />,
    current: location.pathname.startsWith(RouteConstants.dataSources),
  },
  {
    name: "Audiences",
    to: RouteConstants.audiences,
    icon: () => <AudiencesIcon className="mr-3" />,
    current: location.pathname.startsWith(RouteConstants.audiences),
  },
  {
    name: "Destinations",
    to: RouteConstants.destinations,
    icon: () => <DestinationsIcon className="mr-3" />,
    current: location.pathname.startsWith(RouteConstants.destinations),
  },
  // { name: 'Reports', to: RouteConstants.reports, icon: ChartBarIcon, current: false },
  // { name: 'Components', to: RouteConstants.componentShowcase, icon: PuzzleIcon, current: false },
  {
    name: "Settings",
    to: `${RouteConstants.settings}/workspace/${ws}`,
    icon: () => <SettingsIcon className="mr-3" />,
    current: location.pathname.startsWith(`${RouteConstants.settings}/workspace/${ws}`),
  },
];

const SidebarWrapper = () => {
  const location = useLocation();
  if (location.pathname !== "/" && !SidebarRoutes.some((path) => location.pathname.includes(path))) return null;

  const currentWorkspace = useAppSelector((r) => r.workspaces.currentWorkspace);
  const [items, setItems] = useState<NavigationItem[]>(getSidebarItems(currentWorkspace?.id || ""));

  useEffect(() => {
    setItems(getSidebarItems(currentWorkspace?.id || ""));
  }, [location]);

  const onItemClick = (item: NavigationItem) => {
    setItems(items.map((currentItem) => ({ ...currentItem, current: currentItem.name === item.name })));
  };

  return (
    <>
      <CollapsibleSidebar items={items} onItemClick={onItemClick} />
      {/* Static sidebar for desktop */}
      <div className="hidden bg-blue-700 md:flex md:flex-shrink-0">
        <div className="flex w-64 flex-col">
          <div className="flex flex-grow flex-col pb-4 pt-5">
            <SidebarContent items={items} onItemClick={onItemClick} />
          </div>
        </div>
      </div>
    </>
  );
};

const Layout = () => {
  const userInfo = useUserInfo();

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Routes>
        <Route path={RouteConstants.logout} element={<Logout />} />
        <Route path={RouteConstants.callbackLanding} element={<CallbackLanding />} />
      </Routes>
      <SidebarWrapper />
      {userInfo.onboard ? (
        userInfo.onboard.forceWorkspaceCreation ? (
          <>
            <div className="flex w-0 flex-1 flex-col overflow-hidden">
              <Routes>
                <Route path={RouteConstants.home} element={<Home />} />
                <Route path={RouteConstants.createCompany} element={<CompanyCreation />} />
                <Route path={RouteConstants.createWorkspace} element={<WorkspaceCreation />} />
                <Route path={RouteConstants.settings + "/*"} element={<Settings />} />
              </Routes>
              <Footer />
            </div>
          </>
        ) : (
          <div className="flex w-0 flex-1 flex-col overflow-hidden">
            <Routes>
              <Route path={RouteConstants.home} element={<Home />} />
              <Route path={RouteConstants.createCompany} element={<CompanyCreation />} />
              <Route path={RouteConstants.createWorkspace} element={<WorkspaceCreation />} />
              <Route path={RouteConstants.dataSources + "/*"} element={<DataSourcesRouter />} />
              <Route path={RouteConstants.audiences + "/*"} element={<AudiencesRouter />} />
              <Route path={`${RouteConstants.extendedAudiences}/:id`} element={<ExtendedAudienceDetails />} />
              <Route path={RouteConstants.destinations + "/*"} element={<Destinations />} />
              <Route path={RouteConstants.settings + "/*"} element={<SettingsRouter />} />
              <Route path={RouteConstants.cyclrAuthRedirect} element={<CyclrAuthRedirect />} />
            </Routes>
            <Footer />
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Layout;
