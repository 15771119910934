import { type FunctionComponent, useState } from "react";

import type { IModalContentProps } from "../../models/modals";
import type { Workspace } from "../../models/workspace";
import InviteWorkspaceExternalCollaborators from "./InviteWorkspaceExternalCollaborators";
import InviteWorkspaceInternalCollaborators from "./InviteWorkspaceInternalCollaborators";
import WorkspaceCreate from "./WorkspaceCreate";

type IWorkspaceCreationProps = Record<string, unknown>;

const WorkspaceCreation: FunctionComponent<IWorkspaceCreationProps & Partial<IModalContentProps>> = () => {
  const [activeStep, setActiveStep] = useState<string>("create");
  const [workspace, setWorkspace] = useState<Workspace>({} as Workspace);
  const [internalCollaborators, setInternalCollaborators] = useState<any[]>([]);
  const [externalCollaborators, setExternalCollaborators] = useState<any[]>([]);

  const renderTabContent = () => {
    switch (activeStep) {
      case "create": {
        return (
          <WorkspaceCreate
            workspace={workspace}
            workspaceChange={setWorkspace}
            nextStep={() => {
              setActiveStep("invite_internal");
            }}
          />
        );
      }

      case "invite_internal": {
        return (
          <InviteWorkspaceInternalCollaborators
            collaborators={internalCollaborators}
            collaboratorsChange={setInternalCollaborators}
            nextStep={() => {
              setActiveStep("invite_external");
            }}
            prevStep={() => {
              setActiveStep("create");
            }}
          />
        );
      }

      case "invite_external": {
        return (
          <InviteWorkspaceExternalCollaborators
            workspace={workspace}
            internalCollaborators={internalCollaborators}
            internalCollaboratorsChange={setInternalCollaborators}
            externalCollaborators={externalCollaborators}
            externalCollaboratorsChange={setExternalCollaborators}
            prevStep={() => {
              setActiveStep("invite_internal");
            }}
          />
        );
      }
    }
  };

  return <>{renderTabContent()}</>;
};

export default WorkspaceCreation;
