import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";
import { first, last } from "lodash";

import { CodeDataSourceContent } from ".";
import { DataSourceHeader } from "../..";
import { Button } from "../../../../../components/buttons";
import { EStepStatus, type Step, Stepper } from "../../../../../components/steppers";
import {
  type DataSourceCodeConfig,
  type DataSourceType,
  EDataSourceStatus,
  type TDataSource,
} from "../../../../../models/dataSource";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import { useSaveDataSourceMutation, useUpdateDataSourceMutation } from "../../../../../services/dataSources";
import { useLazyGetRulesByDataSourceIdQuery, useSaveRulesMutation } from "../../../../../services/endpoints/rules";
import { prependHttp } from "../../../../../utils";
import { setDataSourceTabs, stashDataSource } from "../../../../dataSources/dataSourcesSlice";
import { stashRules } from "../../../../rules/rulesSlice";
import { useWorkspace } from "../../../../workspaces/hooks";
import { ECodeSteps } from "../../../constants";
import { stashSteps } from "../../../dataSourceEditSlice";
import { useDataSourceSteps } from "../../../hooks/useDataSourceSteps";
import type { DataSourceFormModel, ITrackWebsiteValue } from "../../../types";
import { mapEventsToRules } from "./tracking/utils";

type ICodeDataSourceContainer = {
  dataSourceType: DataSourceType;
  closeModal: () => void;
};

const CodeDataSourceContainer: FunctionComponent<ICodeDataSourceContainer> = ({ dataSourceType, closeModal }) => {
  const { t } = useTranslation("data_source_edit");

  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const dataSourceInfoFormRef = useRef<FormikProps<DataSourceFormModel>>(null);
  const trackWebsiteActivityFormRef = useRef<FormikProps<ITrackWebsiteValue>>(null);

  const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs);
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as
    | Partial<TDataSource<DataSourceCodeConfig>>
    | undefined;

  const [steps, handleNextStep, handlePrevStep] = useDataSourceSteps();

  const [saveDataSource, { isLoading: isSaveLoading, isSuccess: isSaveSuccess, data: createdDataSource }] =
    useSaveDataSourceMutation();
  const [updateDataSource, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, data: updatedDataSource }] =
    useUpdateDataSourceMutation();

  const [saveRules, { data: createdRules, isLoading: isSaveRulesLoading }] = useSaveRulesMutation();
  const [getRulesByDataSourceId, rulesByDataSourceId] = useLazyGetRulesByDataSourceIdQuery();

  const isLoading = isSaveLoading || isUpdateLoading || isSaveRulesLoading;

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCompleted = first(steps)?.status === EStepStatus.COMPLETE;

  const codeSteps: Step[] = [
    {
      id: ECodeSteps.SOURCE_DETAILS,
      label: t("code.steps.source_details"),
      status: EStepStatus.CURRENT,
    },
    {
      id: ECodeSteps.TRACK_ACTIVITY,
      label: t("code.steps.track_events"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: ECodeSteps.INSTALL_SNIPPET,
      label: t("code.steps.install_snippet"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: ECodeSteps.CHECK_INSTALLATION,
      label: t("code.steps.check_installation"),
      status: EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    if (rulesByDataSourceId.data) {
      dispatch(stashRules(rulesByDataSourceId.data));
    }
  }, [rulesByDataSourceId.data]);

  useEffect(() => {
    dispatch(stashSteps(codeSteps));
  }, []);

  useEffect(() => {
    if (createdDataSource) {
      dispatch(stashDataSource(createdDataSource.dataSource));
    }
  }, [createdDataSource]);

  useEffect(() => {
    if (isUpdateSuccess) {
      handleNextStep();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (updatedDataSource) {
      dispatch(stashDataSource(updatedDataSource));
    }
  }, [updatedDataSource]);

  useEffect(() => {
    if (isSaveSuccess) {
      handleNextStep();
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (createdRules) {
      handleNextStep();
    }
  }, [createdRules]);

  const createOrUpdateDataSource = (dataSource: Partial<TDataSource<DataSourceCodeConfig>>) => {
    if (Boolean(dataSource.name) && dataSource.configuration) {
      if (dataSource.id) {
        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...dataSource.configuration, url: prependHttp(dataSource.configuration?.url) },
          },
        });
      } else {
        saveDataSource({
          workspaceId: workspace.id,
          dataSource: {
            type: dataSourceType.id,
            name: dataSource.name,
            configuration: { ...dataSource.configuration, url: prependHttp(dataSource.configuration?.url) },
          },
        });
      }
    }
  };

  const submitRules = (events: ITrackWebsiteValue) => {
    const rules = mapEventsToRules(events);

    if (dataSource && dataSource.id) {
      const dataSourceId = dataSource.id;

      // Adding new rules (without id)
      const createRules = rules.filter((rule) => rule.id === undefined);

      if (createRules) {
        saveRules({
          workspaceId: workspace.id,
          dataSourceId,
          rules: createRules,
        });
      }
    }
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === ECodeSteps.SOURCE_DETAILS) {
      await dataSourceInfoFormRef.current?.submitForm();

      if (dataSourceInfoFormRef.current?.isValid && dataSourceInfoFormRef.current?.values) {
        createOrUpdateDataSource(dataSourceInfoFormRef.current?.values);
      }

      return;
    }

    if (currentStepId === ECodeSteps.TRACK_ACTIVITY) {
      if (dataSource && dataSource.id) {
        getRulesByDataSourceId({
          dataSourceId: dataSource.id,
          workspaceId: workspace.id,
        });
      }

      await trackWebsiteActivityFormRef.current?.submitForm();
      const events = trackWebsiteActivityFormRef.current?.values;

      if (trackWebsiteActivityFormRef.current?.isValid && events) {
        submitRules(events);
      }

      return;
    }

    if (isLastStepCurrent) {
      closeModal();
      dispatch(setDataSourceTabs(tabs.map((t) => ({ ...t, current: t.id === "datasource.table" }))));

      return;
    }

    handleNextStep();
  };

  const handleOnSubmit = () => {
    // Handlesubmit
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <div className="flex flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <DataSourceHeader
          closeModal={closeModal}
          title={t("title", { name: dataSourceType.name })}
          loading={isLoading}
        />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <Stepper steps={steps} />
          <CodeDataSourceContent
            dataSourceType={dataSourceType}
            step={currentStep}
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            trackWebsiteActivityFormRef={trackWebsiteActivityFormRef}
            goNext={handleNextButtonClicked}
            loading={isLoading}
            handleOnSubmit={handleOnSubmit}
          />
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        {isFirstStepCompleted ? (
          <Button
            variant="light"
            type="button"
            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            disabled={isLoading}
            onClick={handlePrevStep}
          >
            {t("back")}
          </Button>
        ) : null}
        <Button
          variant={isLastStepCurrent ? "confirm" : "primary"}
          type="submit"
          className="ml-4 inline-flex justify-center px-4 py-2"
          disabled={isLastStepCurrent ? dataSource?.status !== EDataSourceStatus.ACTIVE : null}
          loading={isLoading}
          onClick={handleNextButtonClicked}
        >
          {t(isLastStepCurrent ? "finish" : "next")}
        </Button>
      </div>
    </div>
  );
};

export default CodeDataSourceContainer;
