import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { type ISimpleTableProps, SimpleTable } from "../../../../../components/tables";
import type { ExtendedAudience } from "../../../../../models/extendedAudiences";
import ExtendedAudienceRow from "./ExtendedAudienceRow";

type IAudienceTableProps = {
  audiences: ExtendedAudience[];
} & ISimpleTableProps;

const ExtendedAudienceTable: FunctionComponent<IAudienceTableProps> = ({ audiences, ...rest }) => {
  const { t } = useTranslation("audiences");

  const headings = [
    {
      label: "",
      className: "text-left",
    },
    {
      label: t("table.id"),
      className: "text-left pl-3",
    },
    {
      label: t("table.audience_name"),
      className: "text-left",
    },
    {
      label: t("table.change"),
      className: "text-left",
    },
    {
      label: t("table.total_users"),
      className: "text-right",
    },
  ];

  const renderRow = (audience: ExtendedAudience, index: number) => {
    const key = `${audience.id}-${index}`;

    return <ExtendedAudienceRow key={key} id={key} audience={audience} />;
  };

  return (
    <SimpleTable {...rest}>
      <colgroup>
        <col style={{ width: "30px" }} />
        <col style={{ width: "200px" }} />
        <col />
        <col style={{ width: "150px" }} />
        <col style={{ width: "150px" }} />
      </colgroup>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope="col"
              className={`sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 ${heading.className}`}
            >
              {heading.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{audiences?.map(renderRow)}</tbody>
    </SimpleTable>
  );
};

export default ExtendedAudienceTable;
