import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../../components/buttons";

type IAddSourceButtonProps = {
  onClick?: () => void;
};

const AddDataSourceButton: FunctionComponent<IAddSourceButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button variant="confirm" icon={PlusIcon} className="mr-6 h-9" onClick={onClick}>
      {t("data_sources:buttons.add_first_data_source.add_source")}
    </Button>
  );
};

export default AddDataSourceButton;
