import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { Company } from "../../models/company";
import type { UserInfo, UserOnboard } from "../../models/user";

export const initialState: Partial<UserInfo> = {
  onboard: {
    forceWorkspaceCreation: false,
    forceCompanyCreation: false,
  },
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo | undefined>) => ({
      ...action.payload,
    }),
    setCompany: (state, action: PayloadAction<Company | undefined>) => ({
      ...state,
      company: action.payload,
    }),
    setOnboard: (state, action: PayloadAction<Partial<UserOnboard>>) => ({
      ...state,
      onboard: { ...state.onboard, ...action.payload },
    }),
  },
});

export const { setUserInfo, setCompany, setOnboard } = userInfoSlice.actions;

export default userInfoSlice.reducer;
