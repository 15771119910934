import { useSelector } from "react-redux";

import type { CompanyId } from "../../../models/company";
import type { UserInfo } from "../../../models/user";
import { type RootState, useAppSelector } from "../../../reducers";

function assertUserInfoIsDefined(userInfo: Partial<UserInfo> | undefined): asserts userInfo is UserInfo {
  if (!userInfo) {
    throw new Error(`'RootState' has an undefined 'userInfo' configured`);
  }
}

export function useUserInfo() {
  const userInfo = useSelector((state: RootState) => state.userInfo);

  assertUserInfoIsDefined(userInfo);

  return userInfo; // TypeScript knows this is a workspace defined
}

export function useUserRoles() {
  const userInfo = useSelector((state: RootState) => state.userInfo);

  assertUserInfoIsDefined(userInfo);

  const roleMap: Record<string, boolean> = {};

  for (const role of userInfo.roles) {
    roleMap[role] = true;
  }

  return roleMap; // TypeScript knows this is a workspace defined
}

export function isWorkspaceAdmin(workspaceId: string) {
  const userInfo = useAppSelector((s) => s.userInfo);
  return [`${workspaceId}_Admin`, "Admin"].some((r) => userInfo.roles?.includes(r));
}

/* Export function isCompanyAdmin() {
  const userInfo = useAppSelector(s => s.userInfo);
  return ['Company_Admin', 'Admin'].some(r => userInfo.roles?.includes(r));
} */

export function isCompanyAdmin(companyId?: CompanyId) {
  const userInfo = useAppSelector((s) => s.userInfo);
  if (userInfo.roles?.includes("Admin")) {
    return true;
  }

  if (!companyId || userInfo.company?.id == companyId) {
    return userInfo.roles?.includes("Company_Admin") || false;
  }

  return false;
}

export function isCompanyAccounting() {
  const userInfo = useAppSelector((s) => s.userInfo);
  return ["Company_Accounting", "Admin"].some((r) => userInfo.roles?.includes(r));
}
