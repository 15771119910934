import type React from "react";
import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { CheckIcon } from "@heroicons/react/20/solid";
import type { FormikProps } from "formik";

import { AudienceForm, type AudienceFormModel, ExpressionContainer } from ".";
import type { Step } from "../../../components/steppers";
import type { Trait } from "../../../models/traits";
import { type RootState, useAppDispatch } from "../../../reducers";
import { stashAudience } from "../audienceEditSlice";
import AudienceSyncContainer, { type AudienceSyncFormModel } from "./sync/AudienceSyncContainer";

type IAudienceContentProps = {
  createFormRef: React.RefObject<FormikProps<AudienceFormModel>>;
  syncFormRef: React.RefObject<FormikProps<AudienceSyncFormModel>>;
  step?: Step;
  traits?: Trait[];
};

export enum EAudienceSteps {
  NAME_AND_METADATA = 1,
  EXPRESSION = 2,
  SYNC = 3,
  CONFIRMATION = 4,
}

const AudienceContent: FunctionComponent<IAudienceContentProps> = ({ step, createFormRef, syncFormRef, traits }) => {
  const dispatch = useAppDispatch();

  const audience = useSelector((state: RootState) => state.audienceEdit.audience);

  const handleSubmit = async (form: AudienceFormModel) => {
    if (form.name && form.matchingTrait && form.datasourceIds) {
      dispatch(stashAudience({ ...form }));
    }
  };

  const stepTemplate = () => {
    switch (step?.id) {
      case EAudienceSteps.NAME_AND_METADATA: {
        return <AudienceForm formRef={createFormRef} traits={traits} audience={audience} onSubmit={handleSubmit} />;
      }

      case EAudienceSteps.EXPRESSION: {
        return <ExpressionContainer />;
      }

      case EAudienceSteps.SYNC: {
        return <AudienceSyncContainer formRef={syncFormRef} />;
      }

      case EAudienceSteps.CONFIRMATION: {
        return (
          <p>
            <span className="m-auto my-8 flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
              <CheckIcon className="text-white-100 h-6 w-6" aria-hidden="true" />
            </span>
            Congratulations, your audience was correctly created!
          </p>
        );
      }

      default: {
        return null;
      }
    }
  };

  return <div className="mt-6 h-auto rounded-lg">{stepTemplate()}</div>;
};

export default AudienceContent;
