import { type FunctionComponent, useEffect, useState } from "react";

import {
  type AudienceSyncTimeBasedSchedule,
  type WeekDay,
  WeekDays,
  WeekDayShortNames,
} from "../../../../models/audiences";

type IAudienceSyncRepeatWeekdayProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

type Day = {
  id: WeekDay;
  label: string;
  selected: boolean;
};

const AudienceSyncRepeatWeekday: FunctionComponent<IAudienceSyncRepeatWeekdayProps> = ({
  scheduleConfiguration,
  setFieldValue,
}) => {
  const initialDays = scheduleConfiguration.weekDays || [];
  const [days, setDays] = useState<Day[]>(
    WeekDays.map((weekday, index) => ({
      id: weekday,
      label: WeekDayShortNames[index],
      selected: initialDays.includes(weekday),
    }))
  );

  useEffect(() => {
    setFieldValue(
      "scheduleConfiguration.weekDays",
      days.filter((day) => day.selected).map((day) => day.id)
    );
  }, [days]);

  const onDayClick = (days: Day[], clickedDay: Day) => {
    setDays(days.map((day) => (day.id === clickedDay.id ? { ...day, selected: !clickedDay.selected } : day)));
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <span className="mr-2">on</span>
      {days.map((day) =>
        day.selected ? (
          <span
            key={day.id}
            className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-blue-600 bg-blue-600"
            onClick={() => {
              onDayClick(days, day);
            }}
          >
            <span className="text-white-100 text-sm">{day.label}</span>
          </span>
        ) : (
          <span
            key={day.id}
            className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-gray-200 bg-gray-200"
            onClick={() => {
              onDayClick(days, day);
            }}
          >
            <span className="text-sm text-gray-700">{day.label}</span>
          </span>
        )
      )}
    </div>
  );
};

export default AudienceSyncRepeatWeekday;
