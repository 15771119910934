import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { DocumentTextIcon } from "@heroicons/react/20/solid";
import { CogIcon } from "@heroicons/react/24/outline";

import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import type { TabType } from "../../models/tabs";
import { useGetExtendedAudienceQuery } from "../../services/endpoints/extendedAudiences";
import { MainContentWrapper } from "../layout/main";
import { TopbarTabs } from "../layout/topbar";
import RouteConstants from "../router/RouteConstants";
import { useWorkspace } from "../workspaces/hooks";
import { ExtendedAudienceTabContent } from "./components";

type IExtendedAudienceDetailsProps = Record<string, unknown>;

const ExtendedAudienceDetails: FunctionComponent<IExtendedAudienceDetailsProps> = () => {
  const { t } = useTranslation("extended_audience_details");
  const navigate = useNavigate();

  const { id: extendedAudienceId } = useParams<{ id: string }>();

  const workspace = useWorkspace();

  const [tabs, setTabs] = useState([
    { id: "1", label: t("tabs.segments_license"), current: true, icon: DocumentTextIcon },
    { id: "2", label: t("tabs.settings"), current: false, icon: CogIcon },
  ]);

  const {
    isFetching,
    data: extendedAudience,
    isUninitialized,
    isError,
  } = useGetExtendedAudienceQuery({
    workspaceId: workspace.id,
    extendedAudienceId,
  });
  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);

  const shouldShowLoading = isFetching || isFetchingDelayed || isUninitialized;
  const breadcrumbs = [
    {
      label: t("breadcrumbs.my_audiences"),
      path: "/audiences",
    },
    {
      label: extendedAudience?.name || "",
    },
  ];

  useEffect(() => {
    if (isError) {
      navigate(RouteConstants.defaultRoute);
    }
  }, [isError]);

  const handleTabChange = (selectedTab: TabType) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === selectedTab.id })));
  };

  if (shouldShowLoading) {
    return (
      <MainContentWrapper>
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
          <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
        </span>
      </MainContentWrapper>
    );
  }

  return (
    <>
      <TopbarTabs tabs={tabs} breadcrumbs={breadcrumbs} onTabChange={handleTabChange} />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          {extendedAudience ? (
            <div className="h-auto py-4">
              <ExtendedAudienceTabContent tabs={tabs} selectedExtendedAudience={extendedAudience} />
            </div>
          ) : null}
        </div>
      </MainContentWrapper>
    </>
  );
};

export default ExtendedAudienceDetails;
