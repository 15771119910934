import { useTranslation } from "react-i18next";

function NoFileSelected() {
  const { t } = useTranslation();
  return (
    <div className="flex w-96 items-center">
      <div className="mr-4 flex-none">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 14V13.5234C38.9998 13.0283 38.8032 12.5534 38.4531 12.2031L30.8047 4.54688C30.4521 4.19553 29.9743 3.99879 29.4766 4.00001H29V14H39ZM26.5 14.625V4.00001H10.875C10.3773 3.99876 9.89971 4.1959 9.5478 4.5478C9.1959 4.89971 8.99876 5.37735 9.00001 5.87501V42.125C8.99876 42.6227 9.1959 43.1003 9.5478 43.4522C9.89971 43.8041 10.3773 44.0013 10.875 44H37.125C37.6227 44.0013 38.1003 43.8041 38.4522 43.4522C38.8041 43.1003 39.0013 42.6227 39 42.125V16.5H28.375C27.3407 16.497 26.503 15.6593 26.5 14.625ZM19 25.875C19 26.2202 18.7202 26.5 18.375 26.5H17.75C17.0596 26.5 16.5 27.0597 16.5 27.75V30.25C16.5 30.9404 17.0596 31.5 17.75 31.5H18.375C18.7202 31.5 19 31.7798 19 32.125V33.375C19 33.7202 18.7202 34 18.375 34H17.75C15.6789 34 14 32.3211 14 30.25V27.75C14 25.6789 15.6789 24 17.75 24H18.375C18.7202 24 19 24.2798 19 24.625V25.875ZM21.5 34H22.4586C24.2859 34 25.7711 32.6461 25.7703 30.9828C25.7623 30.1328 25.3816 29.3291 24.7289 28.7844L23.0188 27.318C22.9227 27.2467 22.8623 27.1372 22.8531 27.018C22.8531 26.7742 23.2016 26.5008 23.6664 26.5008H24.625C24.9702 26.5008 25.25 26.221 25.25 25.8758V24.6258C25.25 24.2806 24.9702 24.0008 24.625 24.0008H23.668C21.8391 24.0008 20.3547 25.3539 20.3547 27.018C20.3627 27.868 20.7434 28.6717 21.3961 29.2164L23.1063 30.6828C23.2023 30.7541 23.2627 30.8636 23.2719 30.9828C23.2719 31.2266 22.9234 31.5 22.4586 31.5H21.5C21.1548 31.5 20.875 31.7798 20.875 32.125V33.375C20.875 33.7202 21.1548 34 21.5 34ZM29 24.625V26.25C28.9996 27.8181 29.4321 29.3558 30.25 30.6938C31.0679 29.3558 31.5004 27.8181 31.5 26.25V24.625C31.5 24.2798 31.7798 24 32.125 24H33.375C33.7202 24 34 24.2798 34 24.625V26.25C34 29.0219 32.9938 31.632 31.1656 33.6008C30.9291 33.8553 30.5974 33.9998 30.25 33.9998C29.9026 33.9998 29.5709 33.8553 29.3344 33.6008C27.5063 31.632 26.5 29.0219 26.5 26.25V24.625C26.5 24.2798 26.7798 24 27.125 24H28.375C28.7202 24 29 24.2798 29 24.625Z"
            fill="#DAE1EB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.375 24H17.75C15.6789 24 14 25.6789 14 27.75V30.25C14 32.3211 15.6789 34 17.75 34H18.375C18.7202 34 19 33.7202 19 33.375V32.125C19 31.7798 18.7202 31.5 18.375 31.5H17.75C17.0596 31.5 16.5 30.9404 16.5 30.25V27.75C16.5 27.0596 17.0596 26.5 17.75 26.5H18.375C18.7202 26.5 19 26.2202 19 25.875V24.625C19 24.2798 18.7202 24 18.375 24ZM24.7289 28.7844L23.0187 27.318C22.9227 27.2466 22.8623 27.1372 22.8531 27.018C22.8531 26.7742 23.2016 26.5008 23.6664 26.5008H24.625C24.9702 26.5008 25.25 26.221 25.25 25.8758V24.625C25.25 24.2798 24.9702 24 24.625 24H23.668C21.8406 24 20.3547 25.3531 20.3547 27.0172C20.3627 27.8672 20.7434 28.6709 21.3961 29.2156L23.1062 30.682C23.2023 30.7534 23.2627 30.8628 23.2719 30.982C23.2719 31.2258 22.9234 31.4992 22.4586 31.4992H21.5C21.1548 31.4992 20.875 31.779 20.875 32.1242V33.3742C20.875 33.7194 21.1548 33.9992 21.5 33.9992H22.4586C24.2859 33.9992 25.7711 32.6453 25.7703 30.982C25.762 30.1323 25.3814 29.3289 24.7289 28.7844ZM32.125 24H33.375C33.7202 24 34 24.2798 34 24.625V26.25C34 29.0219 32.9937 31.632 31.1656 33.6008C30.9291 33.8552 30.5974 33.9998 30.25 33.9998C29.9026 33.9998 29.5709 33.8552 29.3344 33.6008C27.5063 31.632 26.5 29.0219 26.5 26.25V24.625C26.5 24.2798 26.7798 24 27.125 24H28.375C28.7202 24 29 24.2798 29 24.625V26.25C28.9996 27.8181 29.4321 29.3558 30.25 30.6937C31.0679 29.3558 31.5004 27.8181 31.5 26.25V24.625C31.5 24.2798 31.7798 24 32.125 24Z"
            fill="#DAE1EB"
          />
        </svg>
      </div>
      <div className="m-auto flex-grow text-left">
        <p className="text-sm text-gray-900">{t("data_source_edit:file.progress_bar.no_file_selected")}</p>
      </div>
    </div>
  );
}

export default NoFileSelected;
