import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Form, Formik, type FormikProps } from "formik";
import * as Yup from "yup";

import { Button } from "../../../components/buttons";
import { SpinnerIcon } from "../../../components/icons";
import { Input } from "../../../components/inputs";
import type { Company, CompanyAdministrativeData } from "../../../models/company";
import {
  useLazyGetCompanyAdministativeDataQuery,
  useUpdateCompanyAdministrativeDataMutation,
} from "../../../services/endpoints/companies";
import { isCompanyAccounting, isCompanyAdmin } from "../../userInfo/hooks/useUserInfo";

type ICompanySettingsProps = {
  company: Company;
};

const CompanySettings: FunctionComponent<ICompanySettingsProps> = ({ company }) => {
  const { t } = useTranslation("settings");
  const formRef = useRef<FormikProps<CompanyAdministrativeData>>(null);

  const validationSchema = Yup.object({
    legalEntity: Yup.string().required(t("company.errors.legalEntityRequired")),
    licenseNumber: Yup.string().required(t("company.errors.licenseNumberRequired")),
    vatNumber: Yup.string().required(t("company.errors.vatNumberRequired")),
    email: Yup.string().email(t("company.errors.emailValid")).required(t("company.errors.emailRequired")),
    legalAddress1: Yup.string().required(t("company.errors.legalAddress1Required")),
    legalAddress2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    country: Yup.string().required(t("company.errors.legalEntityRequired")),
  });

  const initialSettings: CompanyAdministrativeData = {
    legalEntity: "",
    licenseNumber: "",
    email: "",
    vatNumber: "",
    legalAddress1: "",
    legalAddress2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    createdOn: "",
    updatedOn: "",
  };

  const canSeeSaveAdministativeData = isCompanyAdmin() || isCompanyAccounting();

  const [
    getAdministativeData,
    { data: loadedSettings, isLoading: isSettingsLoading, isError: isLoadError, error: loadError },
  ] = useLazyGetCompanyAdministativeDataQuery();

  useEffect(() => {
    if (canSeeSaveAdministativeData) {
      getAdministativeData({
        companyId: company.id,
      });
    }
  }, [canSeeSaveAdministativeData]);

  const [
    updateSettings,
    { data: updatedSettings, isLoading: isUpdateLoading, isError: isUpdateError, error: updateError },
  ] = useUpdateCompanyAdministrativeDataMutation();

  useEffect(() => {
    if (!isSettingsLoading && !isLoadError && loadedSettings) {
      formRef.current?.setValues(
        {
          legalEntity: loadedSettings.legalEntity || "",
          licenseNumber: loadedSettings.licenseNumber || "",
          email: loadedSettings.email || "",
          vatNumber: loadedSettings.vatNumber || "",
          legalAddress1: loadedSettings.legalAddress1 || "",
          legalAddress2: loadedSettings.legalAddress2 || "",
          city: loadedSettings.city || "",
          state: loadedSettings.state || "",
          zip: loadedSettings.zip || "",
          country: loadedSettings.country || "",
          createdOn: loadedSettings.createdOn,
          updatedOn: loadedSettings.updatedOn,
        },
        false
      );
    }
  }, [isSettingsLoading, isLoadError, loadedSettings]);

  const handleSaveButtonClicked = async () => {
    await formRef.current?.submitForm();
    const values = formRef.current?.values;

    if (formRef.current?.isValid && values) {
      updateSettings({ companyId: company.id, body: values });
    }
  };

  return (
    <div className="px-6 py-8">
      <h1 className="text-black-100 mb-2 text-lg font-semibold">
        {t("company.title")}
        <span className="relative my-0 inline-block h-7 w-7 pt-3 text-blue-500 opacity-75">
          {isSettingsLoading ? <SpinnerIcon loading className="ml-2 mr-3 h-5 w-5" /> : null}
        </span>
      </h1>

      <Formik
        initialValues={initialSettings}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={() => {
          console.log("submit settings");
        }}
      >
        {({ values, handleChange, errors }) => (
          <Form>
            <div className="bg-white-100 grid grid-cols-2 gap-6 rounded-lg border p-6">
              <div className="col-span-2">
                <Input readOnly disabled label={t("company.company")} value={company.name} />
              </div>

              <Input
                label={t("company.entity")}
                value={values.legalEntity}
                error={errors.legalEntity}
                disabled={isSettingsLoading || isUpdateLoading || !canSeeSaveAdministativeData}
                onChange={(e) => {
                  handleChange("legalEntity")(e.target.value);
                }}
              />

              <Input
                disabled
                label={t("company.license")}
                value={values.licenseNumber}
                error={errors.licenseNumber}
                onChange={(e) => {
                  handleChange("licenseNumber")(e.target.value);
                }}
              />

              <Input
                label={t("company.vat")}
                value={values.vatNumber}
                disabled={!canSeeSaveAdministativeData}
                error={errors.vatNumber}
                onChange={(e) => {
                  handleChange("vatNumber")(e.target.value);
                }}
              />

              <Input
                label={t("company.email")}
                value={values.email}
                disabled={!canSeeSaveAdministativeData}
                error={errors.email}
                onChange={(e) => {
                  handleChange("email")(e.target.value);
                }}
              />

              <div className="col-span-2">
                <label className="block py-2 text-sm font-medium text-gray-700">{t("company.address.title")}</label>

                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-2">
                    <Input
                      placeholder={t("company.address.line1")}
                      value={values.legalAddress1}
                      disabled={!canSeeSaveAdministativeData}
                      error={errors.legalAddress1}
                      onChange={(e) => {
                        handleChange("legalAddress1")(e.target.value);
                      }}
                    />
                  </div>

                  <Input
                    placeholder={t("company.address.city")}
                    value={values.city}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.city}
                    onChange={(e) => {
                      handleChange("city")(e.target.value);
                    }}
                  />

                  <Input
                    placeholder={t("company.address.state")}
                    value={values.state}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.state}
                    onChange={(e) => {
                      handleChange("state")(e.target.value);
                    }}
                  />

                  <div className="col-span-2">
                    <Input
                      placeholder={t("company.address.line2")}
                      value={values.legalAddress2}
                      disabled={!canSeeSaveAdministativeData}
                      error={errors.legalAddress2}
                      onChange={(e) => {
                        handleChange("legalAddress2")(e.target.value);
                      }}
                    />
                  </div>

                  <Input
                    placeholder={t("company.address.zipcode")}
                    value={values.zip}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.zip}
                    onChange={(e) => {
                      handleChange("zip")(e.target.value);
                    }}
                  />

                  <Input
                    placeholder={t("company.address.country")}
                    value={values.country}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.country}
                    onChange={(e) => {
                      handleChange("country")(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-span-2 flex flex-row justify-end">
                {canSeeSaveAdministativeData ? (
                  <Button
                    variant="primary"
                    type="button"
                    className="inline-flex justify-center px-4 py-2"
                    disabled={isUpdateLoading}
                    onClick={handleSaveButtonClicked}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanySettings;
