import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { split } from "lodash";

import { Button } from "../../../../components/buttons";
import type { DataSourceFileConfig, TDataSource } from "../../../../models/dataSource";
import {
  useLazyGetDataSourceFileLinesQuery,
  usePostDataSourceDownloadUrlMutation,
} from "../../../../services/dataSources";
import { useWorkspace } from "../../../workspaces/hooks";
import { PreviewCSVTable } from "../table";

type IFileDataSourceDataProps = {
  dataSource?: TDataSource<DataSourceFileConfig>;
};

const FileDataSourceData: FunctionComponent<IFileDataSourceDataProps> = ({ dataSource }) => {
  const { t } = useTranslation("data_source_details");

  const workspace = useWorkspace();

  const [postDataSourceDownloadUrl, { data, isLoading, isSuccess }] = usePostDataSourceDownloadUrlMutation();
  const [getDataSourceFileLines, { data: lines, isLoading: isLoadingLines }] = useLazyGetDataSourceFileLinesQuery();

  useEffect(() => {
    if (dataSource) {
      postDataSourceDownloadUrl({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });

      getDataSourceFileLines({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });
    }
  }, []);

  const handleDownloadFileClicked = () => {
    if (data) {
      window.open(data.url, "_parent");
    }
  };

  const url = dataSource?.configuration.url;
  const fileContent = lines?.map((l) => split(l, /[,;]+/));

  return (
    <div className="flex flex-col">
      <div className="flex-1">
        <div className="flex py-8">
          <div className="mr-8 flex-col">
            <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("data.title")}</h1>
            <p className="text-base text-gray-800">{url?.slice(Math.max(0, url.lastIndexOf("/") + 1))}</p>
          </div>
          <Button disabled={!isSuccess} onClick={handleDownloadFileClicked}>
            {t("data.download_file")}
          </Button>
        </div>
      </div>
      <div className="flex-1">
        <div className="py-8">
          {lines?.length ? (
            <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">
              {t("data.preview_description", { count: lines?.length })}
            </h1>
          ) : null}
          {fileContent ? (
            <PreviewCSVTable
              fileContent={fileContent}
              isDefaultHeaderCorrect={Boolean(dataSource?.configuration.isDefaultHeader)}
              dataSource={dataSource}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FileDataSourceData;
