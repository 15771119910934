import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ChartBarSquareIcon,
  CreditCardIcon,
  GlobeAltIcon,
  HeartIcon,
  MapIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

import type { RootState } from "../../../reducers";

export default function ExtendedAudienceSummary() {
  const { t } = useTranslation("extended_audience_edit");
  const audience = useSelector((state: RootState) => state.extendedAudienceEdit.extendedAudience);
  const selectedOptions = useSelector((state: RootState) => state.extendedAudienceEdit.selectedOptions);
  const license = useSelector((state: RootState) => state.extendedAudienceEdit.license);

  return (
    <div className="flex-grow p-6">
      <h3 className="text-left font-bold">{t("steps.confirmation.header")}</h3>
      <p className="text-left">{t("steps.confirmation.description")}</p>
      {audience ? (
        <span className="text-md mb-1 mr-3 mt-6 flex text-left font-medium text-gray-700">Name: {audience?.name}</span>
      ) : null}
      {audience ? (
        <div className="h-auto rounded-lg border border-solid border-gray-200 text-left font-medium">
          <div className="px-4 py-5">
            {audience ? (
              <ul>
                {selectedOptions.genderSegmentOptions?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <UserIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Gender is {selectedOptions.genderSegmentOptions?.map((o) => o.displayName)?.join(",")}{" "}
                    </span>
                  </li>
                ) : null}
                {selectedOptions.demographicSegmentOptions?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <ChartBarSquareIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Age is between{" "}
                      {selectedOptions.demographicSegmentOptions?.map((o) => o.displayName)?.join(" or ")}
                    </span>
                  </li>
                ) : null}
                {selectedOptions.geographicSegmentOptions?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <MapIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Located in {selectedOptions.geographicSegmentOptions?.map((o) => o.displayName)?.join(" or in ")}
                    </span>
                  </li>
                ) : null}
                {selectedOptions.interestsSegmentOptions?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <HeartIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Interested in{" "}
                      {selectedOptions.interestsSegmentOptions?.map((o) => o.displayName)?.join(" or in ")}
                    </span>
                  </li>
                ) : null}
              </ul>
            ) : null}
          </div>
        </div>
      ) : null}
      <span className="text-md mb-1 mr-3 mt-3 flex text-left font-medium text-gray-700">License</span>
      <div className="h-auto rounded-lg border border-solid border-gray-200 text-left font-medium">
        <div className="px-4 py-5">
          <ul>
            <li className="mb-2 flex items-center text-base text-gray-700">
              <GlobeAltIcon className="mr-2 h-4 text-gray-600" />
              <span className="text-md mr-3 text-right font-medium text-gray-700">{license?.productName}</span>
            </li>
            <li className="mb-2 flex items-center text-base text-gray-700">
              <CreditCardIcon className="mr-2 h-4 text-gray-600" />
              <span className="text-md mr-3 text-right font-medium text-gray-700">
                {license?.currency?.toUpperCase()}
              </span>
            </li>
            <li className="mb-2 flex items-center text-base text-gray-700">
              <UserIcon className="mr-2 h-4 text-gray-600" />
              <span className="text-md mr-3 text-right font-medium text-gray-700">{license?.seatId}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
