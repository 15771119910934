import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { PaginationState } from "../../models/pagination";
import type { AudienceTopbarTab } from "../../models/tabs";

type ListState = {
  pagination: PaginationState;
  searchText: string;
};

type AudiencesState = {
  ui: {
    tabs: AudienceTopbarTab[];
    regular: ListState;
    extended: ListState;
  };
};

export const initialState: AudiencesState = {
  ui: {
    tabs: [
      { id: "audience.regular", current: true },
      { id: "audience.extended", current: false },
    ],
    regular: {
      pagination: {
        pageNo: 0,
        pageSize: 0,
      },
      searchText: "",
    },
    extended: {
      pagination: {
        pageNo: 0,
        pageSize: 0,
      },
      searchText: "",
    },
  },
};

const audiencesSlice = createSlice({
  name: "audiences",
  initialState,
  reducers: {
    reset: () => initialState,
    setAudienceTabs: (state, action: PayloadAction<AudienceTopbarTab[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        tabs: action.payload,
      },
    }),
    setRegularPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        regular: {
          ...state.ui.regular,
          pagination: action.payload,
        },
      },
    }),
    setRegularSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        regular: {
          ...state.ui.regular,
          searchText: action.payload,
        },
      },
    }),
    setExtendedPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        extended: {
          ...state.ui.extended,
          pagination: action.payload,
        },
      },
    }),
    setExtendedSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        extended: {
          ...state.ui.extended,
          searchText: action.payload,
        },
      },
    }),
  },
});

export const {
  reset,
  setAudienceTabs,
  setRegularPagination,
  setRegularSearchText,
  setExtendedPagination,
  setExtendedSearchText,
} = audiencesSlice.actions;

export default audiencesSlice.reducer;
