import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";

import type { Step } from "../../../../../components/steppers";
import type { DataSourceCrmConfig, DataSourceType, TDataSource } from "../../../../../models/dataSource";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import { stashDataSource } from "../../../../dataSources/dataSourcesSlice";
import { ECrmSteps } from "../../../constants";
import type { DataSourceCrmCreateFormModel, ICrmFields, ICrmParameters } from "../../../types";
import { CrmDataSourceCreateForm } from "./";
import CrmDataSourceFieldsForm from "./CrmDataSourceFieldsForm";
import CrmDataSourceParametersForm from "./CrmDataSourceParametersForm";

type ICrmDataSourceContentProps = {
  dataSourceType: DataSourceType;
  step?: Step;
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceCrmCreateFormModel>>;
  crmParametersFormRef: React.RefObject<FormikProps<ICrmParameters>>;
  crmFieldsFormRef: React.RefObject<FormikProps<ICrmFields>>;
};

const CrmDataSourceContent: FunctionComponent<ICrmDataSourceContentProps> = ({
  dataSourceType,
  step,
  dataSourceInfoFormRef,
  crmParametersFormRef,
  crmFieldsFormRef,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("data_source_edit");

  // Switch base on data source type category here!
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as
    | Partial<TDataSource<DataSourceCrmConfig>>
    | undefined;
  const parameters = useSelector((state: RootState) => state.dataSourceIntegration.parameters);
  const fields = (useSelector((state: RootState) => state.dataSourceIntegration.fields) || []).filter(
    (field) => field.checked
  );

  const handleSubmit = async (form: DataSourceCrmCreateFormModel) => {
    if (form.name) {
      dispatch(
        stashDataSource({
          ...form,
          name: form.name,
          configuration: { ...form.configuration },
          type: dataSourceType,
        })
      );
    }
  };

  switch (step?.id) {
    case ECrmSteps.SOURCE_DETAILS: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <CrmDataSourceCreateForm
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            dataSource={{ ...dataSource, type: dataSourceType }}
            onSubmit={handleSubmit}
          />
        </div>
      );
    }

    case ECrmSteps.UPDATE_PARAMETERS: {
      return (
        <div className="mt-6 h-auto p-6">
          <CrmDataSourceParametersForm dataSourceParametersFormRef={crmParametersFormRef} />
        </div>
      );
    }

    case ECrmSteps.UPDATE_FIELDS: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <CrmDataSourceFieldsForm dataSourceFieldsFormRef={crmFieldsFormRef} />
        </div>
      );
    }

    case ECrmSteps.CHECK_INSTALLATION: {
      return (
        <div className="relative mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6 text-left">
          <h1 className="mb-2 text-base font-bold leading-5 text-gray-900">{t("crm.check_installation.title")}</h1>
          <p className="text-sm font-normal leading-5 text-gray-600">
            {t("crm.check_installation.source_name")}: {dataSource?.name}
          </p>
          <br />
          <p className="text-sm font-semibold leading-5 text-gray-600">{t("crm.check_installation.parameters")}:</p>
          <ul className="list-inside list-disc">
            {parameters && parameters.length ? (
              parameters.map((param) => (
                <li key={param.parameterId} className="text-sm font-normal leading-5 text-gray-600">
                  {param.description}: {param.name}
                </li>
              ))
            ) : (
              <li className="text-sm font-normal leading-5 text-gray-600">
                {t("crm.check_installation.no_parameters")}
              </li>
            )}
          </ul>
          <br />
          <p className="text-sm font-semibold leading-5 text-gray-600">{t("crm.check_installation.fields")}:</p>
          <ul className="list-inside list-disc">
            {fields && fields.length ? (
              fields.map((field) => (
                <li key={field.fieldId} className="text-sm font-normal leading-5 text-gray-600">
                  {field.name} {field.description ? `(${field.description})` : ""}
                </li>
              ))
            ) : (
              <li className="text-sm font-normal leading-5 text-gray-600">{t("crm.check_installation.no_fields")}</li>
            )}
          </ul>
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default CrmDataSourceContent;
