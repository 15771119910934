import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrowRightIcon, CheckIcon, UserIcon } from "@heroicons/react/20/solid";

import { SearchDropDown } from "../../../components/dropdowns";
import MultiSelectDropDownList from "../../../components/dropdowns/MultiSelectDropDownList";
import type { SegmentOption } from "../../../models/extendedAudiences";
import { useLazyGetSearchDemographicSegmentsQuery } from "../../../services/endpoints/extendedAudiences";
import { useWorkspace } from "../../workspaces/hooks";

type IGenderAndAgeInterestsProps = {
  selectedGenderInterests?: string[];
  selectedAgeInterests?: string[];
  onGenderInterestsChanged?: (interests: SegmentOption[]) => void;
  onAgeInterestsChanged?: (interests: SegmentOption[]) => void;
};

const GenderAndAgeInterests: FunctionComponent<IGenderAndAgeInterestsProps> = ({
  selectedAgeInterests,
  selectedGenderInterests,
  onGenderInterestsChanged,
  onAgeInterestsChanged,
}) => {
  const { t } = useTranslation("extended_audience_edit");

  const workspace = useWorkspace();

  const [genderInterests, setGenderInterests] = useState<SegmentOption[]>();
  const [ageInterests, setAgeInterests] = useState<SegmentOption[]>();

  const [getData, { data, isLoading: isSearching, isSuccess }] = useLazyGetSearchDemographicSegmentsQuery();

  useEffect(() => {
    getData({
      workspaceId: workspace.id,
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (data && selectedGenderInterests && !genderInterests?.length) {
        setGenderInterests(data.gender.filter((i) => selectedGenderInterests.includes(i.id)));
      }

      if (data && selectedAgeInterests && !ageInterests?.length) {
        setAgeInterests(data.demographic.filter((i) => selectedAgeInterests.includes(i.id)));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (genderInterests) {
      onGenderInterestsChanged?.(genderInterests);
    }
  }, [genderInterests]);

  useEffect(() => {
    if (ageInterests) {
      onAgeInterestsChanged?.(ageInterests);
    }
  }, [ageInterests]);

  const [query, setQuery] = useState("");

  const nameReplace = ({ displayName }: SegmentOption, isSelected: boolean) => {
    const nameSplit = displayName.split("-");
    return (
      <button
        className="hover:bg-glass-blue30 flex w-full cursor-pointer items-center bg-white px-5 py-2 text-left "
        type="button"
      >
        {nameSplit.length === 2 ? (
          <>
            {nameSplit[0]}
            <ArrowRightIcon className="mx-1 w-3" />
            {nameSplit[1]}
          </>
        ) : (
          displayName
        )}

        {isSelected ? <CheckIcon className="ml-auto w-5 text-green-500" /> : null}
      </button>
    );
  };

  const valueTemplate = () => {
    if (!ageInterests?.length) return [];
    const orderedAgeSelection = ageInterests
      .map((age) => age.displayName.split("-"))
      .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]));
    const resultList: string[][] = [];
    let last: string[] = orderedAgeSelection[0];
    for (let i = 1; i < orderedAgeSelection.length; i++) {
      const curr = orderedAgeSelection[i];
      if (Number.parseInt(last[1]) + 1 === Number.parseInt(curr[0])) {
        last[1] = curr[1] || curr[0];
      } else {
        resultList.push(last);
        last = curr;
      }
    }

    resultList.push(last);
    return resultList.map((res, i) => (
      <div key={i} className="mr-2 inline-flex">
        {res[0]}
        {res[1] ? (
          <>
            <ArrowRightIcon className="mx-1 w-3" />
            {res[1]}
          </>
        ) : null}
      </div>
    ));
  };

  return (
    <div className="mb-5 flex">
      <div className="inline-flex flex-grow flex-row items-center">
        <span className="mr-3 w-28 py-2 text-right text-sm font-medium text-gray-700">
          {t("extended_audience_details.gender")}
        </span>
        <div className="flex flex-grow items-center bg-white px-3 py-2 shadow" style={{ backgroundColor: "#fff" }}>
          <UserIcon className="w-5 text-gray-700" />
          <span className="mx-3">gender is</span>
          <div>
            <SearchDropDown
              openWithFocus
              items={
                query?.trim().length
                  ? data
                    ? data.gender.filter((item) => item.displayName.toLowerCase().includes(query.trim().toLowerCase()))
                    : []
                  : data?.gender || []
              }
              item={genderInterests ? genderInterests[0] : null}
              placeholder="Select a value"
              setItem={(item) => {
                setGenderInterests([item]);
              }}
              setQuery={setQuery}
              loading={isSearching}
              className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
            >
              {(result) => <span>{result.displayName}</span>}
            </SearchDropDown>
          </div>
          <span className="mx-3">and age is</span>

          <MultiSelectDropDownList
            openWithFocus
            items={data?.demographic || []}
            textField="displayName"
            valueField="id"
            value={ageInterests}
            valueChange={setAgeInterests}
            valueTemplate={valueTemplate}
            loading={isSearching}
            itemTemplate={(item, isSelected) => nameReplace(item, isSelected)}
            placeholder="select one or more values"
            className="bg-indigo-50 px-2 py-1 text-purple-500"
          />
        </div>
      </div>
    </div>
  );
};

export default GenderAndAgeInterests;
