import type { FunctionComponent } from "react";

import type { Audience } from "../../../../models/audiences";

type IAudienceOverviewProps = {
  audience?: Audience;
};

const AudienceOverview: FunctionComponent<IAudienceOverviewProps> = () => (
  <div className="flex space-x-6">
    <div className="flex-1" />
    <div className="flex-1" />
  </div>
);

export default AudienceOverview;
