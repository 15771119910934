import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { type ISimpleTableProps, SimpleTable } from "../../../../components/tables";
import type { DataSourceFileConfig, Header, TDataSource } from "../../../../models/dataSource";
import { useGetTraitsQuery } from "../../../../services/endpoints/traits";
import { useWorkspace } from "../../../workspaces/hooks";

type IPreviewCSVTableProps = {
  fileContent: string[][];
  isDefaultHeaderCorrect: boolean;
  dataSource?: TDataSource<DataSourceFileConfig>;
} & ISimpleTableProps;

const PreviewCSVTable: FunctionComponent<IPreviewCSVTableProps> = ({
  fileContent,
  isDefaultHeaderCorrect,
  dataSource,
  ...rest
}) => {
  const { t } = useTranslation("data_source_edit");

  const workspace = useWorkspace();

  const [tableContent, setTableContent] = useState<string[][]>([]);

  const { data: traits, isLoading } = useGetTraitsQuery({
    workspaceId: workspace.id,
  });

  const headers = dataSource?.configuration.headers || [];

  useEffect(() => {
    if (fileContent) {
      setTableContent(fileContent.filter((_, i) => i > 0));
    }
  }, [fileContent]);

  useEffect(() => {
    if (isDefaultHeaderCorrect) {
      setTableContent(fileContent.filter((_, i) => i > 0));
    } else {
      setTableContent(fileContent.filter((_, i) => i >= 0));
    }
  }, [isDefaultHeaderCorrect]);

  const retrieveHeaderName = (index: number) =>
    isDefaultHeaderCorrect ? fileContent[0][index] : `${t("file.header.column")} ${index + 1}`;

  const retrieveHeaderMapping = (header: Header) => (header.skip ? t("file.header.ignored") : header.displayName);

  return (
    <div>
      {headers && tableContent ? (
        <SimpleTable {...rest}>
          <thead className="rounded-lg">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="truncate border border-blue-500 bg-blue-50 px-2 py-1 text-left text-xs font-medium tracking-wider text-blue-700"
                >
                  {`${retrieveHeaderName(index)} - ${retrieveHeaderMapping(header)}`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableContent.map((rows, index) => (
              <tr key={index}>
                {rows.map((row, index) => (
                  <td key={index} className="bg-white-100 truncate border border-gray-300 px-2 py-1 text-left">
                    <span className="text-sm font-normal leading-5 text-gray-700">{row}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      ) : null}
    </div>
  );
};

export default PreviewCSVTable;
