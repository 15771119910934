import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon, CodeBracketIcon } from "@heroicons/react/24/outline";

import { type DataSourceType, type DataSourceTypeCodeConfig, EDataSourceCategory } from "../../../../models/dataSource";

type IDataSourceTypePanelContentProps = {
  dataSourceType?: DataSourceType;
};

const DataSourceTypePanelContent: FunctionComponent<IDataSourceTypePanelContentProps> = ({ dataSourceType }) => {
  const { t } = useTranslation();

  // Switch base on data source type category here!
  const configuration = dataSourceType?.configuration as DataSourceTypeCodeConfig | undefined;

  return (
    <div>
      <div className="mb-3">
        <div>
          {configuration?.website ? (
            <div className="mr-5 inline-block">
              <a href={configuration.website} className="inline-block text-sm text-blue-500 underline">
                {t("data_sources:slideover.content.visit_website")}
              </a>
            </div>
          ) : null}
          {dataSourceType?.category === EDataSourceCategory.CODE ? (
            <div className="inline-block">
              <CodeBracketIcon className="mr-1 inline-block h-5 text-gray-500" />
              <p className="mb-3 inline-block text-sm font-medium text-gray-900">
                {t("data_sources:slideover.content.code_required")}
              </p>
            </div>
          ) : (
            <div className="inline-block">
              <CheckIcon className="mr-1 inline-block h-5 text-gray-500" />
              <p className="mb-3 inline-block text-sm font-medium text-gray-900">
                {t("data_sources:slideover.content.no_code_required")}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="mb-3">
        <p className="text-sm text-gray-600">{configuration?.description}</p>
      </div>
      <div className="mb-3">
        <p className="mb-3 text-sm font-medium text-gray-900">{t("data_sources:slideover.content.best_used_for")}</p>
        <ul>
          {configuration?.usedFor?.map((use, index) => (
            <li key={index} className="list-none text-sm text-gray-600">
              <CheckIcon className="mr-2 inline-block h-5 text-green-500" />
              {use}
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-3">
        <p className="mb-3 text-sm font-medium text-gray-900">{t("data_sources:slideover.content.user_need")}</p>
        <ul className="ml-4 list-disc">
          {configuration?.requirements?.map((requirement) => (
            <li key={requirement} className="text-sm text-gray-600">
              {requirement}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DataSourceTypePanelContent;
