import { type FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UserGroupIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";

import { PercentageBadge } from "../../../../../../components/badges";
import SearchDropDown from "../../../../../../components/dropdowns/SearchDropdown";
import { SpinnerIcon } from "../../../../../../components/icons";
import { useInterval } from "../../../../../../hooks/useInterval";
import type { Audience } from "../../../../../../models/audiences";
import type { DataSource } from "../../../../../../models/dataSource";
import type { Trait } from "../../../../../../models/traits";
import {
  useGetDataSourceDependenciesQuery,
  useGetDataSourceStatsQuery,
  useGetSingleTraitDistributionsQuery,
} from "../../../../../../services/dataSources";
import { useWorkspace } from "../../../../../workspaces/hooks";

type ICommonActiveDataSourceProps = {
  dataSource: DataSource;
  polling: boolean;
};

const DEFAULT_STAT_TRAIT_ID = "email";

const CommonActiveDataSource: FunctionComponent<ICommonActiveDataSourceProps> = ({ dataSource, polling }) => {
  const workspace = useWorkspace();
  const navigate = useNavigate();
  const { t } = useTranslation("data_source_details");

  const updatedAt = useRef<number>(0);
  const [updatedAgo, setUpdatedAgo] = useState<number>(0);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [comparisonSearchQuery, setComparisonSearchQuery] = useState("");
  const [userStatTrait, setUserStatTrait] = useState<Trait>();

  const { isLoading: isTraitsLoading, data: traits } = useGetSingleTraitDistributionsQuery({
    workspaceId: workspace.id,
    datasourceId: dataSource.id,
  });
  const {
    isFetching: isStatsFetching,
    isLoading: isStatsLoading,
    isSuccess: isStatsSuccess,
    isError: isStatsError,
    data: stats,
  } = useGetDataSourceStatsQuery(
    {
      workspaceId: workspace.id,
      dataSourceId: dataSource.id,
      traitId: userStatTrait?.id || DEFAULT_STAT_TRAIT_ID,
    },
    {
      pollingInterval: polling ? 300_000 : 0,
    }
  );

  const { data: dependencies, isLoading: isDependenciesLoading } = useGetDataSourceDependenciesQuery({
    workspaceId: workspace.id,
    dataSourceId: dataSource.id,
  });

  useEffect(() => {
    if (traits) {
      setUserStatTrait(traits.distributions.find((t) => t.trait.id === DEFAULT_STAT_TRAIT_ID)?.trait);
    }
  }, [traits]);

  useEffect(() => {
    if (!isStatsFetching && (isStatsSuccess || isStatsError)) {
      updatedAt.current = Date.now();
      setUpdatedAgo(0);
    }
  }, [isStatsFetching, isStatsSuccess, isStatsError]);

  useInterval(() => {
    // Subtract 20 seconds so Math.round wil round up if it's 50 seconds or more
    const elapsedMillis = Date.now() - updatedAt.current - 20_000;
    setUpdatedAgo(Math.abs(Math.round(elapsedMillis / 60_000)));
  }, 10_000);

  const copyDataSourceID = async () => {
    if (dataSource) {
      await navigator.clipboard.writeText(dataSource.id);
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
    }
  };

  const onAudienceClick = (audience: Pick<Audience, "id" | "name">) => {
    navigate(`/audiences/${audience.id}`);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex-1">
        <div className="py-0">
          <div className="flex justify-between">
            <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("overview.datasource_details")}</h1>
            {dataSource ? (
              <h1
                className={`relative mb-2 flex cursor-pointer text-base font-bold leading-4 text-gray-900 ${
                  showTooltip ? "has-tooltip" : ""
                }`}
                onClick={async () => copyDataSourceID()}
              >
                ID: {dataSource?.id}
                <DocumentDuplicateIcon className="ml-1 h-4 w-4 flex-shrink-0 text-blue-600" aria-hidden="true" />
                <span className="tooltip bg-white-100 right-2 -mt-12 w-auto origin-top-right rounded p-2 text-sm font-normal text-gray-900 shadow-xl">
                  {t("overview.copy_id")}
                </span>
              </h1>
            ) : null}
          </div>

          <div className="bg-white-100 shadow sm:rounded-md">
            <div className="flex justify-between divide-x divide-gray-200">
              <div className="w-1/3 items-center px-4 py-6 sm:px-6">
                <p className="mb-1 w-full text-sm font-normal text-gray-500">{t("overview.events_tracked")}</p>
                {isStatsLoading ? (
                  <SpinnerIcon loading className="h-5 w-5" />
                ) : (
                  <p className="text-xl font-bold text-gray-900">{stats?.events}</p>
                )}
              </div>
              <div className="w-1/3 items-center px-4 py-6 sm:px-6">
                <div className="flex">
                  <p className="grow text-sm font-normal text-gray-500">{t("overview.unique_users")}</p>
                </div>
                {isStatsFetching ? (
                  <SpinnerIcon loading className="h-5 w-5" />
                ) : (
                  <div className="flex">
                    <p className="text-xl font-bold text-gray-900">{stats?.users}</p>
                    <SearchDropDown
                      openWithFocus
                      items={
                        comparisonSearchQuery?.trim()
                          ? traits?.distributions
                              .filter((t) =>
                                t.trait.displayName.toLowerCase().includes(comparisonSearchQuery.toLowerCase())
                              )
                              .map((t) => t.trait) || []
                          : traits?.distributions.map((t) => t.trait) || []
                      }
                      item={userStatTrait}
                      setItem={(opItemValue) => {
                        setUserStatTrait(opItemValue);
                      }}
                      acceptCustomValue={false}
                      setQuery={setComparisonSearchQuery}
                      loading={isTraitsLoading}
                      readOnly={false}
                      className="z-50 mb-1 w-3/4 cursor-pointer border-0 py-1 text-blue-500"
                    >
                      {(item) => {
                        const distribution = traits?.distributions.find((d) => d.trait.id === item.id);
                        const percentage =
                          distribution && distribution.total
                            ? Math.round((distribution.count / distribution.total) * 100)
                            : 0;
                        return (
                          <div className="flex">
                            <div className="whitespace-nowrap">{item.displayName}</div>
                            <span className="ml-auto">
                              <PercentageBadge percentage={percentage} />
                            </span>
                          </div>
                        );
                      }}
                    </SearchDropDown>
                  </div>
                )}
              </div>
              <div className="w-1/3 items-center px-4 py-6 sm:px-6">
                <p className="mb-1 text-sm font-normal text-gray-500">{t("overview.installed_on")}</p>
                {isStatsLoading ? (
                  <SpinnerIcon loading className="h-5 w-5" />
                ) : (
                  <p className="text-xl font-bold text-gray-900">
                    {dataSource.activatedOn ? format(new Date(dataSource.activatedOn), "yyyy-MM-dd HH:mm:ss") : "-"}
                  </p>
                )}
              </div>
            </div>
          </div>

          {polling && !isStatsLoading ? (
            <div className="mt-1 text-xs text-gray-500">
              {t(updatedAgo === 1 ? "overview.reload_period_one" : "overview.reload_period_other", {
                count: updatedAgo,
              })}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex-1">
        <div className="py-6">
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("overview.audiences_title")}</h1>

          <div className="bg-white-100 overflow-hidden shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {isDependenciesLoading ? (
                <li>
                  <div className="flex items-center p-4 sm:px-6">
                    <SpinnerIcon loading className="h-5 w-5" />
                  </div>
                </li>
              ) : null}
              {!isDependenciesLoading &&
                (!dependencies || !dependencies.audiences || !dependencies.audiences.length) && (
                  <li>
                    <div className="flex items-center p-4 text-sm sm:px-6">{t("overview.no_audience")}</div>
                  </li>
                )}
              {dependencies?.audiences?.map((audience) => (
                <li key={audience.id} className="group cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center p-4 sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex items-center text-sm">
                          <UserGroupIcon className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <p className="truncate font-medium text-gray-900">{audience.name}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ml-5 hidden flex-shrink-0 group-hover:flex"
                      onClick={() => {
                        onAudienceClick(audience);
                      }}
                    >
                      <p className="text-sm text-blue-600 underline">{t("overview.audience_link")}</p>
                      <ArrowTopRightOnSquareIcon
                        className="ml-1 h-5 w-5 flex-shrink-0 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonActiveDataSource;
