import type { FunctionComponent } from "react";

import type { DistributionChannel } from "../../../models/distributionChannels";
import { DestinationList } from "./";

type IDestinationSync = {
  destinations: DistributionChannel[];
};

const DestinationSync: FunctionComponent<IDestinationSync> = ({ destinations }) =>
  destinations ? <DestinationList destinations={destinations} /> : null;

export default DestinationSync;
