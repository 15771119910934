import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

import { SpinnerIcon } from "../../components/icons";
import { storeToken } from "../../features/auth/authSlice";
import { showToast } from "../../features/toasts/toastsSlice";
import { ToastType } from "../../models/toast";
import type { WorkspaceInvite } from "../../models/workspace";
import { useAppDispatch } from "../../reducers";
import { useAcceptWorkspaceInviteMutation } from "../../services/endpoints/workspaces";

const WorkspaceInviteRow: FunctionComponent<{ invite: WorkspaceInvite }> = ({ invite }) => {
  const { t } = useTranslation();
  const [acceptInvitation, { isLoading, isSuccess }] = useAcceptWorkspaceInviteMutation();
  const dispatch = useAppDispatch();

  const { logout, getAccessTokenSilently } = useAuth0();

  const accept = () => {
    acceptInvitation({ inviteId: invite.id });
  };

  useEffect(() => {
    async function postAccept() {
      console.log(isSuccess);
      if (isSuccess) {
        try {
          const token = await getAccessTokenSilently({ ignoreCache: true });

          dispatch(storeToken(token));
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              title: t("settings.delete.datasource_eliminated"),
            })
          );
          window.location.reload();
        } catch (error) {
          console.log("could not init token", error);

          /*
          If we target a route without being authenticated, getAccessToken will fail
          with an error, so we need to send user back to landing
          */
          logout({ returnTo: window.location.origin });
        }
      }
    }

    postAccept();
  }, [isSuccess]);

  return (
    <tr className="bg-white-100 group cursor-pointer border-b border-gray-200 last:border-0 hover:bg-gray-100">
      <td className="whitespace-nowrap py-1 pl-6 text-sm font-medium text-gray-900">
        <div className="my-2 flex items-center">
          {invite.workspaceName} {invite.company ? "(" + invite?.company?.name + ")" : null}
          <div className="mx-2 inline-block h-1 w-1 rounded-full bg-gray-900" />
          <span className="text-xs font-normal text-gray-500">Invited by {invite.invitedBy}</span>
        </div>
      </td>
      <td className="relative">
        {isLoading ? (
          <SpinnerIcon loading className="absolute right-5 top-3 h-4 w-4" />
        ) : invite.status === "PENDING" ? (
          <div className="my-2 mr-5 flex items-center justify-end">
            <span className="flex items-center text-sm font-normal text-blue-600 underline" onClick={accept}>
              Join the workspace
              <ArrowRightIcon className="ml-3 h-5" />
            </span>
          </div>
        ) : (
          <p />
        )}
      </td>
    </tr>
  );
};

export default WorkspaceInviteRow;
