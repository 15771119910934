function InviteStatusBadge({ status }: { status: string }) {
  switch (status) {
    case "COMPLETE": {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-green-100 px-3 py-0.5 text-xs leading-4 text-green-800">
          COMPLETE
        </div>
      );
    }

    case "PENDING": {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-yellow-100 px-3 py-0.5 text-xs leading-4 text-yellow-800">
          PENDING
        </div>
      );
    }

    case "EXPIRED": {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-red-100 px-3 py-0.5 text-xs leading-4 text-red-800">
          EXPIRED
        </div>
      );
    }

    default: {
      return null;
    }
  }
}

export default InviteStatusBadge;
