import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isEmpty } from "lodash";

import { SearchDropDown } from "../../../../components/dropdowns";
import type { ITSelectProps } from "../../../../components/selects";
import type { Rule } from "../../../../models/rules";
import type { RootState } from "../../../../reducers";
import { useGetRulesQuery } from "../../../../services/endpoints/rules";
import { useWorkspace } from "../../../workspaces/hooks";

type IEventSelectProps = {
  ruleId?: string;
  type?: "TRACK" | "VIEW";
} & Partial<ITSelectProps<Rule>>;

const EventSelect: FunctionComponent<IEventSelectProps> = ({ ruleId, type, ...rest }) => {
  const { t } = useTranslation("audience_edit");

  const workspace = useWorkspace();
  const audience = useSelector((state: RootState) => state.audienceEdit.audience);

  const { isLoading, data } = useGetRulesQuery({
    workspaceId: workspace.id,
    type,
    datasourceIds: audience?.datasourceIds,
  });

  const rules = data || [];
  const rule = rules.find((a) => a.id === ruleId);

  const [searchQuery, setSearchQuery] = useState("");

  if (!isLoading && isEmpty(rules)) {
    return (
      <div className="grid h-full place-content-center">
        <p className="text-sm">{t("rules.there_are_no_events")}</p>
      </div>
    );
  }

  return (
    <SearchDropDown
      openWithFocus
      items={
        searchQuery?.trim()
          ? rules.filter((item) => item.name.toLowerCase().includes(searchQuery?.trim().toLowerCase()))
          : rules
      }
      item={rule}
      setItem={(item) => {
        rest.onChange && rest.onChange(item as any);
      }}
      setQuery={setSearchQuery}
      loading={isLoading}
      className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
      readOnly={rest.isViewMode}
      displayField="name"
      placeholder="Event"
    >
      {(item) => <div className="whitespace-nowrap">{item.name}</div>}
    </SearchDropDown>
  );
};

export default EventSelect;
