import { useEffect, useRef } from "react";

type FunctionType = () => void;

export function useInterval(callback: FunctionType, delay: number) {
  const savedCallback = useRef<FunctionType>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}
