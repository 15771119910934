import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

const TrackCustomEvents: FunctionComponent = () => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className="w-full rounded-lg border border-gray-200 shadow">
      <div className="mb-2 flex flex-col rounded-lg px-6 py-4">
        <div className="flex">
          <svg
            className="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M14.8755 13.7629L15.5599 17.7455L11.9835 15.8649L8.40712 17.7455L9.09149 13.7629L6.19531 10.9406L10.1943 10.3573L11.9825 6.733L13.7707 10.3573L17.7724 10.9406L14.8755 13.7629Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.2474 8.32674L20.3203 9.06632C21.2301 9.19827 21.5946 10.3198 20.9346 10.9615L17.2644 14.5379L18.1325 19.5899C18.2561 20.3122 17.6804 20.8889 17.0356 20.8889C16.8564 20.8887 16.68 20.8447 16.5217 20.7608L11.9835 18.3754L7.44533 20.7608C7.28689 20.8459 7.10991 20.8906 6.93005 20.891C6.28561 20.891 5.71061 20.3125 5.83422 19.5906L6.70227 14.5385L3.03213 10.9622C2.37241 10.3198 2.737 9.19827 3.64672 9.06632L8.71963 8.32674L10.987 3.72952C11.1741 3.35008 11.5605 3.10983 11.9835 3.10983C12.4066 3.10983 12.7929 3.35008 12.9801 3.72952L15.2474 8.32674ZM15.561 17.7455L14.8766 13.7628L17.7735 10.9406L13.7717 10.3573L11.9835 6.73299L10.1953 10.3573L6.19637 10.9406L9.09255 13.7628L8.40818 17.7455L11.9846 15.8649L15.561 17.7455Z"
              fill="#54647A"
            />
          </svg>
          <h1 className="my-auto text-sm font-semibold text-gray-900">{t("code.track_events.track_custom_event")}</h1>
        </div>
        <div className="mt-3 flex">
          <span className=" text-sm font-normal text-gray-600">
            {t("code.track_events.track_custom_event_description")}
            <a href="#" target="_blank" className="text-sm font-normal text-blue-600">
              <span className="flex underline">
                {t("code.track_events.see_analytics_documentation")}
                <ArrowTopRightOnSquareIcon className="my-auto ml-1 h-4 text-blue-600" />
              </span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrackCustomEvents;
