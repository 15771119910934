import { Fragment, type FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Transition } from "@headlessui/react";
import { first } from "lodash";

import { Button } from "../../../components/buttons";
import type { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import type { Workspace } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import { useDeleteWorkspaceMutation, useGetWorkspacesQuery } from "../../../services/endpoints/workspaces";
import { ModalHeader } from "../../layout/modal";
import RouteConstants from "../../router/RouteConstants";
import { showToast } from "../../toasts/toastsSlice";
import { setCurrentWorkspace } from "../../workspaces/workspacesSlice";

type IConfirmWorkspaceDeleteProps = {
  workspace: Workspace;
};

const ConfirmWorkspaceDelete: FunctionComponent<IConfirmWorkspaceDeleteProps & IModalContentProps> = ({
  closeModal,
  onToggleDismissable,
  workspace,
}) => {
  const { t } = useTranslation("workspaces");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteWorkspace, { isSuccess, isLoading }] = useDeleteWorkspaceMutation();
  const { data: workspaces } = useGetWorkspacesQuery({ limit: 50, offset: 0 });

  const [confirmStep, toggleConfirmStep] = useState(false);

  useEffect(() => {
    if (isSuccess && workspaces) {
      if (workspaces.items.find((w) => w.id === workspace.id)) {
        navigate(RouteConstants.home);
      } else {
        dispatch(setCurrentWorkspace(first(workspaces.items)));

        navigate(RouteConstants.defaultRoute);

        closeModal();

        dispatch(
          showToast({
            type: ToastType.SUCCESS,
            title: `Workspace eliminated!`,
            message: `${workspace?.name} deleted successfully`,
          })
        );
      }
    }
  }, [isSuccess, workspaces]);

  useEffect(() => {
    if (onToggleDismissable) {
      onToggleDismissable(!isLoading);
    }
  }, [isLoading]);

  const handlePrevButtonClicked = () => {
    if (confirmStep) {
      toggleConfirmStep(false);
    } else {
      closeModal();
    }
  };

  const handleNextButtonClicked = () => {
    if (confirmStep) {
      deleteWorkspace({
        workspaceId: workspace.id,
      });
    } else {
      toggleConfirmStep(true);
    }
  };

  return (
    <div className="flex h-auto flex-col divide-y divide-gray-200 text-left">
      <div className="flex min-h-0 flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <ModalHeader closeModal={closeModal} title={t("delete.title")} loading={isLoading} />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <Transition
            as={Fragment}
            show={!confirmStep}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
          >
            <div className="p-3">
              <p>
                <Trans>{t("delete.friction_step.description", { name: workspace.name })}</Trans>
              </p>
              <ul className="list-inside list-disc py-2 pl-4">
                <li>{t("delete.friction_step.first_point")}</li>
                <li>{t("delete.friction_step.second_point")}</li>
                <li>{t("delete.friction_step.third_point")}</li>
                <li>{t("delete.friction_step.fourth_point")}</li>
              </ul>
              <p className="pt-6">{t("delete.friction_step.are_you_sure")}</p>
            </div>
          </Transition>
          <Transition
            as={Fragment}
            show={confirmStep}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
          >
            <div className="p-3">
              <p>
                <Trans>{t("delete.confirm_step.final_confirmation", { name: workspace.name })}</Trans>
              </p>
              <p className="pt-8">{t("delete.confirm_step.are_you_really_sure")}</p>
            </div>
          </Transition>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        <Button
          variant="light"
          type="button"
          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          disabled={isLoading}
          onClick={handlePrevButtonClicked}
        >
          {t(confirmStep ? "delete.back" : "delete.cancel")}
        </Button>
        <Button
          variant={confirmStep ? "delete" : "primary"}
          type="submit"
          className="ml-4 inline-flex justify-center px-4 py-2"
          loading={isLoading}
          onClick={handleNextButtonClicked}
        >
          {t(confirmStep ? "delete.delete_workspace" : "delete.proceed_with_deletion")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmWorkspaceDelete;
