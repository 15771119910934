import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ChartBarSquareIcon,
  CreditCardIcon,
  GlobeAltIcon,
  HeartIcon,
  MapIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import numbro from "numbro";

import { Button } from "../../../../components/buttons";
import { useModalContext } from "../../../../hooks/useModalContext";
import type { ExtendedAudience } from "../../../../models/extendedAudiences";
import { useAppDispatch } from "../../../../reducers";
import {
  useGetSearchDemographicSegmentsQuery,
  useGetSearchGeographicSegmentsQuery,
  useGetSearchInterestsSegmentsQuery,
} from "../../../../services/endpoints/extendedAudiences";
import ExtendedAudienceEdit, { EExtendedAudienceSteps } from "../../../extendedAudienceEdit/ExtendedAudienceEdit";
import { clearStashedExtendedAudience } from "../../../extendedAudienceEdit/extendedAudienceEditSlice";
import { useWorkspace } from "../../../workspaces/hooks";

type IExtendedAudienceOverviewProps = {
  extendedAudience: ExtendedAudience;
};
function format(value: number): string {
  return numbro(value).format({
    average: value >= 10_000,
    spaceSeparated: true,
    trimMantissa: true,
    mantissa: 1,
  });
}

const ExtendedAudienceOverview: FunctionComponent<IExtendedAudienceOverviewProps> = ({ extendedAudience }) => {
  const { t } = useTranslation("extended_audience_details");
  const dispatch = useAppDispatch();
  const { openModal } = useModalContext();

  const { id: workspaceId, extendedAudience: dmpConf } = useWorkspace();
  const { name, configuration } = extendedAudience;
  const { gender, interest, demographic, geographic } = configuration.segments;
  const { size, compositeResponse, licenseResponse } = configuration;
  const { data: geoSegments, isLoading: isLoadingGeo } = useGetSearchGeographicSegmentsQuery({ workspaceId, q: "" });
  const { data: interestsSegment, isLoading: isLoadingInterests } = useGetSearchInterestsSegmentsQuery({
    workspaceId,
    q: "",
  });
  const { data: demoGenderSegments, isLoading: isLoadingDemo } = useGetSearchDemographicSegmentsQuery({ workspaceId });
  console.log(extendedAudience);
  const [demoMap, setDemoMap] = useState<Record<string, string>>({});
  const [genderMap, setGenderMap] = useState<Record<string, string>>({});
  const [geoMap, setGeoMap] = useState<Record<string, string>>({});
  const [interestMap, setInterestMap] = useState<Record<string, string>>({});
  const [dmpItems, setDmpItems] = useState<Record<string, string>>({});

  useEffect(() => {
    if (dmpConf) {
      const m: Record<string, string> = {};
      for (const d of dmpConf.dmp) {
        m[d.dmp + d.product] = d.dmpName + " " + d.productName;
      }

      setDmpItems(m);
    }
  }, []);
  useEffect(() => {
    if (interestsSegment) {
      const m: Record<string, string> = {};
      for (const e of interestsSegment) {
        m[e.id] = e.displayName;
      }

      setInterestMap(m);
    }
  }, [interestsSegment]);

  useEffect(() => {
    if (geoSegments) {
      const m: Record<string, string> = {};
      for (const e of geoSegments) {
        m[e.id] = e.displayName;
      }

      setGeoMap(m);
    }
  }, [geoSegments]);

  useEffect(() => {
    if (demoGenderSegments?.demographic) {
      const m: Record<string, string> = {};
      for (const e of demoGenderSegments.demographic) {
        m[e.id] = e.displayName;
      }

      setDemoMap(m);
    }

    if (demoGenderSegments?.gender) {
      const m: Record<string, string> = {};
      for (const e of demoGenderSegments.gender) {
        m[e.id] = e.displayName;
      }

      setGenderMap(m);
    }
  }, [demoGenderSegments]);

  const openModalForLicense = () => {
    dispatch(clearStashedExtendedAudience());

    openModal({
      renderContent: ExtendedAudienceEdit,
      renderContentProps: { audienceId: extendedAudience.id, initialStep: EExtendedAudienceSteps.LICENSE },
      fullHeight: true,
      fullWidth: true,
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex-1">
        <div>
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">Size</h1>
          <div className="py-2 text-lg font-bold">{format(size || 0)}</div>
        </div>
        <div className="py-2">
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">Segments</h1>
          <div className="h-auto text-left font-medium">
            <div className="px-4 py-5">
              <ul>
                {gender?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <UserIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Gender is {gender?.map((o) => genderMap[o])?.join(",")}{" "}
                    </span>
                  </li>
                ) : null}
                {demographic?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <ChartBarSquareIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Age is between {demographic.map((o) => demoMap[o])?.join(" or ")}
                    </span>
                  </li>
                ) : null}
                {geographic?.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <MapIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Located in {geographic?.map((o) => geoMap[o])?.join(" or in ")}
                    </span>
                  </li>
                ) : null}
                {interest.length ? (
                  <li className="mb-2 flex items-center text-base text-gray-700">
                    <HeartIcon className="mr-2 h-4 text-gray-600" />
                    <span className="text-md mr-3 text-right font-medium text-gray-700">
                      Interested in {interest.map((o) => interestMap[o])?.join(" or in ")}
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="py-2">
            <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">License</h1>
            {!licenseResponse && (
              <div className="px-4 py-5">
                <Button variant="primary" icon={PlusIcon} className="mr-6 h-9" onClick={openModalForLicense}>
                  License audience
                </Button>
              </div>
            )}
            {licenseResponse ? (
              <div className="h-auto text-left font-medium">
                <div className="px-4 py-5">
                  <ul>
                    <li className="mb-2 flex items-center text-base text-gray-700">
                      <GlobeAltIcon className="mr-2 h-4 text-gray-600" />
                      <span className="text-md mr-3 text-right font-medium text-gray-700">
                        {dmpItems[licenseResponse?.dmp + (licenseResponse?.dmpProductId || "")]}
                      </span>
                    </li>
                    <li className="mb-2 flex items-center text-base text-gray-700">
                      <CreditCardIcon className="mr-2 h-4 text-gray-600" />
                      <span className="text-md mr-3 text-right font-medium text-gray-700">
                        {licenseResponse?.currency?.toUpperCase()}
                      </span>
                    </li>
                    <li className="mb-2 flex items-center text-base text-gray-700">
                      <UserIcon className="mr-2 h-4 text-gray-600" />
                      <span className="text-md mr-3 text-right font-medium text-gray-700">
                        {licenseResponse?.dmpClientId}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedAudienceOverview;
