import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { XMarkIcon } from "@heroicons/react/20/solid";
import { FieldArray, type FieldArrayRenderProps, type FormikErrors } from "formik";
import { isEmpty } from "lodash";

import { Button } from "../../../../../../components/buttons";
import { Input, PrefixInput } from "../../../../../../components/inputs";
import type { DataSourceCodeConfig, TDataSource } from "../../../../../../models/dataSource";
import type { IPageEventsError, ITrackClickEvents, ITrackPageVisitEvents, ITrackWebsiteValue } from "../../../../types";
import { AddButton } from "../buttons";

type ITrackPageVisitProps = {
  values: ITrackWebsiteValue;
  errors: FormikErrors<ITrackWebsiteValue>;
  dataSource: Partial<TDataSource<DataSourceCodeConfig>> | undefined;
  handleSetFieldValue: (field: string, value: ITrackClickEvents | ITrackPageVisitEvents) => void;
  handleSetFieldError: (field: string, message: string | undefined) => void;
  handleDeleteRule: (ruleId: string) => void;
};

const TrackPageVisit: FunctionComponent<ITrackPageVisitProps> = (props) => {
  const { values, errors, dataSource, handleSetFieldValue, handleSetFieldError, handleDeleteRule } = props;

  const { t } = useTranslation("data_source_edit");

  const handleAddPage = (pageVisitEventHelper: FieldArrayRenderProps) => {
    const identifier = crypto.randomUUID();
    const baseUrl = `${dataSource?.configuration?.url}/`;

    pageVisitEventHelper.push({ identifier, baseUrl, pagePath: "", eventName: "" });
  };

  const handleDeleteEvent = (pageVisitEventHelper: FieldArrayRenderProps, index: number, ruleId?: string) => {
    if (ruleId) {
      handleDeleteRule(ruleId);
    }

    pageVisitEventHelper.remove(index);
  };

  const getInfoByPath = (event: ITrackPageVisitEvents): string => {
    const path = `${event.baseUrl}${event.pagePath ?? ""}`;

    const parser = document.createElement("a");
    parser.href = path;

    if (parser.pathname !== "/") {
      if (parser.pathname.endsWith("/*")) {
        const pathPage = path.slice(0, Math.max(0, path.length - 2));
        return t("code.track_events.track_all_pages", { path: pathPage });
      }

      return t("code.track_events.track_page", { path });
    }

    return t("code.track_events.track_homepage");
  };

  return (
    <div>
      <FieldArray
        name="pageVisitEvents"
        validateOnChange={false}
        render={(pageVisitEventHelper) => (
          <div>
            {pageVisitEventHelper && !isEmpty(pageVisitEventHelper)
              ? values.pageVisitEvents.map((event, index) => (
                  <div key={index} className="my-2 flex px-4">
                    <div className="w-1/2 flex-auto">
                      <PrefixInput
                        value={event.pagePath}
                        error={
                          errors.pageVisitEvents && errors.pageVisitEvents[index]
                            ? (errors.pageVisitEvents[index] as IPageEventsError).pagePath
                            : undefined
                        }
                        extraInfo={getInfoByPath(event)}
                        disabled={event.id !== undefined}
                        prefix={event.baseUrl}
                        onChange={(e) => {
                          handleSetFieldError(`pageVisitEvents.${index}.pagePathError`);
                          handleSetFieldValue(`pageVisitEvents.${index}`, { ...event, pagePath: e.target.value });
                        }}
                      />
                    </div>
                    <div className="flex w-1/2">
                      <span className="ml-2 mr-1 h-11 py-2">{t("code.track_events.creates")}</span>
                      <div className="flex-auto">
                        <Input
                          placeholder={t("code.track_events.name_of_the_event")}
                          value={event.eventName}
                          error={
                            errors.pageVisitEvents && errors.pageVisitEvents[index]
                              ? (errors.pageVisitEvents[index] as IPageEventsError).eventName
                              : undefined
                          }
                          disabled={event.id !== undefined}
                          onChange={(e) => {
                            handleSetFieldError(`pageVisitEvents.${index}.eventError`);
                            handleSetFieldValue(`pageVisitEvents.${index}`, { ...event, eventName: e.target.value });
                          }}
                        />
                      </div>

                      <Button
                        variant="transparent"
                        type="button"
                        className="ml-1 h-11 flex-1"
                        onClick={() => {
                          handleDeleteEvent(pageVisitEventHelper, index, event.id);
                        }}
                      >
                        <XMarkIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                      </Button>
                    </div>
                  </div>
                ))
              : null}
            <div className="bg-white px-5 py-6">
              <AddButton
                label={t("code.track_events.buttons.add_page")}
                onClick={() => {
                  handleAddPage(pageVisitEventHelper);
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default TrackPageVisit;
