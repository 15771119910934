import type React from "react";
import type { FunctionComponent } from "react";
import DatePicker, { type ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { omit } from "lodash";
import { twMerge } from "tailwind-merge";

type IDatePickerInputProps = {
  id?: string;
  label?: string;
  className?: string;
  error?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  extraInfo?: string;
  isViewMode?: boolean;
} & ReactDatePickerProps;

const DatePickerInput: FunctionComponent<IDatePickerInputProps> = ({
  id,
  name,
  label,
  className,
  onChange,
  error,
  extraInfo,
  isViewMode,
  ...rest
}) => {
  let inputStyle =
    "inline-flex w-full px-2 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400";

  if (rest.icon) {
    inputStyle = twMerge(inputStyle, "pl-10");
  }

  if (error) {
    inputStyle = twMerge(inputStyle, "border-red-500 hover:border-red-500 focus:border-red-500 outline-none");
  }

  if (rest.disabled) {
    inputStyle = twMerge(inputStyle, "bg-gray-200 text-gray-500");
  }

  const handleChange = (
    date: Date | [Date | undefined, Date | undefined] | undefined,
    event: React.SyntheticEvent<any> | undefined
  ) => {
    if (onChange) {
      onChange(date, event);
    }
  };

  return (
    <div className="inline-flex w-full flex-col text-left">
      {label ? (
        <label htmlFor={name} className="block py-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <div className="relative w-auto rounded-md">
        {rest.icon ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <rest.icon className="mr-4 h-6 w-6 flex-shrink-0 text-gray-500" />
          </div>
        ) : null}
        <DatePicker
          {...omit(rest, "icon")}
          id={id}
          name={name}
          className={className ? twMerge(inputStyle, className) : inputStyle}
          disabled={rest.disabled || isViewMode}
          onChange={handleChange}
        />
      </div>
      {extraInfo ? <p className="mt-2 text-sm text-gray-800">{extraInfo}</p> : null}
      {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : null}
    </div>
  );
};

export default DatePickerInput;
