import { type FunctionComponent, useEffect, useState } from "react";

import { Input } from "../../../../components/inputs";
import { Select } from "../../../../components/selects";
import type { AudienceSyncTimeBasedSchedule, SyncScheduleUnit } from "../../../../models/audiences";
import AudienceSyncRepeatMonthday from "./AudienceSyncRepeatMonthday";
import AudienceSyncRepeatWeekday from "./AudienceSyncRepeatWeekday";

type IAudienceSyncRepeatUnitProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

type UnitOption = { id: SyncScheduleUnit; label: string };

const AudienceSyncRepeatUnit: FunctionComponent<IAudienceSyncRepeatUnitProps> = ({
  scheduleConfiguration,
  setFieldValue,
}) => {
  const units: UnitOption[] = [
    {
      id: "HOURS",
      label: "hours",
    },
    {
      id: "DAYS",
      label: "days",
    },
    {
      id: "WEEKS",
      label: "weeks",
    },
    {
      id: "MONTHS",
      label: "months",
    },
  ];
  const [unit, setUnit] = useState<UnitOption>(
    units.find((item) => item.id === scheduleConfiguration.unit) || units[0]
  );

  useEffect(() => {
    if (unit) {
      setFieldValue("scheduleConfiguration.unit", unit.id);
    }
  }, [unit]);

  return (
    <>
      <div className="mb-6 flex flex-row items-center">
        <span className="mr-4">Then, repeats every</span>
        <div className="mr-4 w-20">
          <Input
            type="number"
            min={1}
            value={scheduleConfiguration.repeatsEvery}
            onChange={(e) => {
              setFieldValue("scheduleConfiguration.repeatsEvery", Number.parseInt(e.target.value));
            }}
          />
        </div>
        <div className="mr-4">
          <Select
            items={units}
            value={unit}
            onChange={(value) => {
              setUnit(value as UnitOption);
            }}
          />
        </div>
        {unit.id === "WEEKS" && (
          <AudienceSyncRepeatWeekday scheduleConfiguration={scheduleConfiguration} setFieldValue={setFieldValue} />
        )}
      </div>
      {unit.id === "MONTHS" && (
        <AudienceSyncRepeatMonthday scheduleConfiguration={scheduleConfiguration} setFieldValue={setFieldValue} />
      )}
    </>
  );
};

export default AudienceSyncRepeatUnit;
