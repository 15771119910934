import type { FunctionComponent } from "react";

import type { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import AudienceSyncRepeatNext from "./AudienceSyncRepeatNext";
import AudienceSyncRepeatStart from "./AudienceSyncRepeatStart";
import AudienceSyncRepeatUnit from "./AudienceSyncRepeatUnit";

type IAudienceSyncRepeatConfigProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const AudienceSyncRepeatConfig: FunctionComponent<IAudienceSyncRepeatConfigProps> = ({
  scheduleConfiguration,
  setFieldValue,
}) => (
  <div className="p-6">
    <AudienceSyncRepeatStart scheduleConfiguration={scheduleConfiguration} setFieldValue={setFieldValue} />
    <AudienceSyncRepeatUnit scheduleConfiguration={scheduleConfiguration} setFieldValue={setFieldValue} />
    <AudienceSyncRepeatNext scheduleConfiguration={scheduleConfiguration} />
  </div>
);

export default AudienceSyncRepeatConfig;
