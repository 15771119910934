import { type FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../../../components/buttons";
import { type RootState, useAppDispatch } from "../../../../reducers";
import {
  type InviteCompanyUserListItemArgs,
  useInviteCompanyUserMutation,
} from "../../../../services/endpoints/companies";
import RouteConstants from "../../../router/RouteConstants";
import { setOnboard } from "../../../userInfo/userInfoSlice";

type InviteCollaboratorsProps = {
  back?: () => void;
};

const InviteCollaborators: FunctionComponent<InviteCollaboratorsProps> = ({ back }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const company = useSelector((state: RootState) => state.userInfo.company);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [collaborators, setCollaborators] = useState<string[]>(["", "", ""]);
  const [contact, setContact] = useState<string>("");
  const [contactIsMe, setContactIsMe] = useState<boolean>(false);

  const [inviteCompanyUser, { data }] = useInviteCompanyUserMutation();

  useEffect(() => {
    if (data) {
      dispatch(
        setOnboard({
          forceCompanyCreation: false,
        })
      );
      navigate(RouteConstants.home);
    }
  }, [data]);

  const save = (event: any) => {
    event.preventDefault();

    const reqBody: InviteCompanyUserListItemArgs[] = collaborators
      .filter((collaborator) => Boolean(collaborator.trim()))
      .map((collaborator) => ({
        email: collaborator,
        role: "ADMIN",
      }));
    if (contact && !contactIsMe) {
      reqBody.push({ email: contact, role: "ACCOUNTING" });
    }

    if (reqBody.length) {
      for (const inviteCompanyUserListItemArg of reqBody) {
        inviteCompanyUser({
          companyId: company?.id,
          userList: inviteCompanyUserListItemArg,
        });
      }
    } else {
      dispatch(
        setOnboard({
          forceCompanyCreation: false,
        })
      );
      navigate(RouteConstants.home);
    }
  };

  return (
    <div className="bg-wave flex h-full w-full items-center justify-center bg-gray-100">
      <div className="bg-white-100 flex flex-col items-center rounded-lg p-8 shadow-md" style={{ width: "448px" }}>
        <h3 className="mb-5 text-xl font-semibold">Invite your closest collaborators</h3>
        <h3 className="mb-1 mr-auto text-lg font-semibold">Admins</h3>
        <p className="mb-4 text-sm">
          They help you in the management od Audiencerate. They can create workspaces and invite other people.
        </p>
        {errorMessage ? (
          <div className="text-white-100 mb-3 w-full rounded border-red-300 bg-red-600 p-2">{errorMessage}</div>
        ) : null}
        <form className="w-full">
          {collaborators.map((collaborator, index) => (
            <div key={index} className="mb-4">
              <input
                className="w-full rounded-md border-gray-300 shadow-sm"
                type="text"
                value={collaborator}
                placeholder={`collaborator@${company?.domains[0]}`}
                readOnly={false}
                onChange={(event) => {
                  let newCollaboratorsList = collaborators.map((coll, i) => (i === index ? event.target.value : coll));

                  newCollaboratorsList = newCollaboratorsList || [];

                  const tempArray = [...newCollaboratorsList];
                  let i = tempArray.reverse().findIndex((coll) => Boolean(coll.trim()));
                  if (i > -1) {
                    i = tempArray.length - 1 - i;
                    if (i + 3 < newCollaboratorsList.length) {
                      newCollaboratorsList.splice(i + 3);
                    } else if (i + 3 > newCollaboratorsList.length) {
                      newCollaboratorsList = newCollaboratorsList.concat(
                        new Array(i + 3 - newCollaboratorsList.length).fill("")
                      );
                    }
                  }

                  setCollaborators(newCollaboratorsList);
                }}
              />
            </div>
          ))}
          <h3 className="mb-1 mr-auto text-lg font-semibold">Accounting</h3>
          <p className="mb-4 text-sm">
            They will compile the legal fields for you and be able to download the pdfs of the invoices without
            bothering you, and do the other bureaucratic things.
          </p>
          <div className="mb-4">
            <input
              className="w-full rounded-md border-gray-300 shadow-sm"
              type="text"
              value={contact}
              placeholder={`accounting@${company?.domains[0]}`}
              disabled={contactIsMe}
              onChange={(event) => {
                setContact(event.target.value);
              }}
            />
          </div>
          <div className="mb-8">
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={contactIsMe}
                onChange={(event) => {
                  if (event.target.checked) {
                    setContact(user?.email || "");
                  }

                  setContactIsMe(event.target.checked);
                }}
              />
              I am the one who takes care of it
            </label>
          </div>

          <Button className="mb-3 w-full" type="button" variant="confirm" onClick={save}>
            Finish initial setting
          </Button>

          <button
            className="border-grey-300 bg-white-100 mb-3 w-full rounded-md border px-3 py-2 shadow-sm hover:bg-gray-50"
            type="button"
            onClick={() => {
              back && back();
            }}
          >
            Back
          </button>
        </form>
      </div>
    </div>
  );
};

export default InviteCollaborators;
