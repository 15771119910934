import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "lodash";

import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import { type RootState, useAppDispatch } from "../../reducers";
import { useGetAudiencesQuery } from "../../services/endpoints/audiences";
import { MainContentWrapper } from "../layout/main";
import { useWorkspace } from "../workspaces/hooks";
import { reset } from "./audiencesSlice";
import { AudienceEmptySet } from "./components";
import AudienceListTabs from "./components/lists/AudienceListTabs";

type IAudienceProps = Record<string, unknown>;

const MINIMUM_LOADING_DURATION = 400;

const Audiences: FunctionComponent<IAudienceProps> = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const uiState = useSelector((state: RootState) => state.audiences.ui);
  if (!uiState.regular) {
    dispatch(reset());
  }

  const {
    data: audiences,
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetAudiencesQuery({
    workspaceId: workspace.id,
    offset: 0,
    limit: 1,
  });
  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  return shouldShowLoading ? (
    <MainContentWrapper>
      <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
        <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
      </span>
    </MainContentWrapper>
  ) : isFetching || (audiences && !isEmpty(audiences)) || Boolean(uiState.regular.searchText) ? (
    <AudienceListTabs />
  ) : (
    <AudienceEmptySet />
  );
};

export default Audiences;
