export type Toast = {
  id: string;
  type: ToastType;
  title: string;
  message: string;
  visible: boolean;
  autodismiss?: boolean;
  dismissDuration?: number;
};

export enum ToastType {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
}
