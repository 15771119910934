import { type FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";

import { Select } from "../../../../../../components/selects";
import type { ISelectItem } from "../../../../../../models/select";
import { type TraitDataType, TRAITS_DATA_TYPES } from "../../../../../../models/traits";
import type { RootState } from "../../../../../../reducers";
import { useGetTraitsQuery } from "../../../../../../services/endpoints/traits";
import { useWorkspace } from "../../../../../workspaces/hooks";
import { EVENT } from "../../../../constants";
import { TRAITS } from "../../../../types";

type ITraitInputProps = {
  option: ISelectItem;
  error?: string;
  value?: TraitDataType;
  onChange: (value: TraitDataType) => void;
};

const DataTypeInput: FunctionComponent<ITraitInputProps> = ({ value, option, error, onChange }) => {
  const workspace = useWorkspace();

  const { data } = useGetTraitsQuery({ workspaceId: workspace.id });

  const dataType = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource?.configuration?.type);

  const traitList: ISelectItem[] = [];

  const dataTypesItems = TRAITS_DATA_TYPES.map((dt) => ({
    id: dt.key,
    label: dt.value,
  }));

  if (data) {
    const customerTrait = data.customer.map((trait) => ({
      id: trait.id,
      label: trait.displayName,
    }));

    traitList.push(...customerTrait);

    if (dataType === EVENT) {
      const eventTrait = data.event.map((trait) => ({
        id: trait.id,
        label: trait.displayName,
      }));
      traitList.push(...eventTrait);
    }
  }

  useEffect(() => {
    if (option.id === TRAITS.CUSTOM_TRAIT) {
      onChange(dataTypesItems[0].id as TraitDataType);
    }
  }, [option.id]);

  switch (option.id) {
    case TRAITS.CUSTOM_TRAIT: {
      return (
        <Select
          items={dataTypesItems}
          value={dataTypesItems.find((e) => e.id == value) || dataTypesItems[0]}
          className="-mt-1 w-full text-left "
          error={error}
          onChange={(e) => {
            onChange(String(e.id) as TraitDataType);
          }}
        />
      );
    }

    case TRAITS.MAP_TO_TRAIT:
    case TRAITS.CONSENT_TRAIT:
    case TRAITS.IGNORE_COLUMN:
    default: {
      return <></>;
    }
  }
};

export default DataTypeInput;
