import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../../../components/buttons";

const DuplicateDataSource: FunctionComponent = () => {
  const { t } = useTranslation("data_source_details");

  return (
    <div>
      <h1 className="text-black-100 text-lg font-semibold">{t("settings.duplicate.duplicate_datasource_title")}</h1>
      <h3 className="my-3 text-base">{t("settings.duplicate.duplicate_datasource_desc")}</h3>
      <Button icon={DocumentDuplicateIcon}>{t("settings.duplicate.duplicate_this_datasource")}</Button>
    </div>
  );
};

export default DuplicateDataSource;
