import type { FunctionComponent } from "react";

import { useModalContext } from "../../../../hooks/useModalContext";
import type { ExtendedAudienceEdit } from "../../../../models/extendedAudiences";
import { DeleteExtendedAudience, GeneralExtendedAudienceSettings } from "./settings";
import { DeleteExtendedAudienceModal } from "./settings/edit";

type IExtendedAudienceSettingsProps = {
  extendedAudience?: ExtendedAudienceEdit;
};

const ExtendedAudienceSettings: FunctionComponent<IExtendedAudienceSettingsProps> = ({ extendedAudience }) => {
  const { openModal } = useModalContext();

  const handleDeleteExtendedAudienceClicked = () => {
    if (extendedAudience) {
      openModal({
        renderContent: DeleteExtendedAudienceModal,
        renderContentProps: {
          extendedAudience,
        },
        dismissable: true,
        fullWidth: false,
        fullHeight: false,
      });
    }
  };

  return (
    <div className="flex space-x-6">
      <div className="flex-1">
        <div className="space-y-24 py-8">
          <div className="flex flex-col space-y-24">
            <GeneralExtendedAudienceSettings extendedAudience={extendedAudience} />
          </div>
          <DeleteExtendedAudience handleDeleteExtendedAudienceClicked={handleDeleteExtendedAudienceClicked} />
        </div>
      </div>
    </div>
  );
};

export default ExtendedAudienceSettings;
