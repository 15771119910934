import type React from "react";
import { type FunctionComponent, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { SpinnerIcon } from "../../../../../components/icons";
import Paginated from "../../../../../components/tables/pagination/Paginated";
import { useDelayedBoolean } from "../../../../../hooks/useDelayedBoolean";
import type { ExtendedAudience } from "../../../../../models/extendedAudiences";
import type { RootState } from "../../../../../reducers";
import { useLazyGetExtendedAudiencesQuery } from "../../../../../services/endpoints/extendedAudiences";
import { MainContentWrapper } from "../../../../layout/main";
import { useWorkspace } from "../../../../workspaces/hooks";
import { setExtendedPagination, setExtendedSearchText } from "../../../audiencesSlice";
import EmptyAudiences from "../EmptyAudiences";
import ExtendedAudienceList from "./ExtendedAudienceList";

const MINIMUM_LOADING_DURATION = 400;

type IAudienceExtendedListTabProps = Record<string, unknown>;

const ExtendedAudienceListTab: FunctionComponent<IAudienceExtendedListTabProps> = () => {
  const workspace = useWorkspace();

  const searchText = useSelector((state: RootState) => state.audiences.ui.extended.searchText);

  const [getExtendedAudiences, { data, isFetching, isLoading, isUninitialized }] = useLazyGetExtendedAudiencesQuery();
  const [extendedAudiences, setExtendedAudiences] = useState<ExtendedAudience[]>([]);

  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  const queryCallback = useCallback(
    async (pageNo: number, pageSize: number) =>
      getExtendedAudiences({
        workspaceId: workspace.id,
        offset: pageNo * pageSize,
        limit: pageSize,
        searchText: searchText && searchText.length > 2 ? searchText : undefined,
      }),
    [searchText]
  );

  const renderContent = (pager: React.ReactNode) =>
    shouldShowLoading ? (
      <MainContentWrapper>
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
          <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
        </span>
      </MainContentWrapper>
    ) : isFetching || data?.items?.length || Boolean(searchText) ? (
      <ExtendedAudienceList
        audiences={extendedAudiences}
        searchStateSelector={(state: RootState) => state.audiences.ui.extended.searchText}
        searchStateUpdater={setExtendedSearchText}
        pager={pager}
      />
    ) : (
      <EmptyAudiences isExtended />
    );

  return (
    <Paginated
      stateSelector={(state) => state.audiences.ui.extended.pagination}
      stateUpdater={setExtendedPagination}
      query={queryCallback}
      queryResponse={data ? data.items : undefined}
      setItems={setExtendedAudiences}
      querying={isFetching}
    >
      {renderContent}
    </Paginated>
  );
};

export default ExtendedAudienceListTab;
