import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { InviteStatusBadge, SimpleBadge } from "../../../components/badges";
import { Button } from "../../../components/buttons";
import { Input } from "../../../components/inputs";
import { Loading } from "../../../components/loaders";
import { SimpleTable } from "../../../components/tables";
import { NewCompanyInviteRow } from "../../../containers/home";
import { useUserInfo } from "../../../features/userInfo/hooks";
import { useModalContext } from "../../../hooks/useModalContext";
import type { CompanyId, CompanyUser } from "../../../models/company";
import type { CompanyInvite } from "../../../models/invites";
import { useLazyGetCompanyInvitesQuery, useLazyGetCompanyUsersQuery } from "../../../services/endpoints/companies";
import DeleteCompanyInvitationModal from "../../company/components/DeleteCompanyInvitationModal";
import DeleteCompanyUserModal from "../../company/components/DeleteCompanyUserModal";
import { isCompanyAdmin } from "../../userInfo/hooks/useUserInfo";
import UserDetail from "../../userInfo/UserDetail";

type CompanyUsersProps = {
  clickable?: boolean;
  showSearch?: boolean;
};

function CompanyUsers({ clickable = true, showSearch = true }: CompanyUsersProps) {
  const { company } = useUserInfo();
  const { user } = useAuth0();
  const [getCompanyUsers, { isLoading: isCompanyUsersLoading, data: companyUsers }] = useLazyGetCompanyUsersQuery();
  const [getCompanyInvites, { isLoading: isCompanyInvitesLoading, data: companyInvites }] =
    useLazyGetCompanyInvitesQuery();

  const { openModal } = useModalContext();

  const isAdmin = isCompanyAdmin();
  const [showNewInviteRow, setShowNewInviteRow] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (company) {
      getCompanyUsers({ companyId: company.id });
      getCompanyInvites({ companyId: company.id });
    }
  }, [company]);

  const showUserInfo = (user: CompanyUser) => {
    const { email, firstName, lastName, id } = user;
    openModal({
      renderContent: (props) => (
        <UserDetail showReset={isAdmin} showSave={isAdmin} user={{ email, firstName, lastName, id }} {...props} />
      ),
      position: "right",
      dismissable: true,
      fullHeight: true,
      fullWidth: true,
    });
  };

  const handleDeleteInviteClicked = (invite: CompanyInvite) => {
    openModal({
      renderContent: DeleteCompanyInvitationModal,
      renderContentProps: {
        invite,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  const handleDeleteUserClicked = (user: CompanyUser) => {
    openModal({
      renderContent: DeleteCompanyUserModal,
      renderContentProps: {
        user,
        companyId: company.id as CompanyId,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  if (isCompanyUsersLoading || isCompanyInvitesLoading) {
    return <Loading />;
  }

  let tableContent = [];

  if (companyUsers) {
    tableContent.push(
      (searchText.trim()
        ? companyUsers.filter((item) => item.email.toLowerCase().includes(searchText.trim().toLowerCase()))
        : companyUsers
      ).map((item) => (
        <UserRow
          key={item.id}
          user={item}
          clickable={clickable}
          deletable={isAdmin ? user?.sub !== item.id : null}
          onClick={() => {
            clickable && showUserInfo(item);
          }}
          onDeleteClicked={
            isAdmin && user?.sub !== item.id
              ? () => {
                  handleDeleteUserClicked(item);
                }
              : undefined
          }
        />
      ))
    );
  }

  if (companyInvites?.items) {
    tableContent.push(
      (searchText.trim()
        ? companyInvites.items.filter((item) => item.email.toLowerCase().includes(searchText.trim().toLowerCase()))
        : companyInvites.items
      )
        .filter((i) => i.status !== "COMPLETE")
        .map((item) => (
          <InviteRow
            key={item.id}
            clickable={clickable}
            invite={item}
            deletable={isAdmin}
            onDeleteClicked={() => {
              isAdmin && handleDeleteInviteClicked(item);
            }}
          />
        ))
    );
  }

  if (tableContent.length === 0) {
    tableContent = [
      <tr key="empty" className="bg-white-100 border-b border-gray-200">
        <td className="whitespace-nowrap py-1 pl-6 text-center text-sm font-medium text-gray-900">
          <p className="py-2 text-sm font-medium text-gray-900">No user found</p>
        </td>
      </tr>,
    ];
  }

  return (
    <div>
      <div className="flex justify-end">
        {showSearch ? (
          <div className="w-72">
            <Input
              icon={MagnifyingGlassIcon}
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        ) : null}
      </div>
      <SimpleTable>
        <colgroup>
          <col />
          <col style={{ width: "150px" }} />
          <col style={{ width: "150px" }} />
        </colgroup>
        <thead>
          <tr className="bg-gray-50">
            <td className="px-6 py-2 text-sm">USER</td>
            <td className="px-6 py-2 text-sm">ROLE</td>
            <td className="px-6 py-2 text-sm" />
          </tr>
        </thead>
        <tbody>
          {tableContent}
          {showNewInviteRow ? (
            <NewCompanyInviteRow
              onComplete={() => {
                setShowNewInviteRow(false);
              }}
            />
          ) : null}
        </tbody>
      </SimpleTable>

      {!showNewInviteRow && (
        <Button
          variant="transparent"
          icon={() => <PlusIcon />}
          className="whitespace-nowrap"
          onClick={() => {
            setShowNewInviteRow(!showNewInviteRow);
          }}
        >
          Invite another collaborator
        </Button>
      )}
    </div>
  );
}

type InviteRowProps = {
  onDeleteClicked: () => void;
  deletable?: boolean;
  clickable: boolean;
  invite: CompanyInvite;
  onClick?: () => void;
};

function InviteRow({ invite, onClick, clickable, deletable = false, onDeleteClicked }: InviteRowProps) {
  return (
    <tr className={classNames(clickable && "cursor-pointer", "border-b border-gray-200 bg-white-100 last:border-0")}>
      <td className="whitespace-nowrap py-3 pl-6 text-sm font-medium text-gray-900" onClick={onClick}>
        {invite.email}
      </td>
      <td className="whitespace-nowrap py-1 pl-6 text-sm font-medium text-gray-900">
        <SimpleBadge label={invite.role} />
      </td>
      <td className="flex justify-between whitespace-nowrap py-3 pl-6 text-sm font-medium text-gray-900">
        <InviteStatusBadge status={invite.status} />
        {deletable ? (
          <TrashIcon
            className={classNames(
              deletable ? "cursor-pointer hover:text-red-900" : "text-gray-900",
              "mr-3 h-5 w-5 text-red-500 "
            )}
            onClick={onDeleteClicked}
          />
        ) : null}
      </td>
    </tr>
  );
}

type UserRowProps = {
  clickable: boolean;
  user: CompanyUser;
  onClick?: () => void;
  deletable: boolean;
  onDeleteClicked?: () => void;
};

function UserRow({ user, onClick, clickable, deletable = false, onDeleteClicked }: UserRowProps) {
  return (
    <tr className={classNames("border-b border-gray-200 bg-white-100 last:border-0")}>
      <td
        className={classNames(
          clickable && "cursor-pointer",
          "whitespace-nowrap py-3 pl-6 text-sm font-medium text-gray-900"
        )}
        onClick={onClick}
      >
        {user.email}
      </td>
      <td className="whitespace-nowrap py-3 pl-6 text-sm font-medium text-gray-900">
        {user.roles.map((role) => (
          <div key={role} className="mb-1">
            <SimpleBadge label={role} />
          </div>
        ))}
      </td>
      <td className="whitespace-nowrap py-3 pl-6 text-sm font-medium text-gray-900">
        {deletable ? (
          <TrashIcon
            className={classNames(
              deletable ? "cursor-pointer hover:text-red-900" : "text-gray-900",
              "mr-3 h-5 w-5 text-red-500 "
            )}
            onClick={onDeleteClicked}
          />
        ) : null}
      </td>
    </tr>
  );
}

export default CompanyUsers;
