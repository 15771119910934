import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { persistor } from "../../App";
import { Loading } from "../../components/loaders";
import { type RootState, useAppDispatch } from "../../reducers";
import { Auth0Service } from "../../services/auth0Service";
import { storeToken } from "../auth/authSlice";
import WorkspaceLayout from "../workspaces/components/WorkspaceLayout";

const AuthenticatedRoutes = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { loginWithRedirect, isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();

  /*
  This is needed because logout method is inside useAuth0 hook
  and isn't easily callable from action creator. So we dispatch logout
  action, and here we handle the logic to clear user data and then logoff the user
  */
  const shouldLogout = useSelector((state: RootState) => state.auth.shouldLogout);

  useEffect(() => {
    const initToken = async () => {
      const returnTo = location.pathname + location.search + location.hash;
      if (!isLoading) {
        if (isAuthenticated) {
          Auth0Service.setTokenGenerator(getAccessTokenSilently);

          try {
            const token = await getAccessTokenSilently();

            dispatch(storeToken(token));
          } catch (error) {
            console.error("could not init token", error);

            /*
            If we target a route without being authenticated, getAccessToken will fail
            with an error, so we need to send user back to landing
            */
            logout({ logoutParams: { returnTo: window.location.origin } });
          }
        } else {
          loginWithRedirect({ appState: { returnTo } });
        }
      }
    };

    initToken();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    /*
    Keep in mind we aren't setting shouldLogout back to false
    because the slice isn't persisted and login is via Auth0
    so during login we're redirect and this restore authSlice state
    to initial on every login.
    If you decide to move away from Auth0, you need to change something
    in the login/logout flow
    */
    if (shouldLogout) {
      // Clear user data from redux store here!
      persistor.purge();
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [shouldLogout]);

  if (isLoading) return <Loading />;

  return <WorkspaceLayout />;
};

export default AuthenticatedRoutes;
