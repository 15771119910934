import type { FunctionComponent } from "react";

import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { ErrorStatus, SuccessStatus, UploadingStatus } from ".";
import { EStatus, type IFileUploadStatus } from "../../models/fileUpload";

type IFileUploadStatusProps = {
  fileUpload: IFileUploadStatus;
  handleCancel: () => void;
  handleChangeFile: () => void;
  error?: FetchBaseQueryError | SerializedError | string;
};

const FileUploadStatus: FunctionComponent<IFileUploadStatusProps> = ({
  fileUpload,
  handleCancel,
  handleChangeFile,
  error,
}) => {
  switch (fileUpload.status) {
    case EStatus.UPLOADING: {
      return <UploadingStatus handleCancel={handleCancel} fileUpload={fileUpload} />;
    }

    case EStatus.SUCCESS: {
      return <SuccessStatus handleChangeFile={handleChangeFile} />;
    }

    case EStatus.ERROR: {
      return <ErrorStatus handleTryAgain={handleChangeFile} error={error} />;
    }

    default: {
      return null;
    }
  }
};

export default FileUploadStatus;
