import type { FunctionComponent } from "react";

import type { DataSource } from "../../../../../../models/dataSource";
import { CommonActiveDataSource } from "../common";

type IActiveCodeDataSourceProps = {
  dataSource?: DataSource;
};

const ActiveCodeDataSource: FunctionComponent<IActiveCodeDataSourceProps> = ({ dataSource }) =>
  dataSource ? <CommonActiveDataSource polling dataSource={dataSource} /> : <div />;

export default ActiveCodeDataSource;
