import { Route, Routes } from "react-router-dom";

import { DataSources } from ".";
import { DataSourceDetails } from "../dataSourceDetails";

const DataSourcesRouter = () => (
  <Routes>
    <Route path="/:id" element={<DataSourceDetails />} />
    <Route path="/" element={<DataSources />} />
  </Routes>
);

export default DataSourcesRouter;
