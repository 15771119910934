import { type FunctionComponent, memo, type MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrowPathIcon } from "@heroicons/react/20/solid";

import { SpinnerIcon } from "../../../components/icons";
import type { BaseAudience } from "../../../models/audiences";
import { ToastType } from "../../../models/toast";
import { useAppDispatch } from "../../../reducers";
import { useEvaluateAudienceMutation, useLazyGetEstimateQuery } from "../../../services/endpoints/audiences";
import { useLazyGetExtendedEstimateQuery } from "../../../services/endpoints/extendedAudiences";
import { showToast } from "../../toasts/toastsSlice";
import { useWorkspace } from "../../workspaces/hooks";

type IAudienceStatsProps = {
  audience: BaseAudience;
};

const AudienceStats: FunctionComponent<IAudienceStatsProps> = ({ audience }) => {
  const { t } = useTranslation("audiences");
  const workspace = useWorkspace();
  const dispatch = useAppDispatch();

  const [evaluate, { isLoading: isEvaluateLoading }] = useEvaluateAudienceMutation();
  const [getEstimate, { data, isLoading }] = useLazyGetEstimateQuery();
  const [getExtendedEstimate, { data: dataExtended }] = useLazyGetExtendedEstimateQuery();

  const [reloadAnimation, setReloadAnimation] = useState(false);

  useEffect(() => {
    if (audience.extended) {
      getExtendedEstimate({
        workspaceId: workspace.id,
        extendedAudienceId: audience.id,
      });
    } else {
      getEstimate({
        workspaceId: workspace.id,
        audienceId: audience.id,
      });
    }
  }, []);

  const handleRefreshClicked = (e: MouseEvent) => {
    e.stopPropagation();

    setReloadAnimation(true);
    setTimeout(() => {
      setReloadAnimation(false);
    }, 2000);

    evaluate({
      workspaceId: workspace.id,
      audienceId: audience.id,
    });

    dispatch(
      showToast({
        type: ToastType.INFO,
        title: t("evaluate_title"),
        message: t("evaluate"),
      })
    );
  };

  return (
    <div className="flex justify-end">
      {audience.scheduleType === "MANUAL" && (
        <button
          className="inline-block border-0 bg-transparent  text-blue-500"
          disabled={isLoading || isEvaluateLoading}
          title={t("buttons.refresh_audience_stats")}
          onClick={handleRefreshClicked}
        >
          <ArrowPathIcon className={`h-4 w-4 ${(reloadAnimation || isEvaluateLoading) && "animate-spin"}`} />
        </button>
      )}
      {isLoading ? (
        <div className="flex justify-end px-3 py-1">
          <SpinnerIcon loading className="h-3 w-3" fill="rgba(21,68,155,1)" />
        </div>
      ) : (
        <p className="px-3 text-right text-base font-medium text-gray-900">
          {(audience.extended ? dataExtended?.size : data?.size) || 0}
        </p>
      )}
    </div>
  );
};

export default memo(AudienceStats);
