import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ArrowRightIcon, CircleStackIcon } from "@heroicons/react/20/solid";

import { SimpleTable } from "../../../components/tables";
import type { Workspace } from "../../../models/workspace";

type IWorkspacesTableProps = {
  workspaces: Workspace[];
  isLoading: boolean;
};

const WorkspacesTable: FunctionComponent<IWorkspacesTableProps> = ({ workspaces, isLoading }) => {
  const { t } = useTranslation("settings");

  const headings = [
    {
      label: t("workspace.table.workspace"),
      className: "",
    },
    {
      label: t("workspace.table.users"),
      className: "text-right",
    },
  ];

  return (
    <div>
      <SimpleTable>
        <colgroup>
          <col style={{}} />
          <col style={{ width: "180px" }} />
        </colgroup>
        <thead>
          <tr>
            {headings.map((heading) => (
              <th
                key={heading.label}
                scope="col"
                className={`sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 ${heading.className}`}
              >
                {heading.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="bg-white-100 border-b border-gray-200" />
          ) : workspaces.length ? (
            workspaces.map((workspace) => (
              <tr key={workspace.id} className="bg-white-100 group border-b border-gray-200">
                <td className="whitespace-nowrap py-2 pl-6">
                  <div className="flex flex-row content-center">
                    <span className="mr-1 h-7 w-7 rounded-lg">
                      <CircleStackIcon className="h-7" />
                    </span>
                    <p className="mt-1 text-sm font-medium text-gray-900">{workspace.name}</p>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-2 text-right">
                  <p className="block text-sm font-medium text-gray-900 group-hover:hidden">
                    {workspace?.userCount || "N/A"}
                  </p>
                  <Link
                    to={`/settings/workspace/${workspace.id}`}
                    className="my-auto hidden text-blue-600 underline group-hover:inline"
                  >
                    <div className="flex flex-row content-center justify-end">
                      {t("workspace.table.details")}
                      <span className="ml-2 h-6 w-6 rounded-lg">
                        <ArrowRightIcon className="h-6" />
                      </span>
                    </div>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white-100 border-b border-gray-200">
              <td className="whitespace-nowrap px-6 py-2 text-center" colSpan={headings.length}>
                <p className="text-sm font-medium text-gray-500">{t("workspace.table.empty")}</p>
              </td>
            </tr>
          )}
        </tbody>
      </SimpleTable>
    </div>
  );
};

export default WorkspacesTable;
