import type React from "react";
import type { FunctionComponent } from "react";

import type {
  AudienceSyncSchedule,
  AudienceSyncScheduleType,
  AudienceSyncTimeBasedSchedule,
} from "../../../../models/audiences";
import AudienceSyncRepeatConfig from "./AudienceSyncRepeatConfig";
import AudienceSyncTypeConfig from "./AudienceSyncTypeConfig";

type IAudienceSyncConfigProps = {
  isFetchingSummary: boolean;
  realTimeAudienceLeft: number;
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration: AudienceSyncSchedule;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const AudienceSyncConfig: FunctionComponent<IAudienceSyncConfigProps> = ({
  isFetchingSummary,
  realTimeAudienceLeft,
  scheduleType,
  scheduleConfiguration,
  handleChange,
  setFieldValue,
}) => (
  <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 shadow-md">
    <AudienceSyncTypeConfig
      isFetchingSummary={isFetchingSummary}
      realTimeAudienceLeft={realTimeAudienceLeft}
      scheduleType={scheduleType}
      handleChange={handleChange}
    />
    {scheduleType === "SCHEDULED" && (
      <>
        <hr />
        <AudienceSyncRepeatConfig
          scheduleConfiguration={scheduleConfiguration as AudienceSyncTimeBasedSchedule}
          setFieldValue={setFieldValue}
        />
      </>
    )}
  </div>
);

export default AudienceSyncConfig;
