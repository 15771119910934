import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import vs from "react-syntax-highlighter/dist/esm/styles/hljs/vs";

import { Transition } from "@headlessui/react";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { delay } from "lodash";

import { Button } from "../buttons";

type ISnippetPreviewProps = {
  script?: string;
  ctaRight?: boolean;
};

SyntaxHighlighter.registerLanguage("javascript", js);

const ANIMATION_FADEOUT_DURATION = 1000;

const SnippetPreview: FunctionComponent<ISnippetPreviewProps> = ({ script, ctaRight }) => {
  const { t } = useTranslation("data_source_edit");

  const [isCopying, setIsCopying] = useState(false);

  const handleCopySnippetClicked = () => {
    setIsCopying(true);
    if (script) {
      navigator.clipboard.writeText(script);
    }

    delay(() => {
      setIsCopying(false);
    }, ANIMATION_FADEOUT_DURATION);
  };

  return (
    <div className="flex flex-col">
      <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">
        {t("code.install_snippet.code_title_install")}
      </h1>
      <h4 className="text-sm font-normal leading-4 text-gray-600">{t("code.install_snippet.code_subtitle_install")}</h4>
      <div className="my-4 overflow-x-auto border-gray-300">
        <Transition
          show={Boolean(script)}
          enter="transform ease-linear duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative rounded border-2">
            <SyntaxHighlighter
              className={
                isCopying
                  ? "z-10 rounded opacity-20 backdrop-blur-sm transition duration-75 ease-linear"
                  : "z-10 rounded transition duration-1000 ease-linear"
              }
              language="javascript"
              style={vs}
            >
              {script || ""}
            </SyntaxHighlighter>
            <Transition
              show={isCopying}
              enter="transition ease-linear duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-linear duration-1000"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform">
                <div className="inline-block place-items-center">
                  <CheckIcon className="mx-auto mb-2 h-8 rounded-full bg-green-100 p-1 text-green-600" />
                  <h3 className="text-gray-900">{t("code.install_snippet.copied")}</h3>
                </div>
              </div>
            </Transition>
          </div>
        </Transition>
      </div>
      <Button
        variant="primary"
        icon={DocumentDuplicateIcon}
        className={ctaRight ? "self-end" : "self-start"}
        onClick={handleCopySnippetClicked}
      >
        {t("code.install_snippet.code_copy")}
      </Button>
    </div>
  );
};

export default SnippetPreview;
