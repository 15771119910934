import { storeToken } from "../../features/auth/authSlice";
import type { Company, CompanyAdministrativeData, CompanyUser } from "../../models/company";
import type { CompanyInvite } from "../../models/invites";
import type { Workspace } from "../../models/workspace";
import { audiencerateApi } from "../audiencerate";
import { Auth0Service } from "../auth0Service";

const companiesApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    createCompany: build.mutation<Company, CreateCompanyArg>({
      query: ({ company }) => ({
        url: `/v1/companies`,
        method: "POST",
        body: company,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data: company } = await queryFulfilled;
          // `onSuccess` side-effect
          console.log("refreshing token");
          const token = await Auth0Service.getAccessTokenSilently({ ignoreCache: true });
          console.log("Post workspace creation token refresh", token);
          dispatch(storeToken(token));
        } catch (error) {
          console.error("onQueryStarted: Could not refresh token", error);
          // `onError` side-effect
        }
      },
    }),
    getCompanyAdministativeData: build.query<CompanyAdministrativeData, GetCompanyAdministrativeDataArgs>({
      query: ({ companyId }) => ({
        url: `/v1/companies/${companyId}/administrative-data`,
        method: "GET",
      }),
      providesTags: ["Company"],
    }),
    updateCompanyAdministrativeData: build.mutation<CompanyAdministrativeData, UpdateCompanyAdministrativeDataArgs>({
      query: ({ companyId, body }) => ({
        url: `/v1/companies/${companyId}/administrative-data`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Company"],
    }),
    inviteCompanyUser: build.mutation<any, InviteCompanyUserArgs>({
      query: ({ companyId, userList }) => ({
        url: `/v1/companies/${companyId}/invite`,
        method: "POST",
        body: userList,
      }),
      invalidatesTags: ["CompanyInvites"],
    }),
    acceptCompanyInvite: build.mutation<void, void>({
      query: (_) => ({
        url: `/v1/companies/accept-invite`,
        method: "POST",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const response = await queryFulfilled;
          // `onSuccess` side-effect
          console.log("refreshing token");
          const token = await Auth0Service.getAccessTokenSilently({ ignoreCache: true });
          console.log("Post workspace creation token refresh", token);
          dispatch(storeToken(token));
        } catch (error) {
          console.error("onQueryStarted: Could not refresh token", error);
          // `onError` side-effect
        }
      },
      invalidatesTags: ["userInfo"],
    }),
    getCompanyInvites: build.query<{ items: CompanyInvite[]; limit: number; offset: number }, GetCompanyInvitesArg>({
      query: ({ companyId }) => ({
        url: `/v1/companies/${companyId}/list-invite`,
      }),
      providesTags: () => [{ type: "CompanyInvites", id: "LIST" }],
    }),
    getCompanyUsers: build.query<CompanyUser[], GetCompanyUsersArg>({
      query: ({ companyId }) => ({
        url: `/v1/companies/${companyId}/users`,
      }),
      providesTags: ["CompanyUsers"],
    }),
    deleteCompanyUser: build.mutation<unknown, { companyId: string } & Pick<CompanyUser, "id">>({
      query: ({ companyId, id }) => ({
        url: `/v1/companies/${companyId}/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyUsers"],
    }),
    getCompanyWorkspaces: build.query<Workspace[], { companyId: string; q: string }>({
      query({ companyId, q }) {
        const queryParams = q ? `q=${q}` : "";
        return {
          url: `/v1/companies/${companyId}/workspaces${queryParams}`,
        };
      },
      providesTags: ["CompanyWorkspaces"],
    }),
    deleteCompanyInvite: build.mutation<unknown, Pick<CompanyInvite, "id" | "companyId">>({
      query: ({ companyId, id }) => ({
        url: `/v1/companies/${companyId}/delete-invite/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CompanyInvites", id: "LIST" }],
    }),
  }),
});

export type CreateCompanyArg = {
  company: Partial<Company>;
};

export type GetCompanyAdministrativeDataArgs = {
  companyId: string;
};

export type UpdateCompanyAdministrativeDataArgs = {
  companyId: string;
  body: CompanyAdministrativeData;
};

export type InviteCompanyUserListItemArgs = {
  email: string;
  role: "ADMIN" | "ACCOUNTING" | "READONLY";
};

export type InviteCompanyUserArgs = {
  companyId: string;
  userList: InviteCompanyUserListItemArgs;
};

type GetCompanyInvitesArg = {
  companyId: string;
};

type GetCompanyUsersArg = {
  companyId: string;
};

export const {
  useCreateCompanyMutation,
  useGetCompanyAdministativeDataQuery,
  useLazyGetCompanyAdministativeDataQuery,
  useUpdateCompanyAdministrativeDataMutation,
  useInviteCompanyUserMutation,
  useAcceptCompanyInviteMutation,
  useLazyGetCompanyInvitesQuery,
  useGetCompanyUsersQuery,
  useLazyGetCompanyUsersQuery,
  useGetCompanyWorkspacesQuery,
  useDeleteCompanyInviteMutation,
  useDeleteCompanyUserMutation,
} = companiesApi;
