import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { type AppState, Auth0Provider } from "@auth0/auth0-react";

export const Auth0ProviderWithNavigate = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const clientId = import.meta.env.AUR_AUTH0_CLIENT_ID;
  const domain = import.meta.env.AUR_AUTH0_DOMAIN;
  const audience = import.meta.env.AUR_AUTH0_AUDIENCE;
  const redirectUri = import.meta.env.AUR_AUTH0_CALLBACK_URL ?? window.location.origin;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
      }}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
