import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { XMarkIcon } from "@heroicons/react/20/solid";
import { FieldArray, type FieldArrayRenderProps, type FormikErrors, getIn } from "formik";
import { isEmpty } from "lodash";

import { Button } from "../../../../../../components/buttons";
import { Input } from "../../../../../../components/inputs";
import { Select } from "../../../../../../components/selects";
import type { ISelectItem } from "../../../../../../models/select";
import { concatClassNames } from "../../../../../../utils";
import {
  ESelector,
  type ITrackClickEvents,
  type ITrackPageVisitEvents,
  type ITrackWebsiteValue,
} from "../../../../types";
import { AddButton } from "../buttons";

type ITrackUserClickProps = {
  values: ITrackWebsiteValue;
  errors: FormikErrors<ITrackWebsiteValue>;
  handleSetFieldValue: (field: string, value: ITrackClickEvents | ITrackPageVisitEvents) => void;
  handleSetFieldError: (field: string, message: string | undefined) => void;
  handleDeleteRule: (ruleId: string) => void;
};

const TrackUserClick: FunctionComponent<ITrackUserClickProps> = ({
  values,
  handleSetFieldValue,
  handleSetFieldError,
  handleDeleteRule,
  errors,
}) => {
  const { t } = useTranslation("data_source_edit");

  const selectItems: ISelectItem[] = [
    { id: ESelector.ID, label: t("code.track_events.clicks_on_id") },
    { id: ESelector.CLASS, label: t("code.track_events.clicks_on_class") },
  ];

  const handleOnAddClicked = (clickEventHelper: FieldArrayRenderProps) => {
    const identifier = crypto.randomUUID();
    clickEventHelper.push({ identifier, selectorType: ESelector.ID, selectorName: "", eventName: "" });
  };

  const handleDeleteEvent = (clickEventHelper: FieldArrayRenderProps, index: number, ruleId?: string) => {
    if (ruleId) {
      handleDeleteRule(ruleId);
    }

    clickEventHelper.remove(index);
  };

  return (
    <div>
      <FieldArray
        name="clickEvents"
        validateOnChange={false}
        render={(clickEventHelper) => (
          <div>
            {clickEventHelper && !isEmpty(clickEventHelper)
              ? values.clickEvents.map((event, index) => (
                  <div key={index} className="my-2 flex px-4">
                    <div className="flex w-1/2">
                      <Select
                        items={selectItems}
                        value={selectItems[event.selectorType]}
                        className="ml-1 w-52"
                        disabled={event.id !== undefined}
                        onChange={(value) => {
                          handleSetFieldValue(`clickEvents.${index}`, {
                            ...event,
                            selectorType: value.id === ESelector.ID ? ESelector.ID : ESelector.CLASS,
                          });
                        }}
                      />
                      <div className="relative flex-auto">
                        <span
                          className={concatClassNames(
                            "absolute z-10 h-11 text-gray-400",
                            event.selectorType === ESelector.ID ? "mt-2 ml-2.5" : "mt-2 ml-4"
                          )}
                        >
                          {event.selectorType === ESelector.ID ? "#" : "."}
                        </span>
                        <Input
                          className="mx-1 flex-auto"
                          placeholder={
                            event.selectorType === ESelector.ID
                              ? t("code.track_events.name_of_the_id")
                              : t("code.track_events.name_of_the_class")
                          }
                          value={event.selectorName}
                          disabled={event.id !== undefined}
                          error={
                            errors.clickEvents && errors.clickEvents[index]
                              ? getIn(errors.clickEvents[index], "selectorName")
                              : null
                          }
                          onChange={(e) => {
                            handleSetFieldError(`clickEvents.${index}.selectorName`);
                            handleSetFieldValue(`clickEvents.${index}`, { ...event, selectorName: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex w-1/2">
                      <span className="ml-2 mr-1 h-11 py-2">{t("code.track_events.creates")}</span>
                      <div className="flex-auto">
                        <Input
                          placeholder={t("code.track_events.name_of_the_event")}
                          value={event.eventName}
                          disabled={event.id !== undefined}
                          error={
                            errors.clickEvents && errors.clickEvents[index]
                              ? getIn(errors.clickEvents[index], "eventName")
                              : null
                          }
                          onChange={(e) => {
                            handleSetFieldError(`clickEvents.${index}.eventName`);
                            handleSetFieldValue(`clickEvents.${index}`, {
                              ...event,
                              eventName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <Button
                        variant="transparent"
                        type="button"
                        className="ml-1 h-11 flex-1"
                        onClick={() => {
                          handleDeleteEvent(clickEventHelper, index, event.id);
                        }}
                      >
                        <XMarkIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                      </Button>
                    </div>
                  </div>
                ))
              : null}
            <div className="my-auto bg-white px-5 py-6">
              <AddButton
                label={t("code.track_events.buttons.add_event")}
                onClick={() => {
                  handleOnAddClicked(clickEventHelper);
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default TrackUserClick;
