import type React from "react";
import { type FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Formik, type FormikProps, useFormikContext } from "formik";

import { Button } from "../../../components/buttons";
import type { DataSourceCodeConfig, TDataSource } from "../../../models/dataSource";
import { useSaveRulesMutation } from "../../../services/endpoints/rules";
import { customValidation, mapEventsToRules } from "../../dataSourceEdit/components/categories/code/tracking/utils";
import type { ITrackWebsiteValue } from "../../dataSourceEdit/types";
import { useWorkspace } from "../../workspaces/hooks";

type ITrackEventCountProps = {
  eventCountFn: (values: ITrackWebsiteValue) => number;
  handleEventCountChange: (eventCount: number) => void;
};

const TrackEventCount: FunctionComponent<ITrackEventCountProps> = ({
  eventCountFn,
  handleEventCountChange,
  children,
}) => {
  const { values } = useFormikContext<ITrackWebsiteValue>();
  const eventCountRef = useRef<number>(0);

  useEffect(() => {
    const currentEventCount = eventCountFn(values);
    if (eventCountRef.current != currentEventCount) {
      eventCountRef.current = currentEventCount;
      handleEventCountChange(currentEventCount);
    }
  }, [values]);

  return <>{children}</>;
};

type ITrackRuleFormProps = {
  dataSource?: TDataSource<DataSourceCodeConfig>;
  currentEventCount: number;
  newEventCountFn: (values: ITrackWebsiteValue) => number;
  children: (props: FormikProps<ITrackWebsiteValue>) => React.ReactNode;
};

const TrackRuleForm: FunctionComponent<ITrackRuleFormProps> = ({
  dataSource,
  currentEventCount,
  newEventCountFn,
  children,
}) => {
  const { t } = useTranslation("data_source_edit");

  const workspace = useWorkspace();

  const initialValues: ITrackWebsiteValue = { clickEvents: [], pageVisitEvents: [] };
  const formikFormRef = useRef<FormikProps<ITrackWebsiteValue>>(null);

  const [newEventCount, setNewEventCount] = useState<number>(0);
  const newEventCountRef = useRef<number>(0);
  const targetEventCountRef = useRef<number>(0);

  const [
    saveRules,
    { isUninitialized: isSaveUninitialized, isLoading: isSaveLoading, isSuccess: isSaveSuccess, isError: isSaveError },
  ] = useSaveRulesMutation();

  const handleEventCountChange = (currentEventCount: number) => {
    newEventCountRef.current = currentEventCount;
    setNewEventCount(currentEventCount);
  };

  useEffect(() => {
    if (!isSaveUninitialized && !isSaveLoading && isSaveSuccess && currentEventCount === targetEventCountRef.current) {
      formikFormRef.current?.resetForm();
    } else if (!isSaveUninitialized && !isSaveLoading && isSaveError) {
      window.alert(t("code.track_events.save_error"));
    }
  }, [isSaveUninitialized, isSaveLoading, isSaveSuccess, isSaveError, currentEventCount]);

  const handleOnSubmit = () => {
    console.debug("On submit");
  };

  const handleCancelButtonClicked = async () => {
    formikFormRef.current?.resetForm();
  };

  const handleSaveButtonClicked = async () => {
    await formikFormRef.current?.submitForm();
    const events = formikFormRef.current?.values;

    console.log(formikFormRef.current?.isValid, formikFormRef.current?.errors);

    if (formikFormRef.current?.isValid && events) {
      targetEventCountRef.current = currentEventCount + newEventCountRef.current;
      submitRules(events);
    }
  };

  const submitRules = (events: ITrackWebsiteValue) => {
    const rules = mapEventsToRules(events);

    if (dataSource && dataSource.id) {
      const dataSourceId = dataSource.id;

      // Adding new rules (without id)
      const createRules = rules.filter((rule) => rule.id === undefined);

      if (createRules) {
        saveRules({
          workspaceId: workspace.id,
          dataSourceId,
          rules: createRules,
        });
      }
    }
  };

  return (
    <>
      <Formik
        validate={(values) => customValidation(values, t)}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={handleOnSubmit}
        innerRef={formikFormRef}
        // ValidationSchema={validationSchema}
      >
        {(props) => (
          <TrackEventCount eventCountFn={newEventCountFn} handleEventCountChange={handleEventCountChange}>
            <Form className="space-y-8">{children(props)}</Form>
          </TrackEventCount>
        )}
      </Formik>

      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        <Button
          variant="primary"
          type="submit"
          className="inline-flex justify-center px-4 py-2"
          loading={isSaveLoading}
          disabled={newEventCount === 0}
          onClick={handleSaveButtonClicked}
        >
          {t("code.track_events.buttons.save")}
        </Button>
        <Button
          variant="light"
          type="button"
          className="ml-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          disabled={newEventCount === 0 || isSaveLoading}
          onClick={handleCancelButtonClicked}
        >
          {t("code.track_events.buttons.cancel")}
        </Button>
      </div>
    </>
  );
};

export default TrackRuleForm;
