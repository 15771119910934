import type { FunctionComponent } from "react";

import { PlusIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../../../../components/buttons";

type IAddButtonProps = {
  label: string;
  onClick?: () => void;
};

const AddButton: FunctionComponent<IAddButtonProps> = ({ label, onClick }) => (
  <Button variant="transparent" icon={PlusIcon} className="mr-6 h-9 max-w-max" type="button" onClick={onClick}>
    {label}
  </Button>
);

export default AddButton;
