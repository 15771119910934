import { type FunctionComponent, useEffect, useState } from "react";

import { format, setDate } from "date-fns";

import { Select } from "../../../../components/selects";
import type { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import type { ISelectItem } from "../../../../models/select";

type IAudienceSyncRepeatMonthdayProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const AudienceSyncRepeatMonthday: FunctionComponent<IAudienceSyncRepeatMonthdayProps> = ({
  scheduleConfiguration,
  setFieldValue,
}) => {
  const seed = new Date();
  const days: ISelectItem[] = Array.from(new Array(31).keys()).map((value) => ({
    id: value + 1,
    label: format(setDate(seed, value + 1), "do"),
  }));
  const [day, setDay] = useState<ISelectItem>(
    scheduleConfiguration.dayOfMonth ? days[scheduleConfiguration.dayOfMonth - 1] : days[0]
  );

  useEffect(() => {
    if (day) {
      setFieldValue("scheduleConfiguration.dayOfMonth", day.id);
    }
  }, [day]);

  return (
    <div className="mb-8 flex flex-row items-center">
      <span className="mr-4">On the</span>
      <div className="mr-4 w-32">
        <Select items={days} value={day} onChange={setDay} />
      </div>
    </div>
  );
};

export default AudienceSyncRepeatMonthday;
