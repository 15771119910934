import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/buttons";
import { Checkbox } from "../../../../components/inputs";
import type { DistributionChannelContent } from "../../../../models/distributionChannels";
import { useUpdateDistributionChannelFieldsMutation } from "../../../../services/endpoints/distributionChannels";
import { useWorkspace } from "../../../workspaces/hooks";

type IDestinationStepDataProps = {
  distributionChannelContent: DistributionChannelContent;
  nextStep?: (distributionChannelContent: DistributionChannelContent) => void;
  prevStep?: () => void;
};

const DestinationStepData: FunctionComponent<IDestinationStepDataProps> = ({
  distributionChannelContent,
  prevStep,
  nextStep,
}) => {
  const { t } = useTranslation("destinations");
  const workspace = useWorkspace();
  const [selectedFields, setSelectedFields] = useState<Record<number, boolean>>(
    Object.fromEntries(
      distributionChannelContent.integrationsResult.cyclr.action.fields
        // .filter(field => !field.isOptional) // This was preventing the optional field to be checked. Was it correct?
        .map((field) => [field.fieldId, true])
    )
  );

  const [
    updateDistributionChannelFields,
    {
      isLoading: isUpdateFieldsLoading,
      isSuccess: isUpdateFieldsSuccess,
      isError: isUpdateFieldsError,
      data: updateFieldsData,
      error: updateFieldsError,
    },
  ] = useUpdateDistributionChannelFieldsMutation();

  useEffect(() => {
    if (isUpdateFieldsSuccess && nextStep) {
      nextStep(updateFieldsData);
    }
  }, [isUpdateFieldsSuccess]);

  const changeField = (fieldId: number, checked: boolean) => {
    setSelectedFields({
      ...selectedFields,
      [fieldId]: checked,
    });
  };

  const updateFields = () => {
    const fieldsToIgnore = distributionChannelContent.integrationsResult.cyclr.action.fields
      .filter((field) => !selectedFields[field.fieldId])
      .map((field) => ({
        stepId: field.stepId,
        fieldId: field.fieldId,
        mappingType: "Ignore",
        value: field.value,
      }));
    if (fieldsToIgnore.length === 0 && nextStep) {
      nextStep(distributionChannelContent);
      return;
    }

    updateDistributionChannelFields({
      workspaceId: workspace.id,
      audienceId: distributionChannelContent.distributionChannel.audienceId,
      distributionChannelId: distributionChannelContent.distributionChannel.id,
      fields: fieldsToIgnore,
    });
  };

  return (
    <>
      <div className="flex-grow overflow-auto p-6">
        <div className="text-left">
          <h3 className="mb-1 text-base font-bold text-black">{t("edit.step.data.properties")}</h3>
          <p className="mb-5 text-base">{t("edit.step.data.text")}</p>
        </div>
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 px-6 py-4 text-left font-medium shadow">
          {distributionChannelContent.integrationsResult.cyclr.action.fields.map((field, i, fields) => (
            <Checkbox
              key={field.fieldId}
              label={field.name}
              id={String(field.fieldId)}
              description={field.description}
              disabled={!field.isOptional}
              required={!field.isOptional}
              forceShowingDescription={i !== fields.length - 1}
              checked={selectedFields[field.fieldId]}
              onChange={(e) => {
                changeField(field.fieldId, e.target.checked);
              }}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        {prevStep ? (
          <Button className="mr-2" loading={isUpdateFieldsLoading} onClick={prevStep}>
            {t("edit.step.back")}
          </Button>
        ) : null}

        <Button loading={isUpdateFieldsLoading} onClick={updateFields}>
          {t("edit.step.next")}
        </Button>
      </div>
    </>
  );
};

export default DestinationStepData;
