import type { FunctionComponent } from "react";

export type ISimpleTableProps = {
  paginationComponent?: JSX.Element;
  columnDropdownComponent?: JSX.Element;
};

const SimpleTable: FunctionComponent<ISimpleTableProps> = ({
  children,
  columnDropdownComponent,
  paginationComponent,
}) => (
  <div className="flex flex-col">
    <div className="flex justify-end">{columnDropdownComponent}</div>
    <div className="inline-block min-w-full py-2 align-middle">
      <div className="border-grey-200 h-auto overflow-y-auto rounded-md border shadow-md">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">{children}</table>
        </div>
        {paginationComponent}
      </div>
    </div>
  </div>
);

export default SimpleTable;
