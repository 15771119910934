import { useLocation } from "react-router-dom";

import { SidebarRoutes } from "../../router/RouteConstants";

const Footer = () => (
  <footer className="bg-white-100">
    <div className="justify-end p-4 sm:px-6 md:flex md:items-center lg:px-8">
      <div className="mt-4 hidden md:order-1 md:mt-0 md:flex md:flex-shrink-0">
        <p className="text-center text-sm text-gray-400">
          &copy; {new Date().getFullYear()} CDP - Audiencerate Ltd. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);

const FooterForSomePagesUntilWeRefactorLayout = () => {
  const { pathname } = useLocation();

  if (!SidebarRoutes.includes(pathname)) return null;

  return <Footer />;
};

export default FooterForSomePagesUntilWeRefactorLayout;
