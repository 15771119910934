import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { first } from "lodash";

import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import { SearchDropDown } from "../../../../components/dropdowns";
import type { ExpressionCondition } from "../../../../models/audiences";
import type { ISelectItem } from "../../../../models/select";
import { AudienceSelect } from "../selects";

type IMembershipConditionProps = {
  expression: ExpressionCondition;
  onChange?: (condition: ExpressionCondition) => void;
  isViewMode?: boolean;
};

const MembershipCondition: FunctionComponent<IMembershipConditionProps> = ({ expression, isViewMode, onChange }) => {
  const { t } = useTranslation("audience_edit");

  const membershipOps: Array<ISelectItem & { displayName: string }> = [
    {
      id: 0,
      label: t("rules.belongs_to"),
      displayName: t("rules.belongs_to"),
    },
    {
      id: 1,
      label: t("rules.not_belongs_to"),
      displayName: t("rules.not_belongs_to"),
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="flex w-full">
      <div className="mx-2 inline-flex items-center text-sm">
        <span className="font-bold">
          <UserGroupIcon className="inline-block h-6 w-6" />
        </span>
      </div>
      <div className="mx-2">
        <SearchDropDown
          openWithFocus
          items={
            searchQuery?.trim()
              ? membershipOps.filter((item) =>
                  item.displayName.toLowerCase().includes(searchQuery?.trim().toLowerCase())
                )
              : membershipOps
          }
          item={expression.membership ? (expression.membership === "IN" ? membershipOps[0] : membershipOps[1]) : null}
          placeholder="belongs or not"
          setItem={(opItemValue) => {
            onChange?.({
              ...expression,
              membership: opItemValue.id === 0 ? "IN" : "NOT_IN",
            });
          }}
          setQuery={setSearchQuery}
          loading={false}
          readOnly={isViewMode}
          className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
        >
          {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
        </SearchDropDown>
      </div>
      <div className="mx-2">
        <AudienceSelect
          audienceId={first(expression.audienceIds)}
          isViewMode={isViewMode}
          onChange={(audienceItemValue) => {
            onChange?.({
              ...expression,
              audienceIds: [String(audienceItemValue.id)],
            });
          }}
        />
      </div>
    </div>
  );
};

export default MembershipCondition;
