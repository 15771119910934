import { type FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { SimpleList } from "../../../../components/lists";
import { useModalContext } from "../../../../hooks/useModalContext";
import type { Audience, AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import type { DataSource } from "../../../../models/dataSource";
import type { DistributionChannel } from "../../../../models/distributionChannels";
import { ToastType } from "../../../../models/toast";
import { useAppDispatch } from "../../../../reducers";
import { useGetDataSourcesByIdsQuery } from "../../../../services/dataSources";
import { useEvaluateAudienceMutation, useLazyGetNextScheduleQuery } from "../../../../services/endpoints/audiences";
import {
  useCreateDistributionChannelMutation,
  useLazyGetDistributionChannelsQuery,
} from "../../../../services/endpoints/distributionChannels";
import { mapAudienceExpressionToRules } from "../../../../utils";
import { ExpressionConditionRender } from "../../../audienceEdit/components";
import DestinationCreate from "../../../destinations/components/edit/DestinationCreate";
import { showToast } from "../../../toasts/toastsSlice";
import { useWorkspace } from "../../../workspaces/hooks";
import { useWorkspaceAdmin } from "../../../workspaces/hooks/useWorkspace";
import AudienceDestinations from "./AudienceDestinations";
import DisconnectDestinationModal from "./settings/modals/DisconnectDestinationModal";

type IAudienceSourcesAndRulesProps = {
  audience?: Audience;
};

const AudienceSourcesAndRules: FunctionComponent<IAudienceSourcesAndRulesProps> = ({ audience }) => {
  const { t } = useTranslation("audience_details");
  const navigate = useNavigate();

  const workspace = useWorkspace();
  const dispatch = useAppDispatch();

  const { openModal } = useModalContext();

  const { isLoading, data } = useGetDataSourcesByIdsQuery({
    workspaceId: workspace.id,
    datasourceIds: audience?.datasourceIds || [],
  });

  const dataSources = data || [];

  const rules = mapAudienceExpressionToRules(audience?.expression);

  const [destinations, setDestinations] = useState<DistributionChannel[]>([]);
  const destinationsRef = useRef<DistributionChannel[]>([]);

  const [getDistributionChannels, { isLoading: isDistributionChannelsLoading, data: distributionChannels }] =
    useLazyGetDistributionChannelsQuery();

  // Const [launchCyclr, {
  //   isLoading: isLaunchLoading,
  //   isSuccess: isLaunchSuccess,
  //   isError: isLaunchError,
  //   data: launchData,
  //   error: launchError
  // }] = useLaunchCyclrMutation();

  const [
    createDistributionChannel,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      data: createData,
      error: createError,
    },
  ] = useCreateDistributionChannelMutation();

  const [evaluate] = useEvaluateAudienceMutation();

  const [getNextSchedule, { isLoading: isScheduleLoading, data: scheduleData, isFetching: isScheduleFetching }] =
    useLazyGetNextScheduleQuery();

  useEffect(() => {
    if (audience?.scheduleType === "SCHEDULED") {
      getNextSchedule({
        workspaceId: workspace.id,
        audienceId: audience.id,
      });
    }
  }, []);

  const cyclrUrlRef = useRef<string>("");
  const cyclrPopupRef = useRef<Window | undefined>(null);

  useEffect(() => {
    audience && getDistributionChannels({ workspaceId: workspace.id, audienceIds: [audience.id] });
  }, [audience]);

  useEffect(() => {
    setDestinations(distributionChannels || []);
  }, [distributionChannels]);

  useEffect(() => {
    destinationsRef.current = destinations;
  }, [destinations]);

  const handleConnectToDestinationClick = () => {
    openModal({
      renderContent: DestinationCreate,
      fullHeight: true,
      fullWidth: true,
      title: t("connect_to_destination_modal_title"),
      renderContentProps: {
        forceAudience: audience,
      },
    });
  };

  const handleRefreshClick = () => {
    if (audience) {
      evaluate({ workspaceId: workspace.id, audienceId: audience.id });
      dispatch(
        showToast({
          type: ToastType.INFO,
          title: t("sources_and_rules.schedule.evaluate_title"),
          message: t("sources_and_rules.schedule.evaluate"),
        })
      );
    }
  };

  // UseEffect(() => {
  //   if (!isLaunchLoading && isLaunchSuccess && launchData) {
  //     cyclrUrlRef.current = launchData.LaunchUrl;
  //     cyclrPopupRef.current = window.open(launchData.LaunchUrl, "_blank");
  //   }
  // }, [isLaunchLoading, isLaunchSuccess, launchData]);

  // useEffect(() => {
  //   if (!isLaunchLoading && isLaunchError) {
  //     console.error(launchError);
  //     window.alert(t("destinations.launch_error"));
  //   }
  // }, [isLaunchLoading, isLaunchError, launchError]);

  useEffect(() => {
    const handlePopupMessage = (event: MessageEvent<string>) => {
      const cyclrUrl = cyclrUrlRef.current;
      const cyclrPopup = cyclrPopupRef.current;

      if (cyclrUrl && cyclrUrl.startsWith(event.origin) && cyclrPopup) {
        const response = JSON.parse(event.data);
        cyclrPopup.close();
        cyclrUrlRef.current = "";
        cyclrPopupRef.current = null;
        audience &&
          createDistributionChannel({
            workspaceId: workspace.id,
            audienceId: audience.id,
            configData: response,
          });
      }
    };

    window.addEventListener("message", handlePopupMessage);
    return () => {
      window.removeEventListener("message", handlePopupMessage);
    };
  }, [cyclrUrlRef, cyclrPopupRef]);

  useEffect(() => {
    if (!isCreateLoading && isCreateSuccess && createData) {
      console.log("Created:", createData);
      const currentDestinations = [...destinationsRef.current];
      currentDestinations.push(createData as any);
      setDestinations(currentDestinations);
    }
  }, [isCreateLoading, isCreateSuccess, createData, destinationsRef]);

  useEffect(() => {
    if (!isCreateLoading && createError) {
      console.error(createError);
      window.alert(t("destinations.create_error"));
    }
  }, [isCreateLoading, isCreateError, createError]);

  const handleGoToSourceClick = (data: DataSource) => {
    // Open datasource details
    const { id } = data;

    navigate(`/data-sources/${id}`);
  };

  const handleOnDelete = (destination: DistributionChannel) => {
    console.log(destination);

    audience &&
      openModal({
        renderContent: DisconnectDestinationModal,
        renderContentProps: {
          audience,
          destination,
        },
        dismissable: true,
        fullWidth: false,
        fullHeight: false,
      });
  };

  const renderScheduledSync = () => {
    const syncConfig: AudienceSyncTimeBasedSchedule = audience?.scheduleConfiguration as AudienceSyncTimeBasedSchedule;
    const cardinality: string = syncConfig.repeatsEvery === 1 ? "one" : "other";
    return t(`sources_and_rules.schedule.scheduled_${syncConfig.unit}_${cardinality}` as any, {
      count: syncConfig.repeatsEvery,
      day: syncConfig.dayOfMonth,
      days: syncConfig.weekDays?.map((day) => t(`sources_and_rules.schedule.${day}` as any)).join(", "),
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex-1">
        <div className="py-8">
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">
            {t("sources_and_rules.data_sources_title")}
          </h1>
          {isLoading ? (
            <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
              <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
            </span>
          ) : (
            <SimpleList<DataSource>
              items={dataSources}
              rightElement={(item: DataSource) => (
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleGoToSourceClick(item);
                  }}
                >
                  {t("sources_and_rules.go_to_source")}
                </Button>
              )}
            />
          )}
        </div>
      </div>
      <div className="flex-1">
        <div className="py-8">
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("sources_and_rules.rules_title")}</h1>
          {rules.map((rule, index) => (
            <div key={index} className="bg-white-100 mt-4 flex overflow-hidden p-4 shadow sm:rounded-md">
              <h6 className="mr-6 w-28 text-right text-sm text-gray-900">
                {t(index === 0 ? "sources_and_rules.rules.users_who" : "sources_and_rules.rules.and")}
              </h6>
              <div className="flex w-full flex-col space-y-2">
                <div className="space-y-2 rounded border p-1">
                  {rule.expressions.map((expression, index) => (
                    <div key={index} className="flex items-center p-3">
                      {index > 0 && (
                        <h6 className="mr-2 w-6 text-right text-sm text-gray-900">{t("sources_and_rules.rules.or")}</h6>
                      )}
                      <ExpressionConditionRender
                        isViewMode
                        expression={expression}
                        datasourceIds={dataSources.map((d) => d.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AudienceDestinations
        isLoading={isDistributionChannelsLoading}
        destinations={destinations}
        onConnectClick={handleConnectToDestinationClick}
        onDelete={handleOnDelete}
      />

      <div className="flex-1">
        <div className="py-8">
          <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">
            {t(`sources_and_rules.schedule.${audience?.scheduleType}` as any)}
          </h1>
          {audience?.scheduleType === "SCHEDULED" &&
            (isScheduleFetching || isScheduleLoading ? (
              <div>
                <SpinnerIcon loading className="h-4 w-4" fill="rgba(21,68,155,1)" />
              </div>
            ) : (
              <>
                <div className="mr-1">{renderScheduledSync()}</div>
                <div>
                  <span className="mr-1">{t("sources_and_rules.schedule.next_schedule")}</span>
                  {scheduleData?.nextSchedule ? (
                    format(new Date(scheduleData.nextSchedule), "yyyy-MM-dd HH:mm")
                  ) : (
                    <span className="font-semibold">not calculated</span>
                  )}
                </div>
              </>
            ))}
          {audience?.scheduleType === "MANUAL" && (
            <Button variant="primary" disabled={!useWorkspaceAdmin()} className="mt-2" onClick={handleRefreshClick}>
              {t("sources_and_rules.schedule.refresh")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudienceSourcesAndRules;
