import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Input } from "../../../../../../components/inputs";
import { Select } from "../../../../../../components/selects";
import type { ISelectItem } from "../../../../../../models/select";
import type { RootState } from "../../../../../../reducers";
import { useGetTraitsQuery } from "../../../../../../services/endpoints/traits";
import { generateSlug } from "../../../../../../utils";
import { useWorkspace } from "../../../../../workspaces/hooks";
import { CONSENT_TRAIT, EVENT } from "../../../../constants";
import { type ITraitValue, TRAITS } from "../../../../types";

type ITraitInputProps = {
  option: ISelectItem;
  error?: string;
  value?: ITraitValue;
  onChange: (value: ITraitValue) => void;
};

const TraitInput: FunctionComponent<ITraitInputProps> = ({ value, option, error, onChange }) => {
  const { t } = useTranslation("data_source_edit");
  const workspace = useWorkspace();

  const { data } = useGetTraitsQuery({ workspaceId: workspace.id });

  const dataType = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource?.configuration?.type);

  const traitList: ISelectItem[] = [];

  if (data) {
    const customerTrait = data.customer.map((trait) => ({
      id: trait.id,
      label: trait.displayName,
    }));

    traitList.push(...customerTrait);

    if (dataType === EVENT) {
      const eventTrait = data.event.map((trait) => ({
        id: trait.id,
        label: trait.displayName,
      }));
      traitList.push(...eventTrait);
    }
  }

  switch (option.id) {
    case TRAITS.MAP_TO_TRAIT: {
      return (
        <Select
          items={traitList}
          value={traitList.find((trait) => trait.id === value?.id) || undefined}
          className="-mt-1 mr-1 w-full text-left"
          error={error}
          onChange={(e) => {
            onChange({ id: e.id, displayValue: e.label });
          }}
        />
      );
    }

    case TRAITS.CUSTOM_TRAIT: {
      return (
        <Input
          className="-mt-1 w-full text-left"
          type="text"
          value={value?.displayValue}
          error={error}
          onChange={(e) => {
            onChange({ id: generateSlug(e.target.value) ?? e.target.value, displayValue: e.target.value });
          }}
        />
      );
    }

    case TRAITS.CONSENT_TRAIT: {
      return (
        <Input
          className="-mt-1 w-full text-left"
          type="text"
          value={value?.displayValue}
          placeholder={t("file.map_column.consent_value")}
          error={error}
          onChange={(e) => {
            onChange({ id: CONSENT_TRAIT, displayValue: e.target.value });
          }}
        />
      );
    }

    case TRAITS.IGNORE_COLUMN: {
      return <></>;
    }

    default: {
      return <></>;
    }
  }
};

export default TraitInput;
