import type { FunctionComponent } from "react";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { IconContainer } from "../../../../components/icons";

type IDataSourceTypePanelHeaderProps = {
  title?: string;
  subtitle?: string;
  icon?: string;
  closeSlideOver?: () => void;
};

const DataSourceTypePanelHeader: FunctionComponent<IDataSourceTypePanelHeaderProps> = ({
  title,
  subtitle,
  closeSlideOver,
  ...rest
}) => (
  <div className="px-4 sm:px-6">
    <div className="flex">
      <div className="flex-auto">
        <Dialog.Title className="text-base font-medium text-gray-900">{title}</Dialog.Title>
        <Dialog.Description className="text-sm font-light text-gray-500">{subtitle}</Dialog.Description>
      </div>
      <div className="m-auto flex-auto">
        <div className="flex-shrink-0">
          <div className="flex h-14 w-14 flex-wrap content-center justify-center rounded-lg">
            <IconContainer path={rest.icon} className="h-14" />
          </div>
        </div>
        <button
          className="bg-white-100 block rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 md:hidden"
          onClick={closeSlideOver}
        >
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
);

export default DataSourceTypePanelHeader;
