import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { IHeading } from "../../models/user";

const DEFAULT_USER_HEADINGS = [
  { label: "Name", visible: true },
  { label: "Title", visible: true },
  { label: "Email", visible: true },
  { label: "Role", visible: true },
];

export type UserTableState = {
  headings?: IHeading[];
};

export const initialState: UserTableState = {
  headings: DEFAULT_USER_HEADINGS,
};

const userTableSlice = createSlice({
  name: "userTable",
  initialState,
  reducers: {
    toggleHeading: (state, action: PayloadAction<IHeading>) => ({
      ...state,
      headings: state.headings?.map((h) => (h.label === action.payload.label ? action.payload : h)),
    }),
  },
});

export const { toggleHeading } = userTableSlice.actions;

export default userTableSlice.reducer;
