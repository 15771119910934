import { type FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { addDays, formatISO, isAfter, parseISO } from "date-fns";
import { first } from "lodash";

import { Loading } from "../../../components/loaders";
import { type RootState, useAppDispatch } from "../../../reducers";
import {
  useCreatePasswordTicketMutation,
  useGetUserInfoQuery,
  useLazyGetUserPasswordPolicyQuery,
} from "../../../services/endpoints/users";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/localstorage";
import { Layout } from "../../layout";
import RouteConstants from "../../router/RouteConstants";
import { setUserInfo } from "../../userInfo/userInfoSlice";
import { setCurrentWorkspace } from "../workspacesSlice";

const WorkspaceLayout: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const workspace = useSelector((state: RootState) => state.workspaces.currentWorkspace);
  const onboard = useSelector((state: RootState) => state.userInfo.onboard);

  const { isLoading, data: userInfo } = useGetUserInfoQuery();
  const [getUserPasswordPolicy, { isLoading: isLoadingPasswordPolicy, data: userPasswordPolicy }] =
    useLazyGetUserPasswordPolicyQuery();
  const [createPasswordTicket, { isLoading: isLoadingTicketCreation, data: ticketResponse }] =
    useCreatePasswordTicketMutation();

  useEffect(() => {
    const lastTimeCheck = getLocalStorageItem("password", "last-check-time");
    if (!lastTimeCheck || isAfter(new Date(), addDays(parseISO(lastTimeCheck), 1))) {
      getUserPasswordPolicy();
    }
  }, []);

  useEffect(() => {
    dispatch(setUserInfo(userInfo));

    if (userInfo) {
      if (userInfo.workspaces.length >= 1) {
        const validWs = userInfo.workspaces.find((w) => w.id === workspace?.id);

        if (validWs) {
          dispatch(setCurrentWorkspace(validWs));
        } else {
          dispatch(setCurrentWorkspace(first(userInfo.workspaces)));
        }
      } else {
        dispatch(setCurrentWorkspace());
      }
    }
  }, [userInfo]);

  useEffect(() => {
    setLocalStorageItem("password", "last-check-time", formatISO(new Date()));

    if (userPasswordPolicy?.reset) {
      createPasswordTicket();
    }
  }, [userPasswordPolicy]);

  useEffect(() => {
    if (ticketResponse) {
      window.location.replace(ticketResponse.ticket);
    }
  }, [ticketResponse]);

  if (isLoading || isLoadingPasswordPolicy || isLoadingTicketCreation) return <Loading />;

  if (onboard?.forceCompanyCreation && window.location.pathname !== RouteConstants.createCompany) {
    return <Navigate to={RouteConstants.createCompany} />;
  }

  if (userInfo) {
    return <Layout />;
  }

  return <Loading />;
};

export default WorkspaceLayout;
