import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { PauseIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../../../components/buttons";

const PauseImport: FunctionComponent = () => {
  const { t } = useTranslation("data_source_details");
  return (
    <div>
      <h1 className="text-black-100 text-lg font-semibold">{t("settings.pause_import.pause_import_title")}</h1>
      <h3 className="my-3 text-base">
        {t("settings.pause_import.stop_listen_events")}
        <br />
        {t("settings.pause_import.data_already_present")}
        <br />
        {t("settings.pause_import.internal_datasource")}
      </h3>
      <Button icon={PauseIcon}>{t("settings.pause_import.pause_this_datasource")}</Button>
    </div>
  );
};

export default PauseImport;
