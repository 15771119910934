import type { FunctionComponent } from "react";

import { concatClassNames } from "../../utils";

type ISlideOverContentWrapperProps = {
  className?: string;
};

const SlideOverContentWrapper: FunctionComponent<ISlideOverContentWrapperProps> = ({ children, className }) => (
  <div className={concatClassNames("relative mt-6 flex-1 px-4 sm:px-6", className || "")}>
    <div className="h-full" aria-hidden="true">
      <div className="flex flex-1 flex-col justify-between">
        <div className="divide-y divide-gray-200">
          <div className="space-y-6 pb-5 pt-6">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default SlideOverContentWrapper;
