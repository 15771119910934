import { type FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Formik, type FormikErrors, type FormikProps } from "formik";
import * as Yup from "yup";

import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as LeadingIcon } from "../../assets/icons/leading.svg";
import { Button } from "../../components/buttons";
import { Input } from "../../components/inputs";
import { useUserInfo } from "../userInfo/hooks";
import RoleSelect, { type IRoleSelectItem } from "./components/RoleSelect";

type Collaborator = {
  email: string;
  role: string;
};

type CollaboratorsFormModel = { collaborators: Collaborator[] };

type IInviteWorkspaceInternalCollaboratorsProps = {
  collaborators?: Collaborator[];
  collaboratorsChange?: (collaborators: Collaborator[]) => void;
  nextStep?: () => void;
  prevStep?: () => void;
};

const InviteWorkspaceInternalCollaborators: FunctionComponent<IInviteWorkspaceInternalCollaboratorsProps> = ({
  collaborators,
  collaboratorsChange,
  nextStep,
  prevStep,
}) => {
  const { t } = useTranslation("workspaces");
  const userInfo = useUserInfo();

  const validationSchema = Yup.object({
    collaborators: Yup.array(
      Yup.object({
        email: Yup.string()
          .email(t("edit.validations.not_valid_email"))
          .test(
            "ends-with",
            t("create.invite_internal.domain_check"),
            (value) =>
              !value || Boolean(userInfo.company.domains.find((domain) => value?.endsWith("@" + domain))) || false
          ),
        role: Yup.string().test(
          "required-and-empty",
          t("edit.validations.required"),
          (value, control) => !control.parent.email || Boolean(value)
        ),
      })
    ),
  });

  const roles: IRoleSelectItem[] = [
    {
      id: "ADMIN",
      label: "Admin",
      description: "Can create workspaces and invite collaborators, and all the rest",
      image: <LeadingIcon className="h-5 w-5" />,
    },
    {
      id: "READONLY",
      label: "Readonly",
      description: "Can normally use the platform, doing things like update data or create sources",
      image: <EyeIcon className="h-5 w-5" />,
    },
  ];

  const [errorMessage, setErrorMessage] = useState<string>("");
  const myForm = useRef<FormikProps<CollaboratorsFormModel>>(null);

  const doSubmit = () => {
    myForm.current?.submitForm();
  };

  const handleOnSubmit = (form: CollaboratorsFormModel) => {
    collaboratorsChange?.(form.collaborators.filter((collaborator) => Boolean(collaborator.email.trim())));
    nextStep?.();
  };

  const handleCancel = () => {
    collaboratorsChange?.(
      myForm.current?.values.collaborators.filter((collaborator) => Boolean(collaborator.email.trim())) || []
    );
    prevStep?.();
  };

  return (
    <div className="bg-wave flex h-full w-full items-center justify-center bg-gray-100">
      <div className="bg-white-100 flex flex-col items-center rounded-lg p-8 shadow-md" style={{ width: "448px" }}>
        <h3 className="mb-8 text-xl font-semibold">{t("create.invite_internal.title")}</h3>
        <p dangerouslySetInnerHTML={{ __html: t("create.invite_internal.description") }} className="text-medium mb-8" />
        {errorMessage ? (
          <div className="text-white-100 mb-3 w-full rounded border-red-300 bg-red-600 p-2">{errorMessage}</div>
        ) : null}
        <Formik<CollaboratorsFormModel>
          initialValues={{
            collaborators: new Array(5)
              .fill({
                email: "",
                role: "",
              })
              .map((item, i) => (collaborators && collaborators.length > i ? collaborators[i] : { ...item })),
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          innerRef={myForm}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form className="w-full">
              <table className="mb-3 w-full">
                {values.collaborators.map((collaborator, index) => (
                  <tr key={index}>
                    <td className="pb-2" valign="top">
                      <Input
                        name="name"
                        id="name"
                        type="text"
                        value={collaborator.email}
                        placeholder={t("create.invite_internal.email")}
                        error={
                          errors.collaborators && errors.collaborators[index]
                            ? (errors.collaborators[index] as FormikErrors<Collaborator>).email
                            : undefined
                        }
                        onChange={(e) => {
                          setFieldValue(`collaborators.${index}.email`, e.target.value);
                        }}
                      />
                    </td>
                    <td className="pb-2 pl-3" valign="top">
                      <RoleSelect
                        placeholder="Choose"
                        className="w-full"
                        items={roles}
                        value={collaborator.role}
                        onChange={(value) => {
                          setFieldValue(`collaborators.${index}.role`, value);
                        }}
                      />
                      {errors.collaborators && errors.collaborators[index] ? (
                        <span className="mt-2 text-sm text-red-600">
                          {(errors.collaborators[index] as FormikErrors<Collaborator>).role}
                        </span>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </table>

              <div className="flex flex-col justify-end">
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-4 w-full justify-center px-4 py-2"
                  onClick={doSubmit}
                >
                  {t("create.invite_internal.continue")}
                </Button>
                <Button
                  variant="light"
                  type="button"
                  className="w-full justify-center px-4 py-2"
                  onClick={handleCancel}
                >
                  {t("create.invite_internal.back")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InviteWorkspaceInternalCollaborators;
