import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CollaboratorLink, SnippetPreview } from "../../../../../../components/snippet";
import { type DataSource, EDataSourceStatus } from "../../../../../../models/dataSource";
import { useLazyGetDataSourceSetupQuery } from "../../../../../../services/dataSources";
import { CheckCodeSnippetConnection } from "../../../../../dataSourceEdit/components/categories/code";
import { useWorkspace } from "../../../../../workspaces/hooks";
import { ActivationStatus } from "../../../status";

type IPendingDataSource = {
  dataSource?: DataSource;
};

const PendingCodeDataSource: FunctionComponent<IPendingDataSource> = ({ dataSource }) => {
  const { t } = useTranslation("data_source_details");

  const workspace = useWorkspace();

  const [initDataSource, { data: setupData }] = useLazyGetDataSourceSetupQuery();

  useEffect(() => {
    if (dataSource?.status === EDataSourceStatus.PENDING) {
      initDataSource({
        workspaceId: workspace.id,
        dataSourceId: dataSource?.id || "",
      });
    }
  }, []);

  const script = setupData?.setup.scriptUrl
    ? `<!-- Include 'analytics' from CDN -->
    <script src="${setupData?.setup.scriptUrl}"></script>
    <!--suppress JSUnresolvedVariable -->
    <script type="text/javascript">
      /* Initialize analytics */
      window.Analytics = CDPAnalytics.create({
        apiUrl: '${setupData?.setup.ingestionUrl}',
        datasourceId: "${setupData?.setup.datasourceId}",
        log: true
      });

      Analytics.page();
    </script>`
    : undefined;

  return (
    <div>
      <ActivationStatus dataSource={dataSource} />
      <div className="flex space-x-6">
        <div className="w-1/2 flex-1">
          <div className="py-8">
            <SnippetPreview script={script} />
          </div>
          <hr className="mr-2" />
          <div className="py-8">
            <CollaboratorLink
              onShareLinkToCollaboratorClicked={() => {
                console.log("copy link");
              }}
            />
          </div>
        </div>
        <div className="w-1/2 flex-1">
          <div className="my-8 bg-gray-50 p-6">
            <h1 className="mb-2 text-base font-bold leading-4 text-gray-900">{t("overview.is_it_working")}</h1>
            <h4 className="mb-4 text-sm font-normal leading-4 text-gray-600">
              {t("overview.snippet_install")} <br />
              {t("overview.snippet_install_minutes")}
            </h4>
            <CheckCodeSnippetConnection dataSource={dataSource} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingCodeDataSource;
