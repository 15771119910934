import type { FunctionComponent } from "react";

import type { DataSource } from "../../../../../../models/dataSource";
import { ActivationStatus } from "../../../status";

type IPendingCrmDataSourceProps = {
  dataSource?: DataSource;
};

const PendingCrmDataSource: FunctionComponent<IPendingCrmDataSourceProps> = ({ dataSource }) => (
  <div className="flex">
    <ActivationStatus dataSource={dataSource} />
  </div>
);

export default PendingCrmDataSource;
