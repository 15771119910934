import { Fragment, type FunctionComponent, type ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

import { ReactComponent as CheckIcon } from "../../../assets/icons/check-icon.svg";
import { SpinnerIcon } from "../../../components/icons";

export type IRoleSelectItem = {
  id: string;
  label: string;
  description: string;
  doNotRenderValue?: boolean;
  image?: ReactNode;
};

export type IRoleSelects = {
  id?: string;
  label?: string;
  items: IRoleSelectItem[];
  value?: string;
  error?: string;
  className?: string;
  disabled?: boolean;
  isViewMode?: boolean;
  onChange?: (event: string) => void;
  loading?: boolean;
  placeholder?: string;
};

// This can be used if we need to change props to generic and have typed props
// const Select = <T,>(props: PropsWithChildren<ISelectProps<T>>) => { }

const RoleSelect: FunctionComponent<IRoleSelects> = ({
  id,
  label,
  items,
  value,
  onChange,
  className,
  error,
  disabled,
  isViewMode,
  loading,
  placeholder,
}) => {
  const { t } = useTranslation("ui");

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const [itemMap, setItemMap] = useState<Record<string, IRoleSelectItem>>({});

  useEffect(() => {
    const map: Record<string, IRoleSelectItem> = {};
    for (const item of items) {
      map[item.id] = item;
    }

    setItemMap(map);
  }, [items]);

  const styleListboxButton =
    "bg-white-100 relative w-auto border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm";
  const styleListboxOption =
    "absolute ml-2 z-10 bg-white-100 shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm";

  return (
    <Listbox value={value} disabled={disabled || isViewMode || loading} onChange={handleChange}>
      {({ open }) => (
        <>
          {Boolean(label) && <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
          <div className="relative">
            <Listbox.Button
              id={id}
              className={twMerge(
                className ? twMerge(styleListboxButton, className) : styleListboxButton,
                disabled ? "bg-gray-200 text-gray-500" : "",
                error ? "border-red-500  outline-none hover:border-red-500 focus:border-red-500" : ""
              )}
            >
              {value ? (
                <span className="block truncate">{itemMap[value]?.label}</span>
              ) : (
                <span className="block truncate text-gray-400">{placeholder || t("choose_placeholder")}</span>
              )}
              {loading ? (
                <div className="absolute right-6 top-0 -ml-3 flex h-full w-10 items-center justify-center rounded">
                  <SpinnerIcon loading className="absolute h-6 w-6" />
                </div>
              ) : null}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options static className={styleListboxOption} style={{ top: 0, left: "100%", width: "345px" }}>
                {items.map((item: IRoleSelectItem) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      twMerge(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-pointer select-none py-2 pl-3 pr-4"
                      )
                    }
                    value={item.id}
                  >
                    <div className="flex items-center">
                      {item.image ? <div className="mr-3 inline-block align-middle">{item.image}</div> : null}
                      <div className="flex flex-col">
                        <h3 className="text-sm">{item.label}</h3>
                        <p className="text-sm text-gray-300">{item.description}</p>
                      </div>
                      {value === item.id ? <CheckIcon className="min-w-4 h-4 w-4 text-green-500" /> : null}
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {Boolean(error) && (
            <div className="text-left">
              <Listbox.Label className="mt-2 block text-sm text-red-600">{error}</Listbox.Label>
            </div>
          )}
        </>
      )}
    </Listbox>
  );
};

export default RoleSelect;
