import { type FunctionComponent, useEffect, useState } from "react";

import { differenceInCalendarDays, format, formatDistanceToNow, formatRelative, parse, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import DatePickerInput from "../../../../components/inputs/DatePickerInput";
import type { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import { upperCaseFirstChar } from "../../../../utils";

type IAudienceSyncRepeatStartProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const AudienceSyncRepeatStart: FunctionComponent<IAudienceSyncRepeatStartProps> = ({
  scheduleConfiguration,
  setFieldValue,
}) => {
  const initialStartsOn = scheduleConfiguration.startsOn ? parseISO(scheduleConfiguration.startsOn) : new Date();
  initialStartsOn.setSeconds(0);
  initialStartsOn.setMilliseconds(0);

  const [startDay, setStartDay] = useState<Date>(initialStartsOn);
  const [startHour, setStartHour] = useState<string>(format(initialStartsOn, "HH:mm"));
  const [readableStartDate, setReadableStartDate] = useState<string>();

  useEffect(() => {
    const parsed = parse(startHour, "HH:mm", startDay);
    // eslint-disable-next-line new-cap
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setFieldValue(
      "scheduleConfiguration.startsOn",
      `${formatInTimeZone(parsed, tz, "yyyy-MM-dd")}T${formatInTimeZone(parsed, tz, "HH:mm")}:00.000${formatInTimeZone(
        parsed,
        tz,
        "X"
      )}`
    );

    const daysDiff = differenceInCalendarDays(parsed, new Date());
    if (daysDiff < -6) {
      setReadableStartDate(`(${formatDistanceToNow(parsed)} ago)`);
    } else if (daysDiff === 0 || daysDiff === 1) {
      setReadableStartDate(`(${upperCaseFirstChar(formatRelative(parsed, new Date()))})`);
    } else if (daysDiff < 7) {
      setReadableStartDate(`(This ${formatRelative(parsed, new Date())})`);
    } else if (daysDiff < 14) {
      setReadableStartDate(`(${format(parsed, `iiii 'of the next week at' KK:mm`)})`);
    } else {
      setReadableStartDate(`(in ${formatDistanceToNow(parsed)})`);
    }
  }, [startDay, startHour]);

  return (
    <div className="mb-6 flex flex-row items-center">
      <span className="mr-4">It starts on</span>

      <div className="mr-4 w-32">
        <DatePickerInput
          dateFormat="dd/MM/yyyy"
          className="shadow-sm"
          selected={startDay}
          onChange={(e) => {
            e !== null && !Array.isArray(e) && setStartDay(e);
          }}
        />
      </div>
      <div className="mr-4 w-24">
        <input
          type="time"
          className="rounded-md border border-gray-300 px-2 placeholder-gray-400 shadow-sm focus:border focus:border-blue-600 focus:outline-none sm:text-sm"
          value={startHour}
          onChange={(e) => {
            setStartHour(e.target.value);
          }}
        />
      </div>
      {readableStartDate ? <span>{readableStartDate}</span> : null}
    </div>
  );
};

export default AudienceSyncRepeatStart;
