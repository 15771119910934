import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Input, PrefixInput } from "../../../components/inputs";
import type { DataSourceCodeConfig, TDataSource } from "../../../models/dataSource";
import { TrackPageVisit, TrackPageVisitPanel } from "../../dataSourceEdit/components/categories/code/tracking";
import type { ITrackPageVisitEvents } from "../../dataSourceEdit/types";
import TrackRuleForm from "./TrackRuleForm";

type ITrackPageVisitViewProps = {
  dataSource?: TDataSource<DataSourceCodeConfig>;
  events: ITrackPageVisitEvents[];
  className?: string;
};

const TrackPageVisitView: FunctionComponent<ITrackPageVisitViewProps> = ({ dataSource, events, className }) => {
  const { t } = useTranslation("data_source_edit");

  const handleDeleteRule = (ruleId: string) => {
    console.warn("Ignoring delete rule:", ruleId);
  };

  return (
    <TrackPageVisitPanel className={className}>
      <div className="mt-4">
        {events?.map((event, index) => (
          <div key={index} className="my-2 flex px-4">
            <div className="w-1/2 flex-auto">
              <PrefixInput isViewMode value={event.pagePath} prefix={event.baseUrl} />
            </div>
            <div className="flex w-1/2">
              <span className="ml-2 mr-1 h-11 py-2">{t("code.track_events.creates")}</span>
              <div className="flex-auto">
                <Input isViewMode placeholder={t("code.track_events.name_of_the_event")} value={event.eventName} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TrackRuleForm
        dataSource={dataSource}
        currentEventCount={events?.length || 0}
        newEventCountFn={(values) => values.pageVisitEvents.length}
      >
        {({ values, setFieldValue, setFieldError, errors }) => (
          <TrackPageVisit
            values={values}
            handleSetFieldValue={setFieldValue}
            handleSetFieldError={setFieldError}
            handleDeleteRule={handleDeleteRule}
            errors={errors}
            dataSource={dataSource}
          />
        )}
      </TrackRuleForm>
    </TrackPageVisitPanel>
  );
};

export default TrackPageVisitView;
