import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { format, parseISO } from "date-fns";

import { SpinnerIcon } from "../../../../components/icons";
import { useDebounce } from "../../../../hooks/useDebounce";
import type { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import { useLazyGetNextSchedulesQuery } from "../../../../services/endpoints/audiences";
import { useWorkspace } from "../../../workspaces/hooks";
import { validateSchedule } from "./validation";

type IAudienceSyncRepeatNextProps = {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
};

const AudienceSyncRepeatNext: FunctionComponent<IAudienceSyncRepeatNextProps> = ({ scheduleConfiguration }) => {
  const workspace = useWorkspace();
  const { t } = useTranslation("audience_edit");

  const [getNextSchedules, { data: nextScheduleData }] = useLazyGetNextSchedulesQuery();
  const [nextSchedules, setNextSchedules] = useState<string[]>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const debouncedConfiguration = useDebounce(scheduleConfiguration, 1000);

  useEffect(() => {
    setShowLoading(true);
  }, [scheduleConfiguration]);

  useEffect(() => {
    const errors = validateSchedule(
      {
        scheduleType: "SCHEDULED",
        scheduleConfiguration,
      },
      t
    );

    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      getNextSchedules({
        workspaceId: workspace.id,
        times: 5,
        scheduleType: "SCHEDULED",
        scheduleConfiguration: debouncedConfiguration,
      });
    } else {
      setNextSchedules();
    }
  }, [debouncedConfiguration]);

  useEffect(() => {
    if (nextScheduleData && nextScheduleData.nextSchedules) {
      setNextSchedules(nextScheduleData.nextSchedules);
      setShowLoading(false);
    }
  }, [nextScheduleData]);

  return nextSchedules && nextSchedules.length ? (
    <div>
      <div className="flex flex-row">
        <span className="mr-2 font-semibold">The next updates will be on:</span>
        {showLoading ? <SpinnerIcon loading className="h-6 w-6" fill="rgba(21,68,155,1)" /> : null}
      </div>

      <ul className="list-inside list-disc">
        {nextSchedules.map((schedule) => (
          <li key={schedule} className="ml-3 mt-1 font-normal leading-5 text-gray-700">
            {format(parseISO(schedule), "EEEE, dd MMMM yyyy 'at' HH:mm")}
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="text-gray-400">
      Once selected the repeat frequency, you’ll see the next updates, just to be sure that it was what you mean
    </div>
  );
};

export default AudienceSyncRepeatNext;
