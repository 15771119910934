import { Route, Routes } from "react-router-dom";

import { Audiences } from ".";
import { AudienceDetails } from "../audienceDetails";

const AudiencesRouter = () => (
  <Routes>
    <Route path="/:id" element={<AudienceDetails />} />
    <Route path="" element={<Audiences />} />
  </Routes>
);

export default AudiencesRouter;
