import type { PropsWithChildren } from "react";

import type { DataSourceType } from "../../models/dataSource";
import { IconContainer } from "../icons";

// Base type for simple list structure
type SimpleItem = {
  id: string;
  name: string;
  description?: string;
  type?: DataSourceType;
};

export type ISimpleListProps<T> = {
  items: T[];
  rightElement?: (item: T) => JSX.Element;
  onClick?: (item: T) => void;
};

function SimpleList<T extends SimpleItem>({ items, rightElement, onClick }: PropsWithChildren<ISimpleListProps<T>>) {
  return (
    <div className="bg-white-100 overflow-hidden shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {items.map((item) => (
          <li
            key={item.id}
            className={onClick ? "cursor-pointer hover:bg-gray-50" : ""}
            onClick={() => onClick?.(item)}
          >
            <div className="flex items-center p-4 sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex items-center text-sm">
                    {item.type ? (
                      <div className="mr-2 flex h-14 w-14 flex-wrap content-center justify-center rounded-lg">
                        <IconContainer path={item.type.logoUrl} className="h-10" />
                      </div>
                    ) : null}
                    <p className="truncate font-medium text-blue-600">{item.name}</p>
                  </div>
                </div>
              </div>
              <div className="ml-5 flex-shrink-0">
                {rightElement?.(item)}
                {/* <ChevronRightIcon className='h-5 w-5 text-gray-400' aria-hidden='true' /> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SimpleList;
