import type React from "react";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { PaginationState } from "../../../models/pagination";
import { type RootState, useAppDispatch } from "../../../reducers";
import { SimplePagination } from "./index";

export type PaginatedQueryFn = (pageNo: number, pageSize: number) => void;

type IPaginatedProps<T> = {
  stateSelector: (state: RootState) => PaginationState;
  stateUpdater: (pagination: PaginationState) => PayloadAction<PaginationState>;
  query: PaginatedQueryFn;
  queryResponse: T[] | undefined;
  setItems: (items: T[]) => void;
  querying: boolean;
  children: (pager: React.ReactNode) => React.ReactNode;
  pageSizes?: number[];
};

function Paginated<T>({
  stateSelector,
  stateUpdater,
  query,
  queryResponse,
  setItems,
  querying,
  pageSizes = [10, 15, 25, 50],
  ...props
}: PropsWithChildren<IPaginatedProps<T>>): React.ReactElement<any, any> {
  const dispatch = useAppDispatch();
  const pagination = useSelector(stateSelector);

  const [pageNo, setPageNo] = useState<number>(pagination?.pageNo || 0);
  const [pageSize, setPageSize] = useState<number>(pagination?.pageSize || pageSizes[0]);
  const [itemCount, setItemCount] = useState<number>();
  const [lastPage, setLastPage] = useState<number>();

  const queryFnRef = useRef<PaginatedQueryFn>(query);
  const pageNoRef = useRef<number>(pageNo);
  const pageSizeRef = useRef<number>(pageSize);

  useEffect(() => {
    let actualPageNo: number = pageNo;

    if (queryFnRef.current !== query) {
      setLastPage(-1);
      setPageNo(0);
      actualPageNo = 0;
      queryFnRef.current = query;
    }

    if (pageSizeRef.current !== pageSize) {
      setLastPage(-1);
    }

    pageNoRef.current = actualPageNo;
    pageSizeRef.current = pageSize;

    query(actualPageNo, pageSize);
  }, [query, pageNo, pageSize]);

  useEffect(() => {
    if (queryResponse) {
      if (queryResponse.length || pageNoRef.current === 0) {
        setItems(queryResponse);
        dispatch(
          stateUpdater({
            pageNo: pageNoRef.current,
            pageSize: pageSizeRef.current,
          })
        );
        setItemCount(queryResponse.length);

        if (queryResponse.length < pageSize) {
          setLastPage(pageNoRef.current);
        }
      } else {
        setLastPage(pageNoRef.current - 1);
        setPageNo(pageNoRef.current - 1);
      }
    }
  }, [queryResponse]);

  const updatePageSize = (pageSize: number) => {
    if (pageSize !== pageSizeRef.current) {
      setPageNo(0);
      setPageSize(pageSize);
    }
  };

  return (
    <>
      {props.children(
        <div className="mb-40">
          <SimplePagination
            extended
            firstPage={pageNo === 0}
            lastPage={pageNo === lastPage}
            nextPage={() => {
              setPageNo(pageNo + 1);
            }}
            prevPage={() => {
              setPageNo(pageNo - 1);
            }}
            pageNo={pagination?.pageNo}
            itemCount={itemCount}
            pageSize={pageSize}
            pageSizes={pageSizes}
            toFirstPage={() => {
              setPageNo(0);
            }}
            loading={querying}
            onChangePageSize={updatePageSize}
          />
        </div>
      )}
    </>
  );
}

export default Paginated;
