import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { Step } from "../../components/steppers";
import type { Audience } from "../../models/audiences";

type AudienceEditState = {
  audience?: Partial<Audience>;
  ui: {
    steps: Step[];
  };
};

export const initialState: AudienceEditState = {
  ui: {
    steps: [],
  },
};

const audienceEditSlice = createSlice({
  name: "audienceEdit",
  initialState,
  reducers: {
    stashAudience: (state, action: PayloadAction<Partial<Audience>>) => ({
      ...state,
      audience: { ...state.audience, ...action.payload },
    }),
    clearStashedAudience: (state) => ({
      ...state,
      audience: undefined,
    }),
    stashSteps: (state, action: PayloadAction<Step[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        steps: action.payload,
      },
    }),
  },
});

export const { stashAudience, clearStashedAudience, stashSteps } = audienceEditSlice.actions;

export default audienceEditSlice.reducer;
