import type React from "react";
import { type ChangeEvent, type FunctionComponent, type RefObject, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { isEmpty } from "lodash";

import { concatClassNames } from "../../utils";

type IFileUploadInputProps = {
  id?: string;
  label?: string;
  className?: string;
  error?: string;
  innerRef?: RefObject<HTMLInputElement>;
  onChange?: (files: File[] | undefined) => void;
};

type TInputType = IFileUploadInputProps & Omit<React.ComponentProps<"input">, "onChange">;

const FileUploadInput: FunctionComponent<TInputType> = ({ id, name, label, onChange, error, innerRef, ...rest }) => {
  const { t } = useTranslation("data_source_edit");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (onChange) {
      onChange(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    // accept: ["text/csv", "text/plain", "application/pgp-encrypted"],
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "application/pgp-encrypted": [],
      "application/vnd.ms-excel": [".csv"],
    },
    noClick: true,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const { files } = e.target;

      if (files && !isEmpty(files)) {
        onChange(new Array(files[0]));
      }
    }
  };

  return (
    <div className="w-full">
      {label ? <label className="block py-2 text-left text-sm font-medium text-gray-700">{label}</label> : null}
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <div
          {...getRootProps()}
          className={concatClassNames(
            "flex h-52 justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6",
            isDragActive
              ? " border-blue-500 bg-blue-500"
              : error
              ? "border-red-300 bg-red-50"
              : "border-gray-300 bg-gray-50"
          )}
        >
          <div className="m-auto space-y-1 text-center">
            <div className="h- flex justify-center text-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill={isDragActive ? "transparent" : "none"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.3332 13.3332C25.3332 13.8415 25.2373 14.329 25.0665 14.7748C27.4998 15.2665 29.3332 17.4207 29.3332 19.9998C29.3332 22.9457 26.9457 25.3332 23.9998 25.3332H8.6665C5.354 25.3332 2.6665 22.6457 2.6665 19.3332C2.6665 16.7165 4.3415 14.4915 6.67484 13.6707C6.67067 13.5582 6.6665 13.4457 6.6665 13.3332C6.6665 9.64984 9.64984 6.6665 13.3332 6.6665C15.804 6.6665 17.9582 8.00817 19.1123 10.0082C19.7457 9.58317 20.5123 9.33317 21.3332 9.33317C23.5415 9.33317 25.3332 11.1248 25.3332 13.3332ZM18.7873 17.879L19.2373 17.429C19.6248 17.0415 19.6248 16.4082 19.2332 16.0165L15.3707 12.154C14.9832 11.7623 14.3498 11.7623 13.9582 12.154L10.0957 16.0165C9.704 16.404 9.704 17.0373 10.0957 17.429L10.5457 17.879C10.9457 18.279 11.5957 18.2707 11.9832 17.8582L13.3332 16.4207V21.6665C13.3332 22.2207 13.779 22.6665 14.3332 22.6665H14.9998C15.554 22.6665 15.9998 22.2207 15.9998 21.6665V16.4207L17.3498 17.8582C17.7373 18.2665 18.3873 18.279 18.7873 17.879Z"
                    fill="black"
                  />
                </mask>
                <g mask="url(#mask0)">
                  <rect
                    width="38.4"
                    height="38.4"
                    transform="translate(-3.2002 -3.2002)"
                    fill={isDragActive ? "#FFFFFF" : "#029DE7"}
                  />
                </g>
              </svg>
            </div>
            <div className="flex text-xl text-gray-700">
              <label
                htmlFor={id}
                className={concatClassNames(
                  "bg-white  text-x relative rounded-md font-medium",
                  isDragActive ? "text-white-100" : "text-gray-700"
                )}
              >
                {isDragActive ? (
                  <span>{t("file.name_and_file.file_upload_input_component.release_here")}</span>
                ) : (
                  <>
                    <span>{t("file.name_and_file.file_upload_input_component.drag_and_drop_text")}</span>
                    <span className="cursor-pointer underline">
                      {t("file.name_and_file.file_upload_input_component.browse")}
                    </span>
                  </>
                )}

                <input
                  ref={innerRef}
                  id={id}
                  name={name}
                  type="file"
                  className="sr-only"
                  {...getInputProps()}
                  {...rest}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {error ? (
        <div className="text-left ">
          <p className="mt-2 text-sm text-red-600">{error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FileUploadInput;
