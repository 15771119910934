import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { Workspace } from "../../models/workspace";

type WorkspacesState = {
  currentWorkspace?: Workspace;
};

export const initialState: WorkspacesState = {};

const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setCurrentWorkspace(state, action: PayloadAction<WorkspacesState["currentWorkspace"]>) {
      if (!action.payload || state.currentWorkspace?.id === action.payload.id) return;

      state.currentWorkspace = action.payload;
    },
  },
});

export const { setCurrentWorkspace } = workspacesSlice.actions;

export default workspacesSlice.reducer;
