import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { UserGroupIcon } from "@heroicons/react/20/solid";
import { CogIcon } from "@heroicons/react/24/outline";

import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import { useModalContext } from "../../hooks/useModalContext";
import { ETABS, type TabType } from "../../models/tabs";
import { useGetWorkspaceQuery } from "../../services/endpoints/workspaces";
import { MainContentWrapper } from "../layout/main";
import { TopbarTabs } from "../layout/topbar";
import { ConfirmWorkspaceDelete } from "../settings/components";
import { isCompanyAdmin, isWorkspaceAdmin } from "../userInfo/hooks/useUserInfo";
import { WorkspaceEdit } from "./";
import { WorkspaceUsers } from "./components";

const WorkspaceDetails: FunctionComponent = () => {
  const { t } = useTranslation("workspaces");
  const { id: workspaceId } = useParams<{ id: string }>();
  const { openModal } = useModalContext();

  const [tabs, setTabs] = useState([
    { id: ETABS.SETTINGS, label: t("details.tabs.edit"), current: true, icon: CogIcon },
    ...(isCompanyAdmin() || isWorkspaceAdmin(workspaceId)
      ? [
          {
            id: ETABS.OVERVIEW,
            label: t("details.tabs.users"),
            current: false,
            icon: UserGroupIcon,
          },
        ]
      : []),
  ]);

  const { data: workspace, isFetching, isUninitialized, isError } = useGetWorkspaceQuery({ workspaceId });
  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);
  const shouldShowLoading = isFetching || isFetchingDelayed || isUninitialized;

  const breadcrumbs = [
    {
      label: t("details.breadcrumbs.settings"),
    },
    {
      label: t("details.breadcrumbs.workspaces"),
      path: "/settings",
    },
    {
      label: workspace?.name || "",
    },
  ];

  const handleTabChange = (selectedTab: TabType) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === selectedTab.id })));
  };

  const handleOnDeleteClicked = () => {
    openModal({
      renderContent: ConfirmWorkspaceDelete,
      renderContentProps: {
        workspace: workspace!,
      },
      dismissable: true,
    });
  };

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current);

    switch (currentTab?.id) {
      case ETABS.SETTINGS: {
        return <WorkspaceEdit workspace={workspace} onDeleteClicked={handleOnDeleteClicked} />;
      }

      case ETABS.OVERVIEW: {
        return <WorkspaceUsers workspace={workspace!} />;
      }

      default: {
        return null;
      }
    }
  };

  return shouldShowLoading ? (
    <MainContentWrapper>
      <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
        <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
      </span>
    </MainContentWrapper>
  ) : (
    <>
      <TopbarTabs tabs={tabs} breadcrumbs={breadcrumbs} onTabChange={handleTabChange} />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl">{renderTabContent()}</div>
      </MainContentWrapper>
    </>
  );
};

export default WorkspaceDetails;
