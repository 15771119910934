export enum EDataSourceCategory {
  CODE = "CODE",
  CRM = "CRM",
  FILE = "FILE",
  OTHER = "OTHER",
  REQUESTED = "REQUESTED",
}

export type DataSourceType = {
  id: string;
  name: string;
  subtitle: string;
  logoUrl: string;
  category: EDataSourceCategory;
  // Json field
  configuration: unknown; // Unknown;
};

export type RequestedDataSourceType = {
  id: string;
  name: string;
  subtitle: string;
  logoUrl: string;
  // Json field
  configuration: unknown; // Unknown;
};

export type DataSourceSetup = {
  setup: {
    scriptUrl: string;
    datasourceId: string;
    ingestionUrl: string;
  };
};

export type DataSourceStatus = {
  status: EDataSourceStatus;
  lastEventReceived: string;
};

export type DataSourceStats = {
  users: number | undefined;
  events: number | undefined;
  traits: number | undefined;
};

export enum EDataSourceStatus {
  ACTIVE = "ACTIVE",
  ACTIVATING = "ACTIVATING",
  CONFIGURATION_REQUIRED = "CONFIGURATION_REQUIRED",
  PENDING = "PENDING",
  ERROR = "ERROR",
}

export type DataSource = {
  id: string;
  name: string;
  status: EDataSourceStatus;
  type: DataSourceType;
  createdOn: string;
  // For ui
  createdOnDate?: Date;
  activatedOn: string;
  // Json field
  configuration: unknown; // (DataSourceCodeConfig | )
};

export type TDataSource<T> = {
  configuration: T;
} & DataSource;

export type DataSourceTypeCodeConfig = {
  encrypted?: boolean;
  website?: string;
  description?: string;
  usedFor?: string[];
  requirements?: string[];
};

export type DataSourceCodeConfig = {
  url?: string;
};

export type DataSourceCrmConfig = Record<string, never>;

export type DataSourceCrmAuthenticationData = {
  authUrl: string;
  token: string;
};

export type DataSourceCrmParameterDefinition = {
  stepIds: string[];
  parameterId: number;
  mappingType: string;
  name: string;
  description: string;
  lookupValues: Array<{
    Name: string;
    Value: string;
  }>;
};

export type DataSourceCrmParameter = {
  stepIds: string[];
  parameterId: number;
  mappingType: string;
  value: string;
  name: string;
};

export type DataSourceCrmFieldDefinition = {
  stepId: string;
  fieldId: number;
  mappingType: string;
  name: string;
  description: string;
  dataType: string;
  isOptional: boolean;
  value: unknown;
};

export type DataSourceCrmField = {
  stepId: string;
  fieldId: number;
  mappingType: string;
  value: unknown;
};

export type DataSourceFileConfig = {
  type?: "EVENT" | "CUSTOMER";
  headers?: Header[];
  isDefaultHeader?: boolean;
  url?: string;
};

export type Header = {
  name: string;
  traitId?: number | string;
  displayName?: string;
  custom?: boolean;
  customDataType?: string;
  skip: boolean;
};

export type DataSourceOtherConfig = {};

export type DataSourceRequestedConfig = {
  upvotes?: number;
  integrationUseFor?: string;
};
