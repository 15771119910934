import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../components/buttons";
import type { CompanyId, CompanyUser } from "../../../models/company";
import type { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import { useAppDispatch } from "../../../reducers";
import { useDeleteCompanyUserMutation } from "../../../services/endpoints/companies";
import { showToast } from "../../toasts/toastsSlice";

type IDeleteCompanyUserModal = {
  companyId: CompanyId;
  user: CompanyUser;
};

const DeleteCompanyUserModal: FunctionComponent<IDeleteCompanyUserModal & IModalContentProps> = ({
  companyId,
  user,
  closeModal,
}) => {
  const { t } = useTranslation("company");
  const dispatch = useAppDispatch();

  const [deleteUser, { isLoading, isSuccess }] = useDeleteCompanyUserMutation();

  const handleDeleteClicked = () => {
    deleteUser({ companyId, id: user.id });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("users.delete.success_title"),
          message: t("users.delete.success_message", { email: user.email }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className="flex h-60 justify-center ">
      <div className="my-auto max-w-4xl p-5 text-left">
        <h1 className="text-black-100 mb-5 text-lg font-semibold">{t("users.delete.modal_title")}</h1>
        <p className="text-base">
          {t("users.delete.modal_confirm")}&nbsp;(<b>{user.email}</b>)
        </p>
        <Button className="mt-3" icon={TrashIcon} variant="delete" loading={isLoading} onClick={handleDeleteClicked}>
          {t("users.delete.modal_button_title")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteCompanyUserModal;
