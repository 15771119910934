import { Fragment, type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { filter, isEmpty, map, reduce, values } from "lodash";

import { ConditionSelector, ExpressionConditionRender } from "..";
import type { Expression, ExpressionCondition } from "../../../../models/audiences";
import type { AudienceRule, Condition } from "../../../../models/rules";
import { ToastType } from "../../../../models/toast";
import { type RootState, useAppDispatch } from "../../../../reducers";
import { usePostEstimateMutation } from "../../../../services/endpoints/audiences";
import { showToast } from "../../../toasts/toastsSlice";
import { useWorkspace } from "../../../workspaces/hooks";
import { stashAudience } from "../../audienceEditSlice";

type IExpressionContainerProps = Record<string, unknown>;

function mapRulesToAudienceExpression(rules: AudienceRule[]): Record<string, Expression> {
  return reduce<AudienceRule, Record<string, Expression>>(
    rules,
    (result, rule, ruleIndex) => ({
      ...result,
      [String(ruleIndex)]: reduce(
        rule.expressions,
        (result, expression, expressionIndex) => ({
          ...result,
          [String(expressionIndex)]: expression,
        }),
        {}
      ),
    }),
    {}
  );
}

function mapAudienceExpressionToRules(expression?: Record<string, Expression>): AudienceRule[] {
  return map(values(expression), (exp, index) => ({
    id: String(index),
    expressions: values(exp),
  }));
}

const ExpressionContainer: FunctionComponent<IExpressionContainerProps> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("audience_edit");

  const audience = useSelector((state: RootState) => state.audienceEdit.audience);
  const workspace = useWorkspace();

  const [rules, setRules] = useState<AudienceRule[]>(mapAudienceExpressionToRules(audience?.expression));
  const [canEvaluate, setEvaluate] = useState(false);

  const [getEstimate, { isLoading, data, isSuccess, isError }] = usePostEstimateMutation();

  useEffect(() => {
    if (audience?.id && audience?.expression) {
      getEstimate({
        workspaceId: workspace.id,
        audienceId: audience.id,
        audience: {
          expression: audience.expression,
        },
      });
    }
  }, []);

  const handleAddConditionButtonClicked = (ruleId: string, condition: Condition) => {
    setRules(
      map(rules, (r) =>
        r.id === ruleId
          ? {
              ...r,
              expressions: [
                ...r.expressions,
                {
                  type: condition.kind === "EVENT" || condition.kind === "VISIT" ? "ACTION" : condition.kind,
                  kind: condition.kind === "EVENT" || condition.kind === "VISIT" ? condition.kind : undefined,
                },
              ],
            }
          : r
      )
    );
  };

  const handleRemoveExpressionButtonClicked = (ruleId: string, expressionId: number) => {
    setRules(
      filter(
        map(rules, (r) =>
          r.id === ruleId
            ? {
                ...r,
                expressions: filter(r.expressions, (_, index) => index !== expressionId),
              }
            : r
        ),
        (r) => !isEmpty(r.expressions)
      )
    );
  };

  const handleOnNewRuleSelected = (condition: Condition) => {
    setRules([
      ...rules,
      {
        id: String(rules.length + 1),
        expressions: [
          {
            type: condition.kind === "EVENT" || condition.kind === "VISIT" ? "ACTION" : condition.kind,
            kind: condition.kind === "EVENT" || condition.kind === "VISIT" ? condition.kind : undefined,
            // Special case based on condition type here
          },
        ],
      },
    ]);
  };

  const handleExpressionChanged = (ruleId: string, expression: ExpressionCondition, expressionId: number) => {
    setRules(
      filter(
        map(rules, (r) =>
          r.id === ruleId
            ? {
                ...r,
                expressions: map(r.expressions, (exp, index) => (index === expressionId ? expression : exp)),
              }
            : r
        ),
        (r) => !isEmpty(r.expressions)
      )
    );
  };

  useEffect(() => {
    dispatch(
      stashAudience({
        ...audience,
        expression: mapRulesToAudienceExpression(rules),
      })
    );

    setEvaluate(true);
  }, [rules]);

  useEffect(() => {
    if (!isLoading && (isSuccess || isError)) {
      setEvaluate(false);
    }
  }, [isLoading, isSuccess, isError]);

  const handleEvaluateClicked = () => {
    if (audience?.id && audience?.expression) {
      const isErrorArray = Object.values(audience.expression).flatMap((or) =>
        Object.values(or).map((rule) => {
          switch (rule.type) {
            case "PROPERTY": {
              return (
                rule.comparisonOp !== "NULL" &&
                rule.comparisonOp !== "NOT_NULL" &&
                (rule.value === null || rule.value === "")
              );
            }

            default: {
              false;
            }
          }
        })
      );
      if (isErrorArray.find(Boolean)) {
        dispatch(
          showToast({
            type: ToastType.WARNING,
            title: t("step.audience.evaluation.invalid_rules"),
            message: t("step.audience.evaluation.invalid_rules_message"),
          })
        );
      } else {
        getEstimate({
          workspaceId: workspace.id,
          audienceId: audience.id,
          audience: {
            expression: audience.expression,
          },
        });
      }
    }
  };

  return (
    <div>
      <div className="flex place-content-between items-center">
        <div className="w-1/2 md:w-1/3">
          <h3 className="text-left text-base font-bold">{t("step.rules.title")}</h3>
          <p className="text-left">{t("step.rules.description")}</p>
        </div>
        <div className="group flex w-1/2 flex-col items-end justify-end">
          <h3 className="text-right font-bold">{t("step.rules.recalculate_title")}</h3>
          <div className="align-center flex">
            <button
              className={`border-0 bg-transparent group-hover:inline-block ${
                isLoading ? "inline-block" : "hidden"
              } text-blue-500`}
              title={t(canEvaluate ? "rules.buttons.recalculate" : "rules.buttons.modify_to_recalculate")}
              onClick={handleEvaluateClicked}
            >
              <ArrowPathIcon className={`h-4 w-4 ${isLoading && "animate-spin"}`} />
            </button>
            <p className="ml-2 text-4xl font-bold text-blue-900">{data?.size || 0}</p>
          </div>
        </div>
      </div>
      <div className="mt-16 rounded-lg border pb-2 shadow">
        <table className="w-full table-fixed">
          <colgroup>
            <col style={{ width: "90px" }} />
          </colgroup>
          <tbody>
            {rules.map((rule, i) => (
              <Fragment key={i}>
                {rule.expressions.map((expression, j) => (
                  <tr key={j} className="w-full items-center p-3">
                    <td>
                      <div className={`mb-3 mr-6 text-right text-sm font-bold text-gray-900 ${j === 0 && "mt-5"}`}>
                        {j === 0 ? t(i === 0 ? "rules.users_who" : "rules.and") : t("rules.or")}
                      </div>
                    </td>
                    <td>
                      <div className={`mb-3 mr-4 flex rounded-md border p-3 shadow ${j === 0 && "mt-5"}`}>
                        <ExpressionConditionRender
                          expression={expression}
                          datasourceIds={audience?.datasourceIds}
                          onChange={(exp) => {
                            handleExpressionChanged(rule.id, exp, j);
                          }}
                        />
                        <button
                          className="rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-700"
                          onClick={() => {
                            handleRemoveExpressionButtonClicked(rule.id, j);
                          }}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                <tr className="w-full items-center p-3">
                  <td>
                    <div className="mb-3 mr-6 text-right text-sm font-bold text-gray-900">{t("rules.or")}</div>
                  </td>
                  <td>
                    <div className="mb-3">
                      <ConditionSelector
                        datasourceIds={audience?.datasourceIds}
                        onConditionSelected={(condition) => {
                          handleAddConditionButtonClicked(rule.id, condition);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
            <tr className="mb-4 flex table-row items-center">
              <td>
                <div className="mb-3 mr-6 mt-5 text-right font-bold text-gray-900">
                  {t(isEmpty(rules) ? "rules.users_who" : "rules.and")}
                </div>
              </td>
              <td className="w-full space-y-2">
                <div className="mb-3 mt-5">
                  <ConditionSelector
                    isFirstCondition={isEmpty(rules)}
                    datasourceIds={audience?.datasourceIds}
                    onConditionSelected={handleOnNewRuleSelected}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpressionContainer;
