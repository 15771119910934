import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { persistor } from "../../App";
import { Loading } from "../../components/loaders";

export default function Logout() {
  const { logout } = useAuth0();
  useEffect(() => {
    persistor.purge();
    logout({ returnTo: window.location.origin });
  }, []);
  return <Loading />;
}
