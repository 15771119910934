import type { FunctionComponent } from "react";

import { StatusBadge } from "../../../../components/badges";
import { IconContainer } from "../../../../components/icons";
import {
  type DataSource,
  type DataSourceCodeConfig,
  EDataSourceCategory,
  EDataSourceStatus,
} from "../../../../models/dataSource";

type IActivationStatusProps = {
  dataSource?: DataSource;
};

const ActivationStatus: FunctionComponent<IActivationStatusProps> = ({ dataSource }) => (
  <div className="flex w-full rounded bg-gray-50">
    <div className="flex-col whitespace-nowrap py-1 text-sm font-medium text-gray-900 ">
      <div className=" flex space-x-3 px-2 py-1 sm:px-3">
        <div className="flex-shrink-0">
          <div className="flex h-14 w-14 flex-wrap content-center justify-center rounded-lg">
            <IconContainer path={dataSource?.type.logoUrl} className="h-10" />
          </div>
        </div>
        <div className="flex flex-wrap content-center">
          <div className="flex-col">
            <p className="text-sm font-medium text-gray-900">{dataSource?.name}</p>
            {dataSource?.type.category === EDataSourceCategory.CODE ? (
              <p className="text-sm font-normal text-gray-500">
                {`${dataSource.type.name} • ${(dataSource.configuration as DataSourceCodeConfig).url}`}{" "}
              </p>
            ) : (
              <p className="text-sm font-normal text-gray-500">{`${dataSource?.type.name}`} </p>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="m-auto flex whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
      <StatusBadge status={dataSource?.status || EDataSourceStatus.PENDING} />
    </div>
  </div>
);

export default ActivationStatus;
