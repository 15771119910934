import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SyncYourData } from "../../../assets/image/sync_your_data.svg";
import { Button } from "../../../components/buttons";
import { useModalContext } from "../../../hooks/useModalContext";
import DestinationCreate from "./edit/DestinationCreate";

type IDestinationEmptySetProps = Record<string, unknown>;

const DestinationEmptySet: FunctionComponent<IDestinationEmptySetProps> = () => {
  const { openModal } = useModalContext();

  const { t } = useTranslation("destinations");

  const handleCreateDestination = () => {
    openModal({
      renderContent: DestinationCreate,
      fullHeight: true,
      fullWidth: true,
      title: t("edit.title"),
    });
  };

  return (
    <div className="mt-30 flex h-full flex-row items-center justify-around">
      <div style={{ maxWidth: "400px" }}>
        <h3 className="text-lg font-bold text-black">{t("empty_set.title")}</h3>
        <p className="mb-6">{t("empty_set.text")}</p>
        <Button onClick={handleCreateDestination}>{t("buttons.connect_first_audience")}</Button>
      </div>
      <div className="">
        <SyncYourData />
      </div>
    </div>
  );
};

export default DestinationEmptySet;
