import type { FunctionComponent } from "react";

import { SimpleTable } from "../../components/tables";
import { useUserInfo } from "../../features/userInfo/hooks";
import WorkspaceInviteRow from "./WorkspaceInviteRow";

const WorkspaceInvites: FunctionComponent = () => {
  const userInfo = useUserInfo();

  return !userInfo.workspaceInvites || !userInfo.workspaceInvites.length ? (
    <div className="font-base mb-2 rounded-lg border p-6 text-sm text-gray-600">
      No invites from other workspaces, yet
    </div>
  ) : (
    <div className="mb-2">
      <SimpleTable>
        <colgroup />
        <tbody>
          {userInfo.workspaceInvites
            .filter((i) => i.status == "PENDING")
            .map((invite) => (
              <WorkspaceInviteRow key={invite.workspaceId} invite={invite} />
            ))}
        </tbody>
      </SimpleTable>
    </div>
  );
};

export default WorkspaceInvites;
