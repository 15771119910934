import { Fragment, type FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";

import { concatClassNames } from "../../../utils";

export type Breadcrumb = {
  label: string;
  path?: string;
};

type IBreadcrumbProps = {
  items: Breadcrumb[];
};

const Breadcrumbs: FunctionComponent<IBreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      {isEmpty(items)
        ? items.map((item) => (
            <h1 key={item.label} className="ml-4 text-xl text-gray-900 md:ml-6">
              {item.label}
            </h1>
          ))
        : items.map((item, i, arr) => {
            let style = "text-xl text-gray-900";

            const isLastItem = arr.length - 1 === i && i > 0;
            const isUniqueItem = i === 0 && arr.length === 1;

            if (i === 0) {
              style = concatClassNames(style, "ml-4 md:ml-6");
            }

            if (isLastItem) {
              style = concatClassNames(style, "text-blue-600");
            }

            return (
              <Fragment key={item.label}>
                <h1
                  className={item.path ? concatClassNames(style, "cursor-pointer") : style}
                  onClick={
                    item.path
                      ? () => {
                          handleClick(item.path || "");
                        }
                      : undefined
                  }
                >
                  {item.label}
                </h1>
                {!isLastItem && !isUniqueItem ? (
                  <ChevronRightIcon className="mx-2 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                ) : null}
              </Fragment>
            );
          })}
    </>
  );
};

export default Breadcrumbs;
