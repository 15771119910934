import { type FunctionComponent, useEffect, useState } from "react";

import { SearchDropDown } from "../../../../components/dropdowns";
import type { SegmentOption } from "../../../../models/extendedAudiences";

type IInterestsDropdownProps = {
  isLoading: boolean;
  interests: SegmentOption[];
  pickedResult?: SegmentOption;
  setPickedResult: (picked: SegmentOption) => void;
  readOnly?: boolean;
};

const InterestsDropdown: FunctionComponent<IInterestsDropdownProps> = ({
  readOnly,
  pickedResult,
  setPickedResult,
  isLoading,
  interests,
}) => {
  const [query, setQuery] = useState(pickedResult?.displayName || "");
  const [items, setItems] = useState(interests || []);

  useEffect(() => {
    setItems(interests?.filter((i) => i.name.startsWith(query)));
  }, [query]);

  return (
    <SearchDropDown
      openWithFocus
      readOnly={readOnly}
      item={pickedResult}
      items={items}
      setItem={setPickedResult}
      setQuery={setQuery}
      loading={isLoading}
    >
      {(result) => <span>{result.displayName}</span>}
    </SearchDropDown>
  );
};

export default InterestsDropdown;
