import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import numbro from "numbro";

import { SpinnerIcon } from "../../../components/icons";
import type { ExtendedAudienceEdit } from "../../../models/extendedAudiences";
import { usePostExtendedEstimateMutation } from "../../../services/endpoints/extendedAudiences";
import { useWorkspace } from "../../workspaces/hooks";

type IExtendedAudienceStatsProps = {
  extendedAudience: Partial<ExtendedAudienceEdit>;
};

const ExtendedAudienceStats: FunctionComponent<IExtendedAudienceStatsProps> = ({ extendedAudience }) => {
  const { t } = useTranslation("extended_audience_edit");

  const workspace = useWorkspace();

  const [getEstimate, { data, isLoading }] = usePostExtendedEstimateMutation();

  const { demographicSegmentIds, genderSegmentIds, interestsSegmentIds, geographicSegmentIds } = extendedAudience;

  useEffect(() => {
    getEstimate({
      workspaceId: workspace.id,
      extendedAudience: {
        demographicSegmentIds: demographicSegmentIds || [],
        genderSegmentIds: genderSegmentIds || [],
        interestsSegmentIds: interestsSegmentIds || [],
        geographicSegmentIds: geographicSegmentIds || [],
      },
    });
  }, [demographicSegmentIds, genderSegmentIds, interestsSegmentIds, geographicSegmentIds]);

  function format(value: number): string {
    return numbro(value).format({
      average: value >= 1000,
      spaceSeparated: true,
      trimMantissa: true,
      mantissa: 1,
    });
  }

  return (
    <div className="mb-3 flex w-full flex-col sm:w-1/2">
      <span className="py-2 text-right text-base font-bold text-gray-900">
        {t("extended_audience_details.people_we_can_add")}
      </span>
      <div className="flex justify-end">
        {isLoading ? (
          <div className="flex justify-end">
            <SpinnerIcon loading className="h-6 w-6" fill="rgba(21,68,155,1)" />
          </div>
        ) : (
          <p className="text-right text-4xl font-bold text-blue-800">{format(data?.size || 0)}</p>
        )}
      </div>
    </div>
  );
};

export default ExtendedAudienceStats;
