import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { Step } from "../../components/steppers";
import type { ExtendedAudienceEdit, ExtendedAudienceLicenseEdit, SegmentOption } from "../../models/extendedAudiences";

export type ExtendedAudienceSelectedOptions = {
  genderSegmentOptions?: SegmentOption[];
  demographicSegmentOptions?: SegmentOption[];
  geographicSegmentOptions?: SegmentOption[];
  interestsSegmentOptions?: SegmentOption[];
};

type ExtendedAudienceEditState = {
  extendedAudience?: Partial<ExtendedAudienceEdit>;
  selectedOptions: ExtendedAudienceSelectedOptions;
  license?: Partial<ExtendedAudienceLicenseEdit>;
  ui: {
    steps: Step[];
  };
};

export const initialState: ExtendedAudienceEditState = {
  selectedOptions: {},
  ui: {
    steps: [],
  },
};

const extendedAudienceEditSlice = createSlice({
  name: "extendedAudienceEdit",
  initialState,
  reducers: {
    stashExtendedAudienceOptions: (state, action: PayloadAction<ExtendedAudienceSelectedOptions>) => ({
      ...state,
      selectedOptions: { ...action.payload },
    }),
    stashExtendedAudience: (state, action: PayloadAction<Partial<ExtendedAudienceEdit>>) => ({
      ...state,
      extendedAudience: { ...state.extendedAudience, ...action.payload },
    }),
    clearStashedExtendedAudience: (state) => ({
      ...state,
      selectedOptions: {},
      extendedAudience: undefined,
      license: undefined,
    }),
    stashExtendedAudienceLicense: (state, action: PayloadAction<Partial<ExtendedAudienceLicenseEdit>>) => ({
      ...state,
      license: { ...state.license, ...action.payload },
    }),
    stashSteps: (state, action: PayloadAction<Step[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        steps: action.payload,
      },
    }),
  },
});

export const {
  stashExtendedAudienceOptions,
  stashExtendedAudience,
  clearStashedExtendedAudience,
  stashSteps,
  stashExtendedAudienceLicense,
} = extendedAudienceEditSlice.actions;

export default extendedAudienceEditSlice.reducer;
