import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ArrowPathIcon, ArrowRightIcon, PlusIcon } from "@heroicons/react/20/solid";

import { Button } from "../../components/buttons";
import { SearchInput } from "../../components/tables/search";
import { useModalContext } from "../../hooks/useModalContext";
import type { DistributionChannel } from "../../models/distributionChannels";
import type { TabType } from "../../models/tabs";
import { type RootState, useAppDispatch } from "../../reducers";
import { MainContentWrapper } from "../layout/main";
import { TopbarTabs } from "../layout/topbar";
import { useWorkspaceAdmin } from "../workspaces/hooks/useWorkspace";
import { DestinationEmptySet, DestinationSync } from "./components";
import ConnectorsCatalog from "./components/ConnectorsCatalog";
import DestinationCreate from "./components/edit/DestinationCreate";
import { setDestinationTabs, setSearchText } from "./destinationsSlice";

type IDestinationListProps = {
  destinations: DistributionChannel[];
  pager: React.ReactNode;
};

const DestinationList: FunctionComponent<IDestinationListProps> = ({ destinations, pager }) => {
  const { t } = useTranslation("destinations");
  const dispatch = useAppDispatch();

  const tabs = useSelector((state: RootState) => state.destinations.ui?.tabs);
  const { openModal, closeModal } = useModalContext();

  const mappedTabs = tabs.map((tab) => {
    switch (tab.id) {
      case "destination.sync": {
        return { ...tab, label: t("tabs.sync"), icon: ArrowPathIcon };
      }

      case "destination.list": {
        return { ...tab, label: t("tabs.list"), icon: ArrowRightIcon };
      }
    }
  });

  const handleTabChange = (selectedTab: TabType) => {
    console.log("selected", selectedTab);
    console.log(
      "newTabs",
      tabs?.map((tab) => ({ id: tab.id, current: tab.id === selectedTab.id }))
    );
    dispatch(setDestinationTabs(tabs?.map((tab) => ({ id: tab.id, current: tab.id === selectedTab.id }))));
  };

  const renderTabContent = () => {
    const currentTab = tabs?.find((tab) => tab.current);

    switch (currentTab?.id) {
      case "destination.sync": {
        if (destinations.length > 0) {
          return (
            <>
              <SearchInput
                stateSelector={(state: RootState) => state.destinations.ui.searchText}
                stateUpdater={setSearchText}
                placeholder={t("table.search_placeholder")}
              />
              <DestinationSync destinations={destinations || []} />
              {pager}
            </>
          );
        }

        return <DestinationEmptySet />;
      }

      case "destination.list": {
        return <ConnectorsCatalog />;
      }

      default: {
        return null;
      }
    }
  };

  const openCreateDialog = () => {
    openModal({
      renderContent: DestinationCreate,
      fullHeight: true,
      fullWidth: true,
      title: t("edit.title"),
    });
  };

  const topbarRightElements = () => (
    <Button variant="confirm" icon={PlusIcon} className="mr-6 h-9" onClick={openCreateDialog}>
      {t("buttons.create_sync")}
    </Button>
  );

  return (
    <>
      <TopbarTabs
        label={t("title")}
        tabs={mappedTabs}
        rightElement={useWorkspaceAdmin() ? topbarRightElements : undefined}
        onTabChange={handleTabChange}
      />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="h-auto">{renderTabContent()}</div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default DestinationList;
