import type React from "react";
import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { CheckIcon } from "@heroicons/react/20/solid";

import { Button } from "../buttons";

type ResultContainerProps = {
  visible?: boolean;
};

const ResultContainer: React.FC<ResultContainerProps> = ({ children, visible = true }) => (
  /*
  Used by results, but also by the loading indicator which renders the word "Loading" as
  a single non-clickable result.
  */
  <div
    role="listbox"
    className={`
      bg-white-100 absolute z-10 mt-0.5
      max-h-96
      w-full flex-col
      overflow-auto
      rounded-md rounded-t-none shadow-md
      focus:outline-none focus:ring-0
      focus-visible:outline-none
      ${visible ? "flex" : "hidden"}
    `}
  >
    {children}
  </div>
);

type Props<T> = {
  id?: string;
  placeholder?: string;
  label?: string;
  eager?: boolean;
  textField?: keyof T;
  valueField?: keyof T;
  value?: T[];
  valueChange?: (t: T[]) => void;
  valueTemplate?: (t: T[]) => React.ReactNode;
  items: any[];
  itemTemplate?: (t: T, isSelected: boolean) => React.ReactNode;
  loading?: boolean;
  openWithFocus?: boolean;
  readOnly?: boolean;
};

function MultiSelectDropDownList<T extends unknown>({
  placeholder,
  eager = false,
  textField = "text" as any,
  valueField = "value" as any,
  value = [],
  valueChange = () => {},
  valueTemplate,
  items,
  itemTemplate,
  loading,
  openWithFocus,
  readOnly,
  ...props
}: Props<T> & React.HTMLAttributes<HTMLElement>): React.ReactElement {
  const [open, setOpen] = useState(eager);
  const [selectedItems, setSelectedItems] = useState<T[]>(value);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // When clicking outside component, pressing escape etc. close the widget
    // TODO add keyboard events
    const onClick = (event: MouseEvent) => {
      const { target } = event;
      if (!ref?.current) return;
      const clickedOutsideComponent = !ref?.current.contains(target as Node);
      if (clickedOutsideComponent) {
        setOpen(false);
      }
    };

    if (!readOnly) {
      document.addEventListener("click", onClick, true);
    }

    return () => {
      document.removeEventListener("click", onClick, true);
    };
  }, []);

  const onSelect = (item: T) => {
    let newSelectedItemsList;
    newSelectedItemsList = selectedItems.find((i) => i[valueField] === item[valueField])
      ? selectedItems.filter((i) => i[valueField] !== item[valueField])
      : [...selectedItems, item];

    setSelectedItems(newSelectedItemsList);
    valueChange(newSelectedItemsList);
    // SetOpen(false);
  };

  const showResults = Boolean(items && open && !loading);

  const onFocus = () => {
    if (!readOnly && (eager || openWithFocus)) {
      setOpen(true);
    }
  };

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const isEmpty = Boolean(target.value);
      setOpen(isEmpty);
    }
  };

  const currentValue = valueTemplate
    ? valueTemplate(selectedItems)
    : selectedItems.map((item) => item[textField]).join(", ");

  return (
    <div ref={ref} className="relative flex-grow text-left">
      {(!currentValue || (Array.isArray(currentValue) && !currentValue.length)) && placeholder ? (
        <div
          className="absolute inset-0 z-0 flex cursor-text items-center pl-2 text-gray-400 sm:text-sm"
          onClick={onFocus}
        >
          {placeholder}
        </div>
      ) : null}
      <div
        // ContentEditable={true}
        {...props}
        className={`z-10 h-7 cursor-text rounded-md border-gray-300 focus:border focus:border-blue-600 focus:outline-none sm:text-sm ${props.className}`}
        onClick={onFocus}
        onInput={(e) => {
          onChange((e.target as any).innerText);
        }}
      >
        {currentValue}
      </div>

      {loading ? (
        <ResultContainer>
          <div className="px-5 py-2">Loading</div>
        </ResultContainer>
      ) : null}

      <ResultContainer visible={showResults}>
        <div className="overflow-auto">
          {items.map((item, index) => {
            const selectedItem = selectedItems.find((i) => i[valueField] === item[valueField]);
            if (itemTemplate) {
              return (
                <div
                  key={item[valueField]}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  {itemTemplate(item, Boolean(selectedItem))}
                </div>
              );
            }

            return (
              <button
                key={item[valueField]}
                id={`result-${index.toString()}`}
                className={`${
                  location && selectedItem && item[valueField] === selectedItem[valueField]
                    ? "bg-glass-blue10"
                    : "bg-white"
                } ${
                  selectedItem ? "bg-glass-blue30" : ""
                } hover:bg-glass-blue30 flex w-full cursor-pointer px-5 py-2 text-left`}
                type="button"
                onClick={() => {
                  onSelect(item);
                }}
              >
                {item[textField]}
                {selectedItem ? <CheckIcon className="ml-auto w-5 text-green-500" /> : null}
              </button>
            );
          })}
        </div>

        <div className="bg-gray-100 p-2">
          <Button
            variant="light"
            className="w-full"
            onClick={() => {
              setOpen(false);
            }}
          >
            Save
          </Button>
        </div>
      </ResultContainer>
    </div>
  );
}

export default MultiSelectDropDownList;
