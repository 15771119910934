import type { FunctionComponent } from "react";

import { concatClassNames } from "../../../utils";

type IMainContentWrapperProps = {
  className?: string;
  childClassName?: string;
};

const MainContentWrapper: FunctionComponent<IMainContentWrapperProps> = ({ children, className, childClassName }) => (
  <main className={concatClassNames("relative flex-1 overflow-y-auto focus:outline-none", className || "")}>
    <div className={"h-auto py-6 px-6 " + (childClassName || "")}>{children}</div>
  </main>
);

export default MainContentWrapper;
