import { type FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";
import { last } from "lodash";

import { Button } from "../../components/buttons";
import { EStepStatus, type Step, Stepper } from "../../components/steppers";
import type { Audience, AudienceSyncTimeBasedSchedule } from "../../models/audiences";
import type { IModalContentProps } from "../../models/modals";
import { type RootState, useAppDispatch } from "../../reducers";
import {
  useSaveAudienceMutation,
  useUpdateAudienceMutation,
  useUpdateAudienceScheduleMutation,
} from "../../services/endpoints/audiences";
import { useGetTraitsQuery } from "../../services/endpoints/traits";
import { useWorkspace } from "../workspaces/hooks";
import { stashAudience, stashSteps } from "./audienceEditSlice";
import { AudienceContent, type AudienceFormModel, AudienceHeader, EAudienceSteps } from "./components";
import type { AudienceSyncFormModel } from "./components/sync/AudienceSyncContainer";
import { useAudienceSteps } from "./hooks/useAudienceSteps";

type IAudienceEditProps = Record<string, unknown>;

const AudienceEdit: FunctionComponent<IAudienceEditProps & IModalContentProps> = ({ closeModal }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("audience_edit");

  const createFormRef = useRef<FormikProps<AudienceFormModel>>(null);
  const syncFormRef = useRef<FormikProps<AudienceSyncFormModel>>(null);

  const [steps, handleNextStep] = useAudienceSteps();
  const workspace = useWorkspace();

  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);

  const { data: traits } = useGetTraitsQuery({
    workspaceId: workspace.id,
  });

  const [saveAudience, { isLoading: isSaveLoading, data: createdAudience, isSuccess: isSaveSuccess }] =
    useSaveAudienceMutation();
  const [updateAudience, { isLoading: isUpdateLoading, data: updatedAudience, isSuccess: isUpdateSuccess }] =
    useUpdateAudienceMutation();
  const [
    updateAudienceSchedule,
    { isLoading: isUpdateScheduleLoading, data: updatedAudienceSchedule, isSuccess: isUpdateScheduleSuccess },
  ] = useUpdateAudienceScheduleMutation();

  const isLoading = isSaveLoading || isUpdateLoading || isUpdateScheduleLoading;

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;

  const audienceSteps: Step[] = [
    {
      id: EAudienceSteps.NAME_AND_METADATA,
      label: t("steps.audience"),
      status: EStepStatus.CURRENT,
    },
    {
      id: EAudienceSteps.EXPRESSION,
      label: t("steps.rules"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EAudienceSteps.SYNC,
      label: t("steps.sync"),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EAudienceSteps.CONFIRMATION,
      label: t("steps.confirmation"),
      status: EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    dispatch(stashSteps(audienceSteps));
  }, []);

  useEffect(() => {
    if (isSaveSuccess) {
      handleNextStep();
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (createdAudience) {
      dispatch(stashAudience(createdAudience));
    }
  }, [createdAudience]);

  useEffect(() => {
    if (isUpdateSuccess) {
      handleNextStep();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (updatedAudience) {
      dispatch(stashAudience(updatedAudience));
    }
  }, [updatedAudience]);

  useEffect(() => {
    if (isUpdateScheduleSuccess) {
      handleNextStep();
    }
  }, [isUpdateScheduleSuccess]);

  useEffect(() => {
    if (updatedAudienceSchedule) {
      dispatch(stashAudience(updatedAudienceSchedule));
    }
  }, [updatedAudienceSchedule]);

  const createOrUpdateAudience = (audience: Partial<Audience>) => {
    if (audience.datasourceIds && audience.matchingTrait && audience.name) {
      if (audience.id) {
        updateAudience({
          workspaceId: workspace.id,
          audienceId: audience.id,
          audience: {
            name: audience.name,
            expression: {},
          },
        });
      } else {
        saveAudience({
          workspaceId: workspace.id,
          audience: {
            name: audience.name,
            matchingTrait: audience.matchingTrait,
            datasourceIds: audience.datasourceIds,
          },
        });
      }
    }
  };

  const updateSchedule = (audienceId: string, syncForm: AudienceSyncFormModel) => {
    const syncData: AudienceSyncFormModel = { ...syncForm };
    let scheduleConfiguration: AudienceSyncTimeBasedSchedule;

    switch (syncForm.scheduleType) {
      case "REALTIME": {
        syncData.scheduleConfiguration = {};
        break;
      }

      case "MANUAL": {
        syncData.scheduleConfiguration = {
          emailNotification: syncForm.scheduleConfiguration.emailNotification || false,
          createSnapshotOnRefresh: syncForm.scheduleConfiguration.createSnapshotOnRefresh || false,
        };
        break;
      }

      case "SCHEDULED": {
        scheduleConfiguration = {
          ...(syncForm.scheduleConfiguration as AudienceSyncTimeBasedSchedule),
          emailNotification: syncForm.scheduleConfiguration.emailNotification || false,
          createSnapshotOnRefresh: syncForm.scheduleConfiguration.createSnapshotOnRefresh || false,
        };

        switch (scheduleConfiguration.unit) {
          case "HOURS":
          case "DAYS": {
            delete scheduleConfiguration.weekDays;
            delete scheduleConfiguration.dayOfMonth;
            break;
          }

          case "WEEKS": {
            delete scheduleConfiguration.dayOfMonth;
            break;
          }

          case "MONTHS": {
            delete scheduleConfiguration.weekDays;
            break;
          }
        }

        syncData.scheduleConfiguration = scheduleConfiguration;
        break;
      }
    }

    updateAudienceSchedule({
      workspaceId: workspace.id,
      audienceId,
      audience: syncData,
    });
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === EAudienceSteps.NAME_AND_METADATA) {
      await createFormRef.current?.submitForm();

      const audienceForm = createFormRef.current?.values;

      if (createFormRef.current?.isValid && audienceForm) {
        createOrUpdateAudience(audienceForm);
      }

      return;
    }

    if (currentStepId === EAudienceSteps.EXPRESSION) {
      if (stashedAudience?.id && stashedAudience?.name && stashedAudience?.expression) {
        updateAudience({
          workspaceId: workspace.id,
          audienceId: stashedAudience?.id,
          audience: {
            name: stashedAudience.name,
            expression: stashedAudience.expression,
          },
        });
      }

      return;
    }

    if (currentStepId === EAudienceSteps.SYNC) {
      await syncFormRef.current?.submitForm();
      const syncForm = syncFormRef.current?.values;

      if (stashedAudience?.id && syncFormRef.current?.isValid && syncForm) {
        updateSchedule(stashedAudience?.id, syncForm);
      }

      return;
    }

    if (currentStepId === EAudienceSteps.CONFIRMATION) {
      closeModal();

      return;
    }

    handleNextStep();
  };

  const mergedTraits = traits ? [...traits.customer, ...traits.event] : undefined;

  return (
    <div className="flex h-full flex-col">
      <div className="flex min-h-0 flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <AudienceHeader closeModal={closeModal} title={t("title")} />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <Stepper steps={steps} />
          <AudienceContent
            step={currentStep}
            createFormRef={createFormRef}
            syncFormRef={syncFormRef}
            traits={mergedTraits}
          />
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        {/* {isFirstStepCompleted && !isLastStepCurrent && (
          <Button
            variant='light'
            type='button'
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
            onClick={handlePrevStep}
          >
            {t('back')}
          </Button>
        )} */}
        <Button
          variant={isLastStepCurrent ? "confirm" : "primary"}
          type="submit"
          className="ml-4 inline-flex justify-center px-4 py-2"
          onClick={handleNextButtonClicked}
          // disabled={isLastStepCurrent && dataSource?.status !== EDataSourceStatus.ACTIVE}
          loading={isLoading}
        >
          {t(isLastStepCurrent ? "finish" : "next")}
        </Button>
      </div>
    </div>
  );
};

export default AudienceEdit;
