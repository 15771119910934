import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import type { PayloadAction } from "@reduxjs/toolkit";

import { SearchInput } from "../../../../../components/tables/search";
import type { ExtendedAudience } from "../../../../../models/extendedAudiences";
import type { RootState } from "../../../../../reducers";
import ExtendedAudienceTable from "./ExtendedAudienceTable";

type IAudienceListProps = {
  audiences: ExtendedAudience[];
  searchStateSelector: (state: RootState) => string;
  searchStateUpdater: (searchText: string) => PayloadAction<string>;
  pager: React.ReactNode;
};

const ExtendedAudienceList: FunctionComponent<IAudienceListProps> = ({
  audiences,
  searchStateSelector,
  searchStateUpdater,
  pager,
}) => {
  const { t } = useTranslation("audiences");
  return (
    <>
      <SearchInput
        stateSelector={searchStateSelector}
        stateUpdater={searchStateUpdater}
        placeholder={t("search_placeholder")}
      />
      <ExtendedAudienceTable audiences={audiences} />
      {pager}
    </>
  );
};

export default ExtendedAudienceList;
