import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@headlessui/react";

import { Button } from "../../components/buttons";
import { SpinnerIcon } from "../../components/icons";
import { Input } from "../../components/inputs";
import type { IModalContentProps } from "../../models/modals";
import { ToastType } from "../../models/toast";
import { useAppDispatch } from "../../reducers";
import { useChangeUserInfoMutation, useChangeUserPasswordMutation } from "../../services/endpoints/users";
import { showToast } from "../toasts/toastsSlice";

type IUserDetailProps = {
  disabled?: boolean;
  showReset?: boolean;
  showSave?: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
  };
};

const UserDetail: FunctionComponent<IUserDetailProps & IModalContentProps> = ({
  user,
  closeModal,
  disabled = true,
  showReset = false,
  showSave = false,
}) => {
  const { t } = useTranslation("users");
  const dispatch = useAppDispatch();

  const [editedUser, setEditedUser] = useState(user);
  const [changePassword, { isLoading, isSuccess, isError }] = useChangeUserPasswordMutation();
  const [
    updateUserInfo,
    { isLoading: isLoadingUpdateUserInfo, isSuccess: isSuccessUserInfo, isError: isErrorUserInfo },
  ] = useChangeUserInfoMutation();

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("password.change_success"),
          message: t("password.change_success_message", { email: editedUser.email }),
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          title: t("password.change_error"),
        })
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccessUserInfo) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("info.update_success"),
        })
      );
    }
  }, [isSuccessUserInfo]);

  useEffect(() => {
    if (isErrorUserInfo) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          title: t("info.update_error"),
        })
      );
    }
  }, [isErrorUserInfo]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex min-h-0 flex-col rounded-lg bg-gray-50 py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="flex w-full items-center">
              <Dialog.Title className="text-lg font-medium text-gray-900">
                {((user.firstName || "") + " " + (user.lastName || "")).trim() || user.email}
              </Dialog.Title>
              <div className="ml-auto inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
                {(user.firstName?.[0] || "") + (user.lastName?.[0] || "") || user.email?.[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow p-6">
        <h3 className="mb-6 text-left font-semibold">Personal info</h3>
        <div className="mb-2 flex">
          <div className="w-1/2 pr-2">
            <Input
              label="Name"
              value={editedUser.firstName || ""}
              disabled={!showSave}
              onChange={(e) => {
                setEditedUser({ ...editedUser, firstName: e.target.value });
              }}
            />
          </div>
          <div className="w-1/2 pl-2">
            <Input
              label="Surname"
              value={editedUser.lastName || ""}
              disabled={!showSave}
              onChange={(e) => {
                setEditedUser({ ...editedUser, lastName: e.target.value });
              }}
            />
          </div>
        </div>
        <div>
          <Input label="Email" type="email" value={editedUser.email} disabled={disabled} />
        </div>
      </div>
      <div className="mt-auto flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        {isLoading ? <SpinnerIcon loading className="absolute h-6 w-6" /> : null}
        {showReset && !isLoading ? (
          <span
            className="mr-2 cursor-pointer py-2 text-blue-500 underline hover:text-blue-800"
            onClick={async () => changePassword({ userId: user.id })}
          >
            {t("slideover.password_reset")}
          </span>
        ) : null}
        {showSave ? (
          <Button
            className="mr-2"
            variant="primary"
            disabled={isLoadingUpdateUserInfo}
            onClick={async () =>
              updateUserInfo({ userId: editedUser.id, firstName: editedUser.firstName, lastName: editedUser.lastName })
            }
          >
            {t("slideover.save")}
          </Button>
        ) : null}
        <Button variant="light" onClick={closeModal}>
          {t("slideover.close")}
        </Button>
      </div>
    </div>
  );
};

export default UserDetail;
