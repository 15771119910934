import type React from "react";
import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import type { FormikProps } from "formik";

import {
  FileDataSourceColumnMap,
  FileDataSourceConfigurationCompleted,
  FileDataSourceCSVHeader,
  FileDataSourceUploadForm,
  FileDataType,
} from "../..";
import type { Step } from "../../../../../components/steppers";
import type { DataSourceType } from "../../../../../models/dataSource";
import type { GroupedTraits } from "../../../../../models/traits";
import type { RootState } from "../../../../../reducers";
import { EFileSteps } from "../../../constants";
import type { TColumnItem } from "../../../types";
import type { FileDataSourceUploadFormModel } from "./FileDataSourceUploadForm";

type IFileDataSourceContentProps = {
  dataSourceType: DataSourceType;
  groupedTraits?: GroupedTraits;
  step?: Step;
  goNext?: () => void;
  formRef: React.RefObject<FormikProps<FileDataSourceUploadFormModel>>;
  columnMapFormRef: React.RefObject<FormikProps<TColumnItem[]>>;
  loading: boolean;
  closeModal: () => void;
};

const FileDataSourceContent: FunctionComponent<IFileDataSourceContentProps> = ({
  dataSourceType,
  groupedTraits,
  step,
  formRef,
  goNext,
  loading,
  columnMapFormRef,
  closeModal,
}) => {
  // Switch base on data source type category here!
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);

  const handleSubmit = async (form: FileDataSourceUploadFormModel) => {
    // TODO: Refactor to move some logic here
  };

  switch (step?.id) {
    case EFileSteps.NAME_AND_FILE: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <FileDataSourceUploadForm
            formRef={formRef}
            dataSource={{ ...fileDataSource, type: dataSourceType }}
            onSubmit={handleSubmit}
          />
        </div>
      );
    }

    case EFileSteps.HEADER: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <FileDataSourceCSVHeader />
        </div>
      );
    }

    case EFileSteps.DATA_TYPE: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <FileDataType />
        </div>
      );
    }

    case EFileSteps.MAP_COLUMNS: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <FileDataSourceColumnMap columnMapFormRef={columnMapFormRef} groupedTraits={groupedTraits} />
        </div>
      );
    }

    case EFileSteps.CONFIRMATION: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <FileDataSourceConfigurationCompleted closeModal={closeModal} />
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default FileDataSourceContent;
