import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../buttons";
import { Select } from "../../selects";

type ISimplePaginationProps = {
  firstPage: boolean;
  lastPage: boolean;
  nextPage: () => void;
  prevPage: () => void;
  loading: boolean;
  extended?: boolean;
};

type ISimplePaginationExtendedProps = {
  pageNo: number;
  itemCount: number;
  pageSize: number;
  pageSizes: number[];
  toFirstPage: () => void;
  onChangePageSize: (pageSize: number) => void;
} & ISimplePaginationProps;

const isExtended = (props: ISimplePaginationProps): props is ISimplePaginationExtendedProps => props.extended === true;

const SimplePagination: FunctionComponent<ISimplePaginationProps | ISimplePaginationExtendedProps> = (props) => {
  const { t } = useTranslation("ui");

  return isExtended(props) ? (
    <div className="flex flex-row">
      <div className="flex flex-row items-center">
        <label className="mr-1 block text-sm font-medium text-gray-700">{t("pagination.page_size")}:</label>
        <Select
          items={props.pageSizes.map((size) => ({ id: size, label: t("pagination.count_items", { count: size }) }))}
          value={{ id: props.pageSize, label: t("pagination.count_items", { count: props.pageSize }) }}
          onChange={(item) => {
            props.onChangePageSize(Number(item.id));
          }}
        />
      </div>
      <div className="mx-4 flex flex-row items-center text-sm font-medium text-gray-700">
        {props.pageNo !== undefined && (
          <span>
            {t("pagination.page")}: {props.pageNo + 1}
          </span>
        )}
        {props.itemCount ? (
          <span>, {t("pagination.count_items", { count: props.itemCount })} </span>
        ) : (
          <span>, {t("pagination.no_items")}</span>
        )}
      </div>
      <div className="flex flex-grow flex-row justify-end">
        {props.toFirstPage ? (
          <Button
            variant="transparent"
            className="mr-2 hover:underline"
            disabled={props.firstPage}
            loading={props.loading}
            onClick={props.toFirstPage}
          >
            {t("pagination.buttons.first")}
          </Button>
        ) : null}
        <Button
          variant="transparent"
          className="mr-2 hover:underline"
          disabled={props.firstPage}
          loading={props.loading}
          onClick={props.prevPage}
        >
          {t("pagination.buttons.prev")}
        </Button>
        <Button
          variant="transparent"
          className="hover:underline"
          disabled={props.lastPage}
          loading={props.loading}
          onClick={props.nextPage}
        >
          {t("pagination.buttons.next")}
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex flex-row justify-center">
      <Button
        variant="transparent"
        className="mr-2 hover:underline"
        disabled={props.firstPage}
        loading={props.loading}
        onClick={props.prevPage}
      >
        {t("pagination.buttons.prev")}
      </Button>
      <Button
        variant="transparent"
        className="hover:underline"
        disabled={props.lastPage}
        loading={props.loading}
        onClick={props.nextPage}
      >
        {t("pagination.buttons.next")}
      </Button>
    </div>
  );
};

export default SimplePagination;
