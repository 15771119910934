import type React from "react";
import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { FieldArray, Form, Formik, type FormikProps } from "formik";
import { isEmpty } from "lodash";

import type { RootState } from "../../../../../reducers";
import type { ICrmParameter, ICrmParameters } from "../../../types";

type ICrmDataSourceParametersFormProps = {
  dataSourceParametersFormRef: React.RefObject<FormikProps<ICrmParameters>>;
};

const CrmDataSourceParametersForm: FunctionComponent<ICrmDataSourceParametersFormProps> = ({
  dataSourceParametersFormRef,
}) => {
  const paramDefs = useSelector((state: RootState) => state.dataSourceIntegration.parameterDefinitions);

  const storedParams = useSelector((state: RootState) => state.dataSourceIntegration.parameters);
  const storedParamMap = new Map<number, ICrmParameter>(storedParams.map((param) => [param.parameterId, param]));

  const params: ICrmParameter[] = paramDefs.map((paramDef) =>
    storedParamMap.has(paramDef.parameterId)
      ? {
          ...storedParamMap.get(paramDef.parameterId)!,
        }
      : {
          ...paramDef,
          value: null,
        }
  );

  const onSubmit = () => {
    console.debug("On parameters submit");
  };

  return (
    <Formik
      innerRef={dataSourceParametersFormRef}
      initialValues={{ parameters: params }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FieldArray
            name="parameters"
            validateOnChange={false}
            render={(clickEventHelper) => (
              <>
                {clickEventHelper && !isEmpty(clickEventHelper)
                  ? values.parameters.map((param, index) => (
                      <div
                        key={param.parameterId}
                        className="mt-6 h-auto rounded-lg border border-solid border-gray-200 bg-gray-50 px-6 py-4 text-left font-medium"
                      >
                        <h3 className="mb-3">{param.description}</h3>
                        {param.lookupValues.map((lookupValue) => (
                          <div key={lookupValue.Value} className="flex items-center py-1">
                            <label>
                              <input
                                type="radio"
                                className="mr-3"
                                name={String(param.parameterId)}
                                value={lookupValue.Value}
                                checked={param.value === lookupValue.Value}
                                onChange={() => {
                                  setFieldValue(`parameters.${index}`, {
                                    ...param,
                                    name: lookupValue.Name,
                                    value: lookupValue.Value,
                                  });
                                }}
                              />
                              {lookupValue.Name}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))
                  : null}
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CrmDataSourceParametersForm;
