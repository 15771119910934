import type React from "react";
import { Fragment, type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import type { FormikProps } from "formik";

import { CheckCodeSnippetConnection, CodeDataSourceForm } from ".";
import { Button } from "../../../../../components/buttons";
import { CollaboratorLink, SnippetPreview } from "../../../../../components/snippet";
import type { Step } from "../../../../../components/steppers";
import {
  type DataSourceCodeConfig,
  type DataSourceType,
  EDataSourceStatus,
  type TDataSource,
} from "../../../../../models/dataSource";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import {
  dataSourcesApi,
  useGetDataSourceSetupQuery,
  useGetDataSourceStatusQuery,
} from "../../../../../services/dataSources";
import { prependHttp } from "../../../../../utils";
import { stashDataSource } from "../../../../dataSources/dataSourcesSlice";
import { useWorkspace } from "../../../../workspaces/hooks";
import { ECodeSteps } from "../../../constants";
import type { DataSourceFormModel, ITrackWebsiteValue } from "../../../types";
import { TrackWebsiteActivity } from "./tracking";

type ICodeDataSourceContentProps = {
  dataSourceType: DataSourceType;
  step?: Step;
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceFormModel>>;
  trackWebsiteActivityFormRef: React.RefObject<FormikProps<ITrackWebsiteValue>>;
  loading: boolean;
  goNext?: () => void;
  handleOnSubmit: () => void;
};

const CodeDataSourceContent: FunctionComponent<ICodeDataSourceContentProps> = ({
  dataSourceType,
  step,
  dataSourceInfoFormRef,
  trackWebsiteActivityFormRef,
  goNext,
  loading,
  handleOnSubmit,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Switch base on data source type category here!
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as
    | Partial<TDataSource<DataSourceCodeConfig>>
    | undefined;
  const workspace = useWorkspace();

  const [pollingInterval, setPollingInterval] = useState(5000);

  const { data: setupData } = useGetDataSourceSetupQuery(
    {
      workspaceId: workspace.id,
      dataSourceId: dataSource?.id || "",
    },
    {
      skip: !dataSource?.id,
    }
  );

  const { data } = useGetDataSourceStatusQuery(
    {
      workspaceId: workspace.id,
      dataSourceId: dataSource?.id || "",
    },
    {
      skip: (step ? step.id < 3 : false) || !dataSource?.id,
      pollingInterval,
    }
  );

  const script = setupData?.setup.scriptUrl
    ? `<!-- Include 'analytics' from CDN -->
<script src="${setupData?.setup.scriptUrl}"></script>
<!--suppress JSUnresolvedVariable -->
<script type="text/javascript">
  /* Initialize analytics */
  window.Analytics = CDPAnalytics.create({
    apiUrl: '${setupData?.setup.ingestionUrl}',
    datasourceId: "${setupData?.setup.datasourceId}",
    log: true
  });

  Analytics.page();
</script>`
    : undefined;

  useEffect(() => {
    if (data?.status === EDataSourceStatus.ACTIVE) {
      setPollingInterval(0);

      dispatch(dataSourcesApi.util.invalidateTags(["DataSources"]));

      dispatch(
        stashDataSource({
          ...dataSource,
          status: EDataSourceStatus.ACTIVE,
        })
      );
    }
  }, [data]);

  const handleSubmit = async (form: DataSourceFormModel) => {
    if (form.name && form.configuration) {
      dispatch(
        stashDataSource({
          ...form,
          name: form.name,
          configuration: {
            ...form.configuration,
            url: prependHttp(form.configuration?.url),
          },
          type: dataSourceType,
        })
      );
    }
  };

  const handleAddItAnywayClicked = () => {
    if (goNext) {
      goNext();
    }
  };

  const active = dataSource?.status === EDataSourceStatus.ACTIVE;

  switch (step?.id) {
    case ECodeSteps.SOURCE_DETAILS: {
      return (
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6">
          <CodeDataSourceForm
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            dataSource={{ ...dataSource, type: dataSourceType }}
            onSubmit={handleSubmit}
          />
        </div>
      );
    }

    case ECodeSteps.TRACK_ACTIVITY: {
      return (
        <div className="mt-6 h-auto p-6">
          <TrackWebsiteActivity
            trackWebsiteActivityFormRef={trackWebsiteActivityFormRef}
            handleOnSubmit={handleOnSubmit}
          />
        </div>
      );
    }

    case ECodeSteps.INSTALL_SNIPPET: {
      return (
        <>
          <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6 text-left">
            <SnippetPreview ctaRight script={script} />
          </div>
          <div className="p-6">
            <CollaboratorLink
              onShareLinkToCollaboratorClicked={() => {
                console.log("copy link");
              }}
            />
          </div>
        </>
      );
    }

    case ECodeSteps.CHECK_INSTALLATION: {
      return (
        <>
          <div className="relative mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6 text-left">
            <div
              className={
                active
                  ? "z-10 rounded opacity-20 backdrop-blur-sm transition duration-75 ease-linear"
                  : "z-10 rounded transition duration-1000 ease-linear"
              }
            >
              <h1 className="mb-2 text-base font-bold leading-5 text-gray-900">
                {t("data_source_edit:code.check_installation.is_it_working")}
              </h1>
              <p className="text-sm font-normal leading-5 text-gray-600">
                {t("data_source_edit:code.check_installation.snippet_install")}
              </p>
              <br />
              <p className="text-sm font-semibold leading-5 text-gray-600">
                {t("data_source_edit:code.check_installation.what_to_do")}
              </p>
              <ol className="list-inside list-decimal">
                <li className="text-sm font-normal leading-5 text-gray-600">
                  {t("data_source_edit:code.check_installation.install_snippet")}
                </li>
                <li className="text-sm font-normal leading-5 text-gray-600">
                  {t("data_source_edit:code.check_installation.open_site", { site: dataSource?.configuration?.url })}
                </li>
                <li className="text-sm font-normal leading-5 text-gray-600">
                  {t("data_source_edit:code.check_installation.once_triggered")}
                </li>
              </ol>
              <br />
              <p className="text-sm font-semibold leading-5 text-gray-600">
                {t("data_source_edit:code.check_installation.installed_but_not_working")}
              </p>
              <p className="text-sm font-normal leading-5 text-gray-600">
                {t("data_source_edit:code.check_installation.do_not_worry_keep_waiting")}
              </p>
              <div className="mt-8">
                <CheckCodeSnippetConnection dataSource={dataSource} />
              </div>
            </div>
            <Transition
              as={Fragment}
              show={active}
              enter="transition ease-linear duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-linear duration-1000"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform">
                <div className="inline-block place-items-center">
                  <CheckIcon className="mx-auto mb-2 h-8 rounded-full bg-green-100 p-1 text-green-600" />
                  <h3 className="text-gray-900">
                    {t("data_source_edit:code.check_installation.connected_successfully")}
                  </h3>
                </div>
              </div>
            </Transition>
          </div>
          {!active && (
            <div className="flex p-6">
              <div className="flex-1 text-left">
                <h1 className="mb-2 text-sm font-bold leading-5 text-gray-900">
                  {t("data_source_edit:code.check_installation.cannot_check_now")}
                </h1>
                <h1 className="text-sm font-normal leading-5 text-gray-600">
                  {t("data_source_edit:code.check_installation.you_can_continue")}
                </h1>
              </div>
              <div className="my-auto flex-1 text-right">
                <Button type="button" loading={loading} onClick={handleAddItAnywayClicked}>
                  {t("data_source_edit:code.check_installation.add_it_anyway")}
                </Button>
              </div>
            </div>
          )}
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export default CodeDataSourceContent;
