import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowRightIcon, TrashIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";

import { SpinnerIcon } from "../../../../components/icons";
import { TimeAgo } from "../../../../components/time-ago";
import type { DistributionChannel } from "../../../../models/distributionChannels";
import { useGetDistributionChannelLastTransactionQuery } from "../../../../services/endpoints/distributionChannels";
import { useWorkspace } from "../../../workspaces/hooks";
import templateImage from "../templateImage";

type IDestinationRowProp = {
  id: string;
  destination: DistributionChannel;
  prevDestination?: DistributionChannel;
  onDelete?: (destination: DistributionChannel) => void;
};

const DestinationRow: FunctionComponent<IDestinationRowProp> = ({ id, destination, onDelete, prevDestination }) => {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  const query = useGetDistributionChannelLastTransactionQuery({
    workspaceId: workspace.id,
    audienceId: destination.audienceId,
    distributionChannelId: destination.id,
  });

  const goToAudience = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    navigate("/audiences/" + destination.audience.id);
  };

  return (
    <tr
      id={id}
      className={`bg-white-100 ${
        prevDestination && destination.audience.id !== prevDestination?.audience.id && "border-t border-gray-200"
      }`}
    >
      <td className="whitespace-nowrap px-6 py-1 text-sm font-medium text-gray-900">
        {(!prevDestination || destination.audience.id !== prevDestination.audience.id) && (
          <div className="py-3 text-sm font-medium text-gray-900">
            <div className="mr-3 inline-block align-middle text-gray-500">
              <UserGroupIcon className="w-4" />
            </div>
            <a href={"/audiences/" + destination.audience.id} className="hover:underline" onClick={goToAudience}>
              {destination.audience.name}
            </a>
          </div>
        )}
      </td>
      <td className="whitespace-nowrap px-6 py-1 text-sm font-medium text-gray-900">
        <div className="relative">
          <ArrowRightIcon className="absolute w-4 text-gray-500" style={{ left: "-45px", top: "15px" }} />
          <div className="py-3 text-sm font-medium text-gray-900">
            <div className="mr-3 inline-block align-middle">{templateImage(destination.name)}</div>
            {destination.name}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-1 text-sm font-medium text-gray-900">
        <div className="py-3 text-sm font-medium text-gray-900">
          {destination.destination ? (
            <>
              <div className="mr-3 inline-block align-middle text-gray-500">
                <UserGroupIcon className="w-4" />
              </div>
              {destination.destination}
            </>
          ) : (
            "-"
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-1 text-sm font-medium text-gray-900">
        <p className="w-full py-3 text-sm font-medium text-gray-900">
          {query.isLoading && query.isFetching ? (
            <div className="w-full text-center">
              <SpinnerIcon loading className="absolute h-4 w-4" />
            </div>
          ) : (
            query.data?.LastRunTimestamp && <TimeAgo time={query.data.LastRunTimestamp} />
          )}
        </p>
      </td>
      <td className="whitespace-nowrap px-6 py-1 text-sm font-medium text-gray-900">
        <p className="py-3 text-sm font-medium text-gray-900">
          {format(new Date(destination.createdOn), "yyyy-MM-dd")}
        </p>
      </td>
      {/* <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <p className='py-3 text-sm font-medium text-gray-900'>{''}</p>
      </td> */}
      {onDelete ? (
        <td className="text-center">
          <TrashIcon
            className="mx-auto h-6 w-6 cursor-pointer text-red-600"
            onClick={() => {
              onDelete(destination);
            }}
          />
        </td>
      ) : null}
    </tr>
  );
};

export default DestinationRow;
