import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Loading } from "../../components/loaders";
import { ToastType } from "../../models/toast";
import { useAppDispatch } from "../../reducers";
import { useAcceptCompanyInviteMutation } from "../../services/endpoints/companies";
import { useGetUserInfoQuery } from "../../services/endpoints/users";
import RouteConstants from "../router/RouteConstants";
import { showToast } from "../toasts/toastsSlice";

export default function CallbackLanding() {
  const { t } = useTranslation("company");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const type = new URLSearchParams(location.search).get("type");
  const { data: userInfo, isSuccess: isUserInfoSuccess } = useGetUserInfoQuery();
  const [acceptInvitation, { isUninitialized, isSuccess, isError }] = useAcceptCompanyInviteMutation();

  useEffect(() => {
    if (!type) {
      navigate(RouteConstants.home);
    } else if (type === "company-invite") {
      if (isUserInfoSuccess) {
        if (userInfo?.company) {
          // User is already associated with a company, redirect to home
          navigate(RouteConstants.home);
        } else if (isUninitialized) {
          acceptInvitation();
        }
      }
    } else if (type === "password-change") {
      // TODO understand how to login seamlessly after password change flow
      navigate(RouteConstants.home);
    } else {
      navigate(RouteConstants.home);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isSuccess && type === "company-invite") {
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("invite.success_message", { company: userInfo?.company?.name }),
        })
      );
      navigate(RouteConstants.home);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && type === "company-invite") {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          title: t("invite.error_message"),
        })
      );
      navigate(RouteConstants.home);
    }
  }, [isError]);
  return <Loading />;
}
