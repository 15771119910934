import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ArrowTopRightOnSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { Button } from "../../../components/buttons";
import { Loading } from "../../../components/loaders";
import { useModalContext } from "../../../hooks/useModalContext";
import type { DistributionChannelConnector } from "../../../models/distributionChannels";
import type { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import type { WorkspaceId } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import {
  useAuthenticateConnectorMutation,
  useDeauthenticateConnectorMutation,
  useGetDistributionChannelConnectorsQuery,
  useLazyGetDistributionChannelConnectorsQuery,
} from "../../../services/endpoints/distributionChannels";
import { concatClassNames, popupCenter } from "../../../utils";
import RouteConstants from "../../router/RouteConstants";
import { showToast } from "../../toasts/toastsSlice";
import { useWorkspace } from "../../workspaces/hooks";

export default function ConnectorsCatalog() {
  const { t } = useTranslation("destinations");
  const { id: workspaceId } = useWorkspace();
  const { data, isLoading } = useGetDistributionChannelConnectorsQuery({ workspaceId });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb-8 flex w-full">
        <div className="m-auto flex-1">
          <h1 className="text-2xl font-semibold leading-7 text-gray-900">{t("catalog.connectors")}</h1>
        </div>
      </div>
      <div>
        <div className="mb-12 grid grid-cols-2 gap-12 pt-4 md:grid-cols-3 md:gap-12">
          {data?.map((c) => (
            <ConnectorCard key={c.name} connector={c} />
          ))}
        </div>
      </div>
    </div>
  );
}

type ConnectorCardProps = {
  connector: DistributionChannelConnector;
  onClick?: () => void;
};

function ConnectorCard({ connector, onClick }: ConnectorCardProps) {
  const { openModal } = useModalContext();
  const dispatch = useAppDispatch();
  const { id: workspaceId } = useWorkspace();
  const { name, icon, installed, authenticated, description, integrationPartner } = connector;
  const [authenticateConnector, { isLoading: isAuthenticating, isSuccess, data: authResponse }] =
    useAuthenticateConnectorMutation();
  const [getConnectors] = useLazyGetDistributionChannelConnectorsQuery();
  const style = concatClassNames("bg-white-100 overflow-hidden shadow rounded-lg flex flex-col flex-auto");
  useEffect(() => {
    if (isSuccess && authResponse) {
      console.log(authResponse);
      const connectorId = authResponse.connector.accountConnectorId;
      if (connectorId) {
        const url = `${authResponse.authUrl}?id=${connectorId}&token=${authResponse.token}&targetOrigin=${
          location.origin + RouteConstants.cyclrAuthRedirect
        }`;
        (window as any).cyclrDeauthenticationCompleted = (success: boolean, w: Window) => {
          w.close.apply(w);
          delete (window as any).cyclrDeauthenticationCompleted;
          getConnectors({ workspaceId });
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              title: `Destination connected`,
              message: `Destination ${connector.name} was successfully connected`,
            })
          );
        };

        (window as any).authWindow = popupCenter({
          url,
          title: "Authenticate",
          w: 320,
          h: 320,
        });
      } else {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            title: `Error - Could not connect destination ${connector.name}`,
          })
        );
      }
    }
  }, [isSuccess]);

  const openDeauthenticateConnectorModal = () => {
    openModal({
      renderContent: DeauthenticateConnectorModal,
      renderContentProps: {
        workspaceId,
        connector,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  return (
    <div className={onClick ? concatClassNames(style, "cursor-pointer") : style} onClick={onClick}>
      <div className={classNames("flex content-center justify-center space-x-3 px-4 py-5 sm:px-6")}>
        <div>
          <div className="flex content-center justify-center">
            <div className="mb-3 flex h-16 w-16 flex-wrap rounded-lg">
              <img src={icon} />
            </div>
          </div>
          <div className="flex content-center justify-center">
            <div>
              <p className="text-base font-medium text-gray-900">{name}</p>
              <p className="font-small text-base text-gray-900">{description}</p>
            </div>
          </div>
          <div className="mt-5 flex content-center justify-center">
            {(!installed || !authenticated) && (
              <div className="flex flex-col items-center justify-center">
                <XCircleIcon className="h-10 w-10 text-red-500" />
                <div className="flex">
                  <span className="text-red-500">
                    Destination <b>disconnected</b>
                  </span>
                </div>
                <Button
                  variant="confirm"
                  className="mt-10"
                  loading={isAuthenticating}
                  onClick={async () => authenticateConnector({ workspaceId, connectorName: name, integrationPartner })}
                >
                  Connect
                  <ArrowTopRightOnSquareIcon className="ml-1 h-6 w-6" />
                </Button>
              </div>
            )}
            {authenticated ? (
              <div className="flex flex-col items-center justify-center">
                <CheckCircleIcon className="h-10 w-10 text-green-500" />
                <div className="flex">
                  <span className="text-green-500">
                    Desitination <b>connected</b>
                  </span>
                </div>
                <Button
                  variant="transparent-red"
                  className="mt-10 text-red-100"
                  loading={false}
                  onClick={() => {
                    openDeauthenticateConnectorModal();
                  }}
                >
                  Disconnect
                  <TrashIcon className="ml-1 h-6 w-6" />
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

type DeauthenticateConntectorModalProps = { workspaceId: WorkspaceId; connector: DistributionChannelConnector };

function DeauthenticateConnectorModal({
  connector,
  workspaceId,
  closeModal,
}: DeauthenticateConntectorModalProps & IModalContentProps) {
  const { t } = useTranslation("destinations");
  const [deauthenticateConnector, { isLoading, isSuccess }] = useDeauthenticateConnectorMutation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("catalog.deleteModal.toast_title"),
          message: t("catalog.deleteModal.toast_message", { connector: connector.name }),
        })
      );
    }
  }, [isSuccess]);
  return (
    <div className="flex h-96 justify-center ">
      <div className="my-auto max-w-4xl p-5 text-left">
        <h1 className="text-black-100 mb-5 text-lg font-semibold">{t("catalog.deleteModal.title")}</h1>
        <p className="mb-5 text-base">{t("catalog.deleteModal.subtitle", { connector: connector.name })}</p>
        <Button
          icon={TrashIcon}
          variant="delete"
          loading={isLoading}
          disabled={isLoading}
          onClick={async () =>
            deauthenticateConnector({
              workspaceId,
              connectorName: connector.name,
              integrationPartner: connector.integrationPartner,
            })
          }
        >
          {t("catalog.deleteModal.button_title")}
        </Button>
      </div>
    </div>
  );
}
