import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { DataSourceHeader } from "../..";
import type { DataSourceType } from "../../../../../models/dataSource";

type IEmptyDataSourceContainerProps = {
  dataSourceType: DataSourceType;
  closeModal: () => void;
};

const EmptyDataSourceContainer: FunctionComponent<IEmptyDataSourceContainerProps> = ({
  closeModal,
  dataSourceType,
}) => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <div className="flex min-h-0 flex-1 flex-col rounded-lg bg-gray-50 pt-6">
        <DataSourceHeader closeModal={closeModal} title={t("title", { name: dataSourceType.name })} />
        <div className="bg-white-100 relative mt-6 flex-1 overflow-y-auto px-4 py-6 sm:px-6" />
      </div>
    </div>
  );
};

export default EmptyDataSourceContainer;
