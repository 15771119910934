import { useSelector } from "react-redux";

import { EStepStatus, type Step } from "../../../components/steppers";
import { type RootState, useAppDispatch } from "../../../reducers";
import { stashSteps } from "../audienceEditSlice";

export function useAudienceSteps() {
  const dispatch = useAppDispatch();

  const steps: Step[] = useSelector((state: RootState) => state.audienceEdit.ui.steps);

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);

  const handleNextStep = async () => {
    const currentStepId = currentStep?.id || 0;

    dispatch(
      stashSteps(
        steps.map((s) => {
          if (s.id === currentStepId + 1) {
            return {
              ...s,
              status: EStepStatus.CURRENT,
            };
          }

          if (s.id === currentStepId) {
            return {
              ...s,
              status: EStepStatus.COMPLETE,
            };
          }

          return s;
        })
      )
    );
  };

  const handlePrevStep = () => {
    const currentStepId = currentStep?.id || 0;

    dispatch(
      stashSteps(
        steps.map((s) => {
          if (s.id === currentStepId - 1) {
            return {
              ...s,
              status: EStepStatus.CURRENT,
            };
          }

          if (s.id === currentStepId) {
            return {
              ...s,
              status: EStepStatus.UPCOMING,
            };
          }

          return s;
        })
      )
    );
  };

  return [steps, handleNextStep, handlePrevStep] as const;
}
