import { createContext, Fragment, type ReactNode, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { ModalProviderContent } from ".";
import { CircleButton } from "../components/buttons";
import { toggleSlideOver } from "../features/slideOver/slideOverSlice";
import type { IManagedModalOptions, IModalOptions } from "../models/modals";
import type { IManagedSlideOverOptions, ISlideOverModalContext, ISlideOverOptions } from "../models/slideOver";
import { type RootState, useAppDispatch } from "../reducers";

// Context
export const SlideOverContext = createContext<ISlideOverModalContext>({} as ISlideOverModalContext);

export const SLIDEOVER_TRANSITION_DURATION = 300;

const SlideOverProvider = ({ children }: { children: ReactNode }) => {
  const [slideOverOptions, setSlideOverOptions] = useState<IManagedSlideOverOptions<unknown>>();
  const [modalsOptions, setModalsOptions] = useState<IManagedModalOptions<unknown>>();

  const dispatch = useAppDispatch();

  const isVisible = useSelector((state: RootState) => state.slideOver.isVisible);

  const openModalAboveSlideOver = <M,>(options: IModalOptions<M>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setModalsOptions({
      ...options,
      visible: true,
    });
  };

  const closeModalAboveSlideOver = () => {
    setModalsOptions({
      ...modalsOptions,
      visible: false,
    });
  };

  const openSlideOver = <T,>(options: ISlideOverOptions<T>) => {
    dispatch(toggleSlideOver(true));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setSlideOverOptions({
      ...options,
      visible: true,
    });
  };

  const closeSlideOver = () => {
    dispatch(toggleSlideOver(false));

    setSlideOverOptions({
      ...slideOverOptions,
      visible: false,
    });
  };

  const onModalAboveSlideOverClosed = () => {
    setModalsOptions();
  };

  const onSlideOverClosed = () => {
    setSlideOverOptions();
  };

  const onModalBackdropClicked = () => {
    // This is a method needed for handling events on modal backdrop
  };

  const slideOverContextValue: ISlideOverModalContext = {
    openSlideOver: useCallback(openSlideOver, [slideOverOptions]),
    closeSlideOver: useCallback(closeSlideOver, [slideOverOptions]),
    openModalAboveSlideOver: useCallback(openModalAboveSlideOver, [isVisible]),
    closeModalAboveSlideOver: useCallback(closeModalAboveSlideOver, [isVisible]),
  };
  const renderContentProps: any = slideOverOptions?.renderContentProps;
  return (
    <>
      <SlideOverContext.Provider value={slideOverContextValue}>{children}</SlideOverContext.Provider>
      <Transition.Root show={slideOverOptions?.visible || false} as={Fragment} afterLeave={onSlideOverClosed}>
        <Dialog
          static
          as="div"
          className="fixed inset-0 z-20 overflow-hidden"
          open={slideOverOptions?.visible}
          onClose={closeSlideOver}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="transition backdrop-filter ease-in-out duration-300"
              enterFrom="opacity-0 backdrop-blur-none"
              enterTo="opacity-100 backdrop-blur-md"
              entered="backdrop-filter backdrop-blur-md"
              leave="transition backdrop-filter ease-in-out duration-300"
              leaveFrom="opacity-100 backdrop-blur-md"
              leaveTo="opacity-0 backdrop-blur-none"
            >
              <Dialog.Overlay className="bg-black-24 fixed inset-0" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <div className="absolute left-0 top-0 -ml-16 hidden px-2 pt-4 md:block">
                    <CircleButton icon={XMarkIcon} onClick={closeSlideOver} />
                  </div>
                  {Boolean(slideOverOptions?.renderContent) && (
                    <slideOverOptions.renderContent {...renderContentProps} closeSlideOver={closeSlideOver} />
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
          <ModalProviderContent
            modalsOptions={modalsOptions}
            closeModal={closeModalAboveSlideOver}
            onModalClosed={onModalAboveSlideOverClosed}
            onModalBackdropClicked={onModalBackdropClicked}
          />
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SlideOverProvider;
