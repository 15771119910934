import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Form, Formik, type FormikProps, getIn } from "formik";
import * as Yup from "yup";

import { Input, VisualInput } from "../../../../../components/inputs";
import { type DataSourceCodeConfig, EDataSourceCategory, type TDataSource } from "../../../../../models/dataSource";
import type { DataSourceFormModel } from "../../../types";

type ICodeDataSourceFormProps = {
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceFormModel>>;
  onSubmit: (form: DataSourceFormModel) => void;
  dataSource?: Partial<TDataSource<DataSourceCodeConfig>>;
};

const CodeDataSourceForm: FunctionComponent<ICodeDataSourceFormProps> = ({
  dataSource,
  onSubmit,
  dataSourceInfoFormRef,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("data_source_edit:validations.too_short"))
      .max(50, t("data_source_edit:validations.too_long"))
      .required(t("data_source_edit:validations.required")),
    configuration: Yup.object({
      url: Yup.string()
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[\w-]+(\.[A-Za-z]+)+((\/)[\w#]+)*(\/\w+\?\w+=\w+(&\w+=\w+)*)?$/gm,
          t("data_source_edit:validations.not_valid_url")
        )
        .required(t("data_source_edit:validations.required")),
    }),
  });

  const retrieveLabelForDataSourceType = (type?: EDataSourceCategory) => {
    switch (type) {
      case EDataSourceCategory.CODE:
      default: {
        return "Website";
      }

      case EDataSourceCategory.CRM:
      case EDataSourceCategory.FILE:
      case EDataSourceCategory.OTHER: {
        return "TBD";
      }
    }
  };

  return (
    <Formik
      innerRef={dataSourceInfoFormRef}
      initialValues={{ ...dataSource }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, setFieldError }) => (
        <Form>
          <VisualInput
            name="type"
            id="type"
            label={t("data_source_edit:code.source_details.source_type")}
            value={retrieveLabelForDataSourceType(values.type?.category)}
            iconUrl={values.type?.logoUrl}
          />
          <Input
            name="name"
            id="name"
            type="text"
            label={t("data_source_edit:code.source_details.source_name")}
            value={values.name}
            placeholder={t("data_source_edit:code.source_details.source_name_placeholder")}
            error={errors.name}
            onChange={(e) => {
              setFieldError("name");
              handleChange("name")(e.target.value);
            }}
          />
          <Input
            name="configuration.url"
            id="configuration.url"
            type="text"
            label={t("data_source_edit:code.source_details.source_website")}
            value={values.configuration?.url}
            placeholder={t("data_source_edit:code.source_details.source_website_placeholder")}
            error={getIn(errors.configuration, "url")}
            onChange={(e) => {
              setFieldError("configuration.url");
              handleChange("configuration.url")(e.target.value);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CodeDataSourceForm;
