import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon } from "@heroicons/react/24/outline";

import { concatClassNames } from "../../../utils";
import { Button } from "../../buttons";
import { ExclamationIcon, IconContainer } from "../../icons";

type ISuccessStatus = {
  handleChangeFile: () => void;
};

const SuccessStatus: FunctionComponent<ISuccessStatus> = ({ handleChangeFile }) => {
  const [isChangeFileClicked, setChangeFileClicked] = useState(false);

  const { t } = useTranslation("data_source_edit");

  return (
    <div className="mt-1 w-full sm:col-span-2 sm:mt-0">
      <div
        className={concatClassNames(
          "flex h-52 justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6",
          isChangeFileClicked ? "border-gray-200 bg-gray-50" : "border-green-100 bg-green-50"
        )}
      >
        {isChangeFileClicked ? (
          <div className="m-auto space-y-1 text-center">
            <div className="mb-2 flex h-7 justify-center text-center">
              <IconContainer icon={ExclamationIcon} path="assets/fileuploading/exclamation.svg" />
            </div>
            <div className="flex flex-col">
              <p className="bg-whitpe mb-3 rounded-md text-xl font-medium text-blue-900">
                {t("file.name_and_file.file_upload_input_component.cancel_upload")}
              </p>
              <br />
              <div>
                <Button variant="delete" className="mr-2" onClick={handleChangeFile}>
                  {t("file.name_and_file.file_upload_input_component.delete_file")}
                </Button>
                <Button
                  variant="transparent"
                  className="text-blue-700"
                  onClick={() => {
                    setChangeFileClicked(false);
                  }}
                >
                  {t("file.name_and_file.file_upload_input_component.cancel")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="m-auto space-y-1 text-center transition duration-500">
            <div className="mb-2 flex h-7 justify-center text-center">
              <CheckIcon className="h-8 w-8 text-green-600" />
            </div>
            <div className="flex flex-col">
              <p className="mb-2 rounded-md bg-white text-xl font-medium text-green-900">
                {t("file.name_and_file.file_upload_input_component.success")}
              </p>
              <br />
              <Button
                variant="light"
                onClick={() => {
                  setChangeFileClicked(true);
                }}
              >
                {t("file.name_and_file.file_upload_input_component.change_file")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessStatus;
