import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { type ISimpleTableProps, SimpleTable } from "../../../../../../components/tables";
import { type RootState, useAppDispatch } from "../../../../../../reducers";
import { updateFileData } from "../../../../../fileUploading/fileUploadingSlice";

type ICSVTableProps = {
  fileContent: string[][];
  fileHeader: string[];
  isDefaultHeaderCorrect: boolean;
} & ISimpleTableProps;

const CSVTable: FunctionComponent<ICSVTableProps> = ({ fileContent, fileHeader, isDefaultHeaderCorrect, ...rest }) => {
  const dispatch = useAppDispatch();
  const csvFileData = useSelector((state: RootState) => state.fileUploading.fileData);

  const [tableContent, setTableContent] = useState<string[][]>([]);

  const { t } = useTranslation("data_source_edit");

  useEffect(() => {
    if (fileContent) {
      setTableContent(fileContent.filter((_, i) => i > 0));
    }
  }, [fileContent]);

  useEffect(() => {
    if (isDefaultHeaderCorrect) {
      setTableContent(fileContent.filter((_, i) => i > 0));

      dispatch(
        updateFileData({ preview: fileContent, header: fileContent[0], isDefaultHeader: isDefaultHeaderCorrect })
      );
    } else if (csvFileData) {
      setTableContent(fileContent.filter((_, i) => i >= 0));

      const columnHeader = csvFileData.header.map((_, index) => `${t("file.header.column")} ${index + 1}`);
      dispatch(updateFileData({ preview: fileContent, header: columnHeader, isDefaultHeader: isDefaultHeaderCorrect }));
    }
  }, [isDefaultHeaderCorrect]);

  return (
    <div>
      {fileHeader && tableContent ? (
        <SimpleTable {...rest}>
          <thead className="rounded-lg">
            <tr>
              {fileHeader.map((row, index) => (
                <th
                  key={index}
                  className="truncate border border-blue-500 bg-blue-50 px-2 py-1 text-left text-xs font-medium tracking-wider text-blue-700"
                >
                  {row}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableContent.map((rows, index) => (
              <tr key={index}>
                {rows.map((row, index) => (
                  <td key={index} className="bg-white-100 truncate border border-gray-300 px-2 py-1 text-left">
                    <span className="text-sm font-normal leading-5 text-gray-700">{row}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      ) : null}
    </div>
  );
};

export default CSVTable;
