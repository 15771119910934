import { Fragment, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, UserIcon } from "@heroicons/react/24/outline";

import { type Breadcrumb, Breadcrumbs } from ".";
import { logout } from "../../../features/auth/authSlice";
import { toggleSidebar } from "../../../features/sidebar/sidebarSlice";
import type { TabType } from "../../../models/tabs";
import { useAppDispatch } from "../../../reducers";
import { concatClassNames } from "../../../utils";

type ITopbarTabsProps = {
  tabs: TabType[];
  onTabChange: (tab: TabType) => void;
  breadcrumbs?: Breadcrumb[];
  label?: string;
  rightElement?: () => JSX.Element;
};

const TopbarTabs: FunctionComponent<ITopbarTabsProps> = ({ tabs, onTabChange, breadcrumbs, label, rightElement }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const onOpenSidebarClicked = () => {
    dispatch(toggleSidebar(true));
  };

  const onLogoutClicked = () => {
    dispatch(logout());
  };

  return (
    <div
      className={concatClassNames("relative z-10 flex-shrink-0 bg-white-100 shadow", rightElement ? "pt-3" : "pt-4")}
    >
      <div className="flex flex-col ">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={onOpenSidebarClicked}
        >
          <span className="sr-only">{t("sr_only:open_sidebar")}</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between px-4">
          <div className="flex flex-1 items-center">
            {breadcrumbs ? (
              <Breadcrumbs items={breadcrumbs} />
            ) : (
              Boolean(label) && <h1 className="ml-4 text-xl text-gray-900 md:ml-6">{label}</h1>
            )}
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            {rightElement ? rightElement() : null}
            {/* <button className='bg-white-100 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
              <span className='sr-only'>{t('sr_only:view_notification')}</span>
              <BellIcon className='h-6 w-6' aria-hidden='true' />
            </button> */}

            <Menu as="div" className="ml-3">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-white-100 flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <span className="sr-only">{t("sr_only:open_user_menu")}</span>
                      <UserIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="min-w-48 bg-white-100 absolute right-0 mt-2 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        {() => (
                          <span className="block px-4 py-2 text-sm">
                            {user ? (
                              <span className="flex text-sm text-gray-500">{user.name || user.email}</span>
                            ) : null}
                          </span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={concatClassNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            onClick={() => {
                              navigate("/settings");
                            }}
                          >
                            {t("topbar:company_settings")}
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={concatClassNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            onClick={onLogoutClicked}
                          >
                            {t("topbar:sign_out")}
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <div className="ml-4 flex flex-col px-4 sm:hidden md:ml-6">
          <label htmlFor="tabs" className="sr-only">
            {t("sr_only:select_tab")}
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-1/2 rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current)?.label}
          >
            {tabs.map((tab) => (
              <option key={tab.id}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="ml-4 hidden flex-col px-4 sm:block md:ml-6">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.id}
                className={concatClassNames(
                  tab.current ? " border-b-2 border-blue-600 text-blue-600" : "text-gray-600 hover:text-gray-800",
                  "inline cursor-pointer py-2 px-1 text-sm font-medium "
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => {
                  onTabChange(tab);
                }}
              >
                {Boolean(tab.icon) && <tab.icon className="mr-2 inline h-4 w-4" />}
                <p className="inline h-4">{tab.label}</p>
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopbarTabs;
