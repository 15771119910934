import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import type { IFileUploadStatus } from "../../../models/fileUpload";
import { concatClassNames } from "../../../utils";
import { Button } from "../../buttons";
import { ExclamationIcon, IconContainer, SpinnerIcon } from "../../icons";

type IUploadingStatus = {
  fileUpload: IFileUploadStatus;
  handleCancel: () => void;
};

const UploadingStatus: FunctionComponent<IUploadingStatus> = ({ fileUpload, handleCancel }) => {
  const [isCancelClicked, setCancelClicked] = useState(false);

  const { t } = useTranslation("data_source_edit");

  return (
    <div className="mt-1 w-full sm:col-span-2 sm:mt-0 ">
      <div
        className={concatClassNames(
          "flex h-52 justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6",
          isCancelClicked ? "border-gray-200 bg-gray-50" : "border-blue-300 bg-blue-50"
        )}
      >
        {isCancelClicked ? (
          <div className="m-auto space-y-1 text-center">
            <div className="mb-2 flex h-7 justify-center text-center">
              <IconContainer icon={ExclamationIcon} path="assets/fileuploading/exclamation.svg" />
            </div>
            <div className="flex flex-col">
              <p className="bg-whitpe mb-3 rounded-md text-xl font-medium text-blue-900">
                {t("file.name_and_file.file_upload_input_component.cancel_upload")}
              </p>
              <br />
              <div>
                <Button variant="delete" className="mr-2" onClick={handleCancel}>
                  {t("file.name_and_file.file_upload_input_component.delete_file")}
                </Button>
                <Button
                  variant="transparent"
                  className="text-blue-700"
                  onClick={() => {
                    setCancelClicked(false);
                  }}
                >
                  {t("file.name_and_file.file_upload_input_component.resume_upload")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="m-auto space-y-1 text-center">
            <div className="mb-2 flex h-7 justify-center text-center">
              <SpinnerIcon loading className="h-8 w-8" fill="rgba(21,68,155,1)" />
            </div>
            <div className="flex flex-col">
              <p className="mb-3 rounded-md bg-white text-xl font-medium text-blue-900">
                {`${t("file.name_and_file.file_upload_input_component.uploading")} ${
                  fileUpload.percentage.percentageCompleted
                } %...`}
              </p>
              <br />
              <Button
                variant="light"
                onClick={() => {
                  setCancelClicked(true);
                }}
              >
                {t("file.name_and_file.file_upload_input_component.cancel_upload")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadingStatus;
