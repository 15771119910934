import type { FunctionComponent } from "react";

import { CheckIcon } from "@heroicons/react/20/solid";

export enum EStepStatus {
  COMPLETE,
  CURRENT,
  UPCOMING,
}

export type Step = {
  id: number;
  label: string;
  status: EStepStatus;
  disabled?: boolean;
};

type IStepperProps = {
  steps: Step[];
  onStepChanged?: (step: Step) => void;
};

const Stepper: FunctionComponent<IStepperProps> = ({ steps, onStepChanged }) => {
  const handleStepChanged = (step: Step) => {
    if (onStepChanged) {
      onStepChanged(step);
    }
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="bg-white-100 divide-y divide-gray-300 rounded-md border border-gray-200 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.label} className="relative md:flex md:flex-1">
            <div className={step.disabled ? "opacity-50" : ""}>
              {step.status === EStepStatus.COMPLETE ? (
                <span
                  className="group flex w-full items-center"
                  onClick={() => {
                    handleStepChanged(step);
                  }}
                >
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-600">
                      <CheckIcon className="text-white-100 h-6 w-6" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.label}</span>
                  </span>
                </span>
              ) : step.status === EStepStatus.CURRENT ? (
                <span
                  className="flex items-center px-6 py-4 text-sm font-medium"
                  aria-current="step"
                  onClick={() => {
                    handleStepChanged(step);
                  }}
                >
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-600">
                    <span className="text-blue-600">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">{step.label}</span>
                </span>
              ) : (
                <span
                  className="group flex items-center"
                  onClick={() => {
                    handleStepChanged(step);
                  }}
                >
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                      <span className="text-gray-500">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">{step.label}</span>
                  </span>
                </span>
              )}
            </div>

            {stepIdx === steps.length - 1 ? null : (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
