import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../components/buttons";
import type { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import type { WorkspaceInvite } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import { useDeleteWorkspaceInviteMutation } from "../../../services/endpoints/workspaces";
import { showToast } from "../../toasts/toastsSlice";

type IDeleteWorkspaceInvitationModal = {
  invite: WorkspaceInvite;
};

const DeleteWorkspaceInvitationModal: FunctionComponent<IDeleteWorkspaceInvitationModal & IModalContentProps> = ({
  invite,
  closeModal,
}) => {
  const { t } = useTranslation("company");
  const dispatch = useAppDispatch();

  const [deleteInvite, { isLoading, isSuccess }] = useDeleteWorkspaceInviteMutation();

  const handleDeleteClicked = () => {
    deleteInvite({ workspaceId: invite.workspaceId, id: invite.id });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("invite.delete.successful_title"),
          message: t("invite.delete.successful_message", { email: invite.invitedEmail }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className="flex h-60 justify-center ">
      <div className="my-auto max-w-4xl p-5 text-left">
        <h1 className="text-black-100 mb-5 text-lg font-semibold">{t("invite.delete.delete_title")}</h1>
        <p className="text-base">
          {t("invite.delete.delete_confirm")}&nbsp;(<b>{invite.invitedEmail}</b>)
        </p>
        <Button className="mt-3" icon={TrashIcon} variant="delete" loading={isLoading} onClick={handleDeleteClicked}>
          {t("invite.delete.delete_button_title")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteWorkspaceInvitationModal;
