import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerIcon } from "../../components/icons";

type CyclrAuthRedirectProps = Record<string, unknown>;

const CyclrAuthRedirect: FunctionComponent<CyclrAuthRedirectProps> = () => {
  const { t } = useTranslation("cyclr_integration_redirect");
  const { opener } = window;
  if (opener) {
    const cyclrCallback = opener.window.cyclrAuthenticationCompleted;
    if (cyclrCallback) {
      cyclrCallback(true, window);
    } else if (opener.window.cyclrDeauthenticationCompleted) {
      opener.window.cyclrDeauthenticationCompleted(true, window);
    } else {
      window.close();
    }
  } else {
    window.close();
    // Window.location.href = '/';
  }

  return (
    <div className="h-full grow text-center">
      <span className="relative my-0 inline-block h-7 w-7 pt-3 text-blue-500 opacity-75">
        <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
      </span>
      {t("redirecting")}
    </div>
  );
};

export default CyclrAuthRedirect;
