import type { FunctionComponent } from "react";

import type { Audience } from "../../models/audiences";
import { ETABS, type TabType } from "../../models/tabs";
import { AudienceOverview, AudienceSettings, AudienceSourcesAndRules } from "./components";

type IAudienceTabContentProps = {
  tabs: TabType[];
  selectedAudience?: Audience;
};

const AudienceTabContent: FunctionComponent<IAudienceTabContentProps> = ({ tabs, selectedAudience }) => {
  const currentTab = tabs.find((tab) => tab.current);

  switch (currentTab?.id) {
    case ETABS.OVERVIEW: {
      return <AudienceOverview audience={selectedAudience} />;
    }

    case ETABS.DATA: {
      return <AudienceSourcesAndRules audience={selectedAudience} />;
    }

    case ETABS.SETTINGS: {
      return <AudienceSettings audience={selectedAudience} />;
    }

    default: {
      return null;
    }
  }
};

export default AudienceTabContent;
