import type { FunctionComponent } from "react";

import { DataSourceCardHeader } from "..";
import type { DataSourceType, RequestedDataSourceType } from "../../../../models/dataSource";
import { concatClassNames } from "../../../../utils";

type IDataSourceTypeCardProps = {
  dataSourceType: DataSourceType | RequestedDataSourceType;
  className?: string;
  onClick?: () => void;
};

const DataSourceTypeCard: FunctionComponent<IDataSourceTypeCardProps> = ({ dataSourceType, onClick, className }) => {
  const style = concatClassNames(
    "bg-white-100 overflow-hidden shadow rounded-lg flex flex-col flex-auto",
    className || ""
  );
  return (
    <div className={onClick ? concatClassNames(style, "cursor-pointer") : style} onClick={onClick}>
      <DataSourceCardHeader dataSourceType={dataSourceType} />
    </div>
  );
};

export default DataSourceTypeCard;
