import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { UserGroupIcon } from "@heroicons/react/20/solid";
import numbro from "numbro";

import { TimeAgo } from "../../../../../components/time-ago";
import type { ExtendedAudience } from "../../../../../models/extendedAudiences";

type IAudienceRowProp = {
  id: string;
  audience: ExtendedAudience;
};

const ExtendedAudienceRow: FunctionComponent<IAudienceRowProp> = ({ id, audience }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/extended-audiences/${audience.id}`);
  };

  // TODO move this function in a better place
  function format(value: number): string {
    return numbro(value).format({
      average: value >= 10_000,
      spaceSeparated: true,
      trimMantissa: true,
      mantissa: 1,
    });
  }

  return (
    <tr
      id={id}
      className="bg-white-100 cursor-pointer border-b border-gray-200 last:border-0 hover:bg-gray-50"
      onClick={handleClick}
    >
      <td className="whitespace-nowrap py-1 pl-6 text-sm font-medium text-gray-900">
        <div className="mr-3 inline-block align-middle text-gray-500">
          <UserGroupIcon className="h-5 w-5" />
        </div>
      </td>
      <td className="whitespace-nowrap py-1 pl-3 pr-4 text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">{audience.id}</p>
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <div className="cursor-pointer py-2 text-sm font-medium text-blue-500">{audience.name}</div>
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">
          <TimeAgo time={audience.createdOn} />
        </p>
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <div className="py-2 text-right text-sm font-bold text-blue-600">
          {format(audience.configuration.size || 0)}
        </div>
      </td>
    </tr>
  );
};

export default ExtendedAudienceRow;
