import { useNavigate } from "react-router-dom";

import { PlusIcon } from "@heroicons/react/20/solid";

import { Button } from "../../components/buttons";
import { Loading } from "../../components/loaders";
import { MainContentWrapper } from "../../features/layout/main";
import { Topbar } from "../../features/layout/topbar";
import RouteConstants from "../../features/router/RouteConstants";
import CompanyUsers from "../../features/settings/components/CompanyUsers";
import { useUserInfo } from "../../features/userInfo/hooks";
import { isCompanyAdmin } from "../../features/userInfo/hooks/useUserInfo";
import { WorkspaceInvites } from "./";

const Home = () => {
  const userInfo = useUserInfo();
  const isAdmin = isCompanyAdmin();
  const userInfoLoading = Object.keys(userInfo).length === 0;
  const navigate = useNavigate();

  const handleCreateFirstWorkspaceClicked = () => {
    navigate(RouteConstants.createWorkspace);
  };

  return (
    <>
      <Topbar />
      <MainContentWrapper>
        <div className="px-6">
          <div className="py-8">
            <h1 className="text-black-100 text-lg font-semibold">Workspaces</h1>
            <WorkspaceInvites />
            {userInfoLoading ? (
              <Loading />
            ) : userInfo?.workspaces?.length === 0 ? (
              <div className="font-base bg-white-100 flex flex-row items-center space-x-12 rounded-lg border p-6 text-sm">
                <div className="flex-grow">
                  <div className="text-black-100 font-medium">Do you want to use Audiencerate for your company?</div>
                  <div className="text-gray-600">
                    Create a workspace, where you can start work with Audiencerate and invite operatives. You can also
                    invite other admins and let them do this for you.
                  </div>
                </div>
                <div className="text-right">
                  <Button icon={PlusIcon} className="whitespace-nowrap" onClick={handleCreateFirstWorkspaceClicked}>
                    Create your first workspace
                  </Button>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
          {isAdmin ? (
            <>
              <h1 className="text-black-100 text-lg font-semibold">Company users</h1>
              <CompanyUsers showSearch={false} clickable={false} />
            </>
          ) : null}
        </div>
      </MainContentWrapper>
    </>
  );
};

export default Home;
