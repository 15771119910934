import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { UserGroupIcon } from "@heroicons/react/20/solid";
import { Form, Formik, type FormikProps } from "formik";
import * as Yup from "yup";

import { ExtendedAudienceStats, GenderAndAgeInterests, GeographicalInterests, Interests } from ".";
import { Input } from "../../../components/inputs";
import type { ExtendedAudienceEdit } from "../../../models/extendedAudiences";
import type { ExtendedAudienceSelectedOptions } from "../extendedAudienceEditSlice";

export type ExtendedAudienceFormModel = Partial<ExtendedAudienceEdit> & ExtendedAudienceSelectedOptions;
type IExtendedAudienceFormProps = {
  formRef: React.RefObject<FormikProps<ExtendedAudienceFormModel>>;
  onSubmit: (form: ExtendedAudienceFormModel) => void;
  extendedAudience?: Partial<ExtendedAudienceEdit>;
};

const ExtendedAudienceForm: FunctionComponent<IExtendedAudienceFormProps> = ({
  extendedAudience,
  onSubmit,
  formRef,
}) => {
  const { t } = useTranslation("extended_audience_edit");

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validations.required")),
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...extendedAudience }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, setFieldError, setFieldValue }) => (
        <Form>
          <div className="flex flex-col-reverse sm:flex-row">
            <div className="mb-7 w-full text-left sm:w-1/2">
              <h4 className="mb-1 text-lg font-bold text-gray-700">{t("form_title")}</h4>
              <p className="text-gray-600">{t("description")}</p>
            </div>
            <ExtendedAudienceStats extendedAudience={values} />
          </div>
          <div className="mb-5">
            <Input
              name="name"
              id="name"
              type="text"
              value={values.name}
              icon={() => <UserGroupIcon className="w-4 text-gray-500" />}
              placeholder={t("extended_audience_details.name_placeholder")}
              error={errors.name}
              className="w-full md:w-1/2 lg:w-2/5"
              onChange={(e) => {
                setFieldError("name");
                handleChange("name")(e.target.value);
              }}
            />
          </div>
          <div className="bg-gray-50 p-5 shadow">
            <GenderAndAgeInterests
              selectedGenderInterests={values.genderSegmentIds || []}
              selectedAgeInterests={values.demographicSegmentIds || []}
              onGenderInterestsChanged={(interests) => {
                setFieldError("genderSegmentIds");
                setFieldValue(
                  "genderSegmentIds",
                  interests.map((int) => int.id)
                );
                setFieldValue("genderSegmentOptions", interests);
              }}
              onAgeInterestsChanged={(interests) => {
                setFieldError("demographicSegmentIds");
                setFieldValue(
                  "demographicSegmentIds",
                  interests.map((int) => int.id)
                );
                setFieldValue("demographicSegmentOptions", interests);
              }}
            />

            <GeographicalInterests
              selectedGeographicInterests={values.geographicSegmentIds || []}
              onGeographicInterestsChanged={(interests) => {
                setFieldError("geographicSegmentIds");
                setFieldValue(
                  "geographicSegmentIds",
                  interests.map((int) => int.id)
                );
                setFieldValue("geographicSegmentOptions", interests);
              }}
            />
            <Interests
              selectedInterests={values.interestsSegmentIds || []}
              onInterestsChanged={(interests) => {
                setFieldError("interestsSegmentIds");
                setFieldValue(
                  "interestsSegmentIds",
                  interests.map((int) => int.id)
                );
                setFieldValue("interestsSegmentOptions", interests);
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExtendedAudienceForm;
