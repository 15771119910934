import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../../../components/buttons";

type IDeleteExtendedAudienceProps = {
  handleDeleteExtendedAudienceClicked: () => void;
  isLoading?: boolean;
};

const DeleteExtendedAudience: FunctionComponent<IDeleteExtendedAudienceProps> = ({
  handleDeleteExtendedAudienceClicked,
  isLoading = false,
}) => {
  const { t } = useTranslation("extended_audience_details");

  return (
    <div>
      <h1 className="text-black-100 text-lg font-semibold">{t("settings.delete.delete_title")}</h1>
      <Button
        icon={TrashIcon}
        variant="delete"
        loading={isLoading}
        className="mt-4"
        onClick={handleDeleteExtendedAudienceClicked}
      >
        {t("settings.delete.delete_this_audience")}
      </Button>
    </div>
  );
};

export default DeleteExtendedAudience;
