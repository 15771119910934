export enum ESettingsTab {
  COMPANY = "settings.company",
  WORKSPACE = "settings.workspace",
  COMPANY_USERS = "settings.companyUsers",
}

export type SettingsTopbarTab = {
  id: ESettingsTab;
  current: boolean;
  order: number;
};
