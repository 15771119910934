import type React from "react";
import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { FieldArray, Form, Formik, type FormikProps } from "formik";
import { isEmpty } from "lodash";

import { Checkbox } from "../../../../../components/inputs";
import type { RootState } from "../../../../../reducers";
import type { ICrmField, ICrmFields } from "../../../types";

type ICrmDataSourceFieldsFormProps = {
  dataSourceFieldsFormRef: React.RefObject<FormikProps<ICrmFields>>;
};

const CrmDataSourceFieldsForm: FunctionComponent<ICrmDataSourceFieldsFormProps> = ({ dataSourceFieldsFormRef }) => {
  const fieldDefs = useSelector((state: RootState) => state.dataSourceIntegration.fieldDefinitions);

  const storedFields = useSelector((state: RootState) => state.dataSourceIntegration.fields);
  const storedFieldMap = new Map<number, ICrmField>(storedFields.map((field) => [field.fieldId, field]));

  const fields: ICrmField[] = fieldDefs.map((fieldDef) => ({
    ...fieldDef,
    checked: storedFieldMap.has(fieldDef.fieldId) ? storedFieldMap.get(fieldDef.fieldId)!.checked : true,
  }));

  const onSubmit = () => {
    console.debug("On fields submit");
  };

  return (
    <Formik
      innerRef={dataSourceFieldsFormRef}
      initialValues={{ fields }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FieldArray
            name="fields"
            validateOnChange={false}
            render={(clickEventHelper) => (
              <>
                {clickEventHelper && !isEmpty(clickEventHelper)
                  ? values.fields.map((field, index) => (
                      <Checkbox
                        key={field.fieldId}
                        label={field.name}
                        description={field.description}
                        disabled={!field.isOptional}
                        checked={field.checked}
                        onChange={(e) => {
                          setFieldValue(`fields.${index}`, { ...field, checked: e.target.checked });
                        }}
                      />
                    ))
                  : null}
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CrmDataSourceFieldsForm;
