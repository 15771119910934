import type { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import type { NavigationItem } from "../../../models/navigation";
import { concatClassNames } from "../../../utils";

type ISidebarLinkProps = {
  item: NavigationItem;
  onClick?: (item: NavigationItem) => void;
};

const SidebarLink: FunctionComponent<ISidebarLinkProps> = ({ item, onClick, ...rest }) => {
  const { name, to, current } = item;

  const onLinkClicked = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Link
      {...rest}
      to={to}
      className={concatClassNames(
        current ? "bg-blue-800 text-white-100" : "text-blue-100 hover:bg-blue-800",
        "group flex items-center rounded-md px-2 py-2 text-sm font-medium focus:bg-blue-900"
      )}
      onClick={onLinkClicked}
    >
      <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300" aria-hidden="true" />
      {name}
    </Link>
  );
};

export default SidebarLink;
