import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { ESettingsTab, type SettingsTopbarTab } from "./models";

type SettingsState = {
  ui: {
    tabs: SettingsTopbarTab[];
  };
};

export const initialState: SettingsState = {
  ui: {
    tabs: [
      { id: ESettingsTab.WORKSPACE, current: true, order: 1 },
      { id: ESettingsTab.COMPANY_USERS, current: false, order: 2 },
      { id: ESettingsTab.COMPANY, current: false, order: 3 },
    ],
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: () => initialState,
    setSettingsTabs: (state, action: PayloadAction<SettingsTopbarTab[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        tabs: action.payload,
      },
    }),
  },
});

export const { reset, setSettingsTabs } = settingsSlice.actions;

export default settingsSlice.reducer;
