import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";

import en from "./locales/en/translation.json";

i18n
  // // i18next-http-backend
  // // loads translations from your server
  // // https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    // MissingInterpolationHandler: () => '',
    resources: {
      en,
    },
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
      format(value, format, lng) {
        if (value instanceof Date && lng && format) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format]);
        }

        return value;
      },
    },
  });

export default i18n;
