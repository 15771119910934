import type { FunctionComponent } from "react";

import { ExtendedAudienceSettings } from "..";
import type { ExtendedAudience } from "../../../../models/extendedAudiences";
import type { TabType } from "../../../../models/tabs";
import ExtendedAudienceOverview from "./ExtendedAudienceOverview";

type IExtendedAudienceTabContentProps = {
  tabs: TabType[];
  selectedExtendedAudience: ExtendedAudience;
};

const ExtendedAudienceTabContent: FunctionComponent<IExtendedAudienceTabContentProps> = ({
  tabs,
  selectedExtendedAudience,
}) => {
  const currentTab = tabs.find((tab) => tab.current);

  switch (currentTab?.id) {
    case "1": {
      return <ExtendedAudienceOverview extendedAudience={selectedExtendedAudience} />;
    }

    case "2": {
      return <ExtendedAudienceSettings extendedAudience={selectedExtendedAudience} />;
    }

    default: {
      return null;
    }
  }
};

export default ExtendedAudienceTabContent;
