import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button } from "../../components/buttons";
import { Input } from "../../components/inputs";
import type { Workspace } from "../../models/workspace";
import { generateSlug } from "../../utils";

type WorkspaceModel = Pick<Workspace, "name" | "description" | "slug">;

type IWorkspaceCreateProps = {
  workspace?: Workspace;
  workspaceChange?: (workspace: Workspace) => void;
  nextStep?: () => void;
};

const WorkspaceCreate: FunctionComponent<IWorkspaceCreateProps> = ({ workspace, workspaceChange, nextStep }) => {
  const { t } = useTranslation("workspaces");
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("edit.validations.too_short"))
      .max(50, t("edit.validations.too_long"))
      .required(t("edit.validations.required")),
  });

  const handleOnSubmit = (form: WorkspaceModel) => {
    workspaceChange?.(form as Workspace);
    nextStep?.();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="bg-wave flex h-full w-full items-center justify-center bg-gray-100">
      <div className="bg-white-100 flex flex-col items-center rounded-lg p-8 shadow-md" style={{ width: "448px" }}>
        <h3 className="mb-8 text-xl font-semibold">{t("create.title")}</h3>
        {errorMessage ? (
          <div className="text-white-100 mb-3 w-full rounded border-red-300 bg-red-600 p-2">{errorMessage}</div>
        ) : null}
        <Formik<WorkspaceModel>
          initialValues={{
            name: workspace?.name || "",
            slug: workspace?.slug || "",
            description: workspace?.description || "",
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, handleChange, setFieldError, setFieldValue }) => (
            <Form>
              <Input
                name="name"
                id="name"
                type="text"
                className="mb-3 shadow-sm"
                value={values.name}
                placeholder={t("create.workspace_name")}
                error={errors.name}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldError("name");
                  handleChange("name")(value);
                  setFieldValue("slug", generateSlug(value));
                }}
              />
              <Input
                disabled
                name="slug"
                id="slug"
                type="text"
                placeholder={t("create.workspace_slug")}
                className="mb-3 inline-flex w-full rounded-md border border-gray-300 bg-gray-100 px-4 py-2 text-gray-500 placeholder-gray-400 shadow-sm focus:border focus:border-blue-600 focus:outline-none sm:text-sm"
                value={values.slug}
                error={errors.slug}
                onChange={(e) => {
                  setFieldError("slug");
                  handleChange("slug")(e.target.value);
                }}
              />
              <textarea
                className="mb-6 inline-flex w-full rounded-md border border-gray-300 px-4 placeholder-gray-400 shadow-sm focus:border focus:border-blue-600 focus:outline-none sm:text-sm"
                rows={5}
                placeholder={t("create.description")}
                value={values.description}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldError("description");
                  handleChange("description")(value);
                }}
              />
              <div className="flex flex-col justify-end">
                <Button variant="primary" type="submit" className="mb-4 w-full justify-center px-4 py-2">
                  {t("create.continue")}
                </Button>
                <Button
                  variant="light"
                  type="button"
                  className="w-full justify-center px-4 py-2"
                  onClick={handleCancel}
                >
                  {t("create.cancel")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WorkspaceCreate;
