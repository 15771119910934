import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button } from "../../components/buttons";
import { Input } from "../../components/inputs";
import type { IModalContentProps } from "../../models/modals";
import { ToastType } from "../../models/toast";
import type { Workspace } from "../../models/workspace";
import { useAppDispatch } from "../../reducers";
import { useSaveWorkspaceMutation, useUpdateWorkspaceMutation } from "../../services/endpoints/workspaces";
import { generateSlug } from "../../utils";
import RouteConstants from "../router/RouteConstants";
import { showToast } from "../toasts/toastsSlice";
import { isCompanyAdmin, isWorkspaceAdmin } from "../userInfo/hooks/useUserInfo";
import { setCurrentWorkspace } from "../workspaces/workspacesSlice";

type WorkspaceModel = Pick<Workspace, "name" | "slug">;

type IWorkspaceEditProps = {
  workspace?: Workspace;
  initial?: boolean;
  onDeleteClicked?: () => void;
};

const WorkspaceEdit: FunctionComponent<IWorkspaceEditProps & Partial<IModalContentProps>> = ({
  workspace,
  closeModal,
  onToggleDismissable,
  onDeleteClicked,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = workspace && (isCompanyAdmin(workspace.companyId) || isWorkspaceAdmin(workspace.id));

  const [saveWorkspace, { isLoading: isSaveLoading, data: createdWorkspace }] = useSaveWorkspaceMutation();
  const [updateWorkspace, { isLoading: isUpdateLoading, data: updatedWorkspace }] = useUpdateWorkspaceMutation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("workspaces:edit.validations.too_short"))
      .max(50, t("workspaces:edit.validations.too_long"))
      .required(t("workspaces:edit.validations.required")),
  });

  const isLoading = isSaveLoading || isUpdateLoading;
  const isEditMode = Boolean(workspace?.id);

  useEffect(() => {
    if (createdWorkspace) {
      dispatch(setCurrentWorkspace(createdWorkspace));

      navigate(RouteConstants.defaultRoute);

      if (closeModal) {
        closeModal();
      }
    }
  }, [createdWorkspace]);

  useEffect(() => {
    if (updatedWorkspace) {
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: `Workspace updated!`,
          message: `${updatedWorkspace.name} updated successfully`,
        })
      );
      dispatch(setCurrentWorkspace(updatedWorkspace));

      if (closeModal) {
        closeModal();
      }
    }
  }, [updatedWorkspace]);

  useEffect(() => {
    if (onToggleDismissable) {
      onToggleDismissable(!isLoading);
    }
  }, [isLoading]);

  const handleOnSubmit = (form: WorkspaceModel) => {
    if (workspace?.id) {
      updateWorkspace({
        workspaceId: workspace.id,
        workspace: form,
      });
    } else {
      saveWorkspace({
        workspace: form as any,
      });
    }
  };

  const handleOnDeleteClicked = () => {
    if (onDeleteClicked) {
      onDeleteClicked();
    }
  };

  return (
    <div className="flex flex-col content-between p-4 sm:px-6 md:px-8">
      <h1 className="pb-4 text-2xl font-semibold text-gray-900">
        {t(isEditMode ? "workspaces:edit.edit_workspace" : "workspaces:edit.first_workspace")}
      </h1>
      {!isEditMode && <p>{t("workspaces:edit.workspace_description")}</p>}
      <Formik<WorkspaceModel>
        initialValues={{
          name: workspace?.name || "",
          slug: workspace?.slug || "",
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, handleChange, setFieldError, setFieldValue }) => (
          <Form>
            <Input
              name="name"
              id="name"
              type="text"
              label={t("workspaces:edit.name")}
              value={values.name}
              placeholder={t("workspaces:edit.placeholders.name")}
              disabled={!isAdmin}
              error={errors.name}
              onChange={(e) => {
                const { value } = e.target;
                setFieldError("name");
                handleChange("name")(value);
                setFieldValue("slug", generateSlug(value));
              }}
            />
            <Input
              disabled
              name="slug"
              id="slug"
              type="text"
              label={t("workspaces:edit.slug")}
              value={values.slug}
              placeholder={t("workspaces:edit.placeholders.slug")}
              error={errors.slug}
              onChange={(e) => {
                setFieldError("slug");
                handleChange("slug")(e.target.value);
              }}
            />
            {isEditMode ? (
              <div className="flex flex-col justify-end py-4">
                <h3 className="text-lg font-bold">{t("workspaces:delete.button")}</h3>
                <p className="py-2 text-base">{t("workspaces:delete.what_will_happen")}</p>
                <Button
                  variant="delete"
                  type="button"
                  className="self-start py-2"
                  disabled={!isAdmin}
                  onClick={handleOnDeleteClicked}
                >
                  {t("workspaces:delete.delete_this_workspace")}
                </Button>
              </div>
            ) : null}
            <div className="flex justify-end py-4">
              <Button
                variant="primary"
                type="submit"
                disabled={!isAdmin}
                className="justify-center px-4 py-2"
                loading={isLoading}
              >
                {t(isEditMode ? "workspaces:edit.save" : "workspaces:edit.create")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WorkspaceEdit;
