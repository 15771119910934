import type {
  DataSourceCodeConfig,
  DataSourceCrmConfig,
  DataSourceCrmFieldDefinition,
  DataSourceCrmParameterDefinition,
  TDataSource,
} from "../../../models/dataSource";
import type { ISelectItem } from "../../../models/select";
import type { TraitDataType } from "../../../models/traits";

// FILE DATASOURCE TYPES

export enum TRAITS {
  MAP_TO_TRAIT,
  CUSTOM_TRAIT,
  CONSENT_TRAIT,
  IGNORE_COLUMN,
}

export type ITraitValue = {
  id: number | string;
  displayValue: string;
};

export type TColumnItem = {
  id: string;
  header: string;
  trait: ISelectItem;
  traitValue?: ITraitValue;
  customDataType?: TraitDataType;
};

export type IColumnMapSelect = {
  id: TRAITS;
  label: string;
};

// CRM DATASOURCE TYPES

export type DataSourceCrmCreateFormModel = Partial<TDataSource<DataSourceCrmConfig>>;

// CODE DATASOURCE TYPES

export type DataSourceFormModel = Partial<TDataSource<DataSourceCodeConfig>>;

export enum ESelector {
  ID,
  CLASS,
}

export type ITrackClickEvents = {
  id?: string;
  selectorType: ESelector;
  selectorName: string;
  eventName: string;
  identifier: string; // Serve solo lato frontend
};

export type ITrackPageVisitEvents = {
  id?: string;
  pagePath: string;
  eventName: string;
  baseUrl: string;
  identifier: string; // Serve solo lato frontend
};

export type ITrackWebsiteValue = {
  clickEvents: ITrackClickEvents[];
  pageVisitEvents: ITrackPageVisitEvents[];
};

export type IClickEventsError = {
  selectorName?: string;
  eventName?: string;
};

export type IFormTrackEvents = {
  id: string;
  selectorType: ESelector;
  selectorName?: string;
  eventName?: string;
};

export type IPageEventsError = {
  pagePath?: string;
  eventName?: string;
};

export type TTrackWebsiteError = {
  clickEvents: Record<number, IClickEventsError>;
  pageVisitEvents: Record<number, IPageEventsError>;
};

export type ICrmParameter = {
  value: unknown;
} & DataSourceCrmParameterDefinition;

export type ICrmParameters = {
  parameters: ICrmParameter[];
};

export type ICrmField = {
  checked: boolean;
} & DataSourceCrmFieldDefinition;

export type ICrmFields = {
  fields: ICrmField[];
};
