import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isEmpty } from "lodash";

import { SearchDropDown } from "../../../../components/dropdowns";
import type { ITSelectProps } from "../../../../components/selects";
import type { Audience } from "../../../../models/audiences";
import type { RootState } from "../../../../reducers";
import { useGetAudiencesQuery } from "../../../../services/endpoints/audiences";
import { useWorkspace } from "../../../workspaces/hooks";

type IAudienceSelectProps = {
  audienceId?: string;
  isViewMode?: boolean;
} & Partial<ITSelectProps<Audience>>;

const AudienceSelect: FunctionComponent<IAudienceSelectProps> = ({ audienceId, isViewMode, ...rest }) => {
  const { t } = useTranslation("audience_edit");

  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const workspace = useWorkspace();

  const { isLoading, data } = useGetAudiencesQuery({
    workspaceId: workspace.id,
  });

  const audiences = data?.items || [];
  const audience = audiences.find((a) => a.id === audienceId);
  const validAudiences = isViewMode ? audiences : audiences.filter((a) => a.id !== stashedAudience?.id);

  if (!isLoading && isEmpty(validAudiences)) {
    return (
      <div className="grid h-full place-content-center">
        <p className="text-sm">{t("rules.there_are_no_audience")}</p>
      </div>
    );
  }

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <SearchDropDown
      openWithFocus
      items={
        searchQuery?.trim()
          ? validAudiences.filter((item) => item.name.toLowerCase().includes(searchQuery?.trim()))
          : validAudiences
      }
      item={audience}
      setItem={(value) => {
        rest.onChange && rest.onChange(value as any);
      }}
      setQuery={setSearchQuery}
      loading={isLoading}
      readOnly={isViewMode}
      displayField="name"
      placeholder="some audience"
      className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
    >
      {(item) => <div className="whitespace-nowrap">{item.name}</div>}
    </SearchDropDown>
  );
};

export default AudienceSelect;
