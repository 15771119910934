import type {
  DistributionChannel,
  DistributionChannelAdvancedParameter,
  DistributionChannelConnector,
  DistributionChannelContent,
  DistributionChannelFieldUpdate,
  DistributionChannelLastCyclrTransaction,
  DistributionChannelParameterUpdate,
  IntegrationPartner,
} from "../../models/distributionChannels";
import type { WorkspaceId } from "../../models/workspace";
import { audiencerateApi } from "../audiencerate";

const distributionChannelApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getDistributionChannels: build.query<DistributionChannel[], GetDistributionChannelsArg>({
      query: ({ workspaceId, audienceIds, limit, offset, searchText: q }) => ({
        url: `/v1/workspaces/${workspaceId}/distribution-channels`,
        params:
          audienceIds && audienceIds.length
            ? { audienceIds: audienceIds.join(","), limit, offset, q }
            : {
                limit,
                offset,
                q,
              },
        method: "GET",
      }),
      providesTags: ["DistributionChannels"],
    }),
    distributionChannelAuthenticated: build.mutation<DistributionChannelContent, GetDistributionChannelArg>({
      query: ({ workspaceId, audienceId, distributionChannelId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}/authenticated`,
        method: "POST",
      }),
    }),
    createDistributionChannel: build.mutation<DistributionChannelContent, CreateDistributionChannelArg>({
      query: ({ workspaceId, audienceId, configData }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels`,
        method: "POST",
        body: {
          integrationPartner: "CYCLR",
          configuration: configData,
        },
      }),
      invalidatesTags: ["DistributionChannels"],
    }),
    patchDistributionChannel: build.mutation<any, DistributionChannelParameterPatchArg>({
      query: ({ workspaceId, audienceId, distributionChannelId, parameters }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}`,
        method: "PATCH",
        body: parameters,
      }),
      invalidatesTags: ["DistributionChannels"],
    }),
    updateDistributionChannelParameters: build.mutation<any, DistributionChannelParameterUpdateArg>({
      query: ({ workspaceId, audienceId, distributionChannelId, parameters }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}/update-parameters`,
        method: "PUT",
        body: {
          parameters,
        },
      }),
      invalidatesTags: ["DistributionChannels"],
    }),
    updateDistributionChannelFields: build.mutation<any, DistributionChannelFieldUpdateArg>({
      query: ({ workspaceId, audienceId, distributionChannelId, fields }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}/update-fields`,
        method: "PUT",
        body: {
          fields,
        },
      }),
    }),
    activeDistributionChannel: build.mutation<any, ActiveDistributionChannelArg>({
      query: ({ workspaceId, audienceId, distributionChannelId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}/activate`,
        method: "POST",
      }),
      invalidatesTags: ["DistributionChannels"],
    }),
    disconnectDistributionChannel: build.mutation<any, ActiveDistributionChannelArg>({
      query: ({ workspaceId, audienceId, distributionChannelId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DistributionChannels"],
    }),
    getDistributionChannelLastTransaction: build.query<
      DistributionChannelLastCyclrTransaction,
      { workspaceId: string; audienceId: string; distributionChannelId: string }
    >({
      query: ({ workspaceId, audienceId, distributionChannelId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/distribution-channels/${distributionChannelId}/last-cyclr-transaction`,
        method: "GET",
      }),
    }),
    getDistributionChannelConnectors: build.query<DistributionChannelConnector[], { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/distribution-channels/connectors`,
      }),
      providesTags: (r, e, { workspaceId }) => [{ type: "DistributionChannelConnector", id: workspaceId }],
    }),
    authenticateConnector: build.mutation<AuthenticateConnectorResponse, AuthenticateConnectorArgs>({
      query: ({ workspaceId, connectorName, integrationPartner }) => ({
        url: `/v1/workspaces/${workspaceId}/distribution-channels/connectors/authenticate`,
        method: "POST",
        body: {
          connectorName,
          integrationPartner,
        },
      }),
    }),
    deauthenticateConnector: build.mutation<unknown, AuthenticateConnectorArgs>({
      query: ({ workspaceId, connectorName, integrationPartner }) => ({
        url: `/v1/workspaces/${workspaceId}/distribution-channels/connectors/deauthenticate`,
        method: "POST",
        body: {
          connectorName,
          integrationPartner,
        },
      }),
      invalidatesTags: (r, e, { workspaceId }) => [{ type: "DistributionChannelConnector", id: workspaceId }],
    }),
  }),
});

type AuthenticateConnectorResponse = {
  connector: DistributionChannelConnector;
  token: string;
  authUrl: string;
};

type AuthenticateConnectorArgs = {
  workspaceId: WorkspaceId;
  connectorName: string;
  integrationPartner: IntegrationPartner;
};

type GetDistributionChannelsArg = {
  workspaceId: string;
  audienceIds?: string[];
  limit?: number;
  offset?: number;
  searchText?: string;
};

type GetDistributionChannelArg = {
  workspaceId: string;
  audienceId: string;
  distributionChannelId: string;
};

type CreateDistributionChannelArg = {
  workspaceId: string;
  audienceId: string;
  configData: any;
};

type DistributionChannelParameterPatchArg = {
  workspaceId: string;
  audienceId: string;
  distributionChannelId: string;
  parameters: DistributionChannelAdvancedParameter;
};

type DistributionChannelParameterUpdateArg = {
  workspaceId: string;
  audienceId: string;
  distributionChannelId: string;
  parameters: DistributionChannelParameterUpdate[];
};

type DistributionChannelFieldUpdateArg = {
  workspaceId: string;
  audienceId: string;
  distributionChannelId: string;
  fields: DistributionChannelFieldUpdate[];
};

type ActiveDistributionChannelArg = {
  workspaceId: string;
  audienceId: string;
  distributionChannelId: string;
};

export const {
  useGetDistributionChannelsQuery,
  useLazyGetDistributionChannelsQuery,
  useDistributionChannelAuthenticatedMutation,
  useCreateDistributionChannelMutation,
  useUpdateDistributionChannelParametersMutation,
  useUpdateDistributionChannelFieldsMutation,
  useActiveDistributionChannelMutation,
  usePatchDistributionChannelMutation,
  useDisconnectDistributionChannelMutation,
  useGetDistributionChannelLastTransactionQuery,
  useGetDistributionChannelConnectorsQuery,
  useLazyGetDistributionChannelConnectorsQuery,
  useAuthenticateConnectorMutation,
  useDeauthenticateConnectorMutation,
} = distributionChannelApi;
