import type { FunctionComponent } from "react";

import type { DataSource } from "../../../../../../models/dataSource";
import { ActivationStatus } from "../../../status";

type IPendingFileDataSourceProps = {
  dataSource?: DataSource;
};

const PendingFileDataSource: FunctionComponent<IPendingFileDataSourceProps> = ({ dataSource }) => (
  <div className="flex">
    <ActivationStatus dataSource={dataSource} />
  </div>
);

export default PendingFileDataSource;
