import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as BoardUserIcon } from "../../../../assets/icons/board-user.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { Checkbox } from "../../../../components/inputs";
import type {
  DistributionChannelAdvancedParameter,
  DistributionChannelContent,
} from "../../../../models/distributionChannels";
import { useGetEstimateQuery } from "../../../../services/endpoints/audiences";
import { usePatchDistributionChannelMutation } from "../../../../services/endpoints/distributionChannels";
import { useGetExtendedEstimateQuery } from "../../../../services/endpoints/extendedAudiences";
import { useWorkspace } from "../../../workspaces/hooks";

type IDestinationStepSyncProps = {
  distributionChannelContent: DistributionChannelContent;
  isExtendedAudience: boolean;
  closeModal: () => void;
};

const DestinationStepSync: FunctionComponent<IDestinationStepSyncProps> = ({
  distributionChannelContent,
  isExtendedAudience,
  closeModal,
}) => {
  const { t } = useTranslation("destinations");
  const workspace = useWorkspace();

  const [
    patchDistributionChannel,
    {
      isLoading: isActiveLoading,
      isSuccess: isActiveSuccess,
      isError: isActiveError,
      data: activeData,
      error: activeError,
    },
  ] = usePatchDistributionChannelMutation();

  const { data: estimateData, isLoading: isEstimateLoading } = isExtendedAudience
    ? useGetExtendedEstimateQuery({
        extendedAudienceId: distributionChannelContent.distributionChannel.audienceId,
        workspaceId: workspace.id,
      })
    : useGetEstimateQuery({
        audienceId: distributionChannelContent.distributionChannel.audienceId,
        workspaceId: workspace.id,
      });

  const [advancedSettings, setAdvancedSettings] = useState<DistributionChannelAdvancedParameter>({
    activate: true,
    allowAddUsers: true,
    allowRemoveUsers: true,
    allowUpdateUsers: true,
  });

  useEffect(() => {
    if (isActiveSuccess) {
      closeModal();
    }
  }, [isActiveSuccess]);

  const syncEvent = () => {
    patchDistributionChannel({
      workspaceId: workspace.id,
      audienceId: distributionChannelContent.distributionChannel.audienceId,
      distributionChannelId: distributionChannelContent.distributionChannel.id,
      parameters: advancedSettings,
    });
  };

  return (
    <>
      <div className="flex-grow p-6">
        <h3 className="text-left font-bold">{t("edit.step.sync.header")}</h3>
        <p className="text-left">{t("edit.step.sync.description")}</p>
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 text-left font-medium">
          <div className="bg-gray-50 p-4">
            <div className="flex items-start justify-between">
              {t("edit.step.sync.sync_with", {
                audienceName: distributionChannelContent.distributionChannel.audience.name,
                destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name,
              })}
            </div>
          </div>

          <div className="px-4 py-5">
            <ul>
              <li className="mb-2 flex items-center text-base text-gray-700">
                <PlusIcon className="mr-2 h-4 text-gray-600" />
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("edit.step.sync.create_segment", {
                      destinationName: distributionChannelContent.distributionChannel.destination,
                      audienceName: distributionChannelContent.distributionChannel.audience.name,
                    }),
                  }}
                />
              </li>
              <li className="mb-2 flex items-center text-base text-gray-700">
                <UserGroupIcon className="mr-2 h-4 text-gray-600" />
                {isEstimateLoading ? (
                  <div className="relative flex h-full w-3 cursor-not-allowed items-center justify-center rounded">
                    <SpinnerIcon loading className="h-3 w-3" />
                  </div>
                ) : (
                  estimateData?.size + " users"
                )}
              </li>
              <li className="mb-2 flex items-center text-base text-gray-700">
                <BoardUserIcon className="mr-2 h-4 text-gray-600" />
                {t("edit.step.sync.properties", {
                  count: distributionChannelContent.integrationsResult.cyclr.action.fields.length,
                })}
              </li>
              <li className="flex items-center text-base text-gray-700">
                <ClockIcon className="mr-2 h-4 text-gray-600" />
                {t(
                  `edit.step.sync.sync_${distributionChannelContent.distributionChannel.audience.scheduleType.toLowerCase()}` as any
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 px-4 py-5 text-left font-medium">
          <h3 className="font-bold">{t("edit.step.sync.advanced_settings")}</h3>
          <div className="flex">
            <div className="w-1/2">
              <Checkbox
                label={t("edit.step.sync.add_new_users_label")}
                description={t("edit.step.sync.add_new_users_description", {
                  destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name,
                })}
                defaultChecked={advancedSettings.allowAddUsers}
                onChange={(event) => {
                  setAdvancedSettings({
                    ...advancedSettings,
                    allowAddUsers: event.target.checked,
                  });
                }}
              />
            </div>
            <div className="w-1/2">
              <Checkbox
                label={t("edit.step.sync.remove_users_label")}
                description={t("edit.step.sync.remove_users_description", {
                  destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name,
                })}
                defaultChecked={advancedSettings.allowRemoveUsers}
                onChange={(event) => {
                  setAdvancedSettings({
                    ...advancedSettings,
                    allowRemoveUsers: event.target.checked,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">
        <Button loading={isActiveLoading} onClick={syncEvent}>
          {t("edit.step.complete")}
        </Button>
      </div>
    </>
  );
};

export default DestinationStepSync;
