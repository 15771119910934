import type React from "react";
import { type FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { orderBy } from "lodash";

import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { ReactComponent as SquareIcon } from "../../assets/icons/square.svg";
import { ReactComponent as UserGroupIcon } from "../../assets/icons/user-group.svg";
import { MainContentWrapper } from "../../features/layout/main";
import { TopbarTabs } from "../../features/layout/topbar";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { useUserInfo } from "../userInfo/hooks";
import { isCompanyAdmin } from "../userInfo/hooks/useUserInfo";
import { CompanySettings, CompanyWorkspaces } from "./components";
import CompanyUsers from "./components/CompanyUsers";
import { ESettingsTab } from "./models";
import { reset, initialState as settingsInitialState } from "./settingsSlice";

type ISettingsProps = Record<string, unknown>;

const Settings: FunctionComponent<ISettingsProps> = () => {
  const { t } = useTranslation("settings");
  const dispatch = useAppDispatch();
  const userInfo = useUserInfo();

  const uiState = useAppSelector((state) => state.settings.ui);
  if (uiState.tabs.length < settingsInitialState.ui.tabs.length) {
    dispatch(reset());
  }

  const [selectedTab, setSelectedTab] = useState<ESettingsTab>(
    uiState.tabs.find((t) => t.current)?.id || uiState.tabs[0].id
  );

  const tabs = orderBy(uiState.tabs, "order")
    .filter((tab) => (isCompanyAdmin() ? true : tab.id !== "settings.companyUsers"))
    .map((tab) => {
      switch (tab.id) {
        case ESettingsTab.WORKSPACE: {
          return {
            ...tab,
            current: tab.id === selectedTab,
            label: t("tabs.workspace"),
            icon: (props: React.SVGProps<SVGSVGElement>) => <SquareIcon {...props} />,
          };
        }

        case ESettingsTab.COMPANY_USERS: {
          return {
            ...tab,
            current: tab.id === selectedTab,
            label: t("tabs.users"),
            icon: (props: React.SVGProps<SVGSVGElement>) => <UserGroupIcon {...props} />,
          };
        }

        case ESettingsTab.COMPANY: {
          return {
            ...tab,
            current: tab.id === selectedTab,
            label: t("tabs.company"),
            icon: (props: React.SVGProps<SVGSVGElement>) => <FileIcon {...props} />,
          };
        }
      }
    });

  const renderTabContent = () => {
    switch (selectedTab) {
      case ESettingsTab.COMPANY: {
        return <CompanySettings company={userInfo.company} />;
      }

      case ESettingsTab.WORKSPACE: {
        return <CompanyWorkspaces company={userInfo.company} />;
      }

      case ESettingsTab.COMPANY_USERS: {
        return <CompanyUsers />;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <>
      <TopbarTabs
        tabs={tabs}
        label={t("title")}
        onTabChange={(t) => {
          setSelectedTab(t.id as ESettingsTab);
        }}
      />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl">{renderTabContent()}</div>
      </MainContentWrapper>
    </>
  );
};

export default Settings;
