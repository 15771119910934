import { Fragment, type FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { delay } from "lodash";

import { hideToast, removeToastFromQueue } from "../features/toasts/toastsSlice";
import { type Toast, ToastType } from "../models/toast";
import { type RootState, useAppDispatch } from "../reducers";

// Keep in mind to check toastsSlice for some default config on how toasts are presented
// also used by rtkQueryErrorLogger to group similar errors
export const DEFAULT_DISMISS_DURATION = 4000;

const ToastProvider: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const toasts = useSelector((state: RootState) => state.toasts.toasts);

  const onDismissToastClicked = (id: string) => {
    dispatch(hideToast(id));
  };

  const onToastDismissed = (id: string) => {
    dispatch(removeToastFromQueue(id));
  };

  const onStartTimerForToast = (toast: Toast) => {
    if (toast.autodismiss) {
      delay(() => {
        dispatch(hideToast(toast.id));
      }, toast.dismissDuration || DEFAULT_DISMISS_DURATION);
    }
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end ">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {toasts?.map((toast) => (
            <Transition
              key={toast.id}
              appear
              show={toast.visible}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterEnter={() => {
                onStartTimerForToast(toast);
              }}
              afterLeave={() => {
                onToastDismissed(toast.id);
              }}
            >
              <div className="bg-white-100 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <ToastIcon toastStatus={toast.type} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{toast.title}</p>
                      <p className="mt-1 text-sm text-gray-500">{toast.message}</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        className="bg-white-100 inline-flex rounded-md text-gray-400 hover:text-gray-500"
                        onClick={() => {
                          onDismissToastClicked(toast.id);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          ))}
        </div>
      </div>
    </>
  );
};

type IToastIcon = {
  toastStatus: ToastType;
};
const ToastIcon: FunctionComponent<IToastIcon> = ({ toastStatus }) => {
  switch (toastStatus) {
    case ToastType.SUCCESS: {
      return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
    }

    case ToastType.ERROR: {
      return <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
    }

    case ToastType.WARNING: {
      return <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />;
    }

    case ToastType.INFO: {
      return <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />;
    }

    default: {
      return <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />;
    }
  }
};

export default ToastProvider;
