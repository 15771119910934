import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Form, Formik, type FormikProps } from "formik";
import * as Yup from "yup";

import { SearchDropDown } from "../../../components/dropdowns";
import { Input } from "../../../components/inputs";
import type { DMPDetail, ExtendedAudienceLicenseEdit } from "../../../models/extendedAudiences";
import { useWorkspace } from "../../workspaces/hooks";

export type ExtendedAudienceLicenseFormModel = Partial<ExtendedAudienceLicenseEdit>;

type IExtendedAudienceLicenseFormProps = {
  formRef: React.RefObject<FormikProps<ExtendedAudienceLicenseFormModel>>;
  license?: Partial<ExtendedAudienceLicenseEdit>;
  onSubmit: (form: ExtendedAudienceLicenseFormModel) => void;
};

const ExtendedAudienceLicenseForm: FunctionComponent<IExtendedAudienceLicenseFormProps> = ({
  license,
  onSubmit,
  formRef,
}) => {
  const { t } = useTranslation("extended_audience_edit");
  const { extendedAudience: dmpConf } = useWorkspace();
  const [dmpItems, setDmpItems] = useState<Array<DMPDetail & { id: string; displayName: string }>>([]);
  const [currencies, setCurrencies] = useState<Array<{ id: string; displayName: string }>>();
  const [currency, setCurrency] = useState(license?.currency);
  const validationSchema = Yup.object({
    product: Yup.string().required(t("validations.required")),
    dmp: Yup.string().required(t("validations.required")),
    currency: Yup.string().required(t("validations.required")),
    seatId: Yup.string().required(t("validations.required")),
  });

  useEffect(() => {
    if (dmpConf) {
      setDmpItems(
        dmpConf.dmp.map((d) => ({ ...d, id: d.dmp + d.product, displayName: d.dmpName + " - " + d.productName }))
      );
      if (license?.product) {
        const currencies =
          dmpConf.dmp.find((d) => d.product === license.product && d.dmp === license.dmp)?.currencies || [];
        setCurrencies(currencies.map((c) => ({ id: c.toUpperCase(), displayName: c.toUpperCase() })));
      }
    }
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...license }}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, setFieldError, setFieldValue }) => {
        const productId = (values.dmp || "") + (values.product || "");
        return (
          <Form>
            <div className="flex flex-col-reverse sm:flex-row">
              <div className="mb-7 w-full text-left sm:w-1/2">
                <h4 className="mb-1 text-lg font-bold text-gray-700">{t("steps.license.form_title")}</h4>
                <p className="text-gray-600">{t("steps.license.form_description")}</p>
              </div>
            </div>
            <div className="mb-5">
              <SearchDropDown
                openWithFocus
                label="Product"
                name="dmpProduct"
                id="dmpProduct"
                placeholder="Select a product"
                loading={false}
                item={license ? dmpItems.find((i) => i.id === productId) : null}
                items={dmpItems}
                setItem={(i) => {
                  setFieldError("product");
                  handleChange("product")(i.product);
                  handleChange("dmp")(i.dmp);
                  handleChange("productName")(i.displayName);
                  const currencies = dmpConf.dmp.find((d) => d.product === i.product && d.dmp === i.dmp)?.currencies;
                  if (currencies) {
                    setCurrencies(currencies.map((c) => ({ id: c.toUpperCase(), displayName: c.toUpperCase() })));
                    // Reset currency if selected product's currencies does not contain the selected one
                    if (!currencies.find((c) => c === currency)) {
                      setFieldValue("currency");
                      handleChange("currency")("");
                    }
                  }
                }}
                className={classNames(errors.product ? "border-red-500" : "", "w-full md:w-1/2 lg:w-2/5")}
              >
                {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
              </SearchDropDown>
              {errors.product ? <p className="mt-2 flex text-sm text-red-600">{errors.product}</p> : null}
              <SearchDropDown
                openWithFocus
                label="Currency"
                name="currency"
                id="currency"
                placeholder="Select a currency"
                loading={false}
                item={currencies?.find((i) => i.id === values.currency)}
                items={currencies || []}
                setItem={(i) => {
                  setFieldError("currency");
                  handleChange("currency")(i.id);
                  setCurrency(i.id);
                }}
                className={classNames(errors.currency ? "border-red-500" : "", "d:w-1/2 max-w-xs lg:w-2/5")}
              >
                {(item) => <div className="whitespace-nowrap">{item.displayName}</div>}
              </SearchDropDown>
              {errors.currency ? <p className="mt-2 flex text-sm text-red-600">{errors.currency}</p> : null}
              <Input
                label="Seat id"
                className="max-w-xs"
                name="seatId"
                id="seatId"
                type="text"
                icon={UserIcon}
                placeholder="Type a seat id..."
                value={values.seatId}
                error={errors.seatId}
                onChange={(e) => {
                  handleChange("seatId")(e.target.value);
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExtendedAudienceLicenseForm;
