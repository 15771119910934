import type { FunctionComponent } from "react";

import { DestinationTable } from "../";
import type { DistributionChannel } from "../../../../models/distributionChannels";

type IDestinationListProps = {
  destinations: DistributionChannel[];
};

const DestinationList: FunctionComponent<IDestinationListProps> = ({ destinations }) => (
  <div className="mx-auto">
    <div className="h-auto">
      <DestinationTable destinations={destinations} />
    </div>
  </div>
);

export default DestinationList;
