import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CogIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

import { AudienceTabContent } from ".";
import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import { ETABS, type TabType } from "../../models/tabs";
import { useGetAudienceQuery } from "../../services/endpoints/audiences";
import { MainContentWrapper } from "../layout/main";
import { TopbarTabs } from "../layout/topbar";
import RouteConstants from "../router/RouteConstants";
import { useWorkspace } from "../workspaces/hooks";
import { useWorkspaceAdmin } from "../workspaces/hooks/useWorkspace";

type IAudienceDetailsProps = Record<string, unknown>;

const AudienceDetails: FunctionComponent<IAudienceDetailsProps> = () => {
  const { t } = useTranslation("audience_details");
  const navigate = useNavigate();

  const { id: audienceId } = useParams<{ id: string }>();

  const workspace = useWorkspace();
  const isWorkspaceAdmin = useWorkspaceAdmin();
  const settingsTab = isWorkspaceAdmin
    ? [{ id: ETABS.SETTINGS, label: t("tabs.settings"), current: false, icon: CogIcon }]
    : [];
  const [tabs, setTabs] = useState([
    // Hiding overview tab
    // { id: ETABS.OVERVIEW, label: t('tabs.overview'), current: true, icon: Squares2X2Icon },
    { id: ETABS.DATA, label: t("tabs.sources_and_rules"), current: true, icon: DocumentTextIcon },
    ...settingsTab,
  ]);

  const {
    isFetching,
    data: audience,
    isUninitialized,
    isError,
  } = useGetAudienceQuery({
    workspaceId: workspace.id,
    audienceId,
  });
  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);

  const shouldShowLoading = isFetching || isFetchingDelayed || isUninitialized;
  const breadcrumbs = [
    {
      label: t("breadcrumbs.my_audiences"),
      path: "/audiences",
    },
    {
      label: audience?.name || "",
    },
  ];

  useEffect(() => {
    if (isError) {
      navigate(RouteConstants.defaultRoute);
    }
  }, [isError]);

  const handleTabChange = (selectedTab: TabType) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === selectedTab.id })));
  };

  if (shouldShowLoading) {
    return (
      <MainContentWrapper>
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 text-blue-500 opacity-75">
          <SpinnerIcon loading className="-ml-1 mr-3 h-5 w-5" />
        </span>
      </MainContentWrapper>
    );
  }

  return (
    <>
      <TopbarTabs tabs={tabs} breadcrumbs={breadcrumbs} onTabChange={handleTabChange} />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="h-auto py-4">
            <AudienceTabContent tabs={tabs} selectedAudience={audience} />
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default AudienceDetails;
