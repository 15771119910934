import { Route, Routes } from "react-router-dom";

import { WorkspaceDetails } from "../workspaceEdit";
import { Settings } from "./";

const SettingsRouter = () => (
  <Routes>
    <Route path="/workspace/:id" element={<WorkspaceDetails />} />
    <Route path="/" element={<Settings />} />
  </Routes>
);

export default SettingsRouter;
