import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type Toast, ToastType } from "../../models/toast";

export type ToastsState = {
  toasts?: Toast[];
};

export const initialState: ToastsState = {
  toasts: [],
};

const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<Partial<Toast>>) {
      const toast = {
        id: crypto.randomUUID(),
        type: action.payload.type || ToastType.INFO,
        title: action.payload.title || "",
        message: action.payload.message || "",
        autodismiss: true,
        visible: true,
        ...action.payload,
      };

      return {
        ...state,
        toasts: state.toasts ? [...state.toasts, toast] : [toast],
      };
    },
    hideToast(state, action: PayloadAction<string>) {
      return {
        ...state,
        toasts: state.toasts?.map((t) =>
          t.id === action.payload
            ? {
                ...t,
                visible: false,
              }
            : t
        ),
      };
    },
    removeToastFromQueue(state, action: PayloadAction<string>) {
      return {
        ...state,
        toasts: state.toasts?.filter((t) => t.id !== action.payload),
      };
    },
  },
});

export const { showToast, hideToast, removeToastFromQueue } = toastsSlice.actions;

export default toastsSlice.reducer;
