import type React from "react";
import type { FunctionComponent } from "react";

import { concatClassNames } from "../../utils";
import { IconContainer } from "../icons";

type IVisualInputProps = {
  id?: string;
  value?: string;
  name?: string;
  label?: string;
  className?: string;
  error?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconUrl?: string;
};

type TInputType = IVisualInputProps & React.ComponentProps<"span">;

const VisualInput: FunctionComponent<TInputType> = (props) => {
  const { id, value, name, label, className, error, iconUrl, icon, ...rest } = props;

  let spanStyle = "inline-flex w-full my-auto px-4 sm:text-sm";

  if (props.icon || iconUrl) {
    spanStyle = concatClassNames(spanStyle, "pl-2");
  }

  if (error) {
    spanStyle = concatClassNames(spanStyle, "border-red-500 hover:border-red-500 focus:border-red-500 outline-none");
  }

  return (
    <div className="my-4 inline-flex w-full flex-col text-left">
      {label ? (
        <label htmlFor={name} className="block py-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <div className="mt-1 inline-flex w-auto rounded-md">
        {props.icon ||
          (iconUrl && (
            <div className="flex items-center">
              <IconContainer icon={icon} path={iconUrl} className="h-6 w-6 flex-shrink-0 text-gray-500" />
            </div>
          ))}
        <span id={id} className={className ? concatClassNames(spanStyle, className) : spanStyle} {...rest}>
          {value}
        </span>
      </div>
      {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : null}
    </div>
  );
};

export default VisualInput;
