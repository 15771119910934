import type { FunctionComponent } from "react";

import { differenceInMinutes, formatDistanceToNow, format as formatFn } from "date-fns";

type TimeAgoProps = {
  time: string | number | Date | undefined;
  thresholdInMinutes?: number;
  format?: string;
};

const TimeAgo: FunctionComponent<TimeAgoProps> = ({ thresholdInMinutes = 5, time, format = "yyyy-MM-dd HH:mm" }) => {
  if (time) {
    if (typeof time === "string" || typeof time === "number") {
      try {
        time = new Date(time);
      } catch {
        return null;
      }
    }

    const createdAgo = differenceInMinutes(Date.now(), time);
    if (createdAgo <= thresholdInMinutes) {
      return (
        <span className="text-green-500">
          <b>Added</b> {formatDistanceToNow(time)} ago
        </span>
      );
    }

    return <>{formatFn(time, format)}</>;
  }

  return <></>;
};

export default TimeAgo;
