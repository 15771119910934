import type { LaunchData } from "../../models/integrations";
import { audiencerateApi } from "../audiencerate";

const integrationsApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    launchCyclr: build.mutation<LaunchData, LaunchCyclrArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/integrations/cyclr/launch`,
        method: "POST",
        body: { audienceId },
      }),
    }),
    cyclrTemplates: build.query<CyclrTemplate[], { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/integrations/cyclr/templates`,
      }),
    }),
  }),
});

type LaunchCyclrArg = {
  workspaceId: string;
  audienceId: string;
};

type CyclrConnector = {
  Id: string;
  Name: string;
  Version: string;
  Icon: string;
};

export type CyclrTemplate = {
  Id: string;
  Name: string;
  Description: string;
  Connectors: CyclrConnector[];
  Tags: string[];
};

export const { useLaunchCyclrMutation, useCyclrTemplatesQuery } = integrationsApi;
