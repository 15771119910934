import { type FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "../../../components/buttons";
import { useModalContext } from "../../../hooks/useModalContext";
import type { ISelectItem } from "../../../models/select";
import { type RootState, useAppDispatch } from "../../../reducers";
import { useDeleteAudienceMutation } from "../../../services/endpoints/audiences";
import { AudienceEdit } from "../../audienceEdit";
import { clearStashedAudience } from "../../audienceEdit/audienceEditSlice";
import { ExtendedAudienceEdit } from "../../extendedAudienceEdit";
import { clearStashedExtendedAudience } from "../../extendedAudienceEdit/extendedAudienceEditSlice";
import { MainContentWrapper } from "../../layout/main";
import { Topbar } from "../../layout/topbar";
import { isWorkspaceAdmin } from "../../userInfo/hooks/useUserInfo";
import { useWorkspace } from "../../workspaces/hooks";
import { ReactComponent as NoAudiences } from "../images/audience/audience_empty_set.svg";
import { AudienceTypeSelector, EAudienceTypes } from "./dropdowns";

type IAudienceEmptySetProps = Record<string, unknown>;

const AudienceEmptySet: FunctionComponent<IAudienceEmptySetProps> = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const { t } = useTranslation("audiences");
  const { openModal } = useModalContext();

  const [deleteAudience] = useDeleteAudienceMutation();
  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const stashedAudienceRef = useRef(stashedAudience);
  const isAdmin = isWorkspaceAdmin(workspace.id);

  const handleCreateAudience = (type?: ISelectItem) => {
    if (!type || type.id === EAudienceTypes.AUDIENCE) {
      dispatch(clearStashedAudience());

      openModal({
        renderContent: AudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
        onClose: handleModalClose,
      });
    } else {
      dispatch(clearStashedExtendedAudience());

      openModal({
        renderContent: ExtendedAudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
      });
    }
  };

  const handleTopBarRightElement = () => <AudienceTypeSelector onTypeSelected={handleCreateAudience} />;

  const handleModalClose = () => {
    const createdAudience = stashedAudienceRef.current;
    stashedAudienceRef.current = undefined;

    if (createdAudience && createdAudience.id && Object.keys(createdAudience.expression || {}).length === 0) {
      deleteAudience({
        workspaceId: workspace.id,
        audienceId: createdAudience.id,
      });
    }
  };

  return (
    <>
      <Topbar rightElement={isAdmin ? handleTopBarRightElement : undefined} />
      <MainContentWrapper>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="flex">
            <div className="flex flex-col justify-center">
              <h1 className="text-2xl font-semibold text-gray-900">{t("empty_set.create_first_audience")}</h1>
              <p className="pb-8 pt-2 text-base font-medium text-gray-600">{t("empty_set.body")}</p>
              <Button
                variant="primary"
                className="mt-2"
                onClick={() => {
                  handleCreateAudience();
                }}
              >
                {t("buttons.create_audience")}
              </Button>
            </div>
            <NoAudiences />
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};

export default AudienceEmptySet;
