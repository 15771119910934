import { type FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CSVTable, FileDataSourceRadioButton } from "../..";
import type { DataSourceFileConfig } from "../../../../../models/dataSource";
import { type RootState, useAppDispatch } from "../../../../../reducers";
import { stashFileDataSource } from "../../../../dataSources/dataSourcesSlice";

const EVENT_BASED = "EVENT";
const CUSTOMER_BASED = "CUSTOMER";

type IFileDataTypeProps = Record<string, unknown>;

const FileDataType: FunctionComponent<IFileDataTypeProps> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("data_source_edit");

  const csvFileData = useSelector((state: RootState) => state.fileUploading.fileData);
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);

  useEffect(() => {
    handleChangeDataType(fileDataSource?.configuration?.type || EVENT_BASED);
  }, []);

  const handleChangeDataType = (value: Partial<DataSourceFileConfig["type"]>) => {
    const dataSourceConfig = { ...fileDataSource?.configuration, type: value };
    dispatch(stashFileDataSource({ configuration: dataSourceConfig }));
  };

  return (
    <div className="flex flex-col justify-start">
      <div className="mb-8 block justify-start text-left">
        <span className="text-sm text-gray-700">{t("file.data_type.type_of_data")}</span>
        <div className="mb-24 ml-2 mt-2 flex w-3/4">
          <div className="flex-1">
            <FileDataSourceRadioButton
              title={t("file.data_type.event_based")}
              description={t("file.data_type.event_based_description")}
              value={EVENT_BASED}
              currentValue={fileDataSource?.configuration?.type || EVENT_BASED}
              onClick={() => {
                handleChangeDataType(EVENT_BASED);
              }}
            />
          </div>
          <div className="flex-1 justify-center">
            <FileDataSourceRadioButton
              title={t("file.data_type.customer_based")}
              description={t("file.data_type.customer_based_description")}
              value={CUSTOMER_BASED}
              currentValue={fileDataSource?.configuration?.type || EVENT_BASED}
              onClick={() => {
                handleChangeDataType(CUSTOMER_BASED);
              }}
            />
          </div>
        </div>
        <span className="text-sm text-gray-700">{t("file.data_type.csv_preview")}</span>
        <div className="flex-auto overflow-x-auto">
          {csvFileData ? (
            <CSVTable
              fileContent={csvFileData.preview}
              fileHeader={csvFileData.header}
              isDefaultHeaderCorrect={csvFileData.isDefaultHeader}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FileDataType;
