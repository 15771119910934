import { useSelector } from "react-redux";

import { EStepStatus, type Step } from "../../../components/steppers";
import { type RootState, useAppDispatch } from "../../../reducers";
import { stashSteps } from "../dataSourceEditSlice";

export function useDataSourceSteps() {
  const dispatch = useAppDispatch();

  const steps: Step[] = useSelector((state: RootState) => state.dataSourceEdit.ui.steps);

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);

  const handleNextStep = async () => {
    const currentStepId = currentStep?.id || 0;
    const targetStepId = Math.min(...steps.filter((s) => s.id > currentStepId && !s.disabled).map((s) => s.id));

    dispatch(
      stashSteps(
        steps.map((s) => {
          if (s.id === targetStepId) {
            return {
              ...s,
              status: EStepStatus.CURRENT,
            };
          }

          if (s.id < targetStepId) {
            return {
              ...s,
              status: EStepStatus.COMPLETE,
            };
          }

          return s;
        })
      )
    );
  };

  const handlePrevStep = () => {
    const currentStepId = currentStep?.id || 0;
    const targetStepId = Math.max(...steps.filter((s) => s.id < currentStepId && !s.disabled).map((s) => s.id));

    dispatch(
      stashSteps(
        steps.map((s) => {
          if (s.id === targetStepId) {
            return {
              ...s,
              status: EStepStatus.CURRENT,
            };
          }

          if (s.id > targetStepId) {
            return {
              ...s,
              status: EStepStatus.UPCOMING,
            };
          }

          return s;
        })
      )
    );
  };

  const skipToStep = (targetStepId: number) => {
    const currentStepId = currentStep?.id || 0;

    dispatch(
      stashSteps(
        steps.map((s) => ({
          ...s,
          status:
            s.id < targetStepId
              ? EStepStatus.COMPLETE
              : s.id === targetStepId
              ? EStepStatus.CURRENT
              : EStepStatus.UPCOMING,
          disabled: s.id > currentStepId && s.id < targetStepId ? true : s.disabled,
        }))
      )
    );
  };

  return [steps, handleNextStep, handlePrevStep, skipToStep] as const;
}
