import type React from "react";
import { type FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Form, Formik, type FormikProps } from "formik";
import { some } from "lodash";
import * as Yup from "yup";

import { DataSourceCheckbox } from ".";
import { Input } from "../../../components/inputs";
import { SimplePagination } from "../../../components/tables/pagination";
import { useDebounce } from "../../../hooks/useDebounce";
import type { Audience } from "../../../models/audiences";
import { type DataSource, EDataSourceStatus } from "../../../models/dataSource";
import type { Trait } from "../../../models/traits";
import { useLazyGetDataSourcesQuery } from "../../../services/dataSources";
import { concatClassNames } from "../../../utils";
import { useWorkspace } from "../../workspaces/hooks";

export type AudienceFormModel = Partial<Audience>;

type IAudienceFormProps = {
  formRef: React.RefObject<FormikProps<AudienceFormModel>>;
  onSubmit: (form: AudienceFormModel) => void;
  audience?: Partial<Audience>;
  traits?: Trait[];
};

const AudienceForm: FunctionComponent<IAudienceFormProps> = ({ audience, traits, onSubmit, formRef }) => {
  const workspace = useWorkspace();
  const { t } = useTranslation("audience_edit");
  const limit = 5;

  const [getDataSources, { isLoading: isLoadingDataSources, data: dataSourcesData }] = useLazyGetDataSourcesQuery();
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [pageNo, setPageNo] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const searchTextRef = useRef<string>("");

  useEffect(() => {
    let actualPageNo = pageNo;

    if (searchTextRef.current !== debouncedSearchText) {
      searchTextRef.current = debouncedSearchText;
      actualPageNo = 0;
      setPageNo(0);
    }

    getDataSources({
      workspaceId: workspace.id,
      offset: actualPageNo * limit,
      limit,
      status: EDataSourceStatus.ACTIVE,
      searchText: debouncedSearchText.length > 2 ? debouncedSearchText : undefined,
    });
  }, [debouncedSearchText, pageNo]);

  useEffect(() => {
    if (dataSourcesData && dataSourcesData.items && dataSourcesData.items.length) {
      setDataSources(dataSourcesData.items);
      setLastPage(dataSourcesData.items.length < limit);
    } else {
      setLastPage(true);
    }
  }, [dataSourcesData]);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validations.required")),
    datasourceIds: Yup.array().required(t("validations.at_least_one_datasource_required")),
    matchingTrait: Yup.string().required(t("validations.required")),
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...audience }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setTouched, touched, values, errors, handleChange, setFieldError, setFieldValue }) => (
        <Form>
          <div className="w-full text-left md:w-2/3 lg:w-1/2">
            <h3 className="font-bold">{t("step.audience.title")}</h3>
            <p className="text-gray-600">{t("step.audience.description")}</p>
            <Input
              name="name"
              id="name"
              type="text"
              label={t("audience_details.name")}
              value={values.name || ""}
              placeholder={t("audience_details.name_placeholder")}
              error={errors.name}
              className="shadow"
              onChange={(e) => {
                setFieldError("name");
                handleChange("name")(e.target.value);
                setTouched({ name: true });
              }}
            />
          </div>

          <div className="mt-1 mt-4 flex rounded-lg border p-6 shadow">
            <div className="inline-flex w-1/2 flex-col">
              <div className="flex flex-row items-center justify-between">
                <p className="pb-2 text-left text-base font-medium text-gray-700">
                  {t("audience_details.datasources")}
                </p>
                <div className="mr-4 w-72">
                  <Input
                    icon={MagnifyingGlassIcon}
                    placeholder={t("audience_details.search_placeholder")}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
              {errors.datasourceIds ? <p className="text-left text-sm text-red-600">{errors.datasourceIds}</p> : null}
              {dataSources.map((ds) => (
                <DataSourceCheckbox
                  key={ds.id}
                  dataSource={ds}
                  traitId={values.matchingTrait}
                  checked={some(values.datasourceIds, (id) => id === ds.id)}
                  onChange={(e) => {
                    setFieldError("datasourceIds");
                    let newDataSourcesIds = values.datasourceIds || [];
                    newDataSourcesIds = e.target.checked
                      ? newDataSourcesIds.concat(ds.id)
                      : newDataSourcesIds.filter((id) => id !== ds.id);

                    setFieldValue("datasourceIds", newDataSourcesIds);
                    console.log("name touch", touched.name);
                    if (!touched.name) {
                      const dataSourceNames = dataSources
                        .filter((d) => newDataSourcesIds.includes(d.id))
                        .map((d) => d.name);
                      setFieldValue("name", dataSourceNames.join(" and "));
                    }
                  }}
                />
              ))}
              <div className="mt-2">
                <SimplePagination
                  firstPage={pageNo === 0}
                  lastPage={lastPage}
                  nextPage={() => {
                    setPageNo(pageNo + 1);
                  }}
                  prevPage={() => {
                    setPageNo(pageNo - 1);
                  }}
                  loading={isLoadingDataSources}
                />
              </div>
            </div>
            <div className="inline-flex flex-grow flex-col">
              <p className="py-2 text-left text-sm font-medium text-gray-700">{t("audience_details.matching_trait")}</p>
              {errors.matchingTrait ? <p className="text-left text-sm text-red-600">{errors.matchingTrait}</p> : null}
              {traits
                ?.filter((t) => t.identifier)
                .map((t, index) => (
                  <div
                    key={t.id}
                    className={concatClassNames(
                      "w-3/ inline-flex cursor-pointer items-center rounded-sm p-2",
                      t.id === values.matchingTrait ? "border-blue-300 bg-blue-100" : "border-gray-300",
                      index === 0 ? "mt-0" : "mt-2"
                    )}
                    onClick={() => {
                      setFieldError("matchingTrait");
                      setFieldValue("matchingTrait", t.id);
                    }}
                  >
                    <div className="mr-3 flex-none justify-start">
                      <input
                        key={t.id}
                        type="radio"
                        className="form-radio cursor-pointer"
                        name="radio"
                        value={t.id}
                        checked={t.id === values.matchingTrait}
                        onChange={() => {
                          setFieldError("matchingTrait");
                          setFieldValue("matchingTrait", t.id);
                        }}
                      />
                    </div>
                    <div className="">
                      <span className="font-medium text-gray-700">{t.displayName}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AudienceForm;
