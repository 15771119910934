import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import { TimeAgo } from "../../../../components/time-ago";
import type { AudienceSyncTimeBasedSchedule, BaseAudience } from "../../../../models/audiences";
import { AudienceStats } from "../index";

type IAudienceRowProp = {
  id: string;
  audience: BaseAudience;
  showSync: boolean;
};

const AudienceRow: FunctionComponent<IAudienceRowProp> = ({ id, audience, showSync }) => {
  const { t } = useTranslation("audiences");
  const navigate = useNavigate();

  const handleClick = () => {
    if (audience.extended) {
      navigate(`/extended-audiences/${audience.id}`);
    } else {
      navigate(`/audiences/${audience.id}`);
    }
  };

  const renderScheduledSync = () => {
    const syncConfig: AudienceSyncTimeBasedSchedule = audience.scheduleConfiguration as AudienceSyncTimeBasedSchedule;
    const cardinality: string = syncConfig.repeatsEvery === 1 ? "one" : "other";
    return t(`table.syncs.scheduled_${syncConfig.unit}_${cardinality}` as any, { count: syncConfig.repeatsEvery });
  };

  const renderSync = () => {
    switch (audience.scheduleType) {
      case "REALTIME": {
        return t("table.syncs.realtime");
      }

      case "MANUAL": {
        return t("table.syncs.manual");
      }

      case "SCHEDULED": {
        return renderScheduledSync();
      }

      default: {
        return "-";
      }
    }
  };

  return (
    <tr
      id={id}
      className="bg-white-100 cursor-pointer border-b border-gray-200 last:border-0 hover:bg-gray-50"
      onClick={handleClick}
    >
      <td className="whitespace-nowrap py-1 pl-6 text-sm font-medium text-gray-900">
        <div className="mr-3 inline-block align-middle text-gray-500">
          <UserGroupIcon className="h-5 w-5" />
        </div>
      </td>
      <td className="whitespace-nowrap py-1 pl-3 pr-4 text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">{audience.id}</p>
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <div className="cursor-pointer py-2 text-sm font-medium text-blue-500">
          {audience.name}
          {audience.extended ? (
            <span className="mx-2 items-center rounded-md bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
              {t("extended")}
            </span>
          ) : null}
        </div>
      </td>
      {showSync ? (
        <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
          <p className="py-2 text-sm font-medium text-gray-900">{renderSync()}</p>
        </td>
      ) : null}
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">
          <TimeAgo time={audience.createdOn} />
        </p>
      </td>
      <td className="whitespace-nowrap py-1 pl-6 pr-4 text-sm font-medium text-gray-900">
        <div className="py-2 text-sm font-medium text-gray-900">
          <AudienceStats audience={audience} />
        </div>
      </td>
    </tr>
  );
};

export default AudienceRow;
