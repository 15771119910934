import { type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form, Formik, type FormikValues } from "formik";
import { isEqual } from "lodash";
import * as Yup from "yup";

import { Button } from "../../../../components/buttons";
import { Input } from "../../../../components/inputs";
import type { CompanyAdministrativeData } from "../../../../models/company";
import type { RootState } from "../../../../reducers";
import {
  useGetCompanyAdministativeDataQuery,
  useUpdateCompanyAdministrativeDataMutation,
} from "../../../../services/endpoints/companies";

type AdministratorInfoProps = {
  back?: () => void;
  complete?: () => void;
};

const AdministratorInfo: FunctionComponent<AdministratorInfoProps> = ({ back, complete }) => {
  const { t } = useTranslation("company");
  const company = useSelector((state: RootState) => state.userInfo.company);

  if (!company) {
    back && back();
  }

  const { data: currInfo, isLoading: isInfoLoading } = useGetCompanyAdministativeDataQuery({
    companyId: company?.id || "",
  });

  const [updateCompanyAdministration, { data: updatedInfo, isError: isUpdatedInfoError }] =
    useUpdateCompanyAdministrativeDataMutation();

  const validationSchema = Yup.object({
    legalEntity: Yup.string()
      .min(3, t("administrative_info.edit.validation.too_short"))
      .max(100, t("administrative_info.edit.validation.too_long"))
      .required(t("administrative_info.edit.validation.required")),
    email: Yup.string()
      .email(t("administrative_info.edit.validation.not_valid_email"))
      .required(t("administrative_info.edit.validation.required")),
    country: Yup.string().required(t("administrative_info.edit.validation.required")),
    legalAddress1: Yup.string().required(t("administrative_info.edit.validation.required")),
    city: Yup.string().required(t("administrative_info.edit.validation.required")),
    zip: Yup.string().required(t("administrative_info.edit.validation.required")),
    state: Yup.string().required(t("administrative_info.edit.validation.required")),
  });

  const [initialValues, setInitialValues] = useState<Partial<CompanyAdministrativeData>>({
    legalEntity: "",
    email: "",
    vatNumber: "",
    legalAddress1: "",
    legalAddress2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  useEffect(() => {
    if (currInfo) {
      setInitialValues({ ...currInfo });
    }
  }, [currInfo]);

  useEffect(() => {
    if (updatedInfo) {
      complete && complete();
    }
  }, [updatedInfo]);

  const handleOnSubmit = (values: FormikValues) => {
    const touched = !isEqual(values, initialValues);
    if (touched && company) {
      updateCompanyAdministration({ body: values as CompanyAdministrativeData, companyId: company.id });
    } else {
      complete && complete();
    }
  };

  return (
    <div className="bg-wave flex h-full w-full items-center justify-center bg-gray-100">
      <div className="bg-white-100 flex flex-col items-center rounded-lg p-8 shadow-md" style={{ width: "448px" }}>
        <h3 className="mb-5 text-xl font-semibold">{t("administrative_info.administrative_info")}</h3>
        <Formik<Partial<CompanyAdministrativeData>>
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, handleChange, setFieldError, setFieldValue, isSubmitting }) => {
            const isLoading = isSubmitting || isUpdatedInfoError || isInfoLoading;
            return (
              <Form>
                <div className="mb-4 grid grid-cols-4">
                  <div className="col-span-4 mr-1">
                    <label className="mb-1 block font-medium">{t("administrative_info.legal_entity")}</label>
                    <Input
                      name="legalEntity"
                      id="legalEntity"
                      className="w-full rounded-md border-gray-300 shadow-sm"
                      placeholder={t("administrative_info.your_company")}
                      type="text"
                      value={values.legalEntity}
                      error={errors.legalEntity}
                      disabled={isLoading}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldError("legalEntity");
                        handleChange("legalEntity")(value);
                        setFieldValue("legalEntity", value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-4 grid grid-cols-2">
                  <div className="mr-1">
                    <label className="mb-1 block font-medium">{t("administrative_info.company_email")}</label>
                    <Input
                      name="email"
                      id="email"
                      className="w-full rounded-md border-gray-300 shadow-sm"
                      type="text"
                      placeholder="info@company.com"
                      value={values.email}
                      error={errors.email}
                      disabled={isLoading}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldError("email");
                        handleChange("email")(value);
                        setFieldValue("email", value);
                      }}
                    />
                  </div>
                  <div className="ml-1">
                    <label className="mb-1 block font-medium">{t("administrative_info.vat")}</label>
                    <Input
                      name="vatNumber"
                      id="vatNumber"
                      className="w-full rounded-md border-gray-300 shadow-sm"
                      type="text"
                      placeholder="01234567890"
                      value={values.vatNumber}
                      error={errors.vatNumber}
                      disabled={isLoading}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldError("vatNumber");
                        handleChange("vatNumber")(value);
                        setFieldValue("vatNumber", value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label className="mb-1 block font-medium">Country</label>
                  <Input
                    id="country"
                    name="country"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                    type="text"
                    placeholder="Country"
                    value={values.country}
                    error={errors.country}
                    disabled={isLoading}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldError("country");
                      handleChange("country")(value);
                      setFieldValue("country", value);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-1 block font-medium">Legal Address</label>
                  <Input
                    id="legalAddress1"
                    name="legalAddress1"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                    type="text"
                    placeholder="Address Line 1"
                    value={values.legalAddress1}
                    error={errors.legalAddress1}
                    disabled={isLoading}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldError("legalAddress1");
                      handleChange("legalAddress1")(value);
                      setFieldValue("legalAddress1", value);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    id="legalAddress2"
                    name="legalAddress2"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                    type="text"
                    placeholder="Address Line 2"
                    value={values.legalAddress2}
                    error={errors.legalAddress2}
                    disabled={isLoading}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldError("legalAddress2");
                      handleChange("legalAddress2")(value);
                      setFieldValue("legalAddress2", value);
                    }}
                  />
                </div>
                <div className="mb-4 grid grid-cols-2">
                  <div className="mr-1">
                    <Input
                      id="city"
                      name="city"
                      className="w-full rounded-md border-gray-300 shadow-sm"
                      type="text"
                      placeholder="City"
                      value={values.city}
                      error={errors.city}
                      disabled={isLoading}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldError("city");
                        handleChange("city")(value);
                        setFieldValue("city", value);
                      }}
                    />
                  </div>
                  <div className="ml-1">
                    <Input
                      id="zip"
                      name="zip"
                      className="w-full rounded-md border-gray-300 shadow-sm"
                      type="text"
                      placeholder="zip"
                      value={values.zip}
                      error={errors.zip}
                      disabled={isLoading}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldError("zip");
                        handleChange("zip")(value);
                        setFieldValue("zip", value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-6 grid">
                  <Input
                    id="state"
                    name="state"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                    type="text"
                    placeholder="state"
                    value={values.state}
                    error={errors.state}
                    disabled={isLoading}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldError("state");
                      handleChange("state")(value);
                      setFieldValue("state", value);
                    }}
                  />
                </div>

                <Button className="mb-3 w-full shadow-sm" type="submit" loading={isLoading}>
                  {t("administrative_info.btn_continue")}
                </Button>

                {/* <button className={'w-full border bg-white-100 border-grey-300 mb-3 rounded-md px-3 py-2 shadow-sm hover:bg-gray-50'} type={'button'}>
            Skip for now
          </button> */}

                <button
                  className="border-grey-300 bg-white-100 mb-3 w-full rounded-md border px-3 py-2 shadow-sm hover:bg-gray-50"
                  type="button"
                  onClick={back}
                >
                  {t("administrative_info.btn_back")}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AdministratorInfo;
