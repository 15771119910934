import { Fragment, type FunctionComponent } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { DefaultModal } from "../components/modals";
import type { IManagedModalOptions } from "../models/modals";
import { concatClassNames } from "../utils";

type IModalProviderContentProps = {
  modalsOptions?: IManagedModalOptions<unknown>;
  // This callback is needed and is the callback that will handle the "closing" of the modal
  closeModal: () => void;
  // This callback is for doing actions on backdrop of modal
  onModalBackdropClicked: () => void;
  // This callback is fired after animation of modal closing is finished
  onModalClosed: () => void;
  // This callback is fired for toggling the dismissability of the modal
  // i.e. during an API call in the component, I want to block the dismissability of the modal
  onToggleDismissable?: (toggle: boolean) => void;
};

const ModalProviderContent: FunctionComponent<IModalProviderContentProps> = ({
  modalsOptions,
  closeModal,
  onModalClosed,
  onModalBackdropClicked,
  onToggleDismissable,
}) => {
  modalsOptions = modalsOptions || ({} as IManagedModalOptions<unknown>);
  if (!modalsOptions?.position) {
    modalsOptions && (modalsOptions.position = "center");
  }

  const positionClass = {
    center: {
      outer: "justify-center  sm:block",
      content: "max-w-7xl py-16",
    },
    right: {
      outer: "justify-end",
      content: "max-w-xl",
    },
  };
  const { renderContentProps } = modalsOptions;
  return (
    <Transition.Root show={modalsOptions?.visible || false} as={Fragment} afterLeave={onModalClosed}>
      <Dialog
        static
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        open={modalsOptions?.visible}
        onClose={onModalBackdropClicked}
      >
        <div
          className={`flex min-h-screen items-end px-4 pb-20 pt-4 text-center sm:p-0  ${
            modalsOptions?.position && positionClass[modalsOptions.position].outer
          }`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0">
              <div className="absolute inset-0 bg-gray-300 opacity-75" />
            </Dialog.Overlay>
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          {!modalsOptions?.renderContent && (
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
          )}
          {/* Remember that inside Transition.Child we need to have a forwardable component
            If we use our JSX, we need to use forwardRef. Otherwise we can move here the container and just
            create a normal child without ref (use a div as a container) */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {modalsOptions?.renderContent ? (
              <div
                className={`pointer-events-none relative inline-flex h-screen w-screen items-center ${
                  modalsOptions?.position && positionClass[modalsOptions.position].content
                }`}
              >
                <div
                  className={concatClassNames(
                    modalsOptions.fullHeight ? "h-full" : "h-auto",
                    modalsOptions.fullWidth ? "w-full" : "mx-auto w-auto max-w-6xl",
                    "pointer-events-auto rounded-lg bg-white-100"
                  )}
                >
                  <modalsOptions.renderContent
                    {...renderContentProps}
                    closeModal={closeModal}
                    onToggleDismissable={onToggleDismissable}
                  />
                </div>
              </div>
            ) : (
              <DefaultModal closeModal={closeModal} />
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalProviderContent;
