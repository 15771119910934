import type { FunctionComponent } from "react";

import { ReactComponent as PaperPlaneIcon } from "../../../../assets/icons/paper-plane.svg";
import { ReactComponent as PieChartIcon } from "../../../../assets/icons/pie-chart.svg";
import { ReactComponent as EmptyAudienceImage } from "../../../../assets/image/empty_audience.svg";
import { Button } from "../../../../components/buttons";
import { useModalContext } from "../../../../hooks/useModalContext";
import { useAppDispatch } from "../../../../reducers";
import { AudienceEdit } from "../../../audienceEdit";
import { clearStashedAudience } from "../../../audienceEdit/audienceEditSlice";
import { ExtendedAudienceEdit } from "../../../extendedAudienceEdit";
import { clearStashedExtendedAudience } from "../../../extendedAudienceEdit/extendedAudienceEditSlice";

const EmptyAudiences: FunctionComponent<{ isExtended?: boolean }> = ({ isExtended }) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModalContext();
  const create = () => {
    if (isExtended) {
      dispatch(clearStashedExtendedAudience());

      openModal({
        renderContent: ExtendedAudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
      });
    } else {
      dispatch(clearStashedAudience());

      openModal({
        renderContent: AudienceEdit,
        renderContentProps: {},
        fullHeight: true,
        fullWidth: true,
      });
    }
  };

  return (
    <div className="flex h-full grow items-center">
      <div className="w-100 md:w-1/2">
        <h3 className="mb-2 text-lg font-semibold">Make your data dance together</h3>
        <p className="mb-8 font-normal">
          Audiencerate CDP takes data from different sources and combines them together, enriching each other:
        </p>
        <ul className="mb-8">
          <li className="mb-2 flex items-center">
            <PaperPlaneIcon className="mr-2 h-6 w-6" />
            It shows you automatic statistics about your customers
          </li>
          <li className="flex items-center">
            <PieChartIcon className="mr-2 h-6 w-6" />
            Send complete data to your other tools
          </li>
        </ul>
        <Button onClick={create}>Create your first {isExtended ? "amplified" : null} audience</Button>
      </div>
      <div className="flex w-full justify-center md:w-1/2">
        <EmptyAudienceImage style={{ width: "400px", height: "400px" }} />
      </div>
    </div>
  );
};

export default EmptyAudiences;
