import { useCallback } from "react";

import classNames from "classnames";

type PercentageBadgeProps = {
  percentage?: number;
};

export default function PercentageBadge({ percentage }: PercentageBadgeProps) {
  const labelColor = useCallback(() => {
    if (!percentage || percentage == 0) {
      return "bg-red-300";
    }

    if (percentage < 35) {
      return "bg-red-200";
    }

    if (percentage < 70) {
      return "bg-yellow-200";
    }

    return "bg-green-200";
  }, [percentage]);
  return (
    <i
      className={classNames(
        labelColor(),
        "text-grey-800 ml-4 flex max-w-max items-center justify-center rounded-xl py-0.5 px-5 text-xs leading-4"
      )}
    >
      %{percentage}
    </i>
  );
}
