import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { LinkIcon } from "@heroicons/react/24/outline";

type ICollaboratorLinkProps = {
  onShareLinkToCollaboratorClicked: () => void;
};

const CollaboratorLink: FunctionComponent<ICollaboratorLinkProps> = ({ onShareLinkToCollaboratorClicked }) => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className="flex">
      <div className="flex-1 text-left">
        <h1 className="mb-2 text-sm font-bold leading-5 text-gray-900">{t("code.install_snippet.code_no_access")}</h1>
        <h1 className="text-sm font-normal leading-5 text-gray-600">{t("code.install_snippet.code_send_link")}</h1>
      </div>
      <div className="my-auto flex-1 text-right">
        <LinkIcon className="mr-2 inline h-4 text-blue-500" />
        <span
          className="mb-2 inline cursor-pointer text-sm font-medium leading-5 text-blue-700 underline"
          onClick={onShareLinkToCollaboratorClicked}
        >
          {t("code.install_snippet.code_copy_collaborator_link")}
        </span>
      </div>
    </div>
  );
};

export default CollaboratorLink;
