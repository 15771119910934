import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { TrashIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { isEmpty } from "lodash";

import { Button } from "../../../../../components/buttons";
import type { Audience } from "../../../../../models/audiences";

type IDeleteDataSourceProps = {
  handleDeleteAudienceClicked: () => void;
  audiences: Array<Pick<Audience, "id" | "name">> | undefined;
  isLoading?: boolean;
};

const DeleteAudience: FunctionComponent<IDeleteDataSourceProps> = ({
  audiences,
  handleDeleteAudienceClicked,
  isLoading = false,
}) => {
  const { t } = useTranslation("audience_details");

  return (
    <div>
      <h1 className="text-black-100 text-lg font-semibold">{t("settings.delete.delete_title")}</h1>
      {audiences && !isEmpty(audiences) ? (
        <div>
          <h3 className="my-3 w-2/3 text-base">{t("settings.delete.cannot_delete_audience")}</h3>
          <ul>
            {audiences.map((audience) => (
              <li key={audience.id} className="mb-1">
                <span className="inline">
                  <UserGroupIcon className="inline h-6" />
                  <span className="mx-2 inline">{audience.name}</span>
                  <Link to={`/audiences/${audience.id}`} className="my-auto inline text-blue-600 underline">
                    {t("settings.delete.go_to_audience")}
                  </Link>
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <Button
        icon={TrashIcon}
        variant="delete"
        loading={isLoading}
        disabled={!audiences || !isEmpty(audiences)}
        className="mt-4"
        onClick={handleDeleteAudienceClicked}
      >
        {t("settings.delete.delete_this_audience")}
      </Button>
    </div>
  );
};

export default DeleteAudience;
