import type { FunctionComponent, ReactNode } from "react";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";

type AlertProps = {
  type: "WARNING" | "ERROR" | "SUCCESS" | "INFO";
  title: string;
  description?: string;
  actionButtonText?: string;
  onAction?: () => void;
};

const Alert: FunctionComponent<AlertProps> = ({ type, title, description, actionButtonText, onAction }) => {
  let bgClass;
  let icon;
  let titleColor;
  let descriptionColor;
  let actionTextColor;
  let actionHoverColor;
  let actionFocusColor;
  switch (type) {
    case "WARNING": {
      bgClass = "bg-yellow-50";
      titleColor = "text-yellow-800";
      descriptionColor = "text-yellow-700";
      actionTextColor = "text-yellow-800";
      actionHoverColor = "hover:bg-yellow-100";
      actionFocusColor = "focus:ring-offset-yellow-50 focus:ring-yellow-600";
      icon = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />;
      break;
    }

    case "INFO": {
      bgClass = "bg-blue-50";
      titleColor = "text-blue-800";
      descriptionColor = "text-blue-700";
      actionTextColor = "text-blue-800";
      actionHoverColor = "hover:bg-blue-100";
      actionFocusColor = "focus:ring-offset-blue-50 focus:ring-blue-600";
      icon = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />;
      break;
    }

    case "ERROR": {
      bgClass = "bg-red-50";
      titleColor = "text-red-800";
      descriptionColor = "text-red-700";
      actionTextColor = "text-red-800";
      actionHoverColor = "hover:bg-red-100";
      actionFocusColor = "focus:ring-offset-red-50 focus:ring-red-600";
      icon = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
      break;
    }

    case "SUCCESS": {
      bgClass = "bg-green-50";
      titleColor = "text-green-800";
      descriptionColor = "text-green-700";
      actionTextColor = "text-green-800";
      actionHoverColor = "hover:bg-green-100";
      actionFocusColor = "focus:ring-offset-green-50 focus:ring-green-600";
      icon = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
      break;
    }
  }

  let actionButtonView: ReactNode = null;
  if (actionButtonText) {
    actionButtonView = (
      <div className="mt-4">
        <div className="-mx-2 -my-1.5 flex">
          <button
            type="button"
            className={classNames(
              bgClass,
              actionTextColor,
              actionFocusColor,
              actionHoverColor,
              "rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
            )}
            onClick={onAction}
          >
            {actionButtonText}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(bgClass, "rounded-md p-4")}>
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <h3 className={classNames(titleColor, "flex text-sm font-medium")}>{title}</h3>
          <div className={classNames(descriptionColor, "mt-2 text-sm")}>
            <p>{description}</p>
          </div>
          {actionButtonView}
        </div>
      </div>
    </div>
  );
};

export default Alert;
