import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { TFileData, TFileInfo, TFileStatus, UploadProgress } from "../../models/fileUpload";

export type FileUploadingState = {
  fileInfo?: TFileInfo;
  fileData?: TFileData;
  status?: TFileStatus;
  encrypted?: boolean;
  backgroundStatus?: {
    continue: boolean;
    workspaceId?: string;
  };
};

export const initialState: FileUploadingState = {};

const fileUploadingSlice = createSlice({
  name: "fileUploading",
  initialState,
  reducers: {
    resetUploadStatus(state) {
      return {
        ...state,
        encrypted: undefined,
        status: undefined,
        fileInfo: undefined,
        fileData: undefined,
        backgroundStatus: {
          continue: false,
          workspaceId: undefined,
        },
      };
    },
    updateUploadStatus(state, action: PayloadAction<FileUploadingState["status"]>) {
      return {
        ...state,
        status: action.payload,
      };
    },
    updateUploadProgress(state, action: PayloadAction<UploadProgress>) {
      if (state.status) {
        return {
          ...state,
          status: {
            ...state.status,
            progress: action.payload,
          },
        };
      }
    },
    updateFileInfo(state, action: PayloadAction<FileUploadingState["fileInfo"]>) {
      return {
        ...state,
        fileInfo: action.payload,
      };
    },
    updateFileData(state, action: PayloadAction<FileUploadingState["fileData"]>) {
      return {
        ...state,
        fileData: action.payload,
      };
    },
    updateEncrypted(state, action: PayloadAction<FileUploadingState["encrypted"]>) {
      return {
        ...state,
        encrypted: action.payload,
      };
    },
    updateBackgroundStatus(state, action: PayloadAction<string>) {
      return {
        ...state,
        backgroundStatus: {
          continue: true,
          workspaceId: action.payload,
        },
      };
    },
  },
});

export const {
  resetUploadStatus,
  updateUploadStatus,
  updateUploadProgress,
  updateFileInfo,
  updateFileData,
  updateBackgroundStatus,
  updateEncrypted,
} = fileUploadingSlice.actions;

export default fileUploadingSlice.reducer;
