import type { FunctionComponent } from "react";

import { useModalContext } from "../../../../hooks/useModalContext";
import type { Audience } from "../../../../models/audiences";
import {
  useGetAudienceDependenciesQueryQuery,
  useUpdateAudienceMutation,
} from "../../../../services/endpoints/audiences";
import { useWorkspace } from "../../../workspaces/hooks";
import { DeleteAudience, DuplicateAudience, GeneralAudienceSettings } from "./settings";
import { DeleteAudienceModal } from "./settings/modals";

type IAudienceSettingsProps = {
  audience?: Audience;
};

const AudienceSettings: FunctionComponent<IAudienceSettingsProps> = ({ audience }) => {
  const workspace = useWorkspace();
  const { openModal } = useModalContext();

  const [updateAudience, { isLoading: isUpdateLoading }] = useUpdateAudienceMutation();

  const { data } = useGetAudienceDependenciesQueryQuery({
    workspaceId: workspace.id,
    audienceId: audience?.id || "",
  });

  const audiences = data?.audiences || [];

  const handleDeleteAudienceClicked = () => {
    if (audience) {
      openModal({
        renderContent: DeleteAudienceModal,
        renderContentProps: {
          audience,
        },
        dismissable: true,
        fullWidth: false,
        fullHeight: false,
      });
    }
  };

  const onAudienceRenameClicked = (form: Audience) => {
    const audience = { ...form };

    updateAudience({
      workspaceId: workspace.id,
      audienceId: audience.id,
      audience: {
        name: audience.name,
        expression: audience.expression,
      },
    });
  };

  return (
    <div className="flex space-x-6">
      <div className="flex-1">
        <div className="space-y-24 py-8">
          <div className="flex flex-col space-y-24">
            {audience ? (
              <GeneralAudienceSettings
                audience={audience}
                isUpdateLoading={isUpdateLoading}
                onSubmit={onAudienceRenameClicked}
              />
            ) : null}
            <DuplicateAudience />
          </div>
          <DeleteAudience audiences={audiences} handleDeleteAudienceClicked={handleDeleteAudienceClicked} />
        </div>
      </div>
    </div>
  );
};

export default AudienceSettings;
