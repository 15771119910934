import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { concatClassNames } from "../../../../../../utils";

type ITrackPageVisitPanelProps = {
  className?: string;
};

const TrackPageVisitPanel: FunctionComponent<ITrackPageVisitPanelProps> = ({ className, children }) => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className={concatClassNames(className || "", "w-full rounded-lg border border-gray-200 shadow")}>
      <div className="flex rounded-lg bg-gray-50 px-6 py-4">
        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.735 11.1553C19.7806 7.83878 16.1355 5.62218 11.9827 5.62218C7.82809 5.62218 4.18386 7.84034 2.23038 11.1553C1.92321 11.6766 1.92321 12.3235 2.23038 12.8447C4.1848 16.1612 7.82983 18.3778 11.9827 18.3778C16.1373 18.3778 19.7815 16.1596 21.735 12.8447C22.0422 12.3234 22.0422 11.6765 21.735 11.1553ZM11.9827 16.714C9.37742 16.714 7.26865 14.6056 7.26865 12C7.26865 9.39472 9.377 7.28596 11.9827 7.28596C14.588 7.28596 16.6967 9.39431 16.6967 12C16.6967 14.6053 14.5884 16.714 11.9827 16.714ZM11.9827 15.6048C13.9736 15.6048 15.5875 13.9909 15.5875 12C15.5875 10.0091 13.9736 8.39514 11.9827 8.39514C11.3689 8.39514 10.791 8.54873 10.2852 8.81927L10.2863 8.81923C11.1061 8.81923 11.7707 9.48381 11.7707 10.3036C11.7707 11.1234 11.1061 11.7879 10.2863 11.7879C9.4665 11.7879 8.80192 11.1234 8.80192 10.3036L8.80196 10.3025C8.53142 10.8083 8.37783 11.3862 8.37783 12C8.37783 13.9909 9.99177 15.6048 11.9827 15.6048Z"
            fill="#54647A"
          />
        </svg>
        <h1 className="my-auto text-sm font-semibold text-gray-900">{t("code.track_events.track_event_on_visit")}</h1>
      </div>
      {children}
    </div>
  );
};

export default TrackPageVisitPanel;
