import { Fragment, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

import type { Workspace } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import RouteConstants from "../../router/RouteConstants";
import { useWorkspace } from "../hooks";
import { setCurrentWorkspace } from "../workspacesSlice";

type IWorkspaceSelectorProps = {
  workspaces: Workspace[];
};

const WorkspaceSelector: FunctionComponent<IWorkspaceSelectorProps> = ({ workspaces }) => {
  const { t } = useTranslation("workspaces");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentWorkspace = useWorkspace();

  const handleWorkspaceClicked = (workspace: Workspace) => {
    dispatch(setCurrentWorkspace(workspace));

    navigate(RouteConstants.defaultRoute);
    location.reload();
  };

  const handleCreateNewWorkspaceClicked = () => {
    navigate(RouteConstants.createWorkspace);
  };

  return (
    <Popover className="relative z-20">
      {({ open }) => (
        <>
          <Popover.Button
            className={`border
                ${open ? "" : "text-opacity-90"}
                focus-visible:ring-white group inline-flex w-full items-center justify-between rounded-md bg-white-100 px-3 py-2 outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75`}
          >
            <span className="truncate text-base font-medium text-gray-700">{currentWorkspace.name}</span>
            <ChevronDownIcon
              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-700 transition duration-150 ease-in-out`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel static className="absolute -right-full bottom-0 mt-1 w-full origin-top-right">
              {({ close }) => (
                <div className="ml-3 w-full overflow-hidden rounded-lg border shadow-lg">
                  <div className="relative gap-8 bg-white-100 py-2">
                    {workspaces.map((ws) => (
                      <button
                        key={ws.id}
                        className={`${
                          currentWorkspace.id === ws.id ? "bg-blue-400" : "bg-white-100"
                        } group flex w-full px-6 py-3 hover:bg-blue-600`}
                        onClick={() => {
                          handleWorkspaceClicked(ws);

                          close();
                        }}
                      >
                        <span
                          className={`${
                            currentWorkspace.id === ws.id ? "text-white-100" : "text-gray-700"
                          } truncate font-medium group-hover:text-white-100`}
                        >
                          {ws.name}
                        </span>
                      </button>
                    ))}
                    <div
                      className="group mb-3 mt-6 flex cursor-pointer items-center justify-center px-2"
                      onClick={() => {
                        handleCreateNewWorkspaceClicked();

                        close();
                      }}
                    >
                      <PlusSmallIcon className="mr-2 h-5 w-5 text-blue-600 group-hover:opacity-80" aria-hidden="true" />
                      <span className="text-base text-blue-600 group-hover:opacity-80">
                        {t("selector.create_new_workspace")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default WorkspaceSelector;
