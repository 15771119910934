import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { CancelTokenSource } from "axios";

export type IFileUploadContext = {
  uploadFile: (file: File, cancelTokenSource: CancelTokenSource) => void;
};

export type UploadProgress = {
  loaded: number;
  total: number;
  percentageCompleted: number;
};

export enum EStatus {
  UPLOADING,
  SUCCESS,
  ERROR,
  NOT_SELECTED,
}

export type IFileUploadStatus = {
  status: EStatus;
  percentage: UploadProgress;
};
export type UploadedFile = {
  path: string;
};

export type TFileInfo = {
  filename: string;
  path: string;
};

export type TFileData = {
  preview: string[][];
  header: string[];
  isDefaultHeader: boolean;
};

export type TFileStatus = {
  uploadingStatus: EStatus;
  data?: UploadedFile;
  error?: FetchBaseQueryError | SerializedError | string;
  progress?: UploadProgress;
};
