import type { FunctionComponent } from "react";

import { EDataSourceStatus } from "../../models/dataSource";

type IStatusBadgeProps = {
  status: EDataSourceStatus;
};

const StatusBadge: FunctionComponent<IStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case EDataSourceStatus.ACTIVE: {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-green-100 px-3 py-0.5 text-xs leading-4 text-green-800">
          Active
        </div>
      );
    }

    case EDataSourceStatus.ACTIVATING: {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-blue-100 px-3 py-0.5 text-xs leading-4 text-blue-800">
          Activating
        </div>
      );
    }

    case EDataSourceStatus.CONFIGURATION_REQUIRED: {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-red-100 px-3 py-0.5 text-xs leading-4 text-red-800">
          Configuration required
        </div>
      );
    }

    case EDataSourceStatus.PENDING: {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-yellow-100 px-3 py-0.5 text-xs leading-4 text-yellow-800">
          Waiting activation
        </div>
      );
    }

    case EDataSourceStatus.ERROR: {
      return (
        <div className="flex max-w-max justify-center rounded-xl bg-red-100 px-3 py-0.5 text-xs leading-4 text-red-800">
          Error
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default StatusBadge;
