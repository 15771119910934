import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import type { PayloadAction } from "@reduxjs/toolkit";

import { SearchInput } from "../../../../components/tables/search";
import type { BaseAudience } from "../../../../models/audiences";
import type { RootState } from "../../../../reducers";
import AudienceTable from "./AudienceTable";

type IAudienceListProps = {
  audiences: BaseAudience[];
  showSync: boolean;
  searchStateSelector: (state: RootState) => string;
  searchStateUpdater: (searchText: string) => PayloadAction<string>;
  pager: React.ReactNode;
};

const AudienceList: FunctionComponent<IAudienceListProps> = ({
  audiences,
  showSync,
  searchStateSelector,
  searchStateUpdater,
  pager,
}) => {
  const { t } = useTranslation("audiences");
  return (
    <>
      <SearchInput
        stateSelector={searchStateSelector}
        stateUpdater={searchStateUpdater}
        placeholder={t("search_placeholder")}
      />
      <AudienceTable audiences={audiences} showSync={showSync} />
      {pager}
    </>
  );
};

export default AudienceList;
