import { Fragment, type FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrowRightIcon } from "@heroicons/react/20/solid";

import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import GoogleButtonDisabled from "../../../../assets/img/btn_google_signin_dark_disabled_web@2x.png";
import GoogleButtonNormal from "../../../../assets/img/btn_google_signin_dark_normal_web@2x.png";
import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { Input } from "../../../../components/inputs";
import { Select } from "../../../../components/selects";
import type { BaseAudience } from "../../../../models/audiences";
import type {
  CyclrActionParameter,
  CyclrActionTriggerValue,
  DistributionChannelContent,
  DistributionChannelParameterUpdate,
} from "../../../../models/distributionChannels";
import type { ISelectItem } from "../../../../models/select";
import { useGetAllAudiencesQuery } from "../../../../services/endpoints/audiences";
import {
  useCreateDistributionChannelMutation,
  useDistributionChannelAuthenticatedMutation,
  useUpdateDistributionChannelParametersMutation,
} from "../../../../services/endpoints/distributionChannels";
import { type CyclrTemplate, useCyclrTemplatesQuery } from "../../../../services/endpoints/integrations";
import { popupCenter } from "../../../../utils";
import RouteConstants from "../../../router/RouteConstants";
import { useWorkspace } from "../../../workspaces/hooks";
import templateImage from "../templateImage";

type IDestinationStepDestinationProps = {
  distributionChannelContent?: DistributionChannelContent;
  nextStep: (p: DistributionChannelContent, isExtended: boolean) => void;
  forceAudience?: BaseAudience;
};

const DestinationStepDestination: FunctionComponent<IDestinationStepDestinationProps> = ({
  nextStep,
  distributionChannelContent,
  forceAudience,
}) => {
  const { t } = useTranslation("destinations");
  const workspace = useWorkspace();

  const {
    data: audiences,
    isLoading: audienceIsLoading,
    isUninitialized: audienceIsUninitialized,
  } = useGetAllAudiencesQuery({
    workspaceId: workspace.id,
  });

  const {
    data: templates,
    isLoading: templateIsLoading,
    isUninitialized: templateIsUninitialized,
  } = useCyclrTemplatesQuery({
    workspaceId: workspace.id,
  });

  const [
    createDistributionChannel,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      data: createData,
      error: createError,
    },
  ] = useCreateDistributionChannelMutation();

  const [
    distributionChannelAuthenticated,
    { isLoading: isGetLoading, isSuccess: isGetSuccess, isError: isGetError, data: getData, error: getError },
  ] = useDistributionChannelAuthenticatedMutation();

  const [
    updateDistributionChannelParameters,
    {
      isLoading: isUpdateParametersLoading,
      isSuccess: isUpdateParametersSuccess,
      isError: isUpdateParametersError,
      data: updateParametersData,
      error: updateParametersError,
    },
  ] = useUpdateDistributionChannelParametersMutation();

  const [selectedAudience, setSelectedAudience] = useState<(ISelectItem & BaseAudience) | undefined>(
    forceAudience
      ? {
          ...forceAudience,
          label: forceAudience.name,
          image: <UserGroupIcon className="h-5 w-5 text-gray-500" />,
        }
      : undefined
  );
  const [selectedTemplate, setSelectedTemplate] = useState<(ISelectItem & CyclrTemplate) | undefined>();

  const [status, setStatus] = useState<string>(distributionChannelContent?.integrationsResult.cyclr.status || "CREATE");
  const [distributionChannel, setDistributionChannel] = useState<DistributionChannelContent | undefined>(
    distributionChannelContent
  );
  const [selectedParameters, setSelectedParameters] = useState<Record<string, DistributionChannelParameterUpdate>>({});
  const [triggerParam, setTriggerParam] = useState<Record<string, CyclrActionTriggerValue | undefined>>({});

  useEffect(() => {
    const tempDistributionChannel = getData || createData || distributionChannel;
    setDistributionChannel(tempDistributionChannel);
    setStatus(tempDistributionChannel?.integrationsResult.cyclr.status || status);
    // Console.log(createData?.integrationsResult.cyclr.status);
    if (tempDistributionChannel?.integrationsResult.cyclr.status === "AUTHENTICATION_REQUIRED") {
      // Window.open(createData.integrationsResult.cyclr.action.authUrl, '__blank');
      const { action } = tempDistributionChannel.integrationsResult.cyclr;
      authenticationScreen(
        `${action.authUrl}?id=${action.accountConnectors[0]}&token=${action.token}&targetOrigin=${
          location.origin + RouteConstants.cyclrAuthRedirect
        }`,
        tempDistributionChannel as any
      );
    } else if (tempDistributionChannel?.integrationsResult.cyclr.status === "ACTIVABLE") {
      nextStep(tempDistributionChannel, Boolean(selectedAudience?.extended));
    }
  }, [createData, getData]);

  useEffect(() => {
    if (updateParametersData) {
      nextStep(updateParametersData, Boolean(selectedAudience?.extended));
    }
  }, [updateParametersData]);

  const authenticationScreen = (url: string, distributionChannel: DistributionChannelContent) => {
    (window as any).cyclrAuthenticationCompleted = (success: boolean, w: Window) => {
      w.close.apply(w);
      delete (window as any).cyclrAuthenticationCompleted;
      distributionChannelAuthenticated({
        workspaceId: workspace.id,
        audienceId: selectedAudience?.id as any,
        distributionChannelId: distributionChannel?.distributionChannel.id as any,
      });
    };

    const newWindow = popupCenter({
      url,
      title: "Authenticate",
      w: 640,
      h: 640,
    });
    (window as any).authWindow = newWindow;
  };

  const saveDestination = () => {
    createDistributionChannel({
      workspaceId: workspace.id,
      audienceId: selectedAudience?.id!,
      configData: {
        templateId: selectedTemplate?.Id,
        templateName: selectedTemplate?.Name,
      },
    });
  };

  const updateParameters = () => {
    updateDistributionChannelParameters({
      workspaceId: workspace.id,
      audienceId: selectedAudience?.id!,
      distributionChannelId: distributionChannel?.distributionChannel.id!,
      parameters: Object.values(selectedParameters),
    });
  };

  const parameterValueChange = (update: DistributionChannelParameterUpdate) => {
    setSelectedParameters({ ...selectedParameters, [update.parameterId]: update });
  };

  const parameterTemplate = (param: CyclrActionParameter, stepIds?: string[], index = 1) => {
    const translationKey = distributionChannel?.distributionChannel?.name.toLowerCase().replace(/\s+/g, "_");
    const { name, description, triggerParameterLookupValues } = param;
    const paramName = (triggerParameterLookupValues?.trigger?.Name || name).toLowerCase().replace(/\s+/g, "_");
    return (
      <Fragment key={param.parameterId}>
        <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 px-6 py-4 text-left font-medium text-gray-800 shadow-sm">
          {paramName ? (
            <h3 className="mb-0 w-full font-semibold md:w-2/3 lg:w-1/2">
              {t(`edit.cyclr.${translationKey}.${paramName}` as any)}
            </h3>
          ) : null}
          {param.triggerParameterLookupValues?.values?.length == 0 && (
            <p className="mt-5 w-full text-base font-normal italic text-gray-500 md:w-2/3 lg:w-1/2">
              {t(`edit.cyclr.${translationKey}.${paramName}_empty` as any)}
            </p>
          )}
          {param.mappingType === null ? (
            <Input
              className="mb-4 mt-6 w-full md:w-2/3 lg:w-1/2"
              onChange={(e) => {
                parameterValueChange({
                  isAccountConnectorParameter: param.isAccountConnectorParameter,
                  accountConnectorId: param.accountConnectorId,
                  propertyId: param.propertyId,
                  stepIds: param.stepIds,
                  parameterId: param.parameterId,
                  mappingType: "StaticValue",
                  value: e.target.value,
                  name: null,
                });
              }}
            />
          ) : (
            <>
              {param.triggerParameterLookupValues?.values.map((lookupValue) => (
                <div key={lookupValue.value}>
                  <label className="flex items-center pt-6 font-normal">
                    <input
                      type="radio"
                      className="mr-3"
                      name={param.parameterId + "_" + index}
                      value={lookupValue.value}
                      onChange={() => {
                        if (lookupValue.nextTrigger) {
                          setTriggerParam({
                            ...triggerParam,
                            [index]: lookupValue,
                          });
                        } else {
                          parameterValueChange({
                            isAccountConnectorParameter: param.isAccountConnectorParameter,
                            accountConnectorId: param.accountConnectorId,
                            propertyId: param.propertyId,
                            stepIds: stepIds || param.stepIds,
                            parameterId: param.parameterId,
                            mappingType: param.mappingType,
                            value: lookupValue.value,
                            name: lookupValue.name,
                          });
                        }
                      }}
                    />
                    {lookupValue.name}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>

        {triggerParam[index]?.nextTrigger
          ? parameterTemplate(
              {
                triggerParameterLookupValues: {
                  values: triggerParam[index]?.nextTrigger?.values,
                },
                name: triggerParam[index]?.nextTrigger?.trigger.Name,
                description: "",
                parameterId: param.parameterId,
                mappingType: param.mappingType,
              } as any,
              stepIds || param.stepIds,
              index + 1
            )
          : null}
      </Fragment>
    );
  };

  const statusTemplate = () => {
    let action;
    switch (status) {
      case "CREATE": {
        return (
          <div className="mt-6 h-auto rounded-lg border border-solid border-gray-200 p-6 text-left text-gray-500">
            {t("edit.step.destination.select_destination")}
          </div>
        );
      }

      case "PARAMETERS_REQUIRED": {
        return (
          <div className="mt-6">
            {distributionChannel?.integrationsResult.cyclr.action.parameters.map((param) => parameterTemplate(param))}
          </div>
        );
      }

      case "AUTHENTICATION_REQUIRED": {
        action = distributionChannel?.integrationsResult.cyclr.action;
        return action && <div />;
      }
    }
  };

  const actionButton = () => {
    switch (status) {
      case "CREATE": {
        if (selectedTemplate?.Name === "Google Ads")
          return (
            <button
              disabled={!selectedAudience || !selectedTemplate}
              className="border-0 bg-white"
              onClick={saveDestination}
            >
              {isCreateLoading ? (
                <div className="relative flex h-full w-full cursor-not-allowed items-center justify-center rounded">
                  <SpinnerIcon loading className="h-6 w-6" />
                </div>
              ) : (
                <img
                  className="h-12"
                  src={!selectedAudience || !selectedTemplate ? GoogleButtonDisabled : GoogleButtonNormal}
                />
              )}
            </button>
          );
        return (
          <Button disabled={!selectedAudience || !selectedTemplate} loading={isCreateLoading} onClick={saveDestination}>
            {t("edit.step.destination.save")}
          </Button>
        );
      }

      case "PARAMETERS_REQUIRED": {
        return (
          <Button
            loading={isUpdateParametersLoading}
            disabled={Boolean(
              distributionChannel?.integrationsResult.cyclr.action.parameters.find(
                (param) => !selectedParameters[param.parameterId] || !selectedParameters[param.parameterId].value
              )
            )}
            onClick={updateParameters}
          >
            {t("edit.step.next")}
          </Button>
        );
      }

      case "AUTHENTICATION_REQUIRED": {
        return (
          <div className="flex">
            <span className="mr-3 italic text-gray-800">{t("edit.step.destination.authenticating")}</span>
            <SpinnerIcon loading className="h-6 w-6" />
          </div>
        );
      }

      default: {
        return <Button>{t("edit.step.next")}</Button>;
      }
    }
  };

  const connectorImageOrTemplateImage = (template: CyclrTemplate) => {
    const connector = template.Connectors.find((item) => item.Name !== "Generic Webhook");
    if (connector && connector.Icon) {
      return <img className="w-5" src={connector.Icon} />;
    }

    return templateImage(template.Name);
  };

  return (
    <>
      <div className="flex-grow p-6">
        <div className="text-left">
          <h3 className="mb-1 text-base font-bold text-black">{t("edit.step.destination.title")}</h3>
          <p className="mb-5 text-base">{t("edit.step.destination.text")}</p>
        </div>
        <div className="flex w-full">
          <div className="w-1/2">
            <Select
              loading={audienceIsLoading}
              isViewMode={Boolean(distributionChannel) || isCreateLoading}
              disabled={Boolean(forceAudience)}
              items={
                audiences?.audiences.map<ISelectItem & BaseAudience>((audience) => ({
                  ...audience,
                  label: audience.name,
                  image: <UserGroupIcon className="h-5 w-5 text-gray-500" />,
                })) || []
              }
              className="w-full text-left"
              value={selectedAudience}
              onChange={(value) => {
                setSelectedAudience(value as ISelectItem & BaseAudience);
              }}
            />
          </div>
          <ArrowRightIcon height="20px" className="mt-2 px-5 text-gray-500" />
          <div className="w-1/2">
            <Select
              loading={templateIsLoading}
              isViewMode={Boolean(distributionChannel) || isCreateLoading}
              items={
                templates?.map<ISelectItem & CyclrTemplate>((template) => ({
                  ...template,
                  id: template.Id,
                  label: template.Name,
                  image: connectorImageOrTemplateImage(template),
                })) || []
              }
              className="w-full text-left"
              value={selectedTemplate}
              onChange={(value) => {
                setSelectedTemplate(value as ISelectItem & CyclrTemplate);
              }}
            />
          </div>
        </div>
        {statusTemplate()}
      </div>
      <div className="flex flex-shrink-0 justify-end rounded-b-lg bg-gray-50 p-4">{actionButton()}</div>
    </>
  );
};

export default DestinationStepDestination;
