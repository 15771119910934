import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type SlideOverState = {
  isVisible: boolean;
};

export const initialState: SlideOverState = {
  isVisible: false,
};

const slideOverSlice = createSlice({
  name: "slideOver",
  initialState,
  reducers: {
    toggleSlideOver: (state, action: PayloadAction<boolean>) => ({ ...state, isVisible: action.payload }),
  },
});

export const { toggleSlideOver } = slideOverSlice.actions;

export default slideOverSlice.reducer;
