import { type FunctionComponent, useEffect, useState } from "react";

import { SearchDropDown } from "../../../../components/dropdowns";
import type { SegmentOption } from "../../../../models/extendedAudiences";

type IGeographicDropdownProps = {
  isLoading: boolean;
  geographicInterests: SegmentOption[];
  pickedResult?: SegmentOption;
  setPickedResult: (picked: SegmentOption) => void;
  readonly?: boolean;
};

const GeographicDropdown: FunctionComponent<IGeographicDropdownProps> = ({
  readonly,
  geographicInterests,
  pickedResult,
  setPickedResult,
  isLoading,
}) => {
  const [query, setQuery] = useState(pickedResult?.displayName || "");
  const [items, setItems] = useState(geographicInterests || []);
  useEffect(() => {
    if (query) {
      setItems(geographicInterests?.filter((i) => i.name.startsWith(query)));
    } else {
      setItems(geographicInterests || []);
    }
  }, [query]);
  return (
    <SearchDropDown
      openWithFocus
      readOnly={readonly}
      item={pickedResult}
      items={items}
      setItem={setPickedResult}
      setQuery={setQuery}
      loading={isLoading}
    >
      {(result) => <span>{result.displayName}</span>}
    </SearchDropDown>
  );
};

export default GeographicDropdown;
