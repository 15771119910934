import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon } from "@heroicons/react/20/solid";

import { UploadingFile } from "../../..";
import { Button } from "../../../../../../components/buttons";
import type { IFileUploadStatus } from "../../../../../../models/fileUpload";

type IUploadOngoing = {
  fileUpload: IFileUploadStatus;
  filename: string;
  onClick: () => void;
};

const UploadingOngoing: FunctionComponent<IUploadOngoing> = ({ fileUpload, filename, onClick }) => {
  const { t } = useTranslation("data_source_edit");

  return (
    <div className="flex flex-col justify-center text-center">
      <span className="m-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
        <CheckIcon className="text-white-100 h-6 w-6" aria-hidden="true" />
      </span>
      <h3 className="my-3 text-lg font-medium text-gray-900">{t("file.confirmation.all_columns_are_mapped")}</h3>
      <p className="m-auto mb-9 w-2/3 text-base font-normal text-gray-900">
        {t("file.confirmation.all_columns_are_mapped_description")}
      </p>
      <div className="m-auto w-2/3 rounded-sm border border-gray-300 bg-gray-50 p-5">
        <p className="m-auto mb-9 text-sm font-normal text-gray-900">{t("file.confirmation.continue_working")}</p>
        <UploadingFile fileUpload={fileUpload} filename={filename} />
      </div>
      <div className="m-auto my-3 flex w-2/3 justify-end">
        <Button variant="confirm" onClick={onClick}>
          {t("file.confirmation.continue_in_background")}
        </Button>
      </div>
    </div>
  );
};

export default UploadingOngoing;
