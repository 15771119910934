import type React from "react";
import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form, Formik, type FormikProps } from "formik";

import type { Audience } from "../../../../models/audiences";
import type { RootState } from "../../../../reducers";
import { useGetWorkspaceUsageSummaryQuery } from "../../../../services/endpoints/workspaces";
import { useWorkspace } from "../../../workspaces/hooks";
import AudienceSyncConfig from "./AudienceSyncConfig";
import { validateSchedule } from "./validation";

export type AudienceSyncFormModel = Pick<Audience, "scheduleType" | "scheduleConfiguration">;

type IAudienceSyncContainerProps = {
  formRef: React.RefObject<FormikProps<AudienceSyncFormModel>>;
};

const AudienceSyncContainer: FunctionComponent<IAudienceSyncContainerProps> = ({ formRef }) => {
  const workspace = useWorkspace();
  const audience = useSelector((state: RootState) => state.audienceEdit.audience);
  const { t } = useTranslation("audience_edit");

  const { isFetching, data: workspaceUsageSummary } = useGetWorkspaceUsageSummaryQuery({ workspaceId: workspace.id });

  const onSubmit = () => {
    console.log("submit sync");
  };

  return (
    <div className="text-left">
      <h3 className="mb-1 text-base font-bold text-black">Sync</h3>
      <p className="mb-5 w-1/2 text-base text-gray-600">
        Decide when and how much your audience needs to update, based on your specific needs.
      </p>

      <Formik
        innerRef={formRef}
        initialValues={{
          scheduleType: audience?.scheduleType || "MANUAL",
          scheduleConfiguration: audience?.scheduleConfiguration || {},
        }}
        validate={(values) => validateSchedule(values, t)}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <AudienceSyncConfig
              isFetchingSummary={isFetching}
              realTimeAudienceLeft={workspaceUsageSummary?.realTimeAudienceLeft || 0}
              scheduleType={values.scheduleType}
              scheduleConfiguration={values.scheduleConfiguration}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            {/* <AudienceSyncAdvancedOptions
                scheduleType={values.scheduleType}
                scheduleConfiguration={values.scheduleConfiguration}
                setFieldValue={setFieldValue}
              /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AudienceSyncContainer;
