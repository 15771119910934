import { type FunctionComponent, useEffect, useState } from "react";

import { SearchDropDown } from "../../../../components/dropdowns";
import type { ITSelectProps } from "../../../../components/selects";
import type { Trait } from "../../../../models/traits";
import { useGetCustomPropertiesQuery, useGetTraitDistributionsQuery } from "../../../../services/dataSources";
import { useGetTraitsQuery } from "../../../../services/endpoints/traits";
import { useWorkspace } from "../../../workspaces/hooks";

type ITraitSelectProps = {
  traitId?: string;
  datasourceIds?: string[];
} & Partial<ITSelectProps<Trait>>;

export type DistributionCountMapType = Record<string, { count: number; total: number; percent: number }>;

const TraitSelect: FunctionComponent<ITraitSelectProps> = ({ traitId, datasourceIds, ...rest }) => {
  const workspace = useWorkspace();

  const { isLoading, data } = useGetTraitsQuery({
    workspaceId: workspace.id,
  });

  const { data: customPropertiesData } = useGetCustomPropertiesQuery({
    workspaceId: workspace.id,
    datasourceIds: datasourceIds || [],
  });

  const { data: traitDistributionsData } = useGetTraitDistributionsQuery({
    workspaceId: workspace.id,
    datasourceIds: datasourceIds || [],
  });

  const traits = data?.customer || [];
  const trait: any = traits.find((a) => a.id === traitId) || { displayName: traitId };
  const [searchQuery, setSearchQuery] = useState("");

  const [mergedItems, setMergedItems] = useState<Trait[]>([]);
  const [distributionCountMap, setDistributionCountMap] = useState<DistributionCountMapType>({});

  useEffect(() => {
    if (traitDistributionsData) {
      const map: DistributionCountMapType = {};
      for (const traitDistributiondata of traitDistributionsData) {
        for (const distribution of traitDistributiondata.distributions) {
          const distributionCount = map[distribution.trait.id] || { count: 0, percent: 0, total: 0 };
          distributionCount.total += distribution.total;
          distributionCount.count += distribution.count;
          distributionCount.percent = Math.round((distributionCount.count / distributionCount.total) * 100);
          map[distribution.trait.id] = distributionCount;
        }
      }

      setDistributionCountMap(map);
    }
  }, [traitDistributionsData]);

  useEffect(() => {
    let newMergedItems = [];
    if (data) {
      newMergedItems.push(...data.customer);
    }

    if (customPropertiesData) {
      newMergedItems.push(
        ...customPropertiesData.properties.map((customProperty) => ({
          id: customProperty.property,
          displayName: `${customProperty.displayName || customProperty.property} (${customProperty.type})`,
          dataType: customProperty.type,
          custom: true,
        }))
      );
    }

    if (searchQuery?.trim()) {
      newMergedItems = newMergedItems.filter((m) =>
        ((m as any).display || m.displayName).toLowerCase().includes(searchQuery?.trim().toLowerCase())
      );
    }

    setMergedItems(newMergedItems as any);
  }, [data, customPropertiesData, searchQuery]);

  const labelColor = (percent: number) => {
    if (percent < 35) {
      return "bg-red-200";
    }

    if (percent < 70) {
      return "bg-yellow-200";
    }

    return "bg-green-200";
  };

  return (
    <SearchDropDown
      openWithFocus
      acceptCustomValue
      items={mergedItems}
      item={trait}
      setItem={(traitItemValue) => {
        console.log("new trait", traitItemValue);
        rest.onChange?.({
          property: String(traitItemValue.id || traitItemValue.displayName),
          dataType: traitItemValue.dataType,
          value: "", // Reset value every time a trait is selected
          trait: Boolean(traitItemValue.id) && !traitItemValue.custom, //
        });
      }}
      setQuery={setSearchQuery}
      loading={isLoading}
      className="border-0 bg-indigo-50 px-2 py-1 text-purple-500"
      placeholder="property"
      readOnly={rest.isViewMode}
    >
      {(item) => (
        <div className="flex whitespace-nowrap">
          {item.display || item.displayName}
          {distributionCountMap[item.id] ? (
            <span className="ml-auto">
              <i
                className={`ml-4 ${labelColor(
                  distributionCountMap[item.id].percent
                )} text-grey-800 flex max-w-max items-center justify-center rounded-xl px-3 py-0.5 text-xs leading-4`}
              >
                %{distributionCountMap[item.id].percent}
              </i>
            </span>
          ) : null}
        </div>
      )}
    </SearchDropDown>
  );
  // Return <TSelect {...rest} items={traits} keyField='id' labelField='displayName' className='w-full text-left' value={trait} />;
};

export default TraitSelect;
