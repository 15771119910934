import type { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ArrowDownOnSquareIcon } from "@heroicons/react/20/solid";

import { Button } from "../../../../../components/buttons";
import { Input } from "../../../../../components/inputs";
import type { ExtendedAudienceEdit } from "../../../../../models/extendedAudiences";

type IGeneralExtendedAudienceSettingsProps = {
  extendedAudience?: ExtendedAudienceEdit;
};

const GeneralExtendedAudienceSettings: FunctionComponent<IGeneralExtendedAudienceSettingsProps> = ({
  extendedAudience,
}) => {
  const { t } = useTranslation("data_source_details");

  return (
    <div>
      <h1 className="text-black-100 text-lg font-semibold">{t("settings.general.general")}</h1>
      <div className="flex items-end justify-start">
        <div className="mr-4 w-1/3">
          <Input label={t("settings.general.name")} value={extendedAudience?.name} />
        </div>

        <div className="w-1/3">
          <Button icon={ArrowDownOnSquareIcon}>{t("settings.general.save")}</Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralExtendedAudienceSettings;
