import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { PaginationState } from "../../models/pagination";
import type { DestinationTopbarTab } from "../../models/tabs";

type DestinationsState = {
  ui: {
    tabs: DestinationTopbarTab[];
    pagination: PaginationState;
    searchText: string;
  };
};

export const INITIAL_DESTINATIONS_TABS: DestinationTopbarTab[] = [
  { id: "destination.sync", current: true },
  { id: "destination.list", current: false },
];

export const initialState: DestinationsState = {
  ui: {
    tabs: INITIAL_DESTINATIONS_TABS,
    pagination: {
      pageNo: 0,
      pageSize: 0,
    },
    searchText: "",
  },
};

const destinationsSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    reset: () => initialState,
    setDestinationTabs: (state, action: PayloadAction<DestinationTopbarTab[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        tabs: action.payload,
      },
    }),
    setPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        pagination: action.payload,
      },
    }),
    setSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        searchText: action.payload,
      },
    }),
  },
});

export const { reset, setDestinationTabs, setPagination, setSearchText } = destinationsSlice.actions;

export default destinationsSlice.reducer;
