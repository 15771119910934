import type { FunctionComponent } from "react";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type IModalHeaderProps = {
  closeModal: () => void;
  title: string;
  loading: boolean;
};

const ModalHeader: FunctionComponent<IModalHeaderProps> = ({ closeModal, title, loading }) => (
  <div className="px-4 sm:px-6">
    <div className="flex items-start justify-between">
      <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
      <div className="ml-3 flex h-7 items-center">
        <button
          type="button"
          className="rounded-md bg-white text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-700"
          disabled={loading}
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
);

export default ModalHeader;
