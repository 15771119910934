import type { FunctionComponent } from "react";

import { ActionCondition, MembershipCondition, PropertyCondition } from "..";
import type { ExpressionCondition } from "../../../../models/audiences";

type IExpressionConditionRenderProps = {
  expression?: ExpressionCondition;
  onChange?: (Condition: ExpressionCondition) => void;
  isViewMode?: boolean;
  datasourceIds?: string[];
};

const ExpressionConditionRender: FunctionComponent<IExpressionConditionRenderProps> = ({
  expression,
  onChange,
  isViewMode,
  datasourceIds,
}) => {
  switch (expression?.type) {
    case "MEMBERSHIP": {
      return (
        <MembershipCondition expression={expression} isViewMode={isViewMode} onChange={(exp) => onChange?.(exp)} />
      );
    }

    case "ACTION": {
      return <ActionCondition expression={expression} isViewMode={isViewMode} onChange={(exp) => onChange?.(exp)} />;
    }

    case "PROPERTY": {
      return (
        <PropertyCondition
          expression={expression}
          isViewMode={isViewMode}
          datasourceIds={datasourceIds}
          onChange={(exp) => onChange?.(exp)}
        />
      );
    }

    default: {
      return (
        <div className="flex w-full">
          <p>Expression of type {expression?.type}</p>
        </div>
      );
    }
  }
};

export default ExpressionConditionRender;
